import React from "react";
import { Col, Row, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import Widget from "../../components/Widget/Widget.js";
import s from "./Tables.module.scss";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import HolidayService from "../../services/holiday/holiday.service.js"
import EnumHelper from '../../helper/enum-helper.js'
import { isDate } from "date-fns";

export default class PublicHolidayList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.getPublicHoliday = this.getPublicHoliday.bind(this);

        this.state = {
            year: new Date().getFullYear(),
            holidays: [ {
                "holidayName": "New Year Eve",
                "day": "Monday",
                "dayOfMonth": "1st",
                "month": "January",
                "holidayDate": "2024-01-01T00:00:00",
                "country": "Australia",
                "territory": ""
              }],
              territorys_aus:[],
              territorys_other:[],
              contries:[],
        };    
    };

    componentDidMount() {
        this.getPublicHoliday();
        }

    getPublicHoliday = async () => {
        await HolidayService.getAllHoliday().then(result => {
            this.setState({holidays:result.data.data});
            this.setState({contries:[...new Set(result.data.data.map(item=>item.country))]});
            this.setState({territorys_other:[...new Set(result.data.data
            .filter(data => { return (data.country.toLowerCase() != EnumHelper.HolidayCountry.Australia 
                    && data.territory != "") })
                .map(item=>item.territory))]});
            this.setState({territorys_aus:[...new Set(result.data.data
                .filter(data => { return (data.country.toLowerCase() == EnumHelper.HolidayCountry.Australia 
                    && data.territory != "") })
                .map(item=>item.territory))]});
        }).catch(e => {
        console.log(e);
        }) 
      };

    render() {
        return (
            <div>
                 <Row>
                    <Col>
                        <Row className="mb-4">
                            <Col>
                                <Widget>
                                    <div className="col-12">
                                        <div className={s.tableTitle}>
                                            <div className="headline-2"> {this.state.year} Public Holidays</div>
                                        </div>
                                        <div>  {/* Public Holidays without territory */}
                                            <Table className={`table-striped table-borderless`} responsive>
                                                <tbody>
                                                    {this.state.holidays.filter(data => { return (data.territory == "" && data.country.toLowerCase() == EnumHelper.HolidayCountry.Australia) })
                                                    .map(holiday => (
                                                        <tr key={uuidv4()}>
                                                            <td className="col-7">{holiday.holidayName}</td>
                                                            <td className="col-2">{holiday.day}</td>
                                                            <td className="col-1" dangerouslySetInnerHTML={{__html: holiday.dayOfMonth}}></td>
                                                            <td className="col-2">{holiday.month}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        
                                        <div> {/* Public Holidays with territory */}
                                            {this.state.territorys_aus.map( territory =>  {
                                                return(                       
                                                    <div key={uuidv4()}>
                                                        <div className="my-3 headline-3">{territory}</div>
                                                            <Table className={`table-striped table-borderless`} responsive>
                                                                <tbody>
                                                                    {this.state.holidays
                                                                    .filter(data => { return (data.country.toLowerCase() == EnumHelper.HolidayCountry.Australia && data.territory.toLowerCase() == territory.toLowerCase()) })
                                                                    .map((holiday, i) => (
                                                                        <tr key={uuidv4()}>
                                                                            <td className="col-7">{holiday.holidayName}</td>
                                                                            <td className="col-2">{holiday.day}</td>
                                                                            <td className="col-1" dangerouslySetInnerHTML={{__html: holiday.dayOfMonth}}></td>
                                                                            <td className="col-2">{holiday.month}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <div className="mb-3 small text-muted">
                                            <div>**Not a state-wide public holiday.</div>
                                            <div>The above dates were supplied by respective Government Departments and were correct at the time of printing. Contact the relevant Government Department for any possible late change.</div>
                                        </div>

                                        <div className={s.tableTitle}>
                                            <div className="headline-2"> {this.state.year} International Holidays</div>
                                        </div>
                                        <div>
                                            {this.state.contries.filter(data => { return data.toLowerCase() != EnumHelper.HolidayCountry.Australia }).map( country =>  {
                                                return(                        
                                                    <div key={uuidv4()}>
                                                        <div className="my-3 headline-3" style={{color:" #00abbe"}}>{country} Holidays</div>
                                                            <Table className={`table-striped table-borderless`} responsive> {/* Public Holidays without territory */}
                                                                <tbody>
                                                                    {this.state.holidays
                                                                        .filter(data => { return (data.country.toLowerCase() != EnumHelper.HolidayCountry.Australia 
                                                                            && data.country.toLowerCase() == country.toLowerCase() 
                                                                            && data.territory == "") })
                                                                        .map((holiday, i) => (
                                                                        <tr key={uuidv4()}>
                                                                            <td className="col-7">{holiday.holidayName}</td>
                                                                            <td className="col-2">{holiday.day}</td>
                                                                            <td className="col-1" dangerouslySetInnerHTML={{__html: holiday.dayOfMonth}}></td>
                                                                            <td className="col-2">{holiday.month}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>

                                                            {this.state.territorys_other.map( territory =>  {
                                                                if(this.state.holidays.some(d=>d.country.toLowerCase()==country.toLowerCase() && d.territory.toLowerCase()==territory.toLowerCase() && d.territory!="")){
                                                                    return(                       
                                                                        <div key={uuidv4()}>
                                                                            <div className="my-3 headline-3 ">{territory}</div>
                                                                                <Table className={`table-striped table-borderless`} responsive>
                                                                                    <tbody>
                                                                                        {this.state.holidays
                                                                                        .filter(data => { return (data.country.toLowerCase() == country.toLowerCase() && data.territory.toLowerCase() == territory.toLowerCase()) })
                                                                                        .map((holiday, i) => (
                                                                                            <tr key={uuidv4()}>
                                                                                                <td className="col-7">{holiday.holidayName}</td>
                                                                                                <td className="col-2">{holiday.day}</td>
                                                                                                <td className="col-1" dangerouslySetInnerHTML={{__html: holiday.dayOfMonth}}></td>
                                                                                                <td className="col-2">{holiday.month}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </Table>
                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                        </div>
                                                    )                     
                                                    })}
                                        </div>
                                        <Row className="mb-3 small text-muted">
                                            <Col className="col-12">The above dates were supplied by respective Government Departments and were correct at the time of printing. Contact the relevant Government Department for any possible late change.</Col>
                                        </Row>
                                    </div>
                                </Widget>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}