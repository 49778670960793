
import EnumHelper from './enum-helper'

class UserHelper {
    static IsAdmin() {        
        return localStorage.getItem('role')===EnumHelper.Roles.Admin;
    };

    static IsEmployee() {        
        return  localStorage.getItem('role')===EnumHelper.Roles.Employee;
    };

    static IsPracticeAdmin() {
        return localStorage.getItem('role')===EnumHelper.Roles.PracticeAdmin;
    };

}

export default UserHelper