import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../components/Error/TextError";
import EmployeeService from "./../../services/employee/employee.service";
import PracticeService from "./../../services/practice/practice.service";

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification.js";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import s from "./Tables.module.scss";

//DateRange Picker
import "bootstrap/dist/css/bootstrap.css";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CommonHelper from "../../helper/common-helper";

//react phone input
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const EmployeeModify = () => {
  const { id } = useParams();
  let history = useHistory();
  var curr = new Date();
  curr.setDate(curr.getDate());
  const practiceId = localStorage.getItem("practiceId");

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    practiceId: practiceId,
    dateOfJoining: curr.toISOString().substr(0, 10),
    address: "",
    position: "",
    strenth: "",
    roster: "",
    employeeType: "",
    reportingTo: "",
    holidays: "",
    holidaysDocument: "",
    rosterDocument: "",
    holidayDocumentBase64: "",
    rosterDocumentBase64: "",
    isDoctor:false
  };
  const [employees, setPracticeEmployees] = useState([], false);
  const [practices, setPractices] = useState([], false);

  //Date Range Textbox
  const [holidaysEditor, onHolidayChange] = useState("", false);
  const [editorState, onEditorStateChange] = useState("", false);
  const role = localStorage.getItem("role");
  const practiceName = localStorage.getItem("practiceName");
  // this.state = {
  //     editorState:initialValues.roster
  //   };

  const [setFileHolidays, UploadFileHolidays] = useState([], false);
  const [setFileRoster, UploadFileRoster] = useState([], false);
  const [base64Holidays, setImageBase64Holidays] = useState([], false);
  const [base64Roster, setImageBase64Roster] = useState([], false);
  const [logoPreviewHolidays, setLogoPreviewHolidays] = useState(null);
  const [logoPreviewRoster, setLogoPreviewRoster] = useState(null);
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];

  const validationSchema = Yup.object(
    {
    firstName: Yup.string()
      // .matches(/^[a-zA-Z`']*$/g, "Please enter valid First name")
      .max(50, 'First name has a maximum length of 50 characters')
      .required("First name is required"),
    lastName: Yup.string()
      // .matches(/^[a-zA-Z`']*$/g, "Please enter valid Last name")
      .max(50, 'Last name has a maximum length of 50 characters')
      .required("Last name is required"),
    email: Yup.string()
      .email("Enter valid email")
      .max(50, 'Email has a maximum length of 50 characters')
      .required("Email is required"),
    contactNo:Yup.string()
      .matches(/^[^-\sa-zA-Za-zA-Z!@#$%^&~ _\={}`;\[\]\\':\/"|,.<>?][0-9*()+ -]*$/g, "Please enter valid contact number")
      .max(20, 'Contact number has a maximum length of 20 characters')
      .min(10, 'Contact number has a minimum length of 10 characters'),

    practiceId: Yup.number().min(1,'Practice is required').required("Practice is required"),
    holidaysDocument: Yup.mixed().optional().nullable().notRequired()
      .test("fileSize", "File Size is too large, Max 2MB Allowed.", (value) => {
        if (value!=undefined){

          return CommonHelper.isValidUploadfileSize(value.size);
        }
          return true;
      })
      .test(
        "format",
        "Please upload images and pdf",
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
        ),
    rosterDocument: Yup.mixed().optional().nullable().notRequired()
      .test("fileSize", "File Size is too large, Max 2MB Allowed.", (value) => {
        if (value!=undefined){  return CommonHelper.isValidUploadfileSize(value.size);}
        return true;
      })
      .test(
        "format",
        "Please upload images and pdf",
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
        ),
  });

  const onFileChangeHolidays = async (event) => {
    UploadFileHolidays(event.target.files[0]);

    await CommonHelper.getBase64(event.target.files[0])
      .then((result) => {
        setImageBase64Holidays(result);
        setLogoPreviewHolidays(result);
      })
      .catch((err) => {
      });
  };

  const onFileChangeRoster = async (event) => {
    UploadFileRoster(event.target.files[0]);

    await CommonHelper.getBase64(event.target.files[0])
      .then((result) => {
        setImageBase64Roster(result);
        setLogoPreviewRoster(result);
      })
      .catch((err) => {
      });
  };

  const handleSubmit = async (values) => {


    if (id == 0) {

      if (base64Holidays.length > 0) {
        values.holidaysDocument = setFileHolidays.name;
        values.holidayDocumentBase64 = base64Holidays;
      } else {
        values.holidaysDocument = "";
        values.holidayDocumentBase64 = "";
      }
      if (base64Roster.length > 0) {
        values.rosterDocument = setFileRoster.name;
        values.rosterDocumentBase64 = base64Roster;
      } else {
        values.rosterDocument = "";
        values.rosterDocumentBase64 = "";
      }
      CreateEmployee(values);
    } else {
      if (base64Holidays.length > 0) {
        values.holidaysDocument = setFileHolidays.name;
        values.holidayDocumentBase64 = base64Holidays;
      } else {
        values.holidaysDocument = "";
        values.holidayDocumentBase64 = "";
      }
      if (base64Roster.length > 0) {
        values.rosterDocument = setFileRoster.name;
        values.rosterDocumentBase64 = base64Roster;
      } else {
        values.rosterDocument = "";
        values.rosterDocumentBase64 = "";
      }
      UpdateEmployee(id, values);
    }
  };
  useEffect(() => {
    if (role == "Practice-Admin") {
      practiceEmployees();
      return () => {
        setPractices([]);
      };
    } else {
      practiceEmployees();
      practicesList();
      return () => {
        setPractices([]);
        setPracticeEmployees([]);
      };
    }
  }, []);

  const UpdateEmployee = async (id, values) => {
    values.Holidays = CommonHelper.convertEditorToHtml(holidaysEditor);
    values.roster = CommonHelper.convertEditorToHtml(editorState);
    if (role == "Practice-Admin") {
      values.practiceId = practiceId;
    } else {
      values.practiceId = Number(values.practiceId);
    }

    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

    await EmployeeService.update(id, JSON.stringify(values))
      .then((result) => {

        // toast(
        //   <Notification
        //     type="success"
        //     message="Employee record updated successfully."
        //   />
        // ),

          /* Success Loader Called */
          loader.style.display='none';
          successloader.style.display='block';
          success_message.innerHTML="Employee record updated successfully.";
          let timer =   setInterval(myTimer, 3000);
          function myTimer() {
              successloader.style.display='none';
              clearInterval(timer);  
          }

          history.push("/app/employeelist");
      })
      .catch((e) => {
         /* Fail Loader Called */
         loader.style.display='none';
         failloader.style.display='block';
         fail_message.innerHTML="Employee record not updated successfully.";
         let timer =  setInterval(myTimer, 3000);
         function myTimer() {
             failloader.style.display='none';
             clearInterval(timer);   
         }      

        toast();
      });
  };
  const downloadAttachment = async (type) => {
    let attachmentBase64 = "";

    if (type == 1) {
        attachmentBase64 = logoPreviewHolidays;
    } else
      if (type == 0){ attachmentBase64 = logoPreviewRoster;
    }

    if (attachmentBase64 != "") {
      if (CommonHelper.isFilePreviewPossible(attachmentBase64)) {

        saveAs(attachmentBase64);
      } else {
        CommonHelper.downloadAttachment(attachmentBase64);
      }
    } else {

      return toast(
        <Notification type="warning" message="No attachment found" />
      );
    }
  };
  const CreateEmployee = async (values) => {
    values.Holidays = CommonHelper.convertEditorToHtml(holidaysEditor);
    values.roster = CommonHelper.convertEditorToHtml(editorState);
    if (role == "Practice-Admin") {
      values.practiceId = Number(practiceId);
    } else {
      values.practiceId = Number(values.practiceId);
    }
    var payload = JSON.stringify(values);

    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

    await EmployeeService.create(payload)
      .then((result) => {
        if (result.data.statuscode == 200) {
          // toast(<Notification type="success" message="Employee record saved successfully." />)

           /* Success Loader Called */
           loader.style.display='none';
           successloader.style.display='block';
           success_message.innerHTML="Employee record saved successfully.";
           let timer =   setInterval(myTimer, 3000);
           function myTimer() {
               successloader.style.display='none';
               clearInterval(timer);
           }

          history.push('/app/employeelist')
      }
      else {
          // toast(<Notification type="error" message={result.data.message}/>)

          /* Fail Loader Called */
          loader.style.display='none';
          failloader.style.display='block';
          fail_message.innerHTML=result.data.data;
          let timer =  setInterval(myTimer, 3000);
          function myTimer() {
              failloader.style.display='none';
              clearInterval(timer);
          }

      }
      })
      .catch((e) => {

        /* Fail Loader Called */
        loader.style.display='none';
        failloader.style.display='block';
        fail_message.innerHTML=result.data.data;
        let timer =  setInterval(myTimer, 3000);
        function myTimer() {
            failloader.style.display='none';
            clearInterval(timer);
        }
        
        toast();
      });
  };
  const practiceEmployees = async () => {
    await EmployeeService.getPracticeEmployees(practiceId)
      .then((result) => {
        setPracticeEmployees(result.data.data);
      })
      .catch((e) => {
        toast();
      });
  };
  const practicesList = async () => {
    var payload = { practiceId: id, searchTerm: "", Pagesize: 10, PageNo: 1 };
    await PracticeService.getAllPractices(payload)
      .then((result) => {
        setPractices(result.data.data);
      })
      .catch((e) => {
        toast();
      });
  };

  return (
    <div className="card">
      <h5 className="card-header">{id != 0 ? "Edit" : "Add"} Team Member</h5>
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values,errors, touched, isSubmitting,handleChange, setFieldValue }) => {
            useEffect(() => {
              if (id > 0) {
                EmployeeService.get(id).then((employee) => {
                  const fields = [
                    "firstName",
                    "lastName",
                    "email",
                    "contactNo",
                    "address",
                    "position",
                    "strenth",
                    "employeeType",
                    "reportingTo",
                    "practiceId",
                    "employeeType",
                    // "holidaysDocument",
                    // "rosterDocument",
                    "holidayDocumentBase64",
                    "rosterDocumentBase64",
                    "isDoctor"
                  ];
                  fields.forEach((field) =>
                    setFieldValue(field, employee.data.data[field], false)
                  );
                  setFieldValue("dateOfJoining",employee.data.data["dateOfJoining"].substring(0, 10));
                  setFieldValue('holidayDocumentBase64', employee.data.data['holidayDocumentBase64'], false)

                  setFieldValue('rosterDocumentBase64', employee.data.data['rosterDocumentBase64'], false)
                  onEditorStateChange(
                    CommonHelper.createEditorState(employee.data.data["roster"])
                  );
                  onHolidayChange(
                    CommonHelper.createEditorState(
                      employee.data.data["holidays"]
                    )
                  );

                  setLogoPreviewHolidays(
                    employee.data.data["holidayDocumentBase64"]
                  );

                  setLogoPreviewRoster(
                    employee.data.data["rosterDocumentBase64"]
                  );


                });
              }
            }, []);

            return (
              <Form id="form-employee">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="col-form-label null "
                        htmlFor="firstName"
                      >

                        First Name
                      </label>
                      <Field
                        placeholder="Enter first name"
                        className="form-control"
                        type="text"
                        name="firstName"
                      />
                      <ErrorMessage name="firstName" component={TextError} />
                    </div>
                    <div className="form-group has-validation">
                      <label
                        className="col-form-label null "
                        htmlFor="lastName"
                      >

                        Last Name
                      </label>
                      <Field
                        placeholder="Enter last name"
                        className="form-control"
                        type="text"
                        name="lastName"
                      />
                      <ErrorMessage name="lastName" component={TextError} />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label null " htmlFor="email">

                        Email
                      </label>
                      <Field
                        placeholder="Enter email address"
                        className="form-control"
                        type="text"
                        name="email"
                      />
                      <ErrorMessage name="email" component={TextError} />
                    </div>
                    <div className="form-group">
                      <label
                        className="col-form-label null "
                        htmlFor="contactNo"
                      >
                        Contact Number
                      </label>
                      <ReactPhoneInput country={'au'} value={values.contactNo} onChange={(value, country, e, formattedValue)=>{
                                handleChange(e);
                                setFieldValue("contactNo",formattedValue);}}
                                  inputProps={{ name: 'contactNo', required: true, className:"form-control w-100"}} />
                      <ErrorMessage name="contactNo" component={TextError} />
                    </div>
                    <div className="form-group has-validation">
                      <label
                        className="col-form-label null mr-5"
                        htmlFor="practiceId"
                      >
                        Select Practice
                      </label>
                      <>
                        {role == "Practice-Admin" ? (
                          <>
                            <Field
                              placeholder="Enter Practice"
                              className="form-control"
                              type="text"
                              id="practiceId"
                              name="practiceId"
                              value={practiceName}

                            />
                            <ErrorMessage
                              name="practiceName"
                              component={TextError}
                            />
                          </>
                        ) : (
                          <Field
                            className="form-control"
                            as="select"
                            id="practiceId"
                            name="practiceId"

                            required
                          >
                            <option
                              key={uuidv4()}
                              className="form-control"
                            value="0"
                            >
                              Select Practice
                            </option>
                            {practices.map((option) => (
                              <option
                                key={uuidv4()}
                                className="form-control"
                                value={option.id}
                              >
                                {option.businessName}
                              </option>
                            ))}
                          </Field>
                        )}
                      </>
                      <ErrorMessage name="practiceId" component={TextError} />
                    </div>
                    <div className="form-group">
                      <label
                        className="col-form-label null "
                        htmlFor="dateOfJoining"
                      >
                        Date Of Joining
                      </label>
                      <Field
                        className="form-control"
                        type="date"
                        name="dateOfJoining"
                        id="dateOfJoining"
                      />
                      <ErrorMessage
                        name="dateOfJoining"
                        component={TextError}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        className="col-form-label null "
                        htmlFor="holidays"
                      >
                        Holidays
                      </label>
                      <Editor
                        key={uuidv4()}
                        name="holidays"
                        wrapperClassName="wyswing-wrapper"
                        editorState={holidaysEditor}
                        placeholder="Holidays"
                        toolbar={{
                          options: ["inline", "list"],
                          inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: [
                              "bold",
                              "italic",
                              "underline"                            ],
                          },
                        }}
                        onEditorStateChange={onHolidayChange}
                      />
                    </div>

                    <div className="form-group">
                      <label
                        className="col-form-label null "
                        htmlFor="holidaysDocument"
                      >
                        Upload Document
                      </label>


                      <div className="col-sm-10 d-flex align-items-center">
                        {logoPreviewHolidays != null && logoPreviewHolidays != undefined && logoPreviewHolidays != ""  && (
                          <div className="mr-3">
                            <img
                              src={logoPreviewHolidays}
                              alt="Preview"
                              width="50px"
                              height="50px"
                            />
                              <Link
                        to="#"
                        onClick={() => downloadAttachment(1)}
                        title="Attachments"
                        className={s.blackColor}
                      >
                        <i className="fa fa-download"></i>
                      </Link>
                          </div>
                        )}
                        <input
                          type="file"
                          accept="image/jpeg,image/jpg,image/png,application/pdf"
                          name="holidaysDocument"
                          onChange={(event) => {
                            setFieldValue(
                              "holidaysDocument",
                              event.currentTarget.files[0]
                            ),
                              onFileChangeHolidays(event);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="col-form-label null "
                        htmlFor="holidaysDocument"
                      ></label>
                      <div className="col-sm-10 d-flex align-items-center">
                        <ErrorMessage
                          name="holidaysDocument"
                          component={TextError}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-form-label null " htmlFor="address">
                        Address
                      </label>
                      <Field
                        placeholder="Enter address"
                        as="textarea"
                        className="form-control"
                        type="text"
                        name="address"
                      />
                      <ErrorMessage name="address" component={TextError} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label null" htmlFor="position">
                        Position
                      </label>
                      <Field
                        placeholder="Enter position"
                        className="form-control"
                        type="text"
                        name="position"
                      />
                      <ErrorMessage name="position" component={TextError} />
                    </div>
                    <div className="form-group">
                      <label
                        className="col-form-label null"
                        htmlFor="reportingTo"
                      >
                        Reporting To
                      </label>
                      <Field
                        className="form-control"
                        as="select"
                        id="reportingTo"
                        name="reportingTo"
                      >
                        <option
                          key={uuidv4()}
                          className="form-control"
                          value=""
                        >
                          Select Employee
                        </option>
                        {employees.map((option) => (
                          <option
                            key={uuidv4()}
                            className="form-control"
                            value={option.id}
                          >
                            {option.firstName}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="reportingTo" component={TextError} />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label null " htmlFor="roster">
                        Roster
                      </label>
                      <Editor
                        key={uuidv4()}
                        name="roster"
                        wrapperClassName="wyswing-wrapper"
                        editorState={editorState}
                        placeholder="Enter roster details"
                        toolbar={{
                          options: ["inline", "list"],
                          inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: [
                              "bold",
                              "italic",
                              "underline",
                              "strikethrough",
                            ],
                          },
                        }}
                        onEditorStateChange={onEditorStateChange}
                      />



                      <ErrorMessage name="roster" component={TextError} />
                    </div>

                    <div className="form-group">
                      <label
                        className="col-form-label null "
                        htmlFor="rosterDocument"
                      >
                        Upload Document
                      </label>

                      <div className="col-sm-10 d-flex align-items-center">
                        {logoPreviewRoster != null && logoPreviewRoster != undefined && logoPreviewRoster != ""  && (
                          <div className="mr-3">
                            <img
                              src={logoPreviewRoster}
                              alt="Preview"
                              width="50px"
                              height="50px"
                            />
                                <Link
                        to="#"
                        onClick={() => downloadAttachment(0)}
                        title="Attachments"
                        className={s.blackColor}
                      >
                        <i className="fa fa-download"></i>
                      </Link>
                          </div>
                        )}
                        <input
                          type="file"
                          accept="image/jpeg,image/jpg,image/png,application/pdf"
                          name="rosterDocument"
                          onChange={(event) => {
                            setFieldValue(
                              "rosterDocument",
                              event.currentTarget.files[0]
                            ),
                              onFileChangeRoster(event);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="col-form-label null "
                        htmlFor="rosterDocument"
                      ></label>
                      <div className="col-sm-10 d-flex align-items-center">
                        <ErrorMessage
                          name="rosterDocument"
                          component={TextError}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-form-label null " htmlFor="strenth">
                        Strength
                      </label>
                      <Field
                        placeholder="Enter strength details"
                        as="textarea"
                        className="form-control"
                        type="text"
                        name="strenth"
                      />
                      <ErrorMessage name="strenth" component={TextError} />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="employeeType">
                        Employee Type
                      </label>
                      <Field
                        className="form-control"
                        as="select"
                        id="employeeType"
                        name="employeeType"
                      >
                        <option
                          key={uuidv4()}
                          className="form-control"
                          value=""
                        >
                          Select Employee
                        </option>
                        <option
                          key={uuidv4()}
                          className="form-control"
                          value="1"
                        >
                          Full Time
                        </option>
                        <option
                          key={uuidv4()}
                          className="form-control"
                          value="2"
                        >
                          Part Time
                        </option>
                        <option
                          key={uuidv4()}
                          className="form-control"
                          value="3"
                        >
                          Casual
                        </option>
                      </Field>
                      <ErrorMessage name="employeeType"  component={TextError} />
                    </div>

                    <div className="form-group checkbox checkbox-primary ">
                    <Field name="isDoctor" className="styled" type="checkbox" />
                    <label for="isDoctor"> IsDoctor? </label>
                    </div>

                  </div>
                </div>
                <div className="form-buttons">
                  <button
                    className="mr-3 btn btn-primary"
                    type="submit"
                    id="btnSubmit"
                  >
                    Save
                  </button>
                  <Link
                    to="/app/employeelist"
                    className="mr-3 btn btn-secondary"
                  >
                    Cancel
                  </Link>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
export default EmployeeModify;
