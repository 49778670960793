import React, { useMemo, useState, useRef,useEffect } from "react";
import countryList from 'react-select-country-list';
import 'react-phone-input-2/lib/style.css'
import '../autoPracticeRegister/subscriptionPlan.css'
import StripeService from "../../services/stripe/stripe.service";
import CouponCodeService from "./../../services/couponcode/couponcode.service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSquareCheck} from '@fortawesome/free-solid-svg-icons'



const SubscriptionPlan = () => {
const txtCouponCode = useRef("");
const txtCouponCodeMonth = useRef("");
const [couponSuccessmessageYear,setSuccessMessageYear] = useState("");
const [couponFailmessageYear,setFailMessageYear] = useState("");
const [couponSuccessmessageMonth,setSuccessMessageMonth,tempmsg] = useState("");
const [couponFailmessageMonth,setFailMessageMonth] = useState("");
const [trialdays,setTrialDays]=useState(0);
const Countries = useMemo(() => countryList().getData(), [])
var email="";
var customerid="";
var practice=null;
var practiceValues = {
    businessName: "",
    address: "",
    abnNo: "",
    email: "",
    phoneNo: "",
    website: "",
    facebookUrl: "",
    linkedinUrl: "",
    whatsappUrl: "",
    instagramUrl: "",
    file: "",
    logo: "",
    isBlock: false,
  };

  var employeeValues = {
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    practiceId: "",
    dateOfJoining: new Date(),
    address: "",
    position: "",
    strenth: "",
    isDeleted:""
  };
 

useEffect(() => {
if(JSON.stringify(localStorage.getItem("Practice"))  == "null"){
   // window.location = "/PracticeRegister";
}else{
    practice = JSON.parse(localStorage.getItem("Practice"));
    email = practice.email;
    practiceValues.businessName=practice.practicename;
    practiceValues.address=practice.address+", "+practice.city+", "+practice.state+", "+practice.postalcode+", "+practice.country;
    practiceValues.abnNo=practice.abnnumber;
    practiceValues.email=practice.email;
    practiceValues.phoneNo=practice.phoneno;
    practiceValues.isBlock=true;

   employeeValues.firstName= practice.firstname,
   employeeValues.lastName= practice.lastname,
   employeeValues.email= practice.email,
   employeeValues.contactNo= practice.phoneno,
   employeeValues.dateOfJoining= new Date(),
   employeeValues.address= practice.address+", "+practice.city+", "+practice.state+", "+practice.postalcode+", "+practice.country;
   employeeValues.position= "Practice Admin",
   employeeValues.strenth= "Management",
   employeeValues.isDeleted= true
}
});

const ValidateCodeMonth = async ()=>{
   
    await  CouponCodeService.ValidateCode(txtCouponCodeMonth.current.value).then((result) => {
       
        if(result.data.status == "success" &&  result.data.data.id > 0){
            setTrialDays(result.data.data.trialDays);
        
        setSuccessMessageMonth("Congratulations! You have received " + result.data.data.trialDays + " Days free trial.");
        setFailMessageMonth("");
        }
        else{
            setFailMessageMonth("Invalid Coupon Code");
            setTrialDays(30);
            setSuccessMessageMonth("");
        }
      });

};
const ValidateCodeYear = async ()=>{
   
    await CouponCodeService.ValidateCode(txtCouponCode.current.value).then((result) => {
       
        if(result.data.status == "success" &&  result.data.data.id > 0){
            setTrialDays(result.data.data.trialDays);
           
        setSuccessMessageYear("Congratulations! You have received " + result.data.data.trialDays + " Days free trial.");
        setFailMessageYear("");
        }
        else{
            setFailMessageYear("Invalid Coupon Code");
            setTrialDays(30);
            setSuccessMessageYear("");
        }
      });

};



const createSubscription = async (priceId) => {
   await StripeService.createTempPractice(JSON.stringify(practiceValues)).then((res) => {
    employeeValues.practiceId=Number(JSON.stringify(res.data.data));
    localStorage.setItem("tempPracticeId",employeeValues.practiceId);
    localStorage.setItem("tempPracticeEmail",JSON.stringify(practiceValues.email));
      })
      .catch((e) => {
      });
      


      await StripeService.createTempEmployee(JSON.stringify(employeeValues)).then((res) => {
          })
          .catch((e) => {
            alert(e);
          });

       
    StripeService.createcheckoutsession(JSON.stringify({priceId,email,customerid,trialdays})).then((result) => {
    window.open(result.data.url,"_self");
      })
      .catch((e) => {
        alert(e);
      });
    }



return(
    <div className="card mx-auto subcontainer">
            <h5 className="card-header text-center">The Daily Huddle</h5>
            <div className="card-body">
                <div className="text-center">
                <p className="theme-color">Please select subscription plan as per your requirement, you can cancel anytime</p>
                </div>

                <div className="row justify-content-center mt-5">
                <div className="col-md-4">
                    <div className="card text-center sub-border">
                        <div className="card-header bold sub-border card-header">
                            Monthly
                        </div>
                        <div className="card-body mx-auto">
                            <p className="bold">Current Promotion</p>
                            <p className="currency" ><span className="price">$</span>6.49 <span className="price">/ day</span></p>
                            <p className="mt-2">Billed Monthly</p>
                            <p className="mt-2 bold" > </p>
                            <div className="text-left">
                            <p className="card-text mt-3" > <FontAwesomeIcon icon={faSquareCheck} style={{color: "#00abbe",}} />  Full access to website and App</p>
                            <p className="card-text mt-3"> <FontAwesomeIcon icon={faSquareCheck} style={{color: "#00abbe",}} />  365 days of daily huddle templates</p>
                            <p className="card-text mt-3"> <FontAwesomeIcon icon={faSquareCheck} style={{color: "#00abbe",}} />  Practice management tools and templates  </p>
                            <p className="card-text mt-3"> <FontAwesomeIcon icon={faSquareCheck} style={{color: "#00abbe",}} />  Social media and marketing </p>
                            <p className="card-text mt-3"> <FontAwesomeIcon icon={faSquareCheck} style={{color: "#00abbe",}} />  Reviews and Reminders - SMS services </p>
                            </div>
                            <br/>
                      {/* <input type="text" name="txtCouponCode" placeholder="Enter Coupon Code"  ref={txtCouponCodeMonth}></input>
                       <input type="button" onClick={()=> ValidateCodeMonth()}  className="btn btn-primary ml-5 p-1 pr-2 pl-2" style={{width:"130px"}} value="APPLY CODE" /> 
                       <label  name="lblCouponSucessmessageMonth" className="mt-3 " style={{color:"#037a87"}}  ><b>{couponSuccessmessageMonth}</b></label>
                       <label  name="lblCouponFailmessageMonth" className="mt-3 " style={{color:"red"}} ><b>{couponFailmessageMonth}</b></label> */}
                       <div>
                         <input type="button" onClick={() => createSubscription("price_1Pfjp8IWz02ZIyO4ZfWAUdvo")} className="btn btn-primary mt-2" value="Subscribe" />
                        </div>
                        </div>
                    </div>
                </div>
                                    
                    <div className="col-md-4">
                    <div className="card text-center sub-border">
                        <div className="card-header bold sub-border">
                            Yearly
                        </div>
                        <div className="card-body mx-auto">
                            <p className="bold">Current Promotion</p>
                            <p className="currency" ><span className="price">$</span>6.49 <span className="price">/ day</span></p>
                            <p className="mt-2">Billed Yearly</p>
                            <p className="mt-2 bold" >Save 10% when you pay yearly</p>
                            <div className="text-left">
                            <p className="card-text mt-3" >   <FontAwesomeIcon icon={faSquareCheck} style={{color: "#00abbe",}} /> Full access to website and App</p>
                            <p className="card-text mt-3">    <FontAwesomeIcon icon={faSquareCheck} style={{color: "#00abbe",}} />  365 days of daily huddle templates</p>
                            <p className="card-text mt-3">    <FontAwesomeIcon icon={faSquareCheck} style={{color: "#00abbe",}} />  Practice management tools and templates  </p>
                            <p className="card-text mt-3">    <FontAwesomeIcon icon={faSquareCheck} style={{color: "#00abbe",}} />  Social media and marketing </p>
                            <p className="card-text mt-3">    <FontAwesomeIcon icon={faSquareCheck} style={{color: "#00abbe",}} />  Reviews and Reminders - SMS services </p>
                            </div>
                      <br/>
                      {/* <input type="text" name="txtCouponCode" placeholder="Enter Coupon Code"  ref={txtCouponCode}></input>
                       <input type="button" onClick={()=>ValidateCodeYear()}  className="btn btn-primary ml-5 p-1 pr-2 pl-2" style={{width:"130px"}} value="APPLY CODE" /> 
                       <label  name="lblCouponSucessmessage" className="mt-3 " style={{color:"#037a87"}} ><b>{couponSuccessmessageYear}</b></label>
                       <label  name="lblCouponFailmessage" className="mt-3 " style={{color:"red"}} ><b>{couponFailmessageYear}</b></label>*/}
                       <div>
                            <input type="button" onClick={() => createSubscription("price_1PfjqlIWz02ZIyO44FLAqANW")} className="btn btn-primary mt-2 " value="Subscribe" /> 
                            </div> 
                             </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
);

};
 export default SubscriptionPlan;