import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Col, Row, Table,} from "reactstrap";
import ReactPaginate from 'react-paginate';
import Widget from "../../components/Widget/Widget.js";
import PatientService from "../../services/patient/patient.service.js"
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import s from "./Tables.module.scss";
import { Link } from "react-router-dom";
import PreviewModal from "../pdfPreview/preview.js";
import PdfHelper from "../../helper/pdf-helper.js";
import Spinner from "react-bootstrap/Spinner";

// Modal Popup
import PatientSearchModal from "../patient/patient-search-popup.js"

import CommonHelper from "../../helper/common-helper.js";
import labWorkService from "../../services/LabWork/LabWorkService.js";


const LabWorkList = function () {

    const [response, setLabWorkList] = useState([], false);
    const [totalCount, setTotalCount] = useState([], false);
    const [showloader, setLoading] = useState([], false);
    const [remountComponent, setRemountComponent] = useState(0);
    const [LabWorkTypes, setLabWorkTypes] = useState([]);

    let pageSize = 10;
    let pageNo = 1;
    const role = localStorage.getItem('role');
    const practiceId = localStorage.getItem('practiceId');

    const [pdfURl, setPdfUrl ] = useState('', false);
    const [showModel , setModel] = useState(false);

    // const LabWorkTypeslist=[{
    //     "type": "Broken Tooth",
    //     "id": 1
    // },
    // {
    //     "type": "Check Up/Scale Clean",
    //     "id": 2
    // }];

    /* Patient Search Input Fields */
    const txtFirstName = useRef("");
    const txtLastName = useRef("");
    const txtPatientNo = useRef("");
    const txtLabId = useRef(0);
    const txtMobile= useRef("");
    const txtDoctorId = useRef(0);
    const txtTeamMemberId = useRef(0);
    const txtLabWorkTypeId = useRef(0);


    useEffect(() => {
        getLabWorkTypes();
        getLabWorks();
        return () => {
            setLabWorkList([]); // This worked for me
        };
    }, []);

    const handlePageClick = (data) => { 
        let selected = data.selected + 1;
        pageNo = selected;
        getLabWorks();
    };

    const getLabWorkTypes = () => {
        labWorkService.getlabworktypes().then((res) => {
            setLabWorkTypes(res.data.data);
        })
        .catch((e) => {
            console.log(e);
        })
     };

     const GetDateTimeinLocal = (date)  =>{
        if(date != ""){
          // convert utc date to local date time
          let Localdate=  CommonHelper.getISODateTimeinLocalTZ(new Date(date.replace("T"," ")));

          return CommonHelper.ToDateFormat(Localdate,"DD-MM-YYYY HH:mm:ss");
          
        }
   }

    const getLabWorks = async () => {
        /* Reset Selected Page to 1 for Search and Reset */
        // const listItems = document.querySelectorAll('.page-link');
        // for (let i = 0; i < listItems.length; i++) {
        //     if (listItems[i].textContent == 1){
        //         listItems[i].click();
        //     }
        // }

        var payload = { 
                        "searchTerm": "", 
                        "practiceId" : practiceId,
                        "pageSize": pageSize, 
                        "pageNumber": pageNo, 
                        "lastName": txtLastName.current.value,
                        "firstName": txtFirstName.current.value,
                        "doctorId": (txtDoctorId.current.value == ""|| txtDoctorId.current.value == null) ? 0 :parseInt(txtDoctorId.current.value),    
                        "teamMemberId": (txtTeamMemberId.current.value == ""|| txtTeamMemberId.current.value == null) ? 0 :parseInt(txtTeamMemberId.current.value),
                        "mobileNo": txtMobile.current.value,
                        "labId": (txtLabId.current.value == ""|| txtLabId.current.value == null) ? 0 :parseInt(txtLabId.current.value),
                        "patientNo": txtPatientNo.current.value,
                        "labWorkTypeId": (txtLabWorkTypeId.current.value == ""|| txtLabWorkTypeId.current.value == null) ? 0 :parseInt(txtLabWorkTypeId.current.value),
                    }
                    //alert(JSON.stringify(payload));

        setLoading( true );

        await new Promise(function (resolve, reject) {
            labWorkService.search(payload).then(result => {
                resolve(result);
                setLabWorkList(result.data.data.labWorkList);
                setTotalCount(Math.ceil(parseInt(result.data.data.totalRecords) / pageSize));
                pageNo = payload.pageNumber;
                setLoading(  false );
            }).catch(e => {
               console.log(e);
            })
        });
    }

    const deleteRecord = async (id) => {
        const result = await confirm("Are you sure? \nYou will not be able to recover this labwork!");
        if(result){
            let loader = document.getElementById('loader');
            let successloader = document.getElementById('successloader');
            let failloader = document.getElementById('failloader');
            let success_message = document.getElementById('success-message');
            let fail_message = document.getElementById('fail-message');
            loader.style.display='block';/* Loader Section Activited */

            await labWorkService.delete(id).then(result => {
                /* Success Loader Called */
                loader.style.display='none';
                successloader.style.display='block';
                success_message.innerHTML="LabWork record deleted successfully.";
                let timer =   setInterval(myTimer, 3000);
                function myTimer() {
                    successloader.style.display='none';
                    clearInterval(timer);  
                }
                //history.push('/app/patientlist');
                getLabWorks();
            })
            .catch(e => { 
                /* Fail Loader Called */
                loader.style.display='none';
                failloader.style.display='block';
                fail_message.innerHTML="Patient record not deleted successfully.";
                let timer =  setInterval(myTimer, 3000);
                function myTimer() {
                    failloader.style.display='none';
                    clearInterval(timer);   
                }     
                toast(console.log(e)); 
            });
        }
    };   

    /* Function for reset the modal popup */
    const resetSearch = async () => {

        /*Reset All Search Fields */
        txtFirstName.current.value = "";
        txtLastName.current.value = "";
        txtDoctorId.current.value=0;
        txtTeamMemberId.current.value=0;
        txtMobile.current.value="";
        txtLabId.current.value=0;
        txtPatientNo.current.value="";
        txtLabWorkTypeId.current.value=0;
       // setRemountComponent(Math.random());
       getLabWorks();
    }


    // let [patientId, setState] = useState(" ");

    // function handleState(patientId) {
    //     setState(patientId);
    //  }

    return (
        <div id = "main">      
            <Row>
                <Col>
                    <Row className="mb-4">
                        <Col>
                            <Widget>
                                <div className={s.tableTitle}>
                                    <div className="headline-2">LabWork</div>
                                    <div className="d-flex">                                        
                                        <a onClick={(e) => PdfHelper.printPage()}><img src={printerIcon} alt="Printer" /></a>
                                    <a className="mx-2 ppreview" onClick={(e) => { handleOnPdfPreview(e); }}><PreviewModal isOpen={showModel} url={pdfURl} /> Preview</a>
                                    <a onClick={() => PdfHelper.downloadPdf(document.getElementById('main'), "Team Members")}><img src={cloudIcon} alt="Download" /></a>
                                    </div>
                                </div>
                                <div style={{padding:"24px 24px"}}>
                                    <Row>
                                        <Col className="col-2">
                                            <input type="text" ref={txtFirstName} name="txtFirstName" className="form-control" placeholder="Firstname" />
                                        </Col> 
                                        <Col className="col-2">
                                            <input type="text" ref={txtLastName} name="txtLastName" className="form-control" placeholder="Lastname" />
                                        </Col> 
                                        {/* <Col className="col-2">
                                            <select ref={txtSex} name="txtSex" className="form-control">
                                                <option value="">Sex</option>
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                                <option value="O">Other</option>
                                            </select>
                                        </Col>  */}
                                        {/* <Col className="col-2">
                                            <input type="date" ref={txtDateOfBirth} name="txtDateOfBirth" className="form-control" />
                                        </Col> */}
                                        <Col className="col-4">
                                        {role === "Employee" ? <div></div> :   
                                            <div> 
                                                <Link to="/app/LabWork/0" title="Add" className="btn btn-primary" style={{padding:"8px 32px",marginBottom: "10px"}}>Add LabWork </Link>
                                            </div>
                                        }
                                        </Col>
                                    </Row>

                                    <Row style={{paddingTop: "10px"}}> 
                                        <Col className="col-2">
                                            <input type="text" ref={txtPatientNo} name="txtPatientNo" className="form-control" placeholder="PatientNO" />
                                        </Col> 
                                        <Col className="col-2">
                                            <input type="text" ref={txtMobile} name="txtMobile" className="form-control" placeholder="MobileNo" />
                                        </Col> 
                                        <Col className="col-2">
                                            <select ref={txtLabWorkTypeId} name="txtLabWorkTypeId" className="form-control" >
                                                <option value="">LabWorkType</option>
                                                {LabWorkTypes.map((item) => (
                                                <option className="form-control" value={item.id}>
                                                    {item.type}
                                                </option>
                                                ))}
                                            </select>
                                        </Col> 
                                        <Row style={{justifyContent:"space-evenly"}}>
                                            <Col className="col-5">
                                                <button className="btn btn-secondary" title="Search" onClick={getLabWorks}  value="Search" >Search</button>
                                            </Col>
                                            <Col className="col-5" style={{marginLeft: "4px"}}>
                                                <input className="btn btn-secondary pull-left" title="Reset" onClick={resetSearch}  type="button" value="Reset" />
                                            </Col>
                                        </Row>
                                    </Row>
                                </div>
                                    
                                {/* Patient Search Modal Popup Component */}
                                {/* <div className={s.tableTitle}>
                                    <Col>
                                        <PatientSearchModal change = {handleState}></PatientSearchModal>
                                    </Col>  
                                </div> */}

                            <div key={remountComponent}>
                                <div className="widget-table-overflow">
                                    <Table className={`table-striped table-borderless`} responsive>
                                        <thead>
                                            <tr>
                                                <th className="w-20">PATIENT NAME</th>
                                                <th className="w-20">LW SENT DATE</th>
                                                {/* <th className="w-20">LW PARKED DATE</th> */}
                                                <th className="w-20">LW ARRIVAL DATE</th>
                                                <th className="w-20">APPOINTMENT DATE</th>
                                                <th className="w-20">NOTES</th>
                                                <th className="w-20">ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {showloader ? (
                                                <tr>
                                                    <td rowSpan="4" colSpan="7">
                                                    <div className="text-center py-5">
                                                        <Spinner animation="border" />
                                                    </div>
                                                    </td>
                                                </tr>
                ) :   response.length == 0 ? <tr><td colSpan="7"><div className='alert alert-secondary text-primary m-3 color' >No records found</div></td></tr> : response
                                                .map(item => (
                                                    <tr key={uuidv4()}>
                                                        <td>{item.patientName}</td>
                                                        {/* <td>{CommonHelper.ToDateFormat(item.lWSentDate,"DD-MM-YYYY HH:mm:ss")}</td> */}
                                                        <td>{item.lwSentDate ? GetDateTimeinLocal(item.lwSentDate) : ""}</td>
                                                        {/* <td>{item.lwRecievedDate ? GetDateTimeinLocal(item.lwRecievedDate) : ""}</td> */}
                                                        <td>{item.lwArrivedDate ? GetDateTimeinLocal(item.lwArrivedDate):""}</td>
                                                        <td>{item.appointmentBooked ? GetDateTimeinLocal(item.appointmentBooked):""}</td>
                                                        <td>{item.notes}</td>
                                                     
                                                        {role === "Employee" ? 
                                                        <td>
                                                            {/* <Link to={"/app/patient-view/" + item.id} title="View" className={s.blackColor}><i className="fa fa-eye"></i></Link> */}
                                                        </td> : 
                                                            <td>{/*<Link to={"/app/patient-view/" + item.id} title="View" className={s.blackColor}><i className="fa fa-eye"></i></Link>&nbsp; */}
                                                            <Link to={"/app/LabWork/" + item.id} title="Edit" className={s.blackColor}><i className="fa fa-edit"></i></Link>&nbsp;
                                                            <Link to="#" onClick={()=>deleteRecord(item.id)} className={s.blackColor}><i className="fa fa-trash"></i></Link></td>}
                                                    </tr>
                                                ))}
                                          
                                        </tbody>
                                    </Table>

                                    <Row>
                                        <Col className="ml-2"></Col>
                                        <Col className="col-10">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=" >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={pageSize}
                                                pageCount={Math.ceil(totalCount)}
                                                previousLabel="< "
                                                renderOnZeroPageCount={null}
                                                containerClassName={'pagination pagination-sm'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'previous'}
                                                previousLinkClassName={'page-item page-link'}
                                                nextClassName={'next'}
                                                nextLinkClassName={'page-item page-link'}
                                                activeClassName={'active'}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            </Widget>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
    
}
export default LabWorkList;
