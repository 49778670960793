import React  from 'react';
import {Modal} from 'react-bootstrap'

const previewModal = (props) => {    
  return (
    <Modal show={props.isOpen} dialogClassName="fullscreen-modal" animation={false} fullscreen={true}>   
      <Modal.Header closeButton>
        Preview
      </Modal.Header>
        <Modal.Body>
          <embed style={{ height: "93vh" , width: "100%"}} src ={"data:application/pdf;base64, "+props.url}></embed>
        </Modal.Body>
    </Modal>
  )
}


export default previewModal;