import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { v4 as uuidv4 } from "uuid";

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import TextError from "../../components/Error/TextError";
import CategoryService from "./../../services/category/category.service";
import ResourceService from "./../../services/resource/resource.service";
import { Link, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import CommonHelper from "../../helper/common-helper";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification.js";

const ResourceModify = () => {
  let history = useHistory(); // declare here, inside a React component.
  var curr = new Date();
  curr.setDate(curr.getDate());
  const [setFile, UploadFile] = useState([], false);
  const [categories, Category] = useState([], false);
  let { id, resourceId } = useParams();
  const practiceId = localStorage.getItem("practiceId");
  const isAddMode = !resourceId;
  const [base64, setImageBase64] = useState([], false);
  const [logoPreview, setLogoPreview] = useState(null);

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];

  const initialValues = {
    title: "",
    dateOfUpload: curr.toISOString().substr(0, 10),
    category: "",
    documentName: "",
    file: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    dateOfUpload: Yup.string().required("Date of Upload is required"),
    category: Yup.string().required("Category is required"),
    //uploadPerformance: Yup.string().required("Required"),
    documentName: Yup.mixed().nullable().notRequired().test(
      "fileSize",
      "File Size is too large, Max 2MB Allowed.",
      (value) => {
        if (value!=undefined) return CommonHelper.isValidUploadfileSize(value.size);
        return true;
      }
    ),
  });
  const onFileChange = async (event) => {
    UploadFile(event.target.files[0]);

    await CommonHelper.getBase64(event.target.files[0])
      .then((result) => {
        setImageBase64(result);
        setLogoPreview(result);
      })
      .catch((err) => {
       console.log(err);
      });
  };
  const handleSubmit = async (values) => {
    if (resourceId == 0) {
      if (base64.length > 0) {
        values.documentName = setFile.name;
        values.file = base64;
      } else {
        values.documentName = "";
        values.file = "";
      }
      createResources(values);
    } else {
      if (base64.length > 0) {
        values.documentName = setFile.name;
        values.file = base64;
      } else {
        values.documentName = "";
        values.file = "";
      }
      updateResources(values, resourceId);
    }
  };
  const createResources = async (values) => {
    var payload = JSON.stringify(values);

    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

    await ResourceService.create(payload, practiceId, 0)
      .then((result) => {
        // toast(
        //   <Notification type="success" message="Resources saved sucessfully." />
        // );

        /* Success Loader Called */
        loader.style.display='none';
        successloader.style.display='block';
        success_message.innerHTML="Resources saved sucessfully.";
        let timer =   setInterval(myTimer, 3000);
        function myTimer() {
            successloader.style.display='none';
            clearInterval(timer);  
        }

        history.push("/app/resourcelist");
      })
      .catch((e) => {
         /* Fail Loader Called */
         loader.style.display='none';
         failloader.style.display='block';
         fail_message.innerHTML="Resources not saved sucessfully.";
         let timer =  setInterval(myTimer, 3000);
         function myTimer() {
             failloader.style.display='none';
             clearInterval(timer);   
         }     

        toast(console.log(e));
      });
  };
  const updateResources = async (values) => {
    values.resourceId = resourceId;
    /*values.practiceId = practiceId;*/
    var payload = JSON.stringify(values);

    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

    await ResourceService.update(payload, practiceId, resourceId)
      .then((result) => {
        // toast(
        //   <Notification type="success" message="Resources updated sucessfully." />
        // );

        /* Success Loader Called */
        loader.style.display='none';
        successloader.style.display='block';
        success_message.innerHTML="Resources updated sucessfully.";
        let timer =   setInterval(myTimer, 3000);
        function myTimer() {
            successloader.style.display='none';
            clearInterval(timer);  
        }

        history.push("/app/resourcelist");
      })
      .catch((e) => {
         /* Fail Loader Called */
         loader.style.display='none';
         failloader.style.display='block';
         fail_message.innerHTML="Resources not updated sucessfully.";
         let timer =  setInterval(myTimer, 3000);
         function myTimer() {
             failloader.style.display='none';
             clearInterval(timer);   
         }     

        toast(console.log(e));
      });
  };

  const fetchCategories = async () => {
    await CategoryService.getAll()
      .then((result) => {
        Category(result.data.data);
      })
      .catch((e) => {
        toast(console.log(e));
      });
  };

  return (
    <div className="card">
      <h5 className="card-header">Resources</h5>
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting, setFieldValue }) => {
            useEffect(() => {
              fetchCategories();
              if (resourceId > 0) {
                ResourceService.get(resourceId).then((resource) => {
                  const fields = ["title", "category", "documentName"];
                  fields.forEach((field) =>
                    setFieldValue(field, resource.data.data[field], false)
                  );
                  setFieldValue("dateOfUpload",resource.data.data["dateOfUpload"].substring(0, 10));
                  setLogoPreview(resource.data.data["documentBase64"]);

                });
              }
              return () => {
                Category([]); // This worked for me
              };
            }, []);
            return (
              <Form id="form-performance">
                <div className="row1">
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="title">
                      Title
                    </label>
                    <div className="col-sm-10">
                      <Field
                        placeholder="Enter title"
                        className="form-control"
                        type="text"
                        name="title"
                        id="title"
                      />
                      <ErrorMessage name="title" component={TextError} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-2 col-form-label"
                      htmlFor="dateOfUpload"
                    >
                      Date of Upload
                    </label>
                    <div className="col-sm-10">
                      <Field
                        className="form-control"
                        type="date"
                        name="dateOfUpload"
                        id="dateOfUpload"
                      />
                      <ErrorMessage name="dateOfUpload" component={TextError} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-2 col-form-label"
                      htmlFor="category"
                    >
                      Select Category
                    </label>
                    <div className="col-sm-10">
                      <Field
                        className="form-control"
                        as="select"
                        id="category"
                        name="category"
                      >
                        <option
                          key={uuidv4()}
                          className="form-control"
                          value=""
                        >
                          Select Category
                        </option>
                        {categories.map((option) => (
                          <option
                            key={uuidv4()}
                            className="form-control"
                            value={option}
                          >
                            {option}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="category" component={TextError} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-2 col-form-label"
                      htmlFor="documentName"
                    >
                      Upload Document
                    </label>
                    <div className="col-sm-10 d-flex align-items-center">
                      {logoPreview != null && logoPreview != undefined && logoPreview != "" && (
                        <div className="mr-3">
                          <img
                            src={logoPreview}
                            alt="Preview"
                            width="50px"
                            height="50px"
                          />
                        </div>
                      )}
                      <input
                        type="file"
                        name="documentName"
                        onChange={(event) => {
                          setFieldValue(
                            "documentName",
                            event.currentTarget.files[0]
                          ),
                            onFileChange(event);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-2 col-form-label"
                      htmlFor="documentName"
                    ></label>
                    <div className="col-sm-10 d-flex align-items-center">
                      <ErrorMessage name="documentName" component={TextError} />
                    </div>
                  </div>
                </div>
                <div className="form-buttons offset-md-2">
                  <button
                    className="mr-3 btn btn-primary"
                    type="submit"
                    id="btnSubmit"
                  >
                    Save
                  </button>
                  <Link
                    to={"/app/resourcelist"}
                    className="mr-3 btn btn-secondary"
                  >
                    
                    Cancel
                  </Link>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
export default ResourceModify;
