import React, { useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../components/Error/TextError";
import MarketingServices from "./../../services/marketing/marketing.service";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom'; 
import { toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification.js";

const Contact = () => {

    const { id } = useParams();

    let history = useHistory(); 
    var curr = new Date(); curr.setDate(curr.getDate());
    
    const initialValues = { marketingDate: curr.toISOString().substr(0, 10), points: "" };    
    const practiceId = localStorage.getItem('practiceId');

    const validationSchema = Yup.object({
        marketingDate: Yup.string().required("Please enter date"),
        points: Yup.string().required("Please enter points"),
    });

    const handleSubmit = async (values) => {
        if (id == 0) {
            CreateMarketing(values);
        } else {
            UpdateMarketing(id, values);
        }
    }

    const CreateMarketing = async (values) => {
        values.practiceId =practiceId;
        var payload = JSON.stringify(values);
        await MarketingServices.create(payload).then(result => {  toast(<Notification type="success" message="Marketing record saved successfully." />); history.push('/app/marketinglist'); }).catch(e => { });

    }

    const UpdateMarketing = async (id, values) => {
        values.practiceId =practiceId;
        await MarketingServices.update(id, JSON.stringify(values))  
            .then(result => {
                toast(<Notification type="success" message="Marketing record updated successfully." />)
                history.push('/app/marketinglist');
            })
            .catch(e => { toast(); });
    }

    return (
        <div className="card">
            <h5 className="card-header">{ id>0 ? 'Edit' :'Add' } Marketing</h5>
            <div className="card-body">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ errors, touched, isSubmitting, setFieldValue }) => {
                        useEffect(() => {
                            if (id>0) {
                                MarketingServices.get(id).then(marketing => {
                                    const fields = ['points'];
                                    fields.forEach(field => setFieldValue(field, marketing.data.data[field], false));
                                    setFieldValue('marketingDate',marketing.data.data['strMarketingDate'],false)
                                });
                            }
                        }, []);

                        return (
                            <Form id="form-employee">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label null " htmlFor="marketingDate"> Date </label>
                                            <Field placeholder="Enter date" className="form-control" type="date" name="marketingDate" />
                                            <ErrorMessage name="marketingDate" component={TextError} />
                                        </div>
                                        <div className="form-group has-validation">
                                            <label className="col-form-label null " htmlFor="points"> Points </label>
                                            <Field placeholder="Enter points" className="form-control" type="text" as="textarea" name="points" />
                                            <ErrorMessage name="points" component={TextError} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-buttons">
                                    <button className="mr-3 btn btn-primary" type="submit" id="btnSubmit">Save</button>
                                    <Link to="/app/marketinglist" className="mr-3 btn btn-secondary">Cancel</Link>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}
export default Contact;
