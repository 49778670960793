import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../components/Error/TextError";
import PracticeService from "./../../services/practice/practice.service";
import StripeService from "./../../services/stripe/stripe.service.js"
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import CommonHelper from "../../helper/common-helper";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCopy} from '@fortawesome/free-solid-svg-icons'

import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification.js";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const PracticeModify = () => {
  const { id } = useParams();
  const isAddMode = !id;
  let role = localStorage.getItem('role');
  let history = useHistory(); // declare here, inside a React component.
  var curr = new Date();
  curr.setDate(curr.getDate() + 3);
  const initialValues = {
    businessName: "",
    address: "",
    abnNo: "",
    email: "",
    phoneNo: "",
    website: "",
    facebookUrl: "",
    linkedinUrl: "",
    whatsappUrl: "",
    instagramUrl: "",
    googleReviewUrl:"",
    cancellationPolicyUrl:"",
    twillioPhoneNumber:"",
    twillioUserName:"",
    twillioPassword:"",
    file: "",
    logo: "",
    isBlock: false,
  };
  const [setFile, UploadFile] = useState([], false);
  const [base64, setImageBase64] = useState([], false);
  const [logoPreview, setLogoPreview] = useState(null);
  const[webhookURL,setWebhookURL] = useState("");
  const [copyStatus, setCopyStatus] = useState(false); 

  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
  };

  const validationSchema = Yup.object({
    businessName: Yup.string()
    .matches(/^[^-\s][a-zA-Z0-9!@#$%^&~* ()_+\-={}`;\[\]\\':\/"|,.<>?]*$/g, "Please enter valid business name")
    .max(100, 'Business name has a maximum length of 100 characters')
    .required("Business name is required"),
    address: Yup.string()
    .max(255, 'Address has a maximum length of 255 characters')
    .required("Address is required"),
    //abnNo: Yup.string().matches(/^.[0-9]{1,11}$/, "Please enter valid ABN number").required("Please enter ABN number"),
    abnNo: Yup.string()
      .matches(/^[0-9]{11}$/, "Please provide numbers only with 11 digit"),
    email: Yup.string()
      .email("Enter valid email")
      .max(50, 'Email has a maximum length of 50 characters')
      .required("Email is required"),
    phoneNo: Yup.string()
    .matches(/^[^-\sa-zA-Za-zA-Z!@#$%^&~ _\={}`;\[\]\\':\/"|,.<>?][0-9*()+ -]*$/g, "Please enter valid phone number")
    .max(20, 'Phone number has a maximum length of 20 characters')
    .min(10, 'Phone number has a minimum length of 10 characters'),
    website: Yup.string()
    .matches(/^((http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,"Please enter valid website")
    .max(255, 'Email has a maximum length of 255 characters'),
    facebook: Yup.string()
    .max(255, 'Facebook URL has a maximum length of 255 characters'),
    instagram: Yup.string()
    .max(255, 'Instagram URL has a maximum length of 255 characters'),
    googlereview: Yup.string()
    .max(255, 'Google Review URL has a maximum length of 255 characters'),
    cancellationpolicy: Yup.string()
    .max(255, 'Cancellation Policy URL has a maximum length of 255 characters'),
    logo: Yup.mixed().test(
      "fileSize",
      "File Size is too large, Max 2MB Allowed.",
      (value) => {
        if (value) return CommonHelper.isValidUploadfileSize(value.size);
        return true;
      }
    ),
  });

  const handleSubmit = async (values) => {
    if (id == 0) {
      if (base64.length > 0) {
        values.documentName = setFile.name;
        values.file = base64;
        values.logo = base64;
      } else {
        values.documentName = "";
        values.file = "";
        values.logo = "";
      }
      CreatePractice(values);
    } else {
      if (base64.length > 0) {
        values.documentName = setFile.name;
        values.file = base64;
        values.logo = base64;
      } else {
        values.documentName = "";
        values.file = "";
        values.logo = "";
      }
      UpdatePractice(id, values);
    }
  };

  const UpdatePractice = async (id, values) => {
    

    var payload = JSON.stringify(values);

    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */
    
    await PracticeService.update(id, payload)
      .then((result) => {
        // toast("Practice updated sucessfully.");

        /* Success Loader Called */
        loader.style.display='none';
        successloader.style.display='block';
        success_message.innerHTML="Practice updated sucessfully.";
        let timer =   setInterval(myTimer, 3000);
        function myTimer() {
            successloader.style.display='none';
            clearInterval(timer);  
        }

        history.push("/app/practicelist");
      })
      .catch((e) => {

        /* Fail Loader Called */
        loader.style.display='none';
        failloader.style.display='block';
        fail_message.innerHTML="Practice not updated sucessfully.";
        let timer =  setInterval(myTimer, 3000);
        function myTimer() {
            failloader.style.display='none';
            clearInterval(timer);   
        }

        toast();
      });
  };

  const onFileChange = async (event) => {
    UploadFile(event.target.files[0]);
    
    await CommonHelper.getBase64(event.target.files[0])
      .then((result) => {
        setImageBase64(result);
        setLogoPreview(result);
      })
      .catch((err) => {
      });
  };

  const CreatePractice = async (values) => {
    var payload = JSON.stringify(values);

    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

    await PracticeService.create(payload)
      .then((result) => {
        if (result.data.statuscode == 200) {
          // toast(<Notification type="success" message="Practice saved successfully." />)

          /* Success Loader Called */
          loader.style.display='none';
          successloader.style.display='block';
          success_message.innerHTML="Practice saved successfully.";
          let timer =   setInterval(myTimer, 3000);
          function myTimer() {
              successloader.style.display='none';
              clearInterval(timer);  
          }

          history.push('/app/practicelist')
      }
      else {
          // toast(<Notification type="error" message={result.data.message}/>)

          /* Fail Loader Called */
          loader.style.display='none';
          failloader.style.display='block';
          fail_message.innerHTML="Practice not saved successfully.";
          let timer =  setInterval(myTimer, 3000);
          function myTimer() {
              failloader.style.display='none';
              clearInterval(timer);   
          }

      }
      })
      .catch((e) => {

        /* Fail Loader Called */
        loader.style.display='none';
        failloader.style.display='block';
        fail_message.innerHTML="Employee not deleted successfully.";
        let timer =  setInterval(myTimer, 3000);
        function myTimer() {
            failloader.style.display='none';
            clearInterval(timer);   
        }
        
        toast();
      });


    //values.practiceId = Number(values.practiceId)
    //var payload = JSON.stringify(values);
    //await PracticeService.create(payload).then(result => { toast("Practice saved sucessfully."); history.push('/app/practicelist'); }).catch(e => { toast(console.log(e)); });
  };

  const StripeSubscribe = async () =>{
    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

    await StripeService.subscribeSMS(id).then((res) => {
      if (result.data.statuscode == 200) {
        // toast(<Notification type="success" message="Practice saved successfully." />)

        /* Success Loader Called */
        loader.style.display='none';
        successloader.style.display='block';
        success_message.innerHTML="SMS Subscription Successfull.";
        let timer =   setInterval(myTimer, 3000);
        function myTimer() {
            successloader.style.display='none';
            clearInterval(timer);  
        }

    }
    else {
        /* Fail Loader Called */
        loader.style.display='none';
        failloader.style.display='block';
        fail_message.innerHTML="SMS Subscription Failed";
        let timer =  setInterval(myTimer, 3000);
        function myTimer() {
            failloader.style.display='none';
            clearInterval(timer);   
        }

    }
        })
        .catch((e) => {
          loader.style.display='none';
          failloader.style.display='block';
          fail_message.innerHTML="SMS Subscription Failed";
          let timer =  setInterval(myTimer, 3000);
          function myTimer() {
              failloader.style.display='none';
              clearInterval(timer);   
          }
          
          toast();
        }); 
  };

  return (
    <div>
      <div className="card">
        <h5 className="card-header">{id != 0 ? "Edit" : "Add"} Practice</h5>
        <div className="card-body">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values,errors, touched, isSubmitting, setFieldValue, handleChange }) => {
              useEffect(() => {
                if (id > 0) {
                  PracticeService.get(id).then((practice) => {
                    const fields = [
                      "businessName",
                      "address",
                      "abnNo",
                      "email",
                      "phoneNo",
                      "website",
                      "isBlock",
                      "facebookUrl",
                      "instagramUrl",
                      "googleReviewUrl",
                      "cancellationPolicyUrl",
                      "twillioPhoneNumber",
                      "twillioUserName",
                      "twillioPassword"
                    ]; /*, 'facebookUrl', 'linkedinUrl', 'whatsappUrl', 'instagramUrl'*/
                    fields.forEach((field) =>
                      setFieldValue(field, practice.data.data[field], false)
                    );
                    setLogoPreview(practice.data.data["logoBase64"]);
                    setWebhookURL("https://huddlebook-api.azurewebsites.net/api/rcvsms/rcvtext/"+id);
                  });
                }
              }, []);

              return (
                <Form id="form-employee">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="col-form-label"
                          htmlFor="businessName"
                        >
                          
                          Business Name
                        </label>
                        <Field
                          placeholder="Enter Business name"
                          className="form-control"
                          type="text"
                          name="businessName"
                        />
                        <ErrorMessage
                          name="businessName"
                          component={TextError}
                        />
                      </div>
                      <div className="form-group has-validation">
                        <label className="col-form-label" htmlFor="address">
                          
                          Address
                        </label>
                        <Field
                          placeholder="Enter Address"
                          className="form-control"
                          as="textarea"
                          type="text"
                          name="address"
                        />
                        <ErrorMessage name="address" component={TextError} />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="abnNo">
                          
                          ABN Number
                        </label>
                        <Field
                          placeholder="Enter Abn number"
                          className="form-control"
                          type="text"
                          name="abnNo"
                        />
                        <ErrorMessage name="abnNo" component={TextError} />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="email">
                          
                          Email
                        </label>
                        <Field
                          placeholder="Enter email address"
                          className="form-control"
                          type="text"
                          name="email"
                        />
                        <ErrorMessage name="email" component={TextError} />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="phoneNo">
                          
                          Phone No
                        </label>

                        <ReactPhoneInput country={'au'} value={values.phoneNo} onChange={(value, country, e, formattedValue)=>{
                                handleChange(e);
                                              setFieldValue(
                                                "phoneNo",
                                                formattedValue
                                              );
                                        }}
                                        inputProps={{ name: 'phoneNo', required: true, className:"form-control w-100"}} />
                        <ErrorMessage name="phoneNo" component={TextError} />
                      </div>
                     
                  { role=="Admin" ? (
                    <div>
                 
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="email">
                          
                          Twillio Number
                        </label>
                        <Field
                          placeholder="Enter Twillio Phone Number"
                          className="form-control"
                          type="text"
                          name="twillioPhoneNumber"
                          style={{width:"50%"}}
                          
                          {...role!="Admin" ? (
                            "enabled"
                           ):"disabled"}
                      
                        />
                        <ErrorMessage name="twillioPhoneNumber" component={TextError} /> 
                         <button
                      className="mr-3 m-1 btn btn-primary"
                      id="btnSubscribe"
                      onClick={()=>StripeSubscribe()}
                    >
                      Subscribe SMS
                    </button>
                      </div>
                      <div className="form-buttons">
                   
                    </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="email">
                          
                          Twillio WebHook URL
                        </label>
                        <CopyToClipboard text={webhookURL} onCopy={onCopyText}>
                        <span style={{marginLeft:"20px"}}> <FontAwesomeIcon icon={faCopy} style={{color: "#00abbe",}} />  </span>
                      </CopyToClipboard> {copyStatus && <div className="alert-success">URL Copied to clipboard!</div>}
                        <Field
                         // placeholder="Enter Twillio Phone Number"
                          className="form-control"
                          type="text"
                          name="twillioWebHook"
                          value={webhookURL}
                          {...role!="Admin" ? (
                            "enabled"
                           ):"disabled"}
                      
                        />  
                        <ErrorMessage name="twillioWebHook" component={TextError} />
                      </div>
                      {/* <div className="form-group">
                        <label className="col-form-label" htmlFor="email">
                          
                        Twillio UserName
                        </label>
                        <Field
                          placeholder="Enter Twillio UserName"
                          className="form-control"
                          type="text"
                          name="twillioUserName"
                        />
                        <ErrorMessage name="email" component={TextError} />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="email">
                          
                          Twillio Password
                        </label>
                        <Field
                          placeholder="Enter Twillio Password"
                          className="form-control"
                          type="text"
                          name="twillioPassword"
                        />
                        <ErrorMessage name="email" component={TextError} />
                      </div> */}
                      </div>
             ):("")}
            
                    </div>
                    <div className="col-md-6">
                      <div className="col-sm-6">
                        {logoPreview != null && (
                          <div>
                            <img
                              src={logoPreview}
                              alt="Preview"
                              width="50px"
                              height="50px"
                            />
                          </div>
                        )}

                        <label className="col-form-label" htmlFor="logo">
                          Upload Logo
                        </label>
                        <input
                          type="file"
                          name="uploadResources"
                          onChange={(event) => {
                            setFieldValue("logo", event.currentTarget.files[0]),
                              onFileChange(event);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <div className="col-sm-6">
                          <ErrorMessage name="logo" component={TextError} />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="website">
                          Website
                        </label>
                        <Field
                          placeholder="Enter website address"
                          className="form-control"
                          type="text"
                          name="website"
                        />
                        <ErrorMessage name="website" component={TextError} />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="facebook">
                          FaceBook
                        </label>
                        <Field
                          placeholder="Enter FaceBook URL"
                          className="form-control"
                          type="text"
                          name="facebookUrl"
                        />
                        <ErrorMessage name="facebook" component={TextError} />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="instagram">
                          Instagram
                        </label>
                        <Field
                          placeholder="Enter Instagram URL"
                          className="form-control"
                          type="text"
                          name="instagramUrl"
                        />
                        <ErrorMessage name="instagram" component={TextError} />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="googlereview">
                          Google Review
                        </label>
                        <Field
                          placeholder="Enter Google Review URL"
                          className="form-control"
                          type="text"
                          name="googleReviewUrl"
                        />
                        <ErrorMessage name="googlereview" component={TextError} />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label" htmlFor="cancellationpolicy">
                          Cancellation Policy
                        </label>
                        <Field
                          placeholder="Enter Cancellation Policy URL"
                          className="form-control"
                          type="text"
                          name="cancellationPolicyUrl"
                        />
                        <ErrorMessage name="cancellationpolicy" component={TextError} />
                      </div>
                      <div className="checkbox checkbox-primary ">
                        <Field
                          className="styled"
                          type="checkbox"
                          name="isBlock"
                        />
                        <label htmlFor="isBlock">Block Practice</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-buttons">
                    <button
                      className="mr-3 btn btn-primary"
                      type="submit"
                      id="btnSubmit"
                    >
                      Save
                    </button>
                    <Link
                      to="/app/practicelist"
                      className="mr-3 btn btn-secondary"
                    >
                      Cancel
                    </Link>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PracticeModify;
