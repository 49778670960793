import React from "react";
import HbLogo from "../../components/Icons/SidebarIcons//HuddleBook_logo.jpg";

const faq = function () {


    return ( 
<div className="container">
    <div className="text-center">
        <br></br>
        <div>
        <img src={HbLogo} style={{ width: "37%" }} />
        </div>
        <br></br>
    
    <h4>The Daily Huddle FAQs</h4>
    <br></br>
    </div>


<br></br>
<p><strong>Q. Which operating system does The Daily Huddle support?</strong></p>
<p>A. The Daily Huddle Medical practice tasks management software is compatible with Windows, iOS and Android operating systems.</p>
<p>______________________________________________________________________________________________</p>
<br></br>

<p><strong>Q. Is there any The Daily Huddle Book app?</strong></p>
<p>A. The Daily Huddle management software app is available for iOS and Android.</p>
<p>______________________________________________________________________________________________</p>
<br></br>

<p><strong>Q. How much does The Daily Huddle cost?</strong></p>
<p>A. The Daily Huddle Dental practice team management software pricing is available on request at thedailyhuddle.online</p>
<p>______________________________________________________________________________________________</p>
<br></br>

<p><strong>Q. Who are the potential users of The Daily Huddle?</strong></p>
<p>A. The Daily Huddle team management software is typically used in The Dental and allied health clinics.</p>
<p>______________________________________________________________________________________________</p>
<br></br>

<p><strong>Q. How can you download The Daily Huddle?</strong></p>
<p>A. The Daily Huddle team management software installation is not required, as it is web-based. Simply register online to receive your software user licence login details to get started.</p>
<p>______________________________________________________________________________________________</p>
<br></br>

<p><strong>Q. How does The Daily Huddle works?</strong></p>
<p>A. The Daily Huddle helps with practice tasks management on daily basis and employee’s training, performance, and practice administrative tasks.</p>
<p>______________________________________________________________________________________________</p>
<br></br>

<p><strong>Q. How does The Daily Huddle data security works?</strong></p>
<p>A. Your The Daily Huddle data is managed safely, securely, and responsibly by us.</p>
<p>______________________________________________________________________________________________</p>
<br></br>

<p><strong>Q. How can we keep your data safe when you choose to integrate third-parties software into your daily huddle?</strong></p>
<p>A. You can choose to integrate The Daily Huddle data with third parties such as medical software (for consumers), and services like SMS providers. Data is only shared with these third parties when you give your permission, and our integration partners must meet the same industry grade privacy standards that we do (you can read more in our Privacy Policy).</p>
<p>______________________________________________________________________________________________</p>
<br></br>

<p><strong>Q. Is Payment details are safely managed in The Daily Huddle?</strong></p>
<p>A. As a security measures, card details are not stored with The daily Huddle, when you pay for subscription fee, payment gateway is powered by Stripe one of the world’s safest payment providers.</p>
<br></br>
<p>Card details are stored through our partners and not stored by us directly, and The Daily Huddle cannot retrieve card details.</p>
<p>______________________________________________________________________________________________</p>
<br></br>

<p><strong>Q. Is The Daily Huddle uses data for further marketing anything to the clients?</strong></p>
<p>A. The Daily Huddle does not use consumers or client’s data for marketing, we do not provide data to others so they can directly market to our clients.</p>
<p>______________________________________________________________________________________________</p>
<br></br>

<p><strong>Q. Terms and conditions and privacy policy?</strong></p>
<p>A. For more information, please see our terms and conditions and privacy policy. Our Privacy Policy explains, how we store and use data, and how you may access and correct your personal information; how you can lodge a complaint regarding the handling of your personal information; and how we will handle any complaint.</p>
<br></br>
<p>If you would like any further information about our privacy policies or practices, or would like to report any issues, please contact us at team@thedailyhuddle.online</p>
<p>______________________________________________________________________________________________</p>
<br></br>


</div>) 
}

export default faq;
