import quotesData from '../assets/localstore/quotes.json';
import EnumHelper from './enum-helper'
import holidayList from '../assets/localstore/holidays.json';
import schooltermsList from '../assets/localstore/schoolterms.json';

class LocalstoreHelper {
    static getDailyQuote() {
        let storedQuote = localStorage.getItem("db.quote"), dayOfWeek = new Date().getDay(), quotes = quotesData.map(data => { return data; });
        if (storedQuote != null) {
            storedQuote = JSON.parse(storedQuote);
            if (storedQuote.dayOfWeek === dayOfWeek) {
                return quotes[storedQuote.index];
            }
            else {
                let nextIndex = storedQuote.index + 1;
                nextIndex = (nextIndex > (quotes.length - 1) ? 0 : nextIndex);
                localStorage.setItem('db.quote', JSON.stringify({ dayOfWeek: dayOfWeek, index: nextIndex }));
                return quotes[nextIndex];
            }
        }
        else {
            localStorage.setItem('db.quote', JSON.stringify({ dayOfWeek: dayOfWeek, index: 0 }));
            return quotes[0];
        }
    };

    static getHoliday() {
        let holidays = {};
        holidays[EnumHelper.HolidayType.National] = holidayList.filter(data => { return data.type === EnumHelper.HolidayType.National && data.country === EnumHelper.HolidayCountry.Australia });
        let localHolidays = holidayList.filter(data => { return data.type === EnumHelper.HolidayType.Local && data.country === EnumHelper.HolidayCountry.Australia });
        holidays[EnumHelper.HolidayType.Local] = this.groupByKey(localHolidays, EnumHelper.GroupBy.Territory);
        let interNationalHolidays = holidayList.filter(data => { return data.type === EnumHelper.HolidayType.International && data.country != EnumHelper.HolidayCountry.Australia });
        holidays[EnumHelper.HolidayType.International] = this.groupByKey(interNationalHolidays, EnumHelper.GroupBy.Country);
        return holidays;
    };

    static getSchoolTerms() {
        return this.groupByKey(schooltermsList, EnumHelper.GroupBy.Territory);
    };

    static groupByKey(input, key) {
        return input.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
}

export default LocalstoreHelper