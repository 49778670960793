import http from "../../config/http-common";

class patinetMessage {
   async ConversationList(id,data) {
        return  http.post(`Message/ConversationList?practiceId=${id}`,data);
    }
    async MessageList(data) {
        return await http.post(`Message/MessageList`,data);
    }
    async Templates(id) {
        return await http.get(`Message/Templates?practiceId=${id}`);
    }
   
    async SendSms(data){
        return await http.post(`SendSMS/sendText`,data);
    }

    async ManageTemplate(id,data){
        return  http.post(`Message/AddTemplate?practiceId=${id}`,data);
    }

   
}

export default new patinetMessage();