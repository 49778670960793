import React, { useEffect, useState, useRef, Component } from "react";
import { v4 as uuidv4 } from "uuid";
import { Alert, Col, Row, Table,} from "reactstrap";
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../components/Error/TextError";
import ReactPhoneInput from "react-phone-input-2";
import moment from "moment";
import { useHistory } from "react-router-dom";


// Modal Popup
import Modal from 'react-bootstrap/Modal';
import CommonHelper from "../../helper/common-helper";
import PatientService from "./../../services/patient/patient.service"


const AddpatientModel = function ({ change }) {

    const [response, setHuddleList] = useState([], false);
    var curr = new Date(); curr.setDate(curr.getDate());    
    /* Patient Search Input Fields */
    const practiceId =localStorage.getItem("practiceId");
    let history = useHistory(); 
    useEffect(() => {}, []);

    function getHuddleDate(event) {
       let huddleDate = event.target.name;
       change(huddleDate);
       handleModalClose();
    }

    const validationSchema = Yup.object({
        firstName: Yup.string() 
            .matches(/^[a-zA-Z`']*$/g, "Please enter valid First name")
            .max(50, 'First name has a maximum length of 50 characters')
            .required("First name is required"),

        lastName: Yup.string()
            .matches(/^[a-zA-Z`']*$/g, "Please enter valid Last name")
            .max(50, 'Last name has a maximum length of 50 characters')
            .required("Last name is required"),

        dateOfBirth: Yup.string()
        .nullable()
        .test("dateOfBirth", "DOB should be less than Today's Date", function (value) {
          return moment().diff(moment(value, "YYYY-MM-DD"), "days") >= 1;
        }) .required("Please enter DOB"),
        // dateOfBirth: Yup.string()
        // .matches(curr,"Please Select Valid Date")
        // .required("Please select date of birth"),
        sex: Yup.string().required("Please select sex"),

        mobile: Yup.string()
            .matches(/^[^-\sa-zA-Za-zA-Z!@#$%^&~ _\={}`;\[\]\\':\/"|,.<>?][0-9*()+ -]*$/g, "Please enter valid mobile number")
            .max(20, 'Mobile number has a maximum length of 20 characters')
            .min(10, 'Mobile number has a minimum length of 10 characters')
            .required("Mobile number is required"),
        email: Yup.string()
            .email("Enter valid email")
            .max(50, 'Email has a maximum length of 50 characters')
            .required("Email is required"),
        // patientCategory: Yup.string().required("Please select patient category"),
      //  referralSource: Yup.string().required("Please select referral source"),
    });
    const handleSubmit = async (values) => {
       
        CreatePatientService(values);
    
}
const CreatePatientService = async (values) => {
    values.practiceId =practiceId;
    
    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

    var payload = JSON.stringify(values);
    await PatientService.create(payload).then(result => {
        // toast(<Notification type="success" message="Patien trecord saved successfully." />), 

        /* Success Loader Called */
        loader.style.display='none';
        successloader.style.display='block';
        success_message.innerHTML="Patient record saved successfully.";
        let timer =   setInterval(myTimer, 3000);
        function myTimer() {
            successloader.style.display='none';
            clearInterval(timer);  
        }
       
        console.log("id-->"+ result.data.data);
       // history.push('/app/chat/'+result.data.data);
       change(result.data.data);
       handleModalClose();

    })
    .catch(e => { 
        /* Fail Loader Called */
        loader.style.display='none';
        failloader.style.display='block';
        fail_message.innerHTML="Patient record not saved successfully.";
        let timer =  setInterval(myTimer, 3000);
        function myTimer() {
            failloader.style.display='none';
            clearInterval(timer);   
        }     
        toast(console.log(e)); 
    });
};
    const initialValues={
        patientNo: "",
        title: "",
        firstName: "",
        lastName: "",
        dateOfBirth: curr.toISOString().substr(0, 10),
        sex: "",
        email: "",
        mobile: "",
        homePhone: "",
        description: "",
        addressLine1: "",
        addressLine2: "",
        state: "",
        suburb: "",
        postcode: "",
        patientCategory: "",
        referralSource: "",
        referralNotes: "",
    };
    const [show, setModalShow] = useState(false);
    const[searchdisable,setSearchdisable]=useState(true);
    const handleModalClose = () => {setModalShow(false); };
    const handleModalShow = () => {  
    setModalShow(true);
};
    return (
        <>          
            {/* Patient Search Popup */}
            <Modal show={show} onHide={handleModalClose} animation={false} size="l">
                <Modal.Header closeButton>
                    {/* <Modal.Title>Add Patient </Modal.Title> */}
                    </Modal.Header>

                <Modal.Body>
                <div className="card" >
            <h5 className="card-header" >Add Patient</h5>
            <div className="card-body">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}> 
                    {({values, errors, touched, isSubmitting, handleChange,setFieldValue }) => {
                        useEffect(() => {
                            
                        }, []);

                        return (
                                <Form id="form-employee">
                                    <div className="row">
                                        {/*Left Section: Presonal */}
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label className="col-form-label null" htmlFor="sex">Title</label>
                                                <Field className="form-control" as="select" id="title" name="title">
                                                <option key={uuidv4()} className="form-control" value="">Select title</option>
                                                <option key={uuidv4()} className="form-control" value="Mr">Mr</option>
                                                <option key={uuidv4()} className="form-control" value="Ms">Ms</option>
                                                <option key={uuidv4()} className="form-control" value="Mrs">Mrs</option>
                                                <option key={uuidv4()} className="form-control" value="Miss">Miss</option>
                                                </Field>
                                                <ErrorMessage name="title" component={TextError} />

                                                <label className="col-form-label" htmlFor="firstName">First Name </label>
                                                 <Field placeholder="Enter first name" className="form-control" type="text" name="firstName" /> 
                                                <ErrorMessage name="firstName" component={TextError} />

                                                <label className="col-form-label" htmlFor="lastName">Last Name </label>
                                                 <Field placeholder="Enter last name" className="form-control" type="text" name="lastName" /> 
                                                <ErrorMessage name="lastName" component={TextError} />

                                                <label className="col-form-label null " htmlFor="dateOfBirth">Date Of Birth</label>
                                                <Field className="form-control" type="date" name="dateOfBirth" id="dateOfBirth"/>
                                                <ErrorMessage name="dateOfBirth" component={TextError}/>

                                                <label className="col-form-label null" htmlFor="sex">Sex</label>
                                                <Field className="form-control" as="select" id="sex" name="sex">
                                                <option key={uuidv4()} className="form-control" value="">Select sex</option>
                                                <option key={uuidv4()} className="form-control" value="M">Male</option>
                                                <option key={uuidv4()} className="form-control" value="F">Female</option>
                                                <option key={uuidv4()} className="form-control" value="O">Other</option>
                                                </Field>
                                                <ErrorMessage name="sex" component={TextError} />

                                                <label className="col-form-label null " htmlFor="email">Email</label>
                                                <Field placeholder="Enter email address" className="form-control" type="text" name="email"/>
                                                <ErrorMessage name="email" component={TextError} />

                                                <label className="col-form-label null " htmlFor="mobile">Mobile Number</label>
                                                <ReactPhoneInput country={'au'} value={values.mobile}  onChange={(value, country, e, formattedValue)=>{
                                                            handleChange(e);
                                                            {setFieldValue("mobile",formattedValue);}}}
                                                            inputProps={{ name: 'mobile', required: true, className:"form-control w-100"}} />
                                                <ErrorMessage name="mobile" component={TextError} />

                                              

                                         
                                            </div>
                                        </div>
                                       
                                      
                                    </div>
                                   
                                    <div className="form-buttons">
                                        <button className="m-1 btn btn-primary" type="submit" id="btnSubmit">Save</button>
                                        <Link to="/app/chat/0" className="mr-3 btn btn-secondary" onClick={handleModalClose}>Cancel</Link>
                                    </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
              
                    
                </Modal.Body>
            </Modal>

          
            <div className="row align-items-center justify-content-center">
            <input className="btn btn-primary mt-2 mb-2" style={{width:"90%"}} title="Search" onClick={handleModalShow}  type="button" value="Add Patient" />
            </div>
        </>
    )
}
export default AddpatientModel;
