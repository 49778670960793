import React, { useState, useEffect } from 'react';
import logo from '../../components/Icons/Huddle Logo.png'
import './loader.css'

const SuccessLoader = () => {
  const [showClose, setShowClose] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  
  useEffect(() => {
    setShowLoader(true);
    setShowClose(true);
    // const timer_tick = setInterval(() => {
    //   setShowTick(true);
	//   setShowLogo(false);
    // }, 8000);
    // const timer_Section = setInterval(() => {
    //     setShowLoader(false);
    //   }, 10000);

    // return () => {
    //   clearInterval(timer_tick);
    //   clearInterval(timer_Section);
    // };
  }, []);

  return (
    <>
    {showLoader && (
	<div className="overlay">
            <div id="loader-section">
            {showClose && (
                <div className="loader-new-fail">
                    <div className="errormark" />
                </div>
                )}
                <p id='fail-message' style={{marginBottom:'0',fontSize:'larger',color:'black',fontWeight:550,paddingTop:'170px'}}></p>
            </div>
    </div>
    )}
	</>
  );
};
export default SuccessLoader;