import http from "../../config/http-common";

class ChangePasswordService {
   
    get(id) {
        return http.get(`${id}/user`);
    }
    changePassword(data) {
        return http.post(`employees/changePassword`,data)
    }

    changeBlockAccount(data) {
        return http.post(`employees/blockUser`,data)
    }
    getBlockAccount(employeeId) {
        return http.get(`employees/blockUser?employeeId=${employeeId}`);
    }
}

export default new ChangePasswordService();