import React, { useEffect, useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { v4 as uuidv4 } from "uuid";

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import TextError from "../../components/Error/TextError";
import RegisterService from "./../../services/register/register.service.js";
import RoleService from "./../../services/roles/role.service.js";
import EmployeeService from "./../../services/employee/employee.service.js";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  let history = useHistory(); // declare here, inside a React component.
  var curr = new Date();
  curr.setDate(curr.getDate() + 3);
  const [roles, setRole] = useState([], false);
  const [selectedRole, setSelectedRole] = useState("Select Role", false);
  const [selectedRoleIndex, setSelectedRoleIndex] = useState("Select Role Index", false);
  const role = localStorage.getItem("role");

  const [employees, setPracticeEmployees] = useState([], false);
  const[selectedmember,setSelectedMember] = useState("Select Member",false);
  const [email, DataEmail] = useState("Select Email",false);
  const [isDisabled, setDisabled] = useState(false);
  const [isRoleDisabled, setRoleDisabled] = useState(false);
  const[userlist,userdata] = useState(false);

  


  let { id } = useParams();

  var initialValues = {
    emailId: "",
    role: "",
    username: "",
    password: "",
    confirmPassword: "",
    employee: 0,
  };

  useEffect(() => {
    fetchroles();
    practiceEmployees();
    setDisabled(true);
    setRoleDisabled(true);
    return () => {
      setRole([]);
      setPracticeEmployees([]); // This worked for me
    };
  }, []);

  

  const validationSchema = 
  
 

  Yup.object({
      password: Yup.string().required("Password is required"),
      //role : Yup.number().min(1,"Error"),
  // role : Yup.string().oneOf(Object.values(roles),"Role Require"),
    // role: Yup.string().required("Select new Role").oneOf(Userroleslist),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "Password and Confirm Password must be same")
      .required("Confirm Password is required"),
  });

  const handleSubmit = async (values) => {

    if(selectedRole =='')
    {
      alert('Please select role')
      return false;
    }

    values.role = selectedRole;
    values.emailId = email;
    values.username = email;
    values.employee=parseInt(selectedmember);
    var payload = JSON.stringify(values);

    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

    await RegisterService.create(id, payload)
      .then((result) => {
        // toast("User Added Successfully.");

        /* Success Loader Called */
        loader.style.display='none';
        successloader.style.display='block';
        success_message.innerHTML="User Added Successfully.";
        let timer =   setInterval(myTimer, 3000);
        function myTimer() {
            successloader.style.display='none';
            clearInterval(timer);  
        }

        history.push("/app/practicelist");
      })
      .catch((e) => {

        /* Fail Loader Called */
        loader.style.display='none';
        failloader.style.display='block';
        fail_message.innerHTML="User Not Added Successfully.";
        let timer =  setInterval(myTimer, 3000);
        function myTimer() {
            failloader.style.display='none';
            clearInterval(timer);   
        }

        toast();
      });
  };

  const fetchroles = async () => {
    await RoleService.get()
      .then((result) => {
       
        var roles = result.data.filter(item => item !== "Admin")
        setRole(roles);
      })
      .catch((e) => {
        toast();
      });
  };
  
    const practiceEmployees = async () => {
    var payload = { practiceId: id, searchTerm: "", Pagesize: 10, PageNo: 1 };
    await EmployeeService.getPracticeEmployees(id)
      .then((result) => {
        
        userdata(result.data.data);
        setPracticeEmployees(result.data.data);
      })
      .catch((e) => {
        toast();
      });
  };

 

  const CheckRole = (e) => {
   
   
   if(e.target.value != '')
   {
    setSelectedRole(e.target.value);
    initialValues.role = e.target.value;
    setDisabled(false);

    

   }
   else{
    setDisabled(true);
  }
  };

  const memberdetails =  async () => {
    
    var payload = { id: id };
    await EmployeeService.get(id)
      .then((result) => {
      //  DataEmail(result.data.data);
      })
      .catch((e) => {
        toast();
      });
  };
  const CheckTeamMember = (e) => {
    
    if(e.target.value != ''){
      setRoleDisabled(false);
    
    }
    else{
      setRoleDisabled(true);
    }
    setSelectedMember(e.target.value);
    initialValues.role = e.target.value;
    memberdetails(e.target.value);
    
    userlist.forEach(element => {
      if(element.id==e.target.value){
        initialValues.emailId=element.email;
        initialValues.username=element.email;
        initialValues.employee = element.id;
        DataEmail(element.email);
       
      }
      
    });
  };
  return (
    <div className="card">
      <h5 className="card-header">Add User</h5>
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form id="form-performance">
            <div className="row1">
            <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor="teammember">
                  Select Team Member
                </label>
                <div className="col-sm-10">
                  <Field
                    className="form-control"
                    as="select"
                    id="teammember"
                    name="teammember"
                    value={selectedmember}
                    onChange={CheckTeamMember}
                  >
                    <option className="form-control" value="">
                      Select Team Member
                    </option>
                    {employees.map((option) => (
                          <option
                           // key={option.id}
                             key={uuidv4()}
                            className="form-control"
                            value={option.id}
                          >
                            {option.firstName}  {option.lastName}
                          </option>
                        ))}
                  </Field>
                  <ErrorMessage name="teammember" component={TextError} />
                </div>
              </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor="emailId">
                  Email
                </label>
                <div className="col-sm-10">
                  <Field
                  disabled
                    placeholder="Email Id"
                    className="form-control"
                    type="text"
                    name="emailId"
                    id="emailId"
                    value={email}
                  />
                  <ErrorMessage name="emailId" component={TextError} />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor="role">
                  Select Role
                </label>
                <div className="col-sm-10">
                  <Field disabled ={isRoleDisabled} className="form-control" as="select" id="role" name="role"
                    value={selectedRole} 
                    onChange={CheckRole}
                    >
                    <option className="form-control" value="">Select Role</option>
                    {roles.map((option) =>
                       (
                        <option key={uuidv4()} className="form-control" value={option}>{option}</option>
                      )
                    )}
                  </Field>
                  <ErrorMessage name="role" component={TextError} /> 
                   
                </div>
              </div>

              <div
                className={
                  selectedRole == "Employee" ? "form-group row" : "d-none"
                }
              >
                <label className="col-sm-2 col-form-label" htmlFor="employee">
                  Select Employee
                </label>
                <div className="col-sm-10">
                  <Field
                    className="form-control"
                    as="select"
                    id="employee"
                    name="employee"
                    value = {selectedmember}
                    disabled
                  >
                    <option key={uuidv4()} className="form-control" value="">
                      Select Employee
                    </option>
                    {employees.map((option) => (
                      <option
                        key={uuidv4()}
                        className="form-control"
                        value={option.id}
                      >
                        {option.firstName} {option.lastName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="employee" component={TextError} />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor="username">
                  User Name
                </label>
                <div className="col-sm-10">
                  <Field
                    placeholder="User Name"
                    disabled
                    className="form-control"
                    type="text"
                    name="emailId"
                    id="emailId"
                    value = {email}
                    
                  />
                  <ErrorMessage name="username" component={TextError} />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor="password">
                  Password
                </label>
                <div className="col-sm-10">
                  <Field
                    placeholder="Password"
                    className="form-control"
                    type="password"
                    name="password"
                    id="password"
                  />
                  <ErrorMessage name="password" component={TextError} />
                </div>
              </div>
              <div className="form-group row">
                <label
                  className="col-sm-2 col-form-label"
                  htmlFor="confirmPassword"
                >
                  Confirm Password
                </label>
                <div className="col-sm-10">
                  <Field
                    placeholder="Confirm Password"
                    className="form-control"
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                  />
                  <ErrorMessage name="confirmPassword" component={TextError} />
                </div>
              </div>
            </div>
            <div className="form-buttons offset-md-2">
              <button
              disabled ={isDisabled}
                className="mr-3 btn btn-primary"
                type="submit"
                id="btnSubmit"
              >
                Save
              </button>
              <Link to={"/app/practicelist"} className="mr-3 btn btn-secondary">
                
                Cancel
              </Link>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
export default Register;
