import React, { useEffect ,useState, useRef} from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../components/Error/TextError";
import SocialMediaService from "./../../services/socialmedia/socialmedia.service";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';

//Notification
import { toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification.js";
import {ThreeDots} from 'react-loader-spinner';

const ManageSocialMedia = () => {
    const { id } = useParams();
    const practiceId = localStorage.getItem('practiceId');
    let history = useHistory(); 
    var curr = new Date(); curr.setDate(curr.getDate());  
    const upfile = useRef(null);
    const [isLoading, setLoading]=useState(true);
    const [socialmediafiles, setsocialmediafiles]=useState([]);

    const [validFiles , setValidFiles] = useState(true, false);
    const [validFilesSize , setvalidFilesSize] = useState(true, false);
  
    // uploaded files
    const [images, setImages] = useState([]);

    const initialValues = { date: curr.toISOString().substr(0, 10), description: "", idea: "",  teamMember: ""};

    const validationSchema = Yup.object({
        idea: Yup.string().required("Please enter idea"),
        teamMember: Yup.string().required("Please enter team member"),
        // date: Yup.string().required("Please enter date"),
        // description: Yup.string().required("Please enter description"),
    });

    const handleSubmit = async (values) => {
        if (id == 0) {
            CreateSocialMedia(values);
        } else {
            UpdateSocialMedia(id, values);
        }
    }
    const formatImage = async(e) => {
        try {


            var isValidFileSize = true;
        
            if(e.target.files.length > 0){
            
                if(e.target.files.length > 3)
                {
                    setValidFiles(false);
                    setImages([]);
                }else{
                    setValidFiles(true);
                    const selectableMaxFileSize = 1024 * 1024 * 5; // 10 Megabyte
            
                    let initialFileSize = 0
            
                    if (e.target.files) {
                     for (let i = 0; i < e.target.files.length; i++) {
                            const file = e.target.files[i]
                            initialFileSize += file.size
                        }
                    if (initialFileSize > selectableMaxFileSize) {
                        setvalidFilesSize(false);
                        isValidFileSize=false;
                        setImages([]);
                    }else{
                        setvalidFilesSize(true);
                        for (let i = 0; i < e.target.files.length; i++) {
                            const reader = new FileReader();
                            reader.readAsDataURL(e.target.files[i]);
                            reader.onload = (readerEvent) => {
                              images.push({
                                imageName:e.target.files[i].name,
                                imageBase64:readerEvent.target?.result
                    
                              });
                            };
                          }
                    }
                }
                }
            }

        } catch (error) {
          console.log(error);
        }
      };

      const downloadimage = async(e) =>{
        var imageSrc = e.target.currentSrc;

        // Create an anchor element to trigger the download
        var extension = ".jpg";
        var a = document.createElement("a");
        debugger;
        if(imageSrc.includes("image/gif")){
            extension=".gif";
        }else if(imageSrc.includes("image/png")){
            extension=".png";
        }
        a.href = imageSrc;
        a.download = "downloaded-img"+extension;

        // Trigger a click event on the anchor element
        a.click();
      };

    const UpdateSocialMedia = async (id, values) => {
                //limit number of files to upload
                    if(!validFiles){
                        return;
                    }
        values.practiceId =practiceId;
        values.socialMediaFiles = images.length > 0 ? images : [];

        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */
        await SocialMediaService.update(id, JSON.stringify(values))
            .then(result => {                
                // toast(<Notification type="success" message="Social Media record saved successfully." />),
                
                /* Success Loader Called */
                loader.style.display='none';
                successloader.style.display='block';
                success_message.innerHTML="Social Media record saved successfully.";
                let timer =   setInterval(myTimer, 3000);
                function myTimer() {
                    successloader.style.display='none';
                    clearInterval(timer);  
                }
                history.push('/app/socialmedialist');
            }).catch(e => { 
                /* Fail Loader Called */
                loader.style.display='none';
                failloader.style.display='block';
                fail_message.innerHTML="Social Media record not saved successfully.";
                let timer =  setInterval(myTimer, 3000);
                function myTimer() {
                    failloader.style.display='none';
                    clearInterval(timer);   
                }      
                toast(console.log(e)); 
            });
    }

    const CreateSocialMedia = async (values) => {
        values.practiceId =practiceId;
        var payload = JSON.stringify(values);
        await SocialMediaService.create(payload).then(result => { toast(<Notification type="success" message="Social Media record saved successfully." />), history.push('/app/socialmedialist'); }).catch(e => { toast(console.log(e)); });
    }


    return (
        <div className="card">
            <h5 className="card-header">{id>0 ? 'Edit ' : 'Add ' } Social Media</h5>
            <div className="card-body">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ errors, touched, isSubmitting, setFieldValue }) => {
                        useEffect(() => {
                            setLoading(true);
                            if (id > 0) {
                                SocialMediaService.get(id).then(socialMedia => { 
                                    setLoading(false);
                                    const fields = [ 'description', 'idea', 'teamMember', 'location', 'hashTag'];
                                  
                                    fields.forEach(field => setFieldValue(field, socialMedia.data.data[field], false));
                                 
                                    setFieldValue('date',socialMedia.data.data['date'].substring(0, 10));
                                    setsocialmediafiles(socialMedia.data.data['socialMediaImages']);

                                }); 
                            }
                        }, []);
 
                        return (
                                <Form id="form-employee">
                                    <div className="row">
                                        <div className="col-md-6">
                                        <div className="form-group">
                                                <label className="col-form-label" htmlFor="idea"> Idea </label>
                                                 <Field placeholder="Enter Idea" className="form-control" type="text" name="idea" /> 
                                                <ErrorMessage name="idea" component={TextError} />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="location"> Location </label>
                                                 <Field placeholder="Enter Location" className="form-control" type="text" name="location" /> 
                                                <ErrorMessage name="location" component={TextError} />
                                            </div>
                                        <div className="form-group">
                                                <label className="col-form-label" htmlFor="teamMember"> Team Member </label>
                                                 <Field placeholder="Enter Team Member" className="form-control" type="text" name="teamMember" /> 
                                                <ErrorMessage name="teamMember" component={TextError} />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="hashTag"> HashTag </label>
                                                 <Field placeholder="Enter HashTag" className="form-control" type="text" name="hashTag" /> 
                                                <ErrorMessage name="hashTag" component={TextError} />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="date"> Date </label>

                                                  <Field
                                                    className="form-control"
                                                    type="date"
                                                    name="date"
                                                    id="date"
                                                  />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label null " htmlFor="description">
                                                    Description
                                                </label>
                                                <Field placeholder="Enter Description" className="form-control" as="textarea" type="text" name="description" />
                                                <ErrorMessage name="description" component={TextError} />
                                            </div>

                                            <div className="form-group">
                    <label
                      className=" col-form-label"
                      htmlFor="documentName"
                    >
                      Upload Content
                    </label>
                    <div className=" d-flex align-items-center">
                    <input id="files" type="file" accept="image/x-png,image/gif,image/jpeg,video/mp4" ref={upfile} name="upfile" style={{width:"500px"}} multiple  onChange={(e)=> formatImage(e)}/>
                   
                    </div>
                    <p className={validFiles?"error d-none":"error"}>Maximum 3 files allowed</p>
                    <p className={validFilesSize?"error d-none":"error"}>Maximum 5 MB allowed to upload</p>
                    <div className="form-buttons ml-3 mt-4">
                                        <button className="mr-3  btn btn-primary" type="submit" id="btnSubmit">Save</button>
                                        <Link to="/app/socialmedialist" className="mr-3  btn btn-secondary">Cancel</Link>
                                    </div>
                  </div>

                                        </div>
                                        <div className="col-md-6">
                                        {/* {socialmediafiles.length > 0 ? <p></p> :<div style={{alignItems:"center",display:"flex",justifyContent:"center",height:"100%", color:"#00abbe", fontWeight:"600"}}> <p>Media Files Not Avaliable</p></div>} */}
                                        {isLoading ?<div
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                            }}
                                        > 
                                        <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center"
                                            }}
                                        >
                                        <ThreeDots type="ThreeDots" color="#00abbe" height="100" width="100" />
                                        <p style={{ width: "100%", color: "#00abbe", fontWeight: "600"}}>Fetching media files...</p>
                                        </div>
                                        </div> :<div>
                                        
                                        { socialmediafiles.length > 0 ?
                                                <div className="media-img">{socialmediafiles.map((img) => (
                                                    <div className="img-grid">
                                                        { img.imageBase64.includes("image") ?
                                                        <a href="javascript:void(0);" style={{textAlign:"center"}} onClick={(e)=>downloadimage(e)}>
                                                        <img
                                                            src={img.imageBase64}
                                                            alt="Preview"
                                                            width="80%"
                                                        /> </a>:<div style={{textAlign:"center"}}><video controls src={img.imageBase64} width="90%" /><br></br>
                                                        <a href={img.imageBase64} download><i className="fa fa-download"></i></a></div>
                                                            }

                                                    </div>
                                                ))} </div>
                                                
                                                :<div style={{alignItems:"center",display:"flex",justifyContent:"center",height:"100%", color:"#00abbe", fontWeight:"600"}}> <p style={{marginTop:"50%"}}>Media Files Not Available</p></div>
                                        }</div>
                                        
                                        }
                                        </div>
                                    </div>
                                   
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}
export default ManageSocialMedia;
