import React, {useState,useEffect } from "react";
import '../autoPracticeRegister/subscriptionPlan.css'
import StripeService from "../../services/stripe/stripe.service";


const CheckCancelledSubscription = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = async () => {
    if (!isValidEmail(email)) {
      setError('Please provide correct email');
    } else {
      setError(null);
      await StripeService.checkcancelledsubscription(email).then((res) => {
        if(res.data.status ==="success"){
          var customerid= res.data.data.customerid;
          localStorage.setItem("customerId",customerid);
          window.location = "/RenewCancelledSubscriptionPlan";
        }else if(res.data.status==="error" && res.data.message.includes("Validation:")){
          setError(res.data.message.replace("Validation:",""));
        }
       
          })
          .catch((e) => {
          });
    }
  };

    
useEffect(() => {
  localStorage.removeItem("customerId");
});

return(
    <div className="card mx-auto subcontainer">
            <h5 className="card-header text-center">The Daily Huddle</h5>
            <div className="card-body">
                <div className="row justify-content-center mt-5">
                <div className="col-md-5">
                    <div className="card text-center sub-border">
                        <div className="card-header bold sub-border card-header">
                            Renew Cancelled Subscription
                        </div>
                        <div className="card-body mx-auto">
                            <p className="bold">Please provide your main practice's email to renew your plan</p>
                            <input type="text"  placeholder="Enter your practice email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control mt-4 mb-1" name="email" />
                            {error && <div><span style={{color: 'red'}}>{error}</span></div>}

                            <input type="button" disabled={false} onClick={handleChange} className="btn btn-primary mt-3" value="Next" />
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
);

};
 export default CheckCancelledSubscription;