import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Col, Row, Table, Label, } from "reactstrap";
import ReactPaginate from 'react-paginate';
import Widget from "../../components/Widget/Widget.js";
import keyContactService from "./../../services/keyContact/keycontact.service.js";
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import s from "./Tables.module.scss";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification.js";
import PreviewModal from "../pdfPreview/preview.js";
import * as html2pdf from 'html2pdf.js'
import EnumHelper from "../../helper/enum-helper";
import PdfHelper from "../../helper/pdf-helper";
import Spinner from "react-bootstrap/Spinner";

//DateRange Picker
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const KeyContactList = function () {
    const [response, setSocialMediaList] = useState([], false);
    const [totalCount, setTotalCount] = useState([], false);

    //Date Range Textbox
    const [DateRange, setStateDateRange] = useState('', false);

    const [pdfURl, setPdfUrl ] = useState('', false);
    const [showModel , setModel] = useState(false);
    const [showloader, setLoading] = useState([], false);

    //Search Textbox
    const txtSearch = useRef(null);
    let pageNo = 1, pageSize = 10;
    const practiceId = localStorage.getItem('practiceId');
    const role = localStorage.getItem('role');

    const companyName = useRef(null);
    const representativeName = useRef(null);
    const accountNo = useRef(null);
    const contactNo = useRef(null);
    const detailsOfCompany = useRef(null);

    useEffect(() => {
        getRecords();
        return () => {
            setSocialMediaList([]);
        };
    }, []);

    const handlePageClick = (data) => { let selected = data.selected + 1; pageNo = selected; getRecords(); };

    const getRecords = async () => {


        var payload = { "searchTerm": txtSearch.current.value, "pageSize": pageSize, "pageNumber": pageNo, 'practiceId': practiceId, 'dateRange': DateRange }
        setLoading(  true );


        await new Promise(function (resolve, reject) {
            keyContactService.search(payload).then(result => {
                resolve(result);
                setSocialMediaList(result.data.data.list);
                setTotalCount(Math.ceil(parseInt(result.data.data.totalRecords) / pageSize));
                pageNo = payload.pageNumber;
                setLoading(  false );

            }).catch(e => {
               console.log(e);
            })
        });
    }

    const deleteRecord = async (socialMediaId) => {
        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */
        await keyContactService.delete(socialMediaId)
            .then(result => {
                // toast(<Notification type="success" message="Key Contact record deleted successfully." />),
                
                /* Success Loader Called */
                loader.style.display='none';
                successloader.style.display='block';
                success_message.innerHTML="Key Contact record deleted successfully.";
                let timer =   setInterval(myTimer, 3000);
                function myTimer() {
                    successloader.style.display='none';
                    clearInterval(timer);  
                }

                getRecords()
            }).catch(e => {
                 /* Fail Loader Called */
                loader.style.display='none';
                failloader.style.display='block';
                fail_message.innerHTML="Key Contact record not deleted successfully.";
                let timer =  setInterval(myTimer, 3000);
                function myTimer() {
                    failloader.style.display='none';
                    clearInterval(timer);   
                }     
                toast();
            });
    }

    const addKeyContact = async () => {
        let data = {
            "companyName": companyName.current.value,
            "representativeName": representativeName.current.value,
            "accountNo": accountNo.current.value,
            "contactNo": contactNo.current.state.formattedNumber,
            "detailsOfCompany": detailsOfCompany.current.value,
            "practiceId": practiceId,
        }

        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */

        await keyContactService.create(data).then(result =>{
            if(result.data.statuscode === 200){
                // toast(<Notification type="success" message="Key Contact record added successfully." />), 

                /* Success Loader Called */
                loader.style.display='none';
                successloader.style.display='block';
                success_message.innerHTML="Key Contact record added successfully.";
                let timer =   setInterval(myTimer, 3000);
                function myTimer() {
                    successloader.style.display='none';
                    clearInterval(timer);  
                }
                getRecords();
            }
        }).catch(e => { 
            /* Fail Loader Called */
            loader.style.display='none';
            failloader.style.display='block';
            fail_message.innerHTML="Key Contact record not added successfully.";
            let timer =  setInterval(myTimer, 3000);
            function myTimer() {
                failloader.style.display='none';
                clearInterval(timer);   
            }     
            toast();
        });
    }

    const clearKeyContact =() =>{
        companyName.current.value=representativeName.current.value=accountNo.current.value=contactNo.current.value=detailsOfCompany.current.value=txtSearch.current.value='';
        getRecords();
    }

    const datePickerHandleApply = (event, picker) => {
        let selectedDate = picker.startDate.format('DD-MM-YYYY') + ' - ' + picker.endDate.format('DD-MM-YYYY')
        setStateDateRange(selectedDate); picker.element.val(selectedDate);
    }

    const datePickerHandleCancel = (event, picker) => { picker.element.val(''); setStateDateRange('') }

    const handleOnPdfPreview = (event) => {                
        if(event.nativeEvent.target && event.nativeEvent.target.classList.contains('ppreview')){
            let options=EnumHelper.PdfOption, boundingRect=document.getElementById('main').getBoundingClientRect();
            options['jsPDF']['format']=[boundingRect.width-200,boundingRect.height-100];
            html2pdf().from(document.getElementById('main')).set(options).outputPdf().then((pdf) => {
                setPdfUrl(btoa(pdf));
                setModel(!showModel)
            })
        }
        else if(showModel){
            setModel(!showModel)
        }        
    }

    return (
        <div id = "main">
            <Row>
                <Col>
                    <Row className="mb-4">
                        <Col>
                            <Widget>
                                <div className={s.tableTitle}>
                                    <div className="headline-2">Key Contact</div>
                                    <div className="d-flex">                                        
                                    <a onClick={(e) => PdfHelper.printPage()}><img src={printerIcon} alt="Printer" /></a>
                                    <a className="mx-2 ppreview" onClick={(e) => { handleOnPdfPreview(e); }}><PreviewModal isOpen={showModel} url={pdfURl} /> Preview</a>
                                    <a onClick={() => PdfHelper.downloadPdf(document.getElementById('main'), "Key Contacts")}><img src={cloudIcon} alt="Download" /></a>
                                    </div>
                                </div>
                                <div className={s.tableTitle}>
                                    <Col className="col-3">
                                        <input type="text" ref={txtSearch} name="txtSearch" className="form-control " placeholder="Search by Company & Representative" />
                                    </Col>
                                    {/* <Col>
                                        <DateRangePicker onApply={datePickerHandleApply} onCancel={datePickerHandleCancel} initialSettings={{ autoUpdateInput: false, locale: { 'format': 'DD-MM-YYYY' } }}>
                                            <input type="text" className="form-control" defaultValue="" placeholder="Select Date" />
                                        </DateRangePicker>
                                    </Col> */}
                                    <Col>
                                        <input className="btn btn-secondary" type="button" value="Search" onClick={getRecords} />
                                    </Col>
                                    {/* <Col>{role === "Employee" ? <div></div> : <Link to="/app/keycontact/0" className="btn btn-primary pull-right"> Add Key Contact </Link>}
                                    </Col> */}
                                </div>
                                <div className="widget-table-overflow">
                                    <Table className={`table-striped table-borderless table-hover responsive ${s.statesTable}`}>
                                        <thead>
                                            <tr>
                                                <th className="w-1"></th>
                                                <th className="w-15">COMPANY NAME</th>
                                                <th className="w-15">REPRESENTATIVE NAME</th>
                                                <th className="w-15">ACCOUNT NO</th>
                                                <th className="w-15">CONTACT NO</th>
                                                <th className="w-15">DETAILS OF COMPANY</th>
                                                {role === "Employee" ? <th></th> : <th className="W-10">ACTIONS</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={uuidv4()}>
                                                <td></td>
                                                <td><input type="text" ref={companyName}  name="companyName" className="form-control " placeholder="Enter company name" /></td>
                                                <td><input type="text" ref={representativeName}  name="representativeName" className="form-control " placeholder="Enter representative name" /></td>
                                                <td><input type="text" ref={accountNo}  name="accountNo" className="form-control " placeholder="Enter account no" /></td>
                                                <td><ReactPhoneInput country={'au'} ref={contactNo} inputProps={{ name: 'contactNo', required: true ,className:"form-control w-100"}} /></td>
                                                <td><input type="text" ref={detailsOfCompany}  name="detailsOfCompany" className="form-control " placeholder="Enter details of company" /></td>
                                                <td><Link to="#" onClick={() => addKeyContact()} className={s.blackColor}><i className={'eva eva-checkmark-outline ' + s.greenColor} /> </Link> &nbsp;
                                                <Link to="#" onClick={() => clearKeyContact()} className={s.blackColor}><i className={'eva eva-close-outline ' + s.redColor} /></Link></td>
                                            </tr>
                                            {showloader ? (
                  <tr>
                    <td rowSpan="5" colSpan="5">
                      <div className="text-center py-5">
                          <Spinner animation="border" />
                      </div>
                    </td>
                  </tr>
                ) : response.length == 0 ? (
                  <tr>
                    <td colSpan="5">
                      <div className="alert alert-secondary text-primary m-3 color">
                        No records found
                      </div>
                    </td>
                  </tr>
                ) :response.map(item => (
                                                    <tr key={uuidv4()}>
                                                        <td></td>
                                                        <td>{item.companyName}</td>
                                                        <td>{item.representativeName}</td>
                                                        <td>{item.accountNo}</td>
                                                        <td>{item.contactNo}</td>
                                                        <td>{item.detailsOfCompany}</td>
                                                        {role === "Employee" ? <td></td> : <td>
                                                            <Link to={"/app/keycontact/" + item.id} className={s.blackColor}><i className="fa fa-edit"></i></Link>&nbsp;
                                                            <Link to="#" onClick={() =>confirm('Are you sure, you want to delete this?') && deleteRecord(item.id)} className={s.blackColor}><i className="fa fa-trash"></i></Link></td>}
                                                    </tr>
                                                ))}
                                              
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col className="ml-2"></Col>
                                        <Col className="col-10">
                                            <ReactPaginate breakLabel="..." nextLabel=" >" onPageChange={handlePageClick} pageRangeDisplayed={pageSize} pageCount={Math.ceil(totalCount)} previousLabel="< "
                                                renderOnZeroPageCount={null} containerClassName={'pagination pagination-sm'} pageClassName={'page-item'} pageLinkClassName={'page-link'}
                                                previousClassName={'previous'} previousLinkClassName={'page-item page-link'} nextClassName={'next'} nextLinkClassName={'page-item page-link'}
                                                activeClassName={'active'} />
                                        </Col>
                                    </Row>
                                </div>
                            </Widget>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
export default KeyContactList;
