import http from "../../config/http-common";

class dashboardAdmin {
    piechart() {
        return  http.get(`dashboardAdmin/piechart`);
    }
    
    practiceBySubscription(data) {        
        return http.post(`dashboardAdmin/practiceBySubscription`, data);
    }
    
   
}

export default new dashboardAdmin();