import http from "../../config/http-common";

class keyContactService {
    async getAll(pageNo, data) {
        return await http.post(`socialmedia/${pageNo}`, data);
    }
    get(id) {
        return http.get(`keycontact/${id}`);
    }
    async create(data) {
        return await http.post("keycontact?keycontactId=0", data);
    }
    async delete(id) {
        return await http.delete(`keycontact/${id}`);
    }
    update(id, data) {
        return http.post(`keycontact?keycontactId=` + id, data);
    }
    search(data) {
        return http.post(`keycontact/search`,data);
    }

}

export default new keyContactService();