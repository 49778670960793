import React, { useState, useEffect } from 'react';
import logo from '../../components/Icons/Huddle Logo.png'
import './loader.css'

const SuccessLoader = () => {
  const [showTick, setShowTick] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  
  useEffect(() => {
    setShowLoader(true);
    setShowTick(true);
    // const timer_tick = setInterval(() => {
    //   setShowTick(true);
	//   setShowLogo(false);
    // }, 8000);
    // const timer_Section = setInterval(() => {
    //     setShowLoader(false);
    //   }, 10000);

    // return () => {
    //   clearInterval(timer_tick);
    //   clearInterval(timer_Section);
    // };
  }, []);

  return (
    <>
    {showLoader && (
	<div className="overlay">
            <div id="loader-section">
            {showTick && (
                <div id="tick-section">
                    <div className="success-animation">
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx={26} cy={26} r={25} fill="none" />
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                    </div>
                    <p id='success-message' style={{marginBottom:'0',fontSize:'larger',color:'black',fontWeight:550,paddingTop:'20px'}}></p>
                </div>
                )}
            </div>
    </div>
    )}
	</>
  );
};
export default SuccessLoader;