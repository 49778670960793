import React, { useMemo,useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { v4 as uuidv4 } from "uuid";

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import TextError from "../../components/Error/TextError";
import LabService from "./../../services/Lab/LabService.js";
import { Link, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

//react phone input
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

import countryList from 'react-select-country-list';


const ManageLab = () => {
  let history = useHistory(); // declare here, inside a React component.
  let { labId } = useParams();
  const practiceId = localStorage.getItem("practiceId");
  //const isAddMode = !resourceId;
  //const [base64, setImageBase64] = useState([], false);
 // const [logoPreview, setLogoPreview] = useState(null);

//   const SUPPORTED_FORMATS = [
//     "image/jpg",
//     "image/jpeg",
//     "image/png",
//     "application/pdf",
//   ];

const Countries = useMemo(() => countryList().getData(), [])

  const initialValues = {
    labName:""
    ,email:""
    ,website:""
    ,technicianName:""
    ,contactPhoneNo:""
    ,address:""
    ,streetName:""
    ,suburb:""
    ,postcode:""
    ,country:"Australia"
    ,isActive:true
  };

  const validationSchema = Yup.object({
    labName: Yup.string()
    .matches(/^[^-\s!@&#$%^~_\={};\[\]\\:\/"|,.<>?0-9*()+-][a-zA-Z0-9 &`'-]*$/g, "Please enter valid lab name")
    .required("Lab name is required"),
    email: Yup.string()
    .email("Enter valid email")
    .max(50, 'Email has a maximum length of 50 characters')
    .required("Email is required"),
    contactPhoneNo: Yup.string()
    .max(20, 'Office phone number has a maximum length of 20 characters')
    .min(10, 'Office phone number has a minimum length of 10 characters')
    .required("Office phone is required")
  });

  const handleSubmit = async (values) => {
    if (labId == 0) {
      createLab(values);
    } else {
      updateLab(values, labId);
    }
  };
  const createLab = async (values) => {
    values.practiceId = practiceId;
    var payload = JSON.stringify(values);
    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */
    await LabService.create(payload)
      .then((result) => {
        // toast(
        //   <Notification type="success" message="Resources saved sucessfully." />
        // );

        /* Success Loader Called */
        loader.style.display='none';
        successloader.style.display='block';
        success_message.innerHTML="Lab saved sucessfully";
        let timer =   setInterval(myTimer, 3000);
        function myTimer() {
            successloader.style.display='none';
            clearInterval(timer);  
        }

        history.push("/app/lablist");
      })
      .catch((e) => {
         /* Fail Loader Called */
         loader.style.display='none';
         failloader.style.display='block';
         fail_message.innerHTML="Lab not saved sucessfully.";
         let timer =  setInterval(myTimer, 3000);
         function myTimer() {
             failloader.style.display='none';
             clearInterval(timer);   
         }     

        toast(console.log(e));
      });
  };
  const updateLab = async (values) => {
    values.labId = labId;
    values.practiceId = practiceId;
    var payload = JSON.stringify(values);

    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

    await LabService.update(labId,payload)
      .then((result) => {
        // toast(
        //   <Notification type="success" message="Resources updated sucessfully." />
        // );

        /* Success Loader Called */
        loader.style.display='none';
        successloader.style.display='block';
        success_message.innerHTML="Lab updated sucessfully.";
        let timer =   setInterval(myTimer, 3000);
        function myTimer() {
            successloader.style.display='none';
            clearInterval(timer);  
        }

        history.push("/app/lablist");
      })
      .catch((e) => {
         /* Fail Loader Called */
         loader.style.display='none';
         failloader.style.display='block';
         fail_message.innerHTML="Lab not updated sucessfully.";
         let timer =  setInterval(myTimer, 3000);
         function myTimer() {
             failloader.style.display='none';
             clearInterval(timer);   
         }     

        toast(console.log(e));
      });
  };

  return (
    <div className="card">
      <h5 className="card-header"> {labId != 0 ? "Edit" : "Add"} Lab</h5>
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values,errors, touched, isSubmitting,handleChange, setFieldValue }) => {
            useEffect(() => {
              if (labId > 0) {
                LabService.get(labId).then((resource) => {
                  const fields = ["labName","technicianName","email","website","contactPhoneNo","address","streetName","suburb","postcode","country","isActive"];
                  fields.forEach((field) =>
                    setFieldValue(field, resource.data.data[field], false)
                  );
                });
              }
            }, []);
            return (
              <Form id="form-performance">
                <div className="row1">
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="labName"> Lab Name </label>
                    <div className="col-sm-10">
                      <Field placeholder="Enter Lab Name" className="form-control" type="text"  name="labName" id="labName" />
                      <ErrorMessage name="labName" component={TextError} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="technicianName"> Technician Name </label>
                    <div className="col-sm-10">
                      <Field placeholder="Enter Technician Name" className="form-control" type="text"  name="technicianName" id="technicianName" />
                      <ErrorMessage name="technicianName" component={TextError} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="email"> Email </label>
                    <div className="col-sm-10">
                      <Field placeholder="Enter Email" className="form-control" type="text"  name="email" id="email" />
                      <ErrorMessage name="email" component={TextError} />
                    </div>
                  </div>
                  
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="website"> Website </label>
                    <div className="col-sm-10">
                      <Field placeholder="Enter Website" className="form-control" type="text"  name="website" id="website" />
                      <ErrorMessage name="website" component={TextError} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="contactPhoneNo"> Contact PhoneNo </label>
                    <div className="col-sm-10">
                      {/* <Field placeholder="Enter Mobile" className="form-control" type="text"  name="mobileNo" id="mobileNo" /> */}
                      <ReactPhoneInput country={'au'} value={values.contactPhoneNo} onChange={(value, country, e, formattedValue)=>{
                                handleChange(e);
                                setFieldValue("contactPhoneNo",formattedValue);}}
                                  inputProps={{ name: 'contactPhoneNo', required: false, className:"form-control w-100"}} />
                      <ErrorMessage name="contactPhoneNo" component={TextError} />
                    </div>
                  </div>

                  {/* <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="officePhoneNo">Office Phone</label>
                    <div className="col-sm-10">
                    <ReactPhoneInput country={'au'} value={values.officePhoneNo} onChange={(value, country, e, formattedValue)=>{
                                handleChange(e);
                                setFieldValue("officePhoneNo",formattedValue);}}
                                  inputProps={{ name: 'officePhoneNo', required: true, className:"form-control w-100"}} />
                      <ErrorMessage name="officePhoneNo" component={TextError} />
                    </div>
                  </div> */}

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="address"> Address </label>
                    <div className="col-sm-10">
                      <Field placeholder="Enter Address" className="form-control" type="text"  name="address" id="address" />
                      <ErrorMessage name="address" component={TextError} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="streetName"> Street Name </label>
                    <div className="col-sm-10">
                      <Field placeholder="Enter Street Name" className="form-control" type="text"  name="streetName" id="streetName" />
                      <ErrorMessage name="streetName" component={TextError} />
                    </div>
                  </div>


                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="suburb"> Suburb </label>
                    <div className="col-sm-10">
                      <Field placeholder="Enter Suburb" className="form-control" type="text"  name="suburb" id="suburb" />
                      <ErrorMessage name="suburb" component={TextError} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="postcode"> Postcode </label>
                    <div className="col-sm-10">
                      <Field placeholder="Enter Postcode" className="form-control" type="text"  name="postcode" id="postcode" />
                      <ErrorMessage name="postcode" component={TextError} />
                    </div>
                  </div>

                  {/* <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="country"> Country </label>
                    <div className="col-sm-10">
                      <Field placeholder="Enter Country" className="form-control" type="text"  name="country" id="country" />
                      <ErrorMessage name="country" component={TextError} />
                    </div>
                  </div> */}

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="country"> Country </label>
                    <div className="col-sm-10">
                    <select className="form-control" as="select" name="country" required value={values.country} onChange={handleChange}>
                                        {Countries.map((option) => (
                                            <option key={uuidv4()} className="form-control" value={option.label}>
                                            {option.label}
                                            </option>
                                        ))}
                                    </select>
                      <ErrorMessage name="country" component={TextError} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor="isActive">  </label>
                    <div className="col-sm-10">
                    <div className="checkbox checkbox-primary ">
                        <Field
                          className="styled"
                          type="checkbox"
                          name="isActive"
                        />
                        <label htmlFor="isActive">IsActive</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-buttons offset-md-2">
                  <button
                    className="mr-3 btn btn-primary"
                    type="submit"
                    id="btnSubmit"
                  >
                    Save
                  </button>
                  <Link
                    to={"/app/lablist"}
                    className="mr-3 btn btn-secondary"
                  >
                    Cancel
                  </Link>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
export default ManageLab;
