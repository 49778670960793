const EnumHelper = {
    HolidayType: {
        National: 'national',
        Local: 'local',
        International: 'international',
    },
    HolidayCountry: {
        Australia: 'australia'
    },
    GroupBy: {
        Territory: 'territory',
        Country: 'country'
    },
    Roles: {
        Employee: "Employee",
        Admin: "Admin",
        PracticeAdmin: "Practice-Admin"
    },
    AllowedFileSizes: {
        uploadFiles: 2
    },
    PdfOption: {
        margin: [0, 0, 0, 0],
        filename: "",
        enableLinks:false,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2.5 },
        jsPDF: { unit: 'pt', format: "A4", orientation: 'landscape' }
    },
    ManageEmployee: {
        Performance: '2',
        Training: '3',
        Assignment: '4'
    },
    PreviewTypes:['image/png','application/pdf']
}
export default EnumHelper