import React, { useMemo, useState, useRef,useEffect } from "react";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import TextError from "../../components/Error/TextError";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import countryList from 'react-select-country-list';
import * as Yup from "yup";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import StripeService from "../../services/stripe/stripe.service";



const PracticeRegister = () => {

const Countries = useMemo(() => countryList().getData(), [])
const initialValues = {  id:0,
    firstname:"",
    lastname:"",
    practicename:"",
    role:"",
    email:"",
    abnnumber:"",
    address:"",
    phoneno:"",
    city:"",
    state:"",
    postalcode:"",
    country:"Australia" };

    const validationSchema = Yup.object().shape({
    firstname:Yup.string()
    .matches(/^[a-zA-Z`']*$/g, "Please enter valid First name")
    .max(50, 'First name has a maximum length of 50 characters')
    .required("First name is required"),
    lastname:Yup.string()
    .matches(/^[a-zA-Z`']*$/g, "Please enter valid Last name")
    .max(50, 'Last name has a maximum length of 50 characters')
    .required("Last name is required"),
    practicename:Yup.string()
    .matches(/^[^-\s][a-zA-Z0-9!@#$%^&~* ()_+\-={}`;\[\]\\':\/"|,.<>?]*$/g, "Please enter valid practice name")
    .max(100, 'Practice name has a maximum length of 100 characters')
    .required("Practice name is required"),
    role:Yup.string()
    .matches(/^[^-\s][a-zA-Z 0-9]*$/g, "Please enter valid role")
    .max(50, 'Role has a maximum length of 50 characters')
    .required("Role is required"),
    email:Yup.string()
    .email("Enter valid email")
    .max(50, 'Email has a maximum length of 50 characters')
    .required("Email is required"),
    abnnumber:Yup.string()
    .matches(/^[0-9]{11}$/, "Please provide numbers only with 11 digit"),
    phoneno:Yup.string()
    .matches(/^[^-\sa-zA-Za-zA-Z!@#$%^&~ _\={}`;\[\]\\':\/"|,.<>?][0-9*()+ -]*$/g, "Please enter valid phone number")
    .max(20, 'Phone number has a maximum length of 20 characters')
    .min(10, 'Phone number has a minimum length of 10 characters')
    .required("Phone number is required"),
    address:Yup.string()
    .max(125, 'Street address has a maximum length of 125 characters')
    .required("Street address is required"),
    city:Yup.string()
    .max(40, 'City has a maximum length of 40 characters')
    .required("City is required"),
    state:Yup.string()
    .max(40, 'State has a maximum length of 40 characters')
    .required("State is required"),
    postalcode:Yup.string()
    .max(8, 'Postal code has a maximum length of 8 characters')
    .required("Postal code is required"),
    country: Yup.string()
    .required("Country is required")
    })

const practiceRef = useRef();

const handleSubmitData = () => {

var checkpracticedata = {
    PracticeEmail : practiceRef.current.values.email,
    PracticeName : practiceRef.current.values.practicename,
    PracticePhone :  practiceRef.current.values.phoneno
}
        if(checkpracticedata.PracticeEmail != "" && checkpracticedata.PracticeName != "" && checkpracticedata.PracticePhone != "" )
        {
            //check for existing practice
            StripeService.isPracticeExist(checkpracticedata).then((res) => {

                if(JSON.stringify(res.data.data.isPracticeExist)=="true"){
                    // give alert if practice is already registed
                    alert("This practice is already registered. Please contact to administrator");
                }else{

                    // if practice is new then redirect to subscription plan 
                    if(Object.keys(practiceRef?.current?.errors).length == 0){
                        localStorage.setItem("Practice",JSON.stringify(practiceRef.current.values));
                        window.location = "/SubscriptionPlan";
                    }
                }
                })
                .catch((e) => {
                }); 

        }
};

useEffect(() => {
localStorage.removeItem("Practice");
});

return(
    <div className="card">
            <h5 className="card-header text-center">The Daily Huddle</h5>
            <div className="card-body ">
                {/* <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}> */}
                <div className="text-center mb-4">
                <p>30 days free trial, no obligation, cancel anytime, after that, Subscribe as per plan.</p>
                <p>Register now! (*t&c apply)</p>
                <h2>Register</h2>
                </div>
                
                <Formik  initialValues={initialValues} validationSchema={validationSchema} innerRef={practiceRef}>
                {({ values, errors, dirty, touched, handleChange, handleSubmit, isSubmitting }) => (
                    <Form id="form-employee" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
                        <div className="row justify-content-center">
                            <div className="col-md-10 border border-dark rounded ">
                                <div className="row align-center">
                                <div className="col-md-6 form-group">
                                    <label className="col-form-label null " htmlFor="firstname">First Name </label>
                                    <Field autoFocus  type="text" placeholder="Enter First Name" name="firstname" value={values.firstname} onChange={handleChange} className="form-control"  />
                                    <ErrorMessage name="firstname" component={TextError} />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label className="col-form-label null " htmlFor="lastname">Last Name </label>
                                    <input type="text" placeholder="Enter Last Name" name="lastname" value={values.lastname} onChange={handleChange} className="form-control"  />
                                    <ErrorMessage name="lastname" component={TextError} />
                                </div>
                                </div>
                                <div className="form-group has-validation">
                                    <label className="col-form-label null " htmlFor="practicename">Practice Name</label>
                                    <input type="text" placeholder="Enter Practice Name" name="practicename" value={values.practicename} onChange={handleChange} className="form-control"  />
                                    <ErrorMessage name="practicename" component={TextError} />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label null " htmlFor="role">Your Role</label>
                                    <input type="text" placeholder="Enter your role e.g. Practice Admin or Manager" name="role" value={values.role} onChange={handleChange} className="form-control"  />
                                    <ErrorMessage name="role" component={TextError} />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label null " htmlFor="email">Practice Email</label>
                                    <input type="text" placeholder="Enter Practice Email" name="email" value={values.email} onChange={handleChange} className="form-control"  />
                                    <ErrorMessage name="email" component={TextError} />
                                </div>
                                <div className="row">
                                <div className="col-md-6 form-group">
                                    <label className="col-form-label null " htmlFor="abnnumber">ABN Number</label>
                                    <input type="text"  placeholder="Enter ABN Number" name="abnnumber" value={values.abnnumber} onChange={handleChange} className="form-control"  />
                                    <ErrorMessage name="abnnumber" component={TextError} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label className="col-form-label null " htmlFor="phoneno">Phone No</label>
                                    <ReactPhoneInput country={'au'} value={values.phoneno} onChange={(value, country, e, formattedValue)=>{
                                            handleChange(e);
                                        }}
                                        inputProps={{ name: 'phoneno', required: true, className:"form-control w-100"}} />
                                    <ErrorMessage name="phoneno" component={TextError} />
                                </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label null " htmlFor="address">Street Address</label>
                                    <input type="text" placeholder="Enter Street Address" name="address" value={values.address} onChange={handleChange} className="form-control"  />
                                    <ErrorMessage name="address" component={TextError} />
                                </div>

                                <div className="row">
                                <div className="col-md-6 form-group">
                                    <label className="col-form-label null " htmlFor="city">City</label>
                                    <input type="text"  placeholder="Enter City" name="city" value={values.city} onChange={handleChange} className="form-control"  />
                                    <ErrorMessage name="city" component={TextError} />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label className="col-form-label null " htmlFor="state">State</label>
                                    <input type="text"  placeholder="Enter State" name="state" value={values.state} onChange={handleChange} className="form-control"  />
                                    <ErrorMessage name="state" component={TextError} />
                                </div>
                                </div>

                                <div className="row">
                                <div className="col-md-6 form-group">
                                    <label className="col-form-label null " htmlFor="postalcode">Postal Code</label>
                                    <input type="text"  placeholder="Enter Postal Code" name="postalcode" value={values.postalcode} onChange={handleChange} className="form-control"  />
                                    <ErrorMessage name="postalcode" component={TextError} />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label className="col-form-label null " htmlFor="country">Country</label>
                                    <select className="form-control" as="select" name="country" required value={values.country} onChange={handleChange}>
                                        {Countries.map((option) => (
                                            <option key={uuidv4()} className="form-control" value={option.label}>
                                            {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    <ErrorMessage name="country" component={TextError} />
                                </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="form-buttons text-center">
                            <button type="submit" onClick={handleSubmitData}  className="btn btn-primary m-4" >Next</button>
                            <Link to="/login" className="btn btn-secondary m-4">Cancel</Link>
                        </div>
                    </Form>
                    )}
                </Formik>
            </div>
        </div>
);

};
 export default PracticeRegister;