import React, { useEffect, useState,useRef } from "react";
import { Table } from "react-bootstrap";
import Logo from "../../components/Icons/SidebarIcons/SofiaLogo";
const cookiepolicy = function () {


    return ( 
    <div className="container">
        <br></br>
        <Table>
<tr>
    <div className="col-8"><td ><Logo /></td></div>
    <td>
        <p align="right"> Last updated on June 10, 2022</p>
        <p align="right"> The Daily Huddle Pty Ltd </p>
        <p align="right"> ACN 665 245 205 </p>
    </td>
</tr>

    </Table>
   <div className="col-3">

     </div>
   
<br></br>
<br></br>
<p align="center">
    <h4>COOKIES POLICY</h4>
</p>
<p>
    1 &nbsp;&nbsp;&nbsp;<strong>INTRODUCTION</strong>
</p>
<br></br>

<p>
    (a) This Cookies Policy applies between you and us, The Daily Huddle Pty Ltd
ACN 665 245 205 operating as The Daily Huddle (“<strong>we</strong>”, “    <strong>us</strong>”,<strong> </strong>or “<strong>our</strong>”).
</p>
<p>
(b) This Cookies Policy applies when you use our website accessible at    <a href="http://www.thedailyhuddleonline.com/" target="_blank"> <u>http://www.thedailyhuddleonline.com/</u> </a>(“<strong>Website</strong>”), and
    describes the types of cookies we use on our Website, how we use them, and
    how you can control them.
</p>
<p>
    (c) A cookie is a small file that’s stored on your computer or device when
    you visit a website that uses cookies. We may use several different cookies
    on our Website, for the purposes of website functionality, performance,
    advertising, and social media or content cookies. Cookies enhance your
    experience on our Website, as it allows us to recognise you, remember your
    details and preferences (for example, your log-in details), and provide us
    with information on when you’ve visited and how you’ve interacted with our
    Website.
</p>
<br></br>
<p>
    2 &nbsp;&nbsp;&nbsp; <strong>TYPES OF COOKIES WE USE</strong>
</p>
<br></br>
<p>
    The below table sets out the type of cookies we may collect on our Website.
</p>
<br></br>
<div className="container">
<table border="1" cellspacing="" cellpadding="5" width="1100">
    <tbody>
        <tr>
            <td width="159" valign="top">
                <p>
                    <strong>Strictly Necessary Cookies</strong>
                </p>
            </td>
            <td width="426" valign="top">
                <p>
                    Certain cookies we use are essential for the proper
                    functioning of our Website, without which our Website won’t
                    work or certain features won’t be accessible to you. For
                    example, we may need to remember data you’ve inputted from
                    one page to the next in a single session.
                </p>
            </td>
        </tr>
        <tr>
            <td width="159" valign="top">
                <p>
                    <strong>Performance Cookies</strong>
                </p>
            </td>
            <td width="426" valign="top">
                <p>
                    Performance cookies collect information about your use of
                    the Website to help enhance the services we provide to you.
                    We collect information about how you interact with the
                    Website, including the pages you visit and the frequency of
                    your visits. This information helps us identify patterns of
                    usage on the site, collect analytics data, identify issues
                    you may have had on the Website, make changes to enhance
                    your browsing experience, and analyse if our marketing is
                    effective and relevant to you.
                </p>
            </td>
        </tr>
        <tr>
            <td width="159" valign="top">
                <p>
                    <strong>Functional Cookies</strong>
                </p>
            </td>
            <td width="426" valign="top">
                <p>
                    We use functional cookies to improve your experience on our
                    Website and make things more convenient for you. These
                    cookies personalise your experience on our Website based on
                    your preferences, by remembering your details such as your
                    login details or region.
                </p>
                <p>
                    Security cookies are a type of functional cookie, which
                    assist with website and user account security. Load
                    balancing session cookies are used for the duration of the
                    session to distribute user requests across multiple servers
                    to optimize website speed and capacity. We may also use
                    user interface customization persistent cookies to store a
                    user’s preferred version of our Website, such as font and
                    language preferences.
                </p>
            </td>
        </tr>
        <tr>
            <td width="159" valign="top">
                <p>
                    <strong>Advertising cookies</strong>
                </p>
            </td>
            <td width="426" valign="top">
                <p>
                    Advertising cookies are used on our Website to provide you
                    with targeted marketing materials in accordance with your
                    interests and preferences. These cookies remember that you
                    visited our Website, and we may provide this information to
                    third-parties. These cookies usually cannot personally
                    identify you, so your anonymity is typically secured. These
                    cookies ensure that advertisements displayed to you are
                    things that may be of interest to you.
                </p>
            </td>
        </tr>
        <tr>
            <td width="159" valign="top">
                <p>
                    <strong>Content cookies</strong>
                </p>
            </td>
            <td width="426" valign="top">
                <p>
                    Content cookies are placed by many social media plugins
                    (like plugins that allow you to share content on Facebook),
                    and other tools to enhance the content displayed on a
                    website (for example, services that allow the playing of
                    video files). We integrate these plugins into our Website
                    to improve usability and customer experience. Some of these
                    third party services may place cookies that are also used
                    for the purposes of behavioural advertising or market
                    analysis.
                </p>
            </td>
        </tr>
    </tbody>
</table>
</div>
<br></br>
<p>
    3 &nbsp;&nbsp;&nbsp;<strong>How long will cookies remain on my device?</strong>
</p>
<br></br>
<p>
    The amount of time that a cookie remains on your computer or device depends
    on the type of cookie – cookies are either “persistent” or “session”
    cookies. Persistent cookies last until they expire or are deleted, so they
    may remain on your device for as little as 10 minutes to several years.
    Session cookies last until you stop browsing, so just for the relevant
    session.
</p>
<br></br>
<p>
    4 &nbsp;&nbsp;&nbsp;<strong>how do third parties use cookies on the website?</strong>
</p>
<br></br>
<p>
    We may use third party analytics cookies to collect information about your
    interaction with our Website. We also may use Google Analytics and other
    third-party analytics providers to help process data. To find out more, see &nbsp;
    <a href="http://www.google.com/policies/privacy/partners/">
         How Google uses data when you use our partners’ sites or apps
    </a>
    .
</p>
<br></br>
<p>
    5 &nbsp;&nbsp;&nbsp;<strong>How do I control cookies?</strong>
</p>
<br></br>
<p>
    (a) Usually, you can control and manage cookies through your browser. You
    can control whether or not your browser accepts cookies, how to filter and
    manage cookies, and how to delete cookies at the end of a session.
</p>
<p>
    (b) If you remove or block cookies, this may negatively impact your
    experience of our Website and you may not be able to access all parts of
    our Website.
</p>
<p>
    (c) Many third party advertising services allow you to opt out of their
    tracking systems, by giving you the opportunity to opt out by way of a
    pop-up before downloading cookies to your device.
</p>
<br></br>
<p>
    6 &nbsp;&nbsp;&nbsp;<strong>Updates TO THIS POLICY</strong>
</p>
<br></br>
<p>
    We may update this Cookies Policy from time to time. When we make changes,
    we’ll update the “Last updated” date at the top of the Cookies Policy and
    post it on our sites. We encourage you to check back periodically to review
    this Cookies Policy to ensure that you are aware of our current Cookies
    Policy.
</p></div>
) 
}

export default cookiepolicy;

