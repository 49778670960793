import React, { useEffect, useState,useRef } from "react";
import Logo from "../../components/Icons/SidebarIcons/SofiaLogo";

const platformpolicy = function () {


    return ( <div className="container">
<p align="center">
<div className="col-5">
    <br></br>
<Logo/>
<br></br>
<br></br>

</div>
<h4>    PLATFORM TERMS &amp; CONDITIONS</h4>
<br></br>
</p>
<p>
    Welcome to The Daily Huddle!
</p>
<br></br>
<p>
    To make it easier for you to understand the terms on which we provide our
    services, we’ve tried to keep these terms of use (<strong>Terms</strong>)
    as simple as possible by using plain English.
</p>
<br></br>

<p>
    These terms apply to our web and mobile application designed to help dental
    practice owners manage their everyday dental clinic operations, accessible
via    <a href="http://www.thedailyhuddleonline.com/" target="_blank">http://www.thedailyhuddleonline.com</a>
    , the Apple iOS Store and the Google Play Store (<strong>Platform</strong>
). We’ve set out these Terms to govern your subscription to the Platform (    <strong>Subscription</strong>).
</p>
<br></br>

<p>
When we talk about “<strong>The Daily Huddle</strong>”, “    <strong>we</strong>,” “<strong>our</strong>,” or “<strong>us</strong>” in
    these Terms, we are referring to The Daily Huddle Pty Ltd ACN 665 245 205 operating as The
    Daily Huddle. If you have any concerns regarding these
Terms and/or the Platform, please contact us at    <a href="mailto:team@thedailyhuddleonline.com">team@thedailyhuddleonline.com</a>.
</p>
<br></br>
<p>
    We’ve also used a few other capitalised words and phrases as shorthand to
    refer to recurring concepts. Each of these are defined in bold and in
    brackets after the concepts are first mentioned.
</p>
<br></br>
<p>
    By clicking the tick box on the Platform, paying for your Subscription,
    signing up for a Trial or otherwise accepting the benefit of any part of
    the Platform, you agree to be bound by these Terms which form a binding
contractual agreement between you or the dental practice you represent (    <strong>you</strong>) and The Daily Huddle. You represent and warrant that
    you have valid authority to enter into these Terms on behalf of any entity
    you may represent.
</p>
<br></br>
<p>
    <a name="_heading=h.30j0zll"></a>
  <strong>  1 &nbsp;&nbsp;&nbsp;INTRODUCTION</strong>
</p>
<br></br>
<p>
&nbsp;&nbsp;These Terms set out the terms and conditions that apply when you use the
    Platform.
</p>
<p>
&nbsp;&nbsp;By using the Platform or otherwise engaging with the content on the
    Platform, you represent and warrant that you:
</p>
<p>
&nbsp;&nbsp;&nbsp;  (a) are at least 18 years old, have the legal capacity to enter into a
    binding legal agreement with us and you agree to be bound by these &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Terms;
    and
</p>
<p>
&nbsp;&nbsp;&nbsp;  (b) operate a valid dental practice in the jurisdiction you are operating
    in.
</p>
<p>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We may modify our Terms from time to time. If we do, we will post a note on
    the Platform or email you. If you continue to use the &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Platform after we
    modify our Terms, you’ll be taken to have agreed to the Terms as modified.
</p>
<br></br>

<p>
    <a name="_heading=h.1fob9te"></a>
    <a name="_heading=h.3znysh7"></a>
    <strong>   2 &nbsp;&nbsp; ACCOUNT REGISTRATION </strong>
</p>
<br></br>
<p>
&nbsp;&nbsp;   In order to use the Platform, you will be required to sign up for an
    account (<strong>Account</strong>).
</p>
<p>
&nbsp;&nbsp;  When you register for an Account, you must provide true, accurate and
    complete information as requested and keep this information up &nbsp;&nbsp;&nbsp;to date
    after registration.
</p>
<br></br>
<p>
&nbsp;&nbsp;  You agree that you’re solely responsible for:
</p>
<br></br>
<p>
&nbsp;&nbsp;&nbsp;&nbsp;  (a) maintaining the confidentiality and security of your Account
    information and your password; and
</p>
<p>
&nbsp;&nbsp;&nbsp;&nbsp;  (b) any activities and those of any third party that occur through your
    Account, whether those activities have been authorised by you or &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;not.
</p>
<p>
&nbsp;&nbsp; You also agree to let us know if you detect any unusual activity on your
    Account as soon as you become aware of it.
</p>
<p>
&nbsp;&nbsp; We won’t be responsible to you for, and expressly disclaim any liability
    for, any cost, loss, damages or expenses arising out of a failure by &nbsp;&nbsp;&nbsp;you to
    maintain the security of your Account information or your password.
</p>
<br></br>
<p>
    <a name="_heading=h.2et92p0"></a>
    <strong> 3&nbsp;&nbsp;&nbsp; ACCOUNT LIMITATIONS </strong>
</p>
<br></br>
<p>
    You agree that your Account must only be used for the dental practice at
    the physical address as notified to us at the time the Account was
    registered. In the event you own and/or manage other dental practices at
    another physical locations, you must sign up for additional accounts in
    respect of these dental practices and pay the additional relevant
    subscription fees for these acconts.
</p>
<br></br>
<p>
<strong>  4 &nbsp;&nbsp;&nbsp;LOGIN DETAILS </strong>
</p>
<br></br>
<p>
    Upon creating an Account and providing us with all required information,
    you will be provided with master login details and additional login details
    for employees of your dental practice, up to a maximum number as reasonably
    determined by us.
</p>
<p>
    These different login details will access different versions of the
    Platform in terms of functionality and available information.
</p>
<p>
    You must ensure that your employees who you have authorised to use or
    access the Platform comply with the Terms.
</p>
<br></br>
<p>
<strong>   5 &nbsp;&nbsp;&nbsp;VERIFICATION </strong>
</p>
<br></br>
<p>
    (a) (<strong>Verification</strong>) We may offer or require you to verify
    your details (including company, practice and credit card details) using
our processes or an external identity verification service as applicable (    <strong>Verification Service</strong>).
</p>
<p>
    (b) (<strong>Your personal information and privacy</strong>) We will
    collect your personal information in accordance with our Privacy Policy as
    set out clause 18. Where a Verification Service is used, you acknowledge
    and agree that:
</p>
<p>
&nbsp;&nbsp;&nbsp;&nbsp;   (i) we may contact and share your personal information with a Verification
    Service to verify your details;
</p>
<p>
&nbsp;&nbsp;&nbsp;&nbsp;(ii) you consent to us receiving, sharing and using this information to
    enable us to carry out the Verification Service.
</p>
<p>
    (c) (<strong>Fees</strong>) We may charge non-refundable fees for the
    Verification Service, as set out on the Platform.
</p>
<br></br>

<p>
<strong>  6 FEES </strong>
</p>
<br></br>
<p>
    <a name="_Ref105760481">6.1 TRIAL</a>
</p>
<br></br>
<p>
    (a) We may offer a no cost trial which will allow you to access our
Platform for a free period as set out on the Platform (    <strong>Trial</strong>). If at the end of the Trial, you do not make a
    payment, then your Trial will end. If you wish to continue using our
    Platform, you must purchase a Subscription as set out in clause 6.2.
</p>
<p>
    (b) As a part of signing up for the Trial, we will collect your credit card
    details (<strong>Credit Card Details</strong>).
</p>
<p>
    (c) You acknowledge and agree that if you do not cancel your Trial before
    the end of the Trial period in accordance with clause 17, you will be
    automatically billed for the remainder of the relevant Subscription using
    the Credit Card Details.
</p>
<br></br>
<p>
    6.2 SUBSCription
</p>
<br></br>
<p>
    (a) Subject to clause 6.1, access to the Platform is only available via a
    paid subscription (<strong>Subscription</strong>). We may have different
    subscription periods (<strong>Subscription Period</strong>) and different
    levels of Subscriptions which have different functionalities. Details of
the Subscriptions as well as the associated fee for each (    <strong>Subscription Fees</strong>) will be set out on the Platform.
</p>
<p>
    (b) The Subscription Fees may be paid via the Platform or as otherwise
    notified by us to you, depending on our current prices and billing
    processes.
</p>
<p>
    (c) Once you subscribe to the Platform, your Subscription will continue to
    renew automatically at the end of each Subscription Period, and you will
    continue to be charged Subscription Fees unless you cancel your
    Subscription in accordance with clause 17.
</p>
<br></br>
<p>
    6.3 SUBSCRIPTION FEES AND PAYMENT
</p>
<br></br>
<p>
    (a) (<strong>When Subscription Fees are due</strong>) You must pay the
    Subscription Fee at the beginning of each Subscription Period. You may
    choose your preferred Subscription Period via the Platform.
</p>
<p>
    (b) (<strong>Automatic recurring billing</strong>) Your Subscription will
    continue to renew after the end of each Subscription Period, indefinitely,
    and you must pay Subscription Fees in respect of each Subscription Period
    in advance, unless you notify us before the end of the then current
    Subscription Period that you want to cancel your Subscription. Otherwise,
    we will continue to automatically charge the Subscription Fees from your
    nominated account at the beginning of each Subscription Period. We will not
    pay any charge back amount if you fail to cancel your Subscription. You
    acknowledge that your Subscription has an initial and recurring payment
    feature, and you accept responsibility for all recurring charges prior to
    your cancellation of your Subscription.
</p>
<p>
    (c) (<strong>Failure to pay</strong>) If Subscription Fees are not paid
when they are due, we may suspend your Account for 1 month (    <strong>Suspension Period</strong>). If you do not pay the outstanding
    Subscription Fees within the Suspension Period, we will revoke your
    Account.
</p>
<p>
    (d) (<strong>Third Party Payment Provider</strong>) We use third-party
    payment providers (<strong>Payment Provider</strong>) to collect
    Subscription Fees. The processing of payments by the Payment Provider will
    be, in addition to these terms, subject to the terms, conditions and
    privacy policies of the Payment Provider and we are not liable for the
    security or performance of the Payment Provider. We reserve the right to
    correct, or to instruct our Payment Provider to correct, any errors or
    mistakes in collecting your payment.
</p>
<p>
    (e) (<strong>Credit card surcharges)</strong> We reserve the right to
    charge credit card surcharges in the event payments are made using a
    credit, debit or charge card (including Visa, MasterCard or American
    Express).
</p>
<p>
    (f) (<strong>Changes to Subscription Fees</strong>) We reserve the right,
    from time to time, to change the Subscription Fees. We will notify you in
    advance if we do this.
</p>
<br></br>

<p>
    <a name="_heading=h.tyjcwt"></a>
    <strong> 7 &nbsp;&nbsp;&nbsp;REFUNDS </strong>
</p>
<br></br>
<p>
    Except as otherwise set out on our website or required by law (including
    the Australian Consumer Law), we don’t offer refunds for any of our
    subscriptions and any refunds we issue will be solely at our discretion.
    Please let us know if you have any issues with our Platform that you think
    should entitle you to a refund and we’ll consider your situation.
</p>
<br></br>
<p>
    <a name="_Ref105761740"></a>
    <a name="_heading=h.3dy6vkm"></a>
    <strong> 8 &nbsp;&nbsp;&nbsp;ACCEPTABLE USE </strong>
</p>
<br></br>
<p>
    We’ll need you to make a few promises about the way you’ll use the
    Platform.
</p>
<p>
    You agree:
</p>
<p>
    (a) not to copy, reproduce, translate, adapt, vary or modify the Platform
    without our express consent;
</p>
<p>
    (b) not to use the Platform in a manner that is illegal or fraudulent or
    facilitates illegal or fraudulent activity;
</p>
<p>
    (c) not to use the Service for the purpose of distributing unsolicited
    commercial content, junk mail, spam, bulk content or harassment;
</p>
<p>
    (d) not to attempt to breach the security of the Platform or The Daily
    Huddle’s system security, or otherwise interfere with the normal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;function
    of the Platform, including by:
</p>
<p>
&nbsp;&nbsp;&nbsp;&nbsp;(i) gaining unauthorised access to Accounts or data about other users of
    the Platform;
</p>
<p>
&nbsp;&nbsp;&nbsp;&nbsp;(ii) scanning, probing or testing the Platform for security
    vulnerabilities;
</p>
<p>
&nbsp;&nbsp;&nbsp;&nbsp;(iii) overload, flood, mailbomb, crash or submit a virus to the Platform or
    The Daily Huddle’s system; or
</p>
<p>
&nbsp;&nbsp;&nbsp;&nbsp;(iv) instigate or participate in a denial-of-service attack against the
    Platform or The Daily Huddle’s system.
</p>
<p>
    (e) If you breach or are suspected to have breached any of your obligations
    in this clause 8, The Daily Huddle reserves the right to suspend or cancel
    your Subscription without notice or any refunds.
</p>
<br></br>
<p>
    <a name="_heading=h.1t3h5sf"></a>
    <strong>    9 &nbsp;&nbsp;&nbsp;YOUR CONTENT </strong>
</p>
<br></br>
<p>
    9.1 TYPES OF CONTENT
</p>
<br></br>
<p>
    As part of using the Platform, you’ll be uploading images, content,
    information and materials you share with us or the public (including
    feedback, suggestions and enhancement requests), including by using the
    features of the app, sharing content via the app on social media or by
contacting us, or when you register an Account (    <strong>Posted Materials</strong>).
</p>
<br></br>
<p>
    9.2 POSTED MATERIALS
</p>
<br></br>
<p>
    By providing or posting any Posted Materials, you represent and warrant
    that:
</p>
<p>
    (a) you are authorised to provide the Posted Materials;
</p>
<p>
    (b) the Posted Materials are free from any harmful, discriminatory,
    defamatory or maliciously false implications and do not contain any
    offensive or explicit material;
</p>
<p>
    (c) the Posted Materials are not “passing off” of any product or service
    and does not constitute unfair competition;
</p>
<p>
    (d) the Posted Materials do not infringe any and all present and future
    intellectual and industrial property rights throughout the world (whether
    registered or unregistered), including copyright, trade marks, designs,
    patents, moral rights, semiconductor and circuit layout rights, trade,
    business, company and domain names, and other proprietary rights, trade
    secrets, know-how, technical data, confidential information and the right
    to have information kept confidential, or any rights to registration of
    such rights (including renewal), whether created before or after the date
    of this agreement (Intellectual Property Rights);
</p>
<p>
    (e) the Posted Materials are accurate and true at the time they are
    provided;
</p>
<p>
    (f) any Posted Materials which are in the form of a review or feedback is
    honest, accurate and presents a fair view of the relevant person and/or
    your experience;
</p>
<p>
    (g) the Posted Materials do not contain any viruses or other harmful code,
    or otherwise compromise the security or integrity of any network or system;
    and
</p>
<p>
    (h) the Posted Materials do not breach or infringe any applicable laws,
    regulations or orders.
</p>
<br></br>
<p>
    9.3 POSTED MATERIALS – IP LICENCE
</p>
<br></br>
<p>
    By uploading any Posted Materials, you grant to the Daily Huddle (and its
    agents or service providers) a perpetual, irrevocable, transferable,
    worldwide and royalty-free licence (including the right to sublicense) to
    use, copy, modify, reproduce and adapt any Intellectual Property Rights in
    that Posted Material in order for The Daily Huddle to use, exploit or
    otherwise enjoy the benefit of such Posted Material.
</p>
<br></br>
<p>
    9.4 REMOVAL OF POSTED MATERIALS
</p>
<br></br>
<p>
    We don’t have any obligations to screen Posted Materials in advance of them
    being posted and your compliance with these Terms is your responsibility.
    However, we may, if we choose, review and remove any Posted Materials at
    any time without giving any explanation or justification for removing the
    material and/or information.
</p>
<br></br>

<p>
    <a name="_heading=h.4d34og8"></a>
    <strong> 10&nbsp;&nbsp;&nbsp; OUR CONTENT </strong>
</p>
<br></br>
<p>
    Unless we indicate otherwise, all materials used in the Platform (including
    text, graphics, logos, icons, sound recordings and software) are subject to
    Intellectual Property Rights that are owned or licensed by us.
</p>
<p>
    You can only access and use these materials for the sole purpose of
    enabling you to use the Platform in accordance with the plan you are on,
    except to the extent permitted by law or where you have received prior
    written approval from us.
</p>
<br></br>
<p>
    <a name="_heading=h.2s8eyo1"></a>
    <strong>
    11 &nbsp;&nbsp;&nbsp;THIRD PARTIES </strong>
</p>
<br></br>
<p>
    11.1 &nbsp;THIRD PARTY CONTENT
    
</p>
<br></br>
<p>
    The Platform may contain text, images, data and other content provided by a
    third party (<strong>Third Party Content</strong>). We’re not responsible
    for any of this Third Party Content and we make no representation or
    warranty about the quality, suitability, accuracy, reliability, currency or
    completeness of any Third Party Content.
</p>
<br></br>
<p>
    11.2&nbsp; THIRD PARTY LINKS
</p>
<br></br>
<p>
The Platform may also contain links to websites operated by third parties (    <strong>Third Party Links</strong>). Third Party Links are provided for
    convenience and may not remain current or be maintained. We do not endorse
    and are not responsible for Third Party Links and have no control over or
    rights in linked websites.
</p>
<br></br>
<p>
    11.3 &nbsp;THIRD PARTY TERMS AND CONDITIONS
</p>
<br></br>
<p>
    By using the Platform, or any other third party service, you acknowledge
and agree that third party terms &amp; conditions (    <strong>Third Party Terms</strong>) may apply (including app store
    providers such as Apple and Google).
</p>
<p>
    You agree to any Third Party Terms applicable to any third party goods and
    services, and The Daily Huddle will not be liable for any loss or damage
    suffered by you in connection with such Third Party Terms.
</p>
<br></br>
<p>
    <a name="_heading=h.3rdcrjn"></a>
    <strong> 12&nbsp;&nbsp;&nbsp; NOTICE REGARDING APPLE</strong>
</p>
<br></br>
<p>
If you are accessing the Platform from the Apple, Inc. (    <strong>Apple</strong>) iOS Store, you acknowledge and agree:
</p>
<p>
    (a) these Terms are between you and The Daily Huddle and not with Apple.
    Apple is not responsible for the Platform or any content available on the
    Platform;
</p>
<p>
    (b) Apple has no obligation whatsoever to furnish any maintenance and
    support services for the Platform;
</p>
<p>
    (c) in the event of any failure of The Daily Huddle to conform to any
    applicable warranty, you may notify Apple, and Apple will refund the price
    for the Platform. To the maximum extent permitted by applicable law, Apple
    will have no other warranty obligation whatsoever with respect to the
    Platform, and any other claims, losses, liabilities, damages, costs of
    expenses attributable to any failure to conform to any warranty will be The
    Daily Huddle’s responsibility;
</p>
<p>
    (d) Apple is not responsible for addressing any claims by you or any third
    party relating to the Platform, including, but not limited to:
</p>
<p>
&nbsp;&nbsp;&nbsp;(i) product liability claims;
</p>
<p>
&nbsp;&nbsp;&nbsp;(ii) any claim that the Platform fails to conform to any applicable legal
    or regulatory requirement; and
</p>
<p>
&nbsp;&nbsp;&nbsp;(iii) claims arising under consumer protection, privacy, or similar
    legislation;
</p>
<p>
    (e) in the event of any third party claim that the Platform or your use of
    the Platform infringes any third party’s intellectual property rights,
    Apple will not be responsible for the investigation, defence, settlement
    and discharge of any such claim;
</p>
<p>
    (f) that you represent and warrant that:
</p>
<p>
&nbsp;&nbsp;(i) you are not located in a country that is subject to a U.S. Government
    embargo, or that has been designated by the U.S. Government &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;as a “terrorist
    supporting’” country; and
</p>
<p>
&nbsp;&nbsp;(ii) you are not listed on any U.S. Government list of prohibited or
    restricted parties;
</p>
<p>
    (g) you must comply with applicable third party terms of agreement when
    using the Platform; and
</p>
<p>
    (h) Apple, and Apple’s subsidiaries, are third party beneficiaries of these
    Terms, and that, upon your acceptance of these Terms, Apple will have the
    right (and will be deemed to have accepted the right) to enforce these
    Terms against you as a third party beneficiary.
</p>
<br></br>
<p>
<strong> 13 &nbsp;&nbsp;&nbsp;OPERATION OF THE APP DEPENDENT ON THIRD PARTIES</strong>
</p>
<br></br>
<p>
    You acknowledge that the Platform are dependent on software and hardware
    developed by third party providers such as Apple and Google. If following
    an update by such third party provider, the Platform can no longer function
    as they did prior to the update, we will not (to the maximum extent
    permitted by law) be liable to you for any loss or damage you might suffer
    as a result.
</p>
<br></br>
<p>
<strong>14&nbsp;&nbsp;&nbsp; SERVICE LIMITATIONS</strong>
</p>
<br></br>
<p>
    The Platform are made available to you strictly on an ‘as is’ basis. We
    can’t guarantee, and make no warranties, to the extent permitted by law,
    that:
</p>
<p>
    (a) the Platform will be free from errors or defects;
</p>
<p>
    (b) the Platform will be accessible or available at all times;
</p>
<p>
    (c) messages sent through the Platform will be delivered promptly, or
    delivered at all;
</p>
<p>
    (d) information you receive or supply through the Platform will be secure
    or confidential; or
</p>
<p>
    (e) any information provided through the Platform is accurate or true.
</p>
<br></br>
<p>
    <a name="_heading=h.26in1rg"></a>
    <strong>15&nbsp;&nbsp;&nbsp; SECURITY </strong>
</p>
<br></br>
<p>
    We do not accept responsibility for any unauthorised use, destruction,
    loss, damage or alteration to your data or information (including Posted
    Materials), your computer systems, mobile phones or other electronic
    devices arising in connection with use of the Platform. You should take
    your own precautions to ensure that the process which you employ for
    accessing the Platform does not expose you to the risk of hacking, malware,
    ransomware, viruses, malicious computer code or other forms of
    interference.
</p>
<br></br>
<p>
<strong>    16&nbsp;&nbsp;&nbsp; LIABILITY</strong>
</p>
<br></br>
<p>
    To the maximum extent permitted by applicable law, The Daily Huddle limits
    all liability to any person for loss or damage of any kind, however arising
    whether in contract, tort (including negligence), statute, equity,
    indemnity or otherwise, arising from or relating in any way to the Platform
    to the total amount of fees paid by you in the 3 months preceding the event
    which gives rise to the liability. This includes the transmission of any
    computer virus.
</p>
<p>
    You agree to indemnify The Daily Huddle and its employees, contractors and
    agents in respect of all liability for loss, damage or injury which may be
    suffered by any person arising from, or in connection with, you or your
    representatives use of the Platform and/or breach of these Terms.
</p>
<p>
    All express or implied representations and warranties given by us are, to
    the maximum extent permitted by applicable law, excluded.
</p>
<p>
    Nothing in this agreement is intended to limit the operation of the
Australian Consumer Law contained in the    <em>Competition and Consumer Act 2010</em> (Cth) (<strong>ACL</strong>).
    Under the ACL, you may be entitled to certain remedies (like a refund,
    replacement or repair) if there is a failure with the services we provide.
</p>
<p>
    To the maximum extent permitted by law, under no circumstances will The
    Daily Huddle be liable for any incidental, special or consequential loss or
    damages, or damages for loss of data, business or business opportunity,
    goodwill, anticipated savings, profits or revenue arising under or in
    connection with the Platform, these Terms or their subject matter (except
to the extent this liability cannot be excluded under the    <em>Competition and Consumer Act 2010</em> (Cth)).
</p>
<br></br>

<p>
    <a name="_Ref105760426"></a>
    <a name="_heading=h.lnxbz9"></a>
    <strong> 17&nbsp;&nbsp;&nbsp; CANCELLATION</strong>
</p>
<br></br>
<p>
    17.1 CANCELLATION BY YOU
</p>
<br></br>
<p>
    You are responsible for the cancellation of your Account. You can cancel
    your Account at any time by using the functionality provided in the app.
</p>
<br></br>
<p>
    17.2 CANCELLATION BY US
</p>
<br></br>
<p>
    To the extent permitted by law, we reserve the right to terminate your
    access to any or all of the Platform or any part of the Platform at any
    time without notice, for any reason, provided that we refund to you any
    Fees for Platform which you have paid for and not received.
</p>
<p>
    We may also terminate your access to any or all of the Platform at any time
    without notice without issuing a refund if you breach any provision of
    these Terms.
</p>
<br></br>
<p>
    17.3 EFFECT OF CANCELLATION
</p>
<br></br>
<p>
    Upon cancellation, termination or expiry of your Account, we will delete
    any Posted Materials associated with your Account. You won’t be able to
    recover any of this after cancellation, termination or expiry of your
    Account so we recommend you back up anything important to you. We won’t be
    responsible to you for, and expressly disclaim any liability for, any cost,
    loss, damages or expenses arising out the cancellation, termination or
    expiry of your Account.
</p>
<br></br>
<p>
    <a name="_heading=h.35nkun2"></a>
    17.4 SURVIVAL
</p>
<br></br>
<p>
    Any clause that by its nature would reasonably be expected to be performed
    after the termination or expiry of this agreement will survive and be
    enforceable after such termination or expiry.
</p>
<br></br>

<p>
<strong>
    <a name="_Ref105760361">18 &nbsp;&nbsp;&nbsp;PRIVACY</a> </strong>
</p>
<br></br>
<p>
    You agree to be bound by the clauses outlined in The Daily Huddle’s Privacy
    Policy, which can be found &lt;here&gt;.
</p>
<br></br>
<p>
<strong>
    19&nbsp;&nbsp;&nbsp; LOCATION OF PLATFORM </strong>
</p>
<br></br>
<p>
    The Daily Huddle controls the operation of the Platform from headquarters
    located in Australia. Some Platform or parts thereof may be operated from,
    or hosted on mirrors or servers, at various locations outside of Australia.
</p>
<p>
    We make no representation or warranty that all of the features of the
    Platform will be available to you outside of Australia or that they are
    permitted to be accessed outside Australia.
</p>
<p>
    You’re solely responsible for your decision to use the Platform from other
    locations and you acknowledge that such use may be subject to, and you are
    responsible for, compliance with applicable local laws in relation to your
    use of the Platform.
</p>
<br></br>
<p>
<strong>
    20 &nbsp;&nbsp;&nbsp;NOTICES </strong>
</p>
<br></br>
<p>
    (a) A notice or other communication to a party under this agreement must
    be:
</p>
<p>
&nbsp;&nbsp;&nbsp; (i) in writing and in English; and
</p>
<p>
&nbsp;&nbsp;&nbsp;  (ii) delivered via email to the other party, to the email address specified
    in this agreement, or if no email address is specified in this &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;agreement,
    then the email address most regularly used by the parties to correspond
    regarding the subject matter of this agreement &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;as at the date of this
    agreement (<strong>Email Address</strong>). The parties may update their
    Email Address by notice to the other party.
</p>
<p>
    (b) Unless the party sending the notice knows or reasonably ought to
    suspect that an email was not delivered to the other party’s Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Address,
    notice will be taken to be given:
</p>
<p>
&nbsp;&nbsp;&nbsp;  (i) 24 hours after the email was sent, unless that falls on a Saturday,
    Sunday or a public holiday in the state or territory whose laws &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;govern this
    agreement, in which case the notice will be taken to be given on the next
    occurring business day in that state, unless that &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;falls on a Saturday,
    Sunday or a public holiday in the state or territory whose laws govern this
    agreement, in which case the notice will &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; be taken to be given on the next
    occurring business day in that state or territory; or
</p>
<p>
&nbsp;&nbsp;&nbsp;(ii) when replied to by the other party,
</p>
<p>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; whichever is earlier.
</p>
<br></br>
<p>
<strong>
    21 GENERAL </strong>
</p>
<br></br>
<p>
    21.1 GOVERNING LAW AND JURISDICTION
</p>
<br></br>
<p>
    This agreement is governed by the law applying in New South Wales,
    Australia. Each party irrevocably submits to the exclusive jurisdiction of
    the courts of New South Wales and courts of appeal from them in respect of
    any proceedings arising out of or in connection with this agreement. Each
    party irrevocably waives any objection to the venue of any legal process on
    the basis that the process has been brought in an inconvenient forum.
</p>
<br></br>
<p>
    21.2 WAIVER
</p>
<br></br>
<p>
    No party to this agreement may rely on the words or conduct of any other
    party as a waiver of any right unless the waiver is in writing and signed
    by the party granting the waiver.
</p>
<br></br>
<p>
    21.3 SEVERANCE
</p>
<br></br>
<p>
    Any term of this agreement which is wholly or partially void or
    unenforceable is severed to the extent that it is void or unenforceable.
    The validity and enforceability of the remainder of this agreement is not
    limited or otherwise affected.
</p>
<br></br>
<p>
    21.4 JOINT AND SEVERAL LIABILITY
</p>
<br></br>
<p>
    An obligation or a liability assumed by, or a right conferred on, two or
    more persons binds or benefits them jointly and severally.
</p>
<br></br>
<p>
    21.5 ASSIGNMENT
</p>
<br></br>
<p>
    A party cannot assign, novate or otherwise transfer any of its rights or
    obligations under this agreement without the prior written consent of the
    other party.
</p>
<p>
    (a) COSTS
</p>
<p>
    Except as otherwise provided in this agreement, each party must pay its own
    costs and expenses in connection with negotiating, preparing, executing and
    performing this agreement.
</p>
<br></br>
<p>
    21.6 ENTIRE AGREEMENT
</p>
<br></br>
<p>
    This agreement embodies the entire agreement between the parties and
    supersedes any prior negotiation, conduct, arrangement, understanding or
    agreement, express or implied, in relation to the subject matter of this
    agreement.
</p>
<br></br>
<p>
    21.7 INTERPRETATION
</p>
<br></br>
<p>
    (a) (<strong>singular and plural</strong>) words in the singular includes
    the plural (and vice versa);
</p>
<p>
    (b) (<strong>currency</strong>) a reference to $; or “dollar” is to
    Australian currency;
</p>
<p>
    (c) (<strong>gender</strong>) words indicating a gender includes the
    corresponding words of any other gender;
</p>
<p>
    (d) (<strong>defined terms</strong>) if a word or phrase is given a defined
    meaning, any other part of speech or grammatical form of that word or
    phrase has a corresponding meaning;
</p>
<p>
    (e) (<strong>person</strong>) a reference to “person” or “you” includes an
    individual, the estate of an individual, a corporation, an authority, an
    association, consortium or joint venture (whether incorporated or
    unincorporated), a partnership, a trust and any other entity;
</p>
<p>
    (f) (<strong>party</strong>) a reference to a party includes that party’s
    executors, administrators, successors and permitted assigns, including
    persons taking by way of novation and, in the case of a trustee, includes
    any substituted or additional trustee;
</p>
<p>
    (g) (<strong>this agreement</strong>) a reference to a party, clause,
    paragraph, schedule, exhibit, attachment or annexure is a reference to a
    party, clause, paragraph, schedule, exhibit, attachment or annexure to or
    of this agreement, and a reference to this agreement includes all
    schedules, exhibits, attachments and annexures to it;
</p>
<p>
    (h) (<strong>document</strong>) a reference to a document (including this
    agreement) is to that document as varied, novated, ratified or replaced
    from time to time;
</p>
<p>
    (i) (<strong>headings</strong>) headings and words in bold type are for
    convenience only and do not affect interpretation;
</p>
<p>
    (j) (<strong>includes</strong>) the word “includes” and similar words in
    any form is not a word of limitation; and
</p>
<p>
    (k) (<strong>adverse interpretation</strong>) no provision of this
    agreement will be interpreted adversely to a party because that party was
    responsible for the preparation of this agreement or that provision.
</p></div>) 
}

export default platformpolicy;
