import http from "../../config/http-common";

class stripeService {
    createcheckoutsession(data) {
        return http.post(`StripeBilling/create-checkout-session`, data);
    }
    createTempPractice(data) {
        return http.post(`StripeBilling/temp-practice`, data);
    }
    createTempEmployee(data) {
        return http.post(`StripeBilling/temp-employee`, data);
    }
    getSucessRegister(practiceId) {
        return http.get(`StripeBilling/success-subscription?practiceId=${practiceId}`);
    }
    isPracticeExist(data) {
        return http.post(`StripeBilling/ispracticeexist`,data);
    }
    managesubscription(data) {
        return http.post(`StripeBilling/create-customer-portal-session`, data);
    }
    checkcancelledsubscription(practicemail) {
        return http.get(`StripeBilling/check-cancelled-subscription?practiceEmail=${practicemail}`);
    }
    subscribeSMS(practiceId,priceId="price_1PfjhJIWz02ZIyO43LJUqqIG"){
        return http.get(`StripeBilling/subscribesms?practiceId=${practiceId}&priceId=${priceId}`);
    }
    
    //delete(id) {
    //    return http.delete(`assignments`);
    //}
    //update(id, data) {
    //    return http.post(`assignments/${id}`, data);
    //}
}

export default new stripeService();