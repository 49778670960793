import http from "../../config/http-common";

class LabService {   
    // async getAll(data,pageNo) {
    //     return await http.post(`lab/${pageNo}`,data);
    // }
    async get(id) {
        return await http.get(`lab/${id}`);
    }
    // getPracticeLabs(id) {
    //     return http.get(`lab/getAll?practiceId=${id}`);
    // }
    async search(data) {
        return await http.post(`lab/search`,data);
    }
    async getAll(practiceId) {
        return await http.get(`lab/getAll?practiceId=`+practiceId);
    }
    async create(data) {
        return await http.post("lab?labId=0", data);
    }
    async delete(id) {
        return await http.delete(`lab/${id}`);
    }
    async update(id,data) {
        return  await http.post("lab?labId="+id, data);
    }
}

export default new LabService();