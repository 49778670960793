import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Col, Row, Table, Label } from "reactstrap";
import ReactPaginate from "react-paginate";
import Widget from "../../components/Widget/Widget.js";
import socialmediaService from "./../../services/socialmedia/socialmedia.service.js";
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import s from "./Tables.module.scss";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification.js";
import PreviewModal from "../pdfPreview/preview.js";
import * as html2pdf from 'html2pdf.js'
import EnumHelper from "../../helper/enum-helper";
import PdfHelper from "../../helper/pdf-helper";
import Spinner from "react-bootstrap/Spinner";
import Loader from "../../components/Loader/loader.js"
import SuccessLoader from "../../components/Loader/success-loader.js"
//DateRange Picker
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import CommonHelper from "../../helper/common-helper.js";

const SocialMediaList = function () {
  const [response, setSocialMediaList] = useState([], false);
  const [totalCount, setTotalCount] = useState([], false);

  //Date Range Textbox
  const [DateRange, setStateDateRange] = useState("", false);
  const [showloader, setLoading] = useState([], false);

  const [pdfURl, setPdfUrl ] = useState('', false);
  const [showModel , setModel] = useState(false);
  const [validIdea , setValidIdea] = useState(true, false);
  const [validTeamMember , setValidTeamMember] = useState(true, false);
//   const [validDescription , setValidDescription] = useState(true, false);
  const [validFiles , setValidFiles] = useState(true, false);
  const [validFilesSize , setvalidFilesSize] = useState(true, false);

//store Before Validate
const [Idea , setIdea] = useState('');
const [TeamMember , setTeamMember] = useState('');
const [Description , setDescription] = useState('');
const [Location , setLocation] = useState('');
const [Hashtag , setHashtag] = useState('');



  // uploaded files
  const [images, setImages] = useState([]);

  //Search Textbox
  const txtSearch = useRef(null);
  let pageNo = 1, pageSize = 10;
  const practiceId = localStorage.getItem("practiceId");
  const role = localStorage.getItem("role");

    const txtIdea = useRef(null);
    const txtMember = useRef(null);
    const txtDescription = useRef(null);
    const txtDate = useRef(CommonHelper.getISODateinLocalTZ(new Date()));
    const txtLocation = useRef(null);
    const txtHashTag = useRef(null);
    const upfile = useRef(null);



    useEffect(() => {
        getRecords();
        return () => {
            setSocialMediaList([]);
        };
    }, []);
    
  const handlePageClick = (data) => {
    let selected = data.selected + 1;
    pageNo = selected;
    getRecords();
  };
    
  const getRecords = async () => {
   txtDate.current.valueAsDate=new Date();
    var payload = { searchTerm: txtSearch.current.value, pageSize: pageSize, pageNumber: pageNo, practiceId: practiceId, dateRange: DateRange };

    setLoading(  true );

    await new Promise(function (resolve, reject) {
      socialmediaService
        .search(payload)
        .then((result) => {
          resolve(result);
          setSocialMediaList(result.data.data.socialMediaList);
          setTotalCount(Math.ceil(parseInt(result.data.data.totalRecords) / pageSize));
          pageNo = payload.pageNumber;
          setLoading(  false );
        })
        .catch((e) => {
         console.log(e);
        });
    });
  };

  const deleteRecord = async (socialMediaId) => {
    const result = await confirm("Are you sure? \nYou will not be able to recover this Content!");
    if (result) {
        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */
    await socialmediaService
      .delete(socialMediaId)
      .then((result) => {
        /* Success Loader Called */
        loader.style.display='none';
        successloader.style.display='block';
        success_message.innerHTML="Social Media record deleted successfully.";
        let timer =   setInterval(myTimer, 3000);
        function myTimer() {
            successloader.style.display='none';
            clearInterval(timer);  
        }
        // toast(<Notification type="success" message="Social Media record deleted successfully." />), 
        getRecords();
      })
      .catch((e) => {
        /* Fail Loader Called */
        loader.style.display='none';
        failloader.style.display='block';
        fail_message.innerHTML="Social Media record not deleted successfully";
        let timer =  setInterval(myTimer, 3000);
        function myTimer() {
            failloader.style.display='none';
            clearInterval(timer);   
        }
        toast(console.log(e));
      });
    }
  };



  const formatImage = async(e) => {
    try {
      for (let i = 0; i < e.target.files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[i]);
        reader.onload = (readerEvent) => {
          images.push({
            imageName:e.target.files[i].name,
            imageBase64:readerEvent.target?.result

          });
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const datePickerHandleApply = (event, picker) => {
    let selectedDate = picker.startDate.format("DD-MM-YYYY") + " - " + picker.endDate.format("DD-MM-YYYY");
    setStateDateRange(selectedDate);
    picker.element.val(selectedDate);
  };

    const addSocialMedia = async () => {      
        setIdea(txtIdea.current.value);
        setTeamMember(txtMember.current.value);
        setDescription(txtDescription.current.value);
        setHashtag(txtHashTag.current.value);
        setLocation(txtLocation.current.value);
        console.log('images --> '+ images.length);
        var isValidFileSize = true;
        
        if(upfile.current.files.length > 0){

            if(upfile.current.files.length > 3)
                {
                setValidFiles(false);
                setImages([]);
                }else{
                setValidFiles(true);
                const selectableMaxFileSize = 1024 * 1024 * 5; // 5 Megabyte

                let initialFileSize = 0

                                if (upfile.current.files) {
                for (let i = 0; i < upfile.current.files.length; i++) {
                        const file = upfile.current.files[i]
                        initialFileSize += file.size
                    }
                if (initialFileSize > selectableMaxFileSize) {
                    setvalidFilesSize(false);      
                    isValidFileSize=false;
                    setImages([]);
                }else{
                    setvalidFilesSize(true);
                }
                    }
                }
            }

           
        if(txtIdea.current.value==''){
            setValidIdea(false);
            setImages([]);
        }
        else{
            setValidIdea(true);
        } 
        if(txtMember.current.value==''){
            setValidTeamMember(false);
            setImages([]);
        }
        else{
            setValidTeamMember(true);
        }
        // if(txtDescription.current.value==''){
        //     setValidDescription(false);
        //     setImages([]);
        // }
        // else{
        //     setValidDescription(true);
        // }       

        // if(txtMember.current.value!='' && txtDescription.current.value!='' && txtIdea.current.value!='' && upfile.current.files.length <= 3 && isValidFileSize){
        if(txtMember.current.value!='' && txtIdea.current.value!='' && upfile.current.files.length <= 3 && isValidFileSize){
       
            let data = {
                "idea": txtIdea.current.value,
                "date": txtDate.current.value,
                "description": txtDescription.current.value,
                "practiceId": practiceId,
                "teamMember": txtMember.current.value,
                "location" : txtLocation.current.value,
                "hashTag" : txtHashTag.current.value,
                "socialMediaFiles" : images.length > 0 ? images : []
            }
           let loader = document.getElementById('loader');
           let successloader = document.getElementById('successloader');
           let failloader = document.getElementById('failloader');
           let success_message = document.getElementById('success-message');
           let fail_message = document.getElementById('fail-message');
           loader.style.display='block';/* Loader Section Activited */
            await socialmediaService.create(data).then(result =>{              
                if(result.data.statuscode === 200){
                    setImages([]);
                    getRecords(); 
                    /* Success Loader Called */
                    loader.style.display='none';
                    successloader.style.display='block';
                    success_message.innerHTML="Social Media record added successfully.";
                 let timer =   setInterval(myTimer, 3000);
                    function myTimer() {
                        successloader.style.display='none';
                        clearInterval(timer);  
                       
                    }
                  
                    // toast(<Notification type="success" message="Social Media record added successfully." />), 
                    getRecords();
                }
                else{
                    /* Fail Loader Called */
                    loader.style.display='none';
                    failloader.style.display='block';
                    fail_message.innerHTML="Social Media record not added successfully";
                    let timer =  setInterval(myTimer, 3000);
                    function myTimer() {
                        failloader.style.display='none';
                        clearInterval(timer);   
                    }               
                }
            }).catch(e => {
                
                /* Fail Loader Called */
                loader.style.display='none';
                failloader.style.display='block';
                fail_message.innerHTML="Social Media record not added successfully";
                let timer =  setInterval(myTimer, 3000);
                function myTimer() {
                    failloader.style.display='none';
                    clearInterval(timer);   
                }
                
                toast(console.log(e)); 
            }
            );
            setIdea('');
            setTeamMember('');
            setDescription('');
            setLocation('');
            setHashtag('');
        }    
    }

    const clearSocialMedia =() =>{
        txtIdea.current.value=txtDate.current.value=txtDescription.current.value=txtMember.current.value=txtLocation.current.value=txtHashTag.current.value=upfile.current.value='';
        setValidTeamMember(true); setValidIdea(true); setValidDescription(true);setvalidFilesSize(true);setValidFiles(true);
        txtDate.current.valueAsDate=new Date();
        setIdea('');
        setTeamMember('');
        setDescription('');
        setLocation('');
        setHashtag('');
        setImages([]);
        }

    const datePickerHandleCancel = (event, picker) => { picker.element.val(''); setStateDateRange('') }

    const handleOnPdfPreview = (event) => {                
        if(event.nativeEvent.target && event.nativeEvent.target.classList.contains('ppreview')){
            let options=EnumHelper.PdfOption, boundingRect=document.getElementById('main').getBoundingClientRect();
            options['jsPDF']['format']=[boundingRect.width-200,boundingRect.height-100];
            html2pdf().from(document.getElementById('main')).set(options).outputPdf().then((pdf) => {
                setPdfUrl(btoa(pdf));
                setModel(!showModel);
            })
        }
        else if(showModel){
            setModel(!showModel);
        }        
    }


    return (
        <div id ="main">      
            <Row>
                <Col>
                    <Row className="mb-4">
                        <Col>
                            <Widget>
                                <div className={s.tableTitle}>
                                    <div className="headline-2">Social Media Brainstorming</div>
                                    <div className="d-flex">                                        
                                        <a onClick={(e) => PdfHelper.printPage()}><img src={printerIcon} alt="Printer" /></a>
                                    <a className="mx-2 ppreview" onClick={(e) => { handleOnPdfPreview(e); }}><PreviewModal isOpen={showModel} url={pdfURl} /> Preview</a>
                                    <a onClick={() => PdfHelper.downloadPdf(document.getElementById('main'),"Social Media")}><img src={cloudIcon} alt="Download" /></a>
                                    </div>
                                </div>
                                <div className={s.tableTitle} style={{padding:"0px"}}>
                                    <Col style={{paddingLeft:"3px"}}>
                                        <input type="text" ref={txtSearch} name="txtSearch" className="form-control " placeholder="Search by Idea & Team Member" />
                                    </Col>
                                    {/* <Col className="col-5">
                                        <DateRangePicker onApply={datePickerHandleApply} onCancel={datePickerHandleCancel} initialSettings={{ autoUpdateInput: false, locale: { 'format': 'DD-MM-YYYY' } }}>
                                            <input type="text" className="form-control" defaultValue="" placeholder="Select Date" />
                                        </DateRangePicker>
                                    </Col> */}
                                    <Col>
                                        <input className="btn btn-secondary" type="button" value="Search" onClick={getRecords} />
                                    </Col>
                                </div>
                                <div className="widget-table-overflow">
                                    <Table className={`table-striped table-borderless table-hover responsive ${s.statesTable}`}>
                                        <thead>
                                            <tr>
                                                <th className="w-15">IDEA</th>
                                                <th className="w-15">LOCATION</th>
                                                <th className="w-15">TEAM MEMBERS</th>
                                                <th className="w-15">HASHTAG</th>
                                                <th className="w-15">DESCRIPTION / DETAILS</th>
                                                <th className="w-15">DATE</th>
                                                <th className="w-15">IMAGE<div style={{fontSize:"small",color:"black"}}>Max 5MB Allowed</div></th>
                                                <th className="W-10">ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={uuidv4()}>
                                                <td className="p-1"><input type="text" ref={txtIdea}  name="txtIdea" defaultValue={Idea}  className="form-control " placeholder="Idea" />
                                                <div className={validIdea?"error d-none":"error"}>Please enter idea</div>
                                                </td>
                                                <td className="p-1"><input type="text" ref={txtLocation}  name="txtLocation" defaultValue={Location} className="form-control " placeholder="Location" />
                                                </td>
                                                <td className="p-1"><input type="text" ref={txtMember}  name="txtMember" defaultValue={TeamMember} className="form-control " placeholder="Team Member" />
                                                <div className={validTeamMember?"error d-none":"error"}>Please enter team member</div></td>

                                                <td className="p-1"><input type="text" ref={txtHashTag}  name="txtHashTag" defaultValue={Hashtag} className="form-control " placeholder="Hashtag" />
                                                </td>

                                                <td className="p-1"><input type="text" ref={txtDescription} defaultValue={Description}  name="txtDescription" className="form-control " placeholder="Description" />
                                                {/* <div className={validDescription?"error d-none":"error"}>Please enter description</div> */} </td>
                                                <td className="p-1"><input type="date"  ref={txtDate} defaultValue={txtDate.current.value}  name="txtDate" className="form-control " /></td>

                                                <td className="p-1"> 
                                                    <input id="files" type="file"  accept="image/x-png,image/gif,image/jpeg,video/mp4" ref={upfile} name="upfile" style={{width:"118px"}} multiple  onChange={(e)=> formatImage(e)}/><br></br>
                                                     <div className={validFiles?"error d-none":"error"}>Maximum 3 files allowed</div>
                                                     <div className={validFilesSize?"error d-none":"error"}>Check files size, maximum 5 MB</div>
                                                </td>
                                                <td><Link to="#" onClick={() => addSocialMedia()} className={s.blackColor}><i className={'eva eva-checkmark-outline ' + s.greenColor} /> </Link> &nbsp;
                                                <a onClick={() => clearSocialMedia()} className={s.blackColor}><i className={'eva eva-close-outline ' + s.redColor} /></a></td>
                                            </tr>
                                            {showloader ? (
                  <tr>
                    <td rowSpan="4" colSpan="4">
                      <div className="text-center py-5">
                          <Spinner animation="border" />
                      </div>
                    </td>
                  </tr>
                ) : response.length == 0 ? (
                  <tr>
                    <td colSpan="4">
                      <div className="alert alert-secondary text-primary m-3 color">
                        No records found
                      </div>
                    </td>
                  </tr>
                ) : response.map(item => (
                                                    <tr key={uuidv4()}>
                                                        <td>{item.idea}</td>
                                                        <td>{item.location}</td>
                                                        <td>{item.teamMember}</td>
                                                        <td>{item.hashTag}</td>
                                                        <td>{item.description}</td>
                                                        <td>{item.strDate}</td>
                                                        <td></td>
                                                        <td>
                                                            <Link to={"/app/socialmedia/" + item.id} className={s.blackColor}><i className="fa fa-edit"></i></Link>&nbsp;
                                                            <Link to="#" onClick={() => deleteRecord(item.id)} className={s.blackColor}><i className="fa fa-trash"></i></Link></td>
                                                    </tr>
                                                ))}
                                            
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col className="ml-2"></Col>
                                        <Col className="col-10">
                                            <ReactPaginate breakLabel="..." nextLabel=" >" onPageChange={handlePageClick} pageRangeDisplayed={pageSize} pageCount={Math.ceil(totalCount)} previousLabel="< "
                                                renderOnZeroPageCount={null} containerClassName={'pagination pagination-sm'} pageClassName={'page-item'} pageLinkClassName={'page-link'}
                                                previousClassName={'previous'} previousLinkClassName={'page-item page-link'} nextClassName={'next'} nextLinkClassName={'page-item page-link'}
                                                activeClassName={'active'} />
                                        </Col>
                                    </Row>
                                </div>
                            </Widget>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
export default SocialMediaList;
