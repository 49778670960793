import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import TextError from "../../components/Error/TextError";
import LabService from "./../../services/Lab/LabService.js";
import { Link, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "./Tables.module.scss";
import EmployeeService from "./../../services/employee/employee.service";
import CommonHelper from "../../helper/common-helper.js";
import LabWorkService from "../../services/LabWork/LabWorkService.js";

// Modal Popup
import PatientSearchModal from "../patient/patient-search-popup.js";

import SelectSearch from 'react-select-search';
import 'react-select-search/style.css';




const ManageLabWork = () => {
  let history = useHistory(); 
  let { labworkId } = useParams();
  const practiceId = localStorage.getItem("practiceId");
  const [employees, setPracticeEmployees] = useState([], false);
  const [typeofwork, setTypeOfWork] = useState([], false);
  const [lablist, setLabList] = useState([], false);

  const [labworksentdate, setLabWorkSentDate] = useState(null,false);
  const [labworkparkeddate, setLabWorkParkedDate] = useState(null,false);
  const [labworkarriveddate, setLabWorkArrivedDate] = useState(null,false);
  const [appointmentbookeddate, setAppointmentBookedDate] = useState(null,false);
  
  const [patientId, setpatientId] = useState(0);
  const [patientName, setpatientName] = useState("");
  const[errormsg,setErrorMsg]=useState("");
  //let [pid] = useField("patientId");

  const initialValues = {
     doctorId:0
    ,teamMemberId:0
    ,labId:0
    ,patientId:0
    ,labWorkTypeId:0
    ,islabworksent:""
    ,islabworkparked:""
    ,islabworkarrived:""
    ,isappointmentbooked:""
    ,isActive:true
    ,notes:""
  };

  const validationSchema = Yup.object({
    doctorId: Yup.number()
    .moreThan(0, 'Please Select Doctor/Clinician'),
    teamMemberId: Yup.number()
    .moreThan(0, 'Please Select Team Member'),
    // patientId: Yup.number()
    // .moreThan(0, 'Please Select Patient'),
    labId: Yup.number()
    .moreThan(0, 'Please Select Lab'),
    labWorkTypeId: Yup.number()
    .moreThan(0, 'Please Select Labwork Type')
  });

const handleSubmit = async (values) => {
let objlabworksentdate = CommonHelper.getISODate(labworksentdate ? labworksentdate?.length==19 ? moment(labworksentdate, "DD/MM/YYYY HH:mm:ss") : new Date(labworksentdate) : null);
let objlabworkparkeddate = CommonHelper.getISODate(labworkparkeddate ? labworkparkeddate?.length==19 ? moment(labworkparkeddate, "DD/MM/YYYY HH:mm:ss") : new Date(labworkparkeddate) : null);
let objlabworkarriveddate = CommonHelper.getISODate(labworkarriveddate ? labworkarriveddate?.length==19 ? moment(labworkarriveddate, "DD/MM/YYYY HH:mm:ss") : new Date(labworkarriveddate) : null);
let objappointmentbookeddate = CommonHelper.getISODate(appointmentbookeddate ? appointmentbookeddate?.length==19 ? moment(appointmentbookeddate, "DD/MM/YYYY HH:mm:ss") : new Date(appointmentbookeddate) : null);

    let payload = {
      "id": labworkId,
      "doctorId": parseInt(values.doctorId),
      "teamMemberId": parseInt(values.teamMemberId),
      "labId": parseInt(values.labId),
      "patientId": parseInt(patientId),
      "labWorkTypeId": parseInt(values.labWorkTypeId),
      "lwSentDate": objlabworksentdate,
      "lwRecievedDate": objlabworkparkeddate,
      "lwArrivedDate": objlabworkarriveddate,
      "appointmentBooked": objappointmentbookeddate, 
      "notes": values.notes,
      "createdBy": parseInt(CommonHelper.getCurrnetUserId()),
      "practiceId": parseInt(practiceId),
      "isActive": true
    }

    createLabwork(payload);
  };


  useEffect(() => {
      labWorkTypes();
      practiceEmployees();
      labList();
  }, []);


  const practiceEmployees = async () => {
    await EmployeeService.getPracticeEmployees(practiceId)
      .then((result) => {
        setPracticeEmployees(result.data.data);
      })
      .catch((e) => {
        toast();
      });
  };


  const labWorkTypes = async () => {
    await LabWorkService.getlabworktypes()
      .then((result) => {
        setTypeOfWork(result.data.data);
      })
      .catch((e) => {
        toast();
      });
  };


  const labList = async () => {
    await LabService.getAll(practiceId)
      .then((result) => {
      setLabList(result.data.data.map(({labName: name, id:value }) => ({name,value })));
      }).catch((e) => {
        toast();
      });
   };

   const GetDateTimeinLocal = (date)  =>{
        if(date && date != ""){
          // convert utc date to local date time
          let Localdate=  CommonHelper.getISODateTimeinLocalTZ(new Date(date.replace("T"," ")));
          return CommonHelper.ToDateFormat(Localdate,"DD-MM-YYYY HH:mm:ss");
        }
   }

   const SetLabWorkDates = (date,type) => {
    if(type == "islabworksent"){
        setLabWorkSentDate(GetDateTimeinLocal(date).toString());
    } else if(type == "islabworkparked"){
        setLabWorkParkedDate(GetDateTimeinLocal(date));
    } else if(type == "islabworkarrived"){
        setLabWorkArrivedDate(GetDateTimeinLocal(date));
    } else if(type == "isappointmentbooked"){
        setAppointmentBookedDate(GetDateTimeinLocal(date));
    } 
  }

  const changeCheck = async (event, id) => {
   if(event.target.checked == true){

    if(id =="islabworksent"){
      setLabWorkSentDate(new Date().toString());
    } else if(id =="islabworkparked"){
      setLabWorkParkedDate(new Date().toString());
    }else if(id =="islabworkarrived"){
      setLabWorkArrivedDate(new Date().toString());
    }else if(id =="isappointmentbooked"){
      setAppointmentBookedDate(new Date().toString());
    }
   }
  }

  const createLabwork = async (payload) => {

    if (patientId <1){
      setErrorMsg("Please Select Patient");
    }
else{
    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

  
    await LabWorkService.create(payload)
      .then((result) => {

        /* Success Loader Called */
        loader.style.display='none';
        successloader.style.display='block';
        success_message.innerHTML="Labwork saved sucessfully";
        let timer =   setInterval(myTimer, 3000);
        function myTimer() {
            successloader.style.display='none';
            clearInterval(timer);  
        }

        history.push("/app/labworklist");
      })
      .catch((e) => {
         /* Fail Loader Called */
         loader.style.display='none';
         failloader.style.display='block';
         fail_message.innerHTML="Labwork not saved sucessfully.";
         let timer =  setInterval(myTimer, 3000);
         function myTimer() {
             failloader.style.display='none';
             clearInterval(timer);   
         }     

        toast(console.log(e));
      });
    }
  };

  function handleState(patientId,patientname) {
    setpatientId(patientId);
    setpatientName(patientname);
    if(patientId>0){
      setErrorMsg("");
    }
    
 }


  
 
  return (
    <div className="card">
      <h5 className="card-header"> {labworkId != 0 ? "Edit" : "Add"} Labwork</h5>
      <div className="card-body">
        <Formik
        id="labworkform"
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values,errors, touched, isSubmitting,handleChange, setFieldValue }) => {
            useEffect(() => {
              if (labworkId > 0) {
                LabWorkService.get(labworkId).then((result) => {
                  const fields = ["doctorId","teamMemberId","labId","patientId","labWorkTypeId","islabworksent","islabworkparked","islabworkarrived","isappointmentbooked","notes"];

                  const data = result.data.data;
                  fields.forEach((field) =>{
                  
                    setFieldValue(field, data[field], false);
                    if(field =="patientId"){
                      setpatientId( data[field]);
                      setpatientName(data["patientName"]);
                    }
                    if(field == "islabworksent"){
                      setFieldValue(field, data["lwSentDate"] != null ? true : false, false);
                      SetLabWorkDates(data["lwSentDate"]?.toString(),field);
                    }

                    if(field == "islabworkparked"){
                      setFieldValue(field, data["lwRecievedDate"] != null ? true : false, false);
                      SetLabWorkDates(data["lwRecievedDate"]?.toString(),field);
                    }

                    if(field == "islabworkarrived"){
                        if(data["lwArrivedDate"] != null){
                          setFieldValue(field, true);
                          SetLabWorkDates(data["lwArrivedDate"]?.toString(),field);
                        }
                      }

                      if(field == "isappointmentbooked"){
                        if(data["appointmentBooked"] != null){
                          setFieldValue(field, true);
                          SetLabWorkDates(data["appointmentBooked"]?.toString(),field);
                        }
                      }

                  });
                });
              }
            }, []);
            return (
              <Form id="form-performance">
                <div className="row1">

                <div className="form-group row">
                        <div className="col-sm-6">
                                        <label htmlFor="labName"> Doctor/Clinician </label>
                                        <Field className="form-control" as="select" id="doctorId" name="doctorId" >
                                                        <option key={uuidv4()} className="form-control" value="" > Select Doctor </option>
                                                        {employees.filter(x=>x.isDoctor==true).map((option) => (
                                                          <option key={uuidv4()} className="form-control" value={option.id} >{option.firstName} </option>
                                                        ))}
                                        </Field>
                                        <ErrorMessage name="doctorId" component={TextError} />
                            </div>

                          <div className="col-sm-6">
                                  <label htmlFor="teammember"> Team Member </label>
                                  <Field className="form-control" as="select" id="teamMemberId" name="teamMemberId" >
                                              <option key={uuidv4()} className="form-control" value="" > Select Team Member </option>
                                                {employees.map((option) => (
                                                  <option key={uuidv4()} className="form-control" value={option.id} > {option.firstName} </option>
                                                ))}
                                  </Field>
                                  <ErrorMessage name="teamMemberId" component={TextError} />
                        </div>
                  </div>


                    <div className="form-group row">
                      <div className="col-sm-6">
                          <label htmlFor="patientname"> Patient Name </label>
                          <Field placeholder="Patient Name" className="form-control" type="text" value={patientName} name="patientname" id="patientname" />
                          <label id="lblerror" style={{color:"red"}}>{errormsg}</label>
                           {/* <ErrorMessage name="patientId" component={TextError} /> */}
                      </div>
                     
                      <div className="col-sm-2" style={{paddingTop:"30px"}} >
                        <PatientSearchModal change = {handleState}></PatientSearchModal>
                      </div>
                   </div>


                   <div className="form-group row">
                   <div className="col-sm-6">
                              <label  htmlFor="labId"> Lab Name </label>
                              <SelectSearch options={lablist} value={values.labId} id="labId" search="true" name="labId" placeholder="Search Lab" onChange={(value)=>{setFieldValue("labId",value)}} />
                              <ErrorMessage name="labId" component={TextError} />
                        </div>
                        <div className="col-sm-6">
                              <label  htmlFor="labWorkTypeId"> Type of Labwork </label>
                              <SelectSearch options={typeofwork} value={values.labWorkTypeId} id="labWorkTypeId" search="true" name="labWorkTypeId" placeholder="Search Labwork Type" onChange={(value)=>{setFieldValue("labWorkTypeId",value)}} />
                              <ErrorMessage name="labWorkTypeId" component={TextError} />
                        </div>
                  </div>

                  <div className="form-group row table-responsive mt-5">
                      <table className="table table-striped">
                            <tbody>
                              <tr>
                                <td className="text-center"> 
                                  <div className="checkbox checkbox-primary m-0" style={{display:"inline-flex",padding:"0"}}>
                                        <Field className="styled" style={{width:"21px",height:"21px"}} type="checkbox" name="islabworksent" onClick={(e) => changeCheck(e,"islabworksent")} />
                                        <label className="font-weight-bold m-0" htmlFor="islabworksent">LabWork Sent?</label>
                                  </div>
                                </td>
                                {/* <td className="text-center">
                                  <div className="checkbox checkbox-primary m-0" style={{display:"inline-flex",padding:"0"}}>
                                        <Field className="styled" style={{width:"21px",height:"21px"}} type="checkbox" disabled={!labworksentdate} name="islabworkparked" onClick={(e) => changeCheck(e,"islabworkparked")} />
                                        <label className="font-weight-bold m-0" htmlFor="islabworkparked">Lab Work Parked</label>
                                        labworkparkeddate
                                  </div>
                                </td> */}
                                <td className="text-center">  
                                  <div className="checkbox checkbox-primary m-0" style={{display:"inline-flex",padding:"0"}}>
                                          <Field className="styled" style={{width:"21px",height:"21px"}} type="checkbox" disabled={!labworksentdate} name="islabworkarrived" onClick={(e) => changeCheck(e,"islabworkarrived")} />
                                          <label className="font-weight-bold m-0" htmlFor="islabworkarrived">Lab Work Arrived</label>
                                  </div>
                                </td>
                                <td className="text-center"> 
                                  <div className="checkbox checkbox-primary m-0" style={{display:"inline-flex",padding:"0"}}>
                                          <Field className="styled" style={{width:"21px",height:"21px"}} type="checkbox" disabled={!labworkarriveddate} name="isappointmentbooked"onClick={(e) => changeCheck(e,"isappointmentbooked")} />
                                          <label className="font-weight-bold m-0" htmlFor="isappointmentbooked">Appointment Booked</label>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td> <div><center><label id='lblsentdate'>{labworksentdate?.toString().length > 20 ? CommonHelper.ToDateFormat(new Date(labworksentdate),"DD-MM-YYYY HH:mm:ss"):labworksentdate}</label></center></div></td>
                                {/* <td> <div><center><label id='lblparkdate'>{labworkparkeddate?.toString().length > 20 ? CommonHelper.ToDateFormat(new Date(labworkparkeddate),"DD-MM-YYYY HH:mm:ss"):labworkparkeddate}</label></center></div></td> */}
                                <td> <div><center><label id='lblarrivedate'>{labworkarriveddate?.toString().length > 20 ? CommonHelper.ToDateFormat(new Date(labworkarriveddate),"DD-MM-YYYY HH:mm:ss"):labworkarriveddate}</label></center></div></td>
                                <td> <div><center><label id='lblappointmentdate'>{appointmentbookeddate?.toString().length > 20 ? CommonHelper.ToDateFormat(new Date(appointmentbookeddate),"DD-MM-YYYY HH:mm:ss"):appointmentbookeddate}</label></center></div></td>
                              </tr>
                            </tbody>
                      </table>
                  </div>

                    <div className="form-group row">
                    <div className="col-sm-12">
                    <label htmlFor="notes"> Notes </label>
                        <Field placeholder="Enter Notes" className="form-control"  as="textarea" type="text"  name="notes" id="notes" />
                        <ErrorMessage name="notes" component={TextError} />
                      </div>
                    </div>
                </div>

                <div className="form-buttons">
                  <button className="mr-3 btn btn-primary" type="submit" id="btnSubmit" > Save</button>
                  <Link to={"/app/labworklist"} className="mr-3 btn btn-secondary" > Cancel </Link>
                 
                </div>

              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
export default ManageLabWork;
