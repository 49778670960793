import React, { useEffect, useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ChangePasswordService from "../../../services/changePassword/changePassword.service"
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Notification from "../../../components/Notification/Notification.js";

//DateRange Picker
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const EmployeeUserModify = () => {
    const { id } = useParams();
    var curr = new Date(); curr.setDate(curr.getDate());
    const initialValues = { email: "", newPassword: "", confirmPassword: "", isBlocked: false };
    const [emailAddress, setStateEmailAddress] = useState("", false);
    let history = useHistory();
    const isBlockAccount = useRef(null);

    const validationSchema = Yup.object({
        newPassword: Yup.string().required("Password is required"),
        confirmPassword: Yup.string().when("newPassword", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("newPassword")],
                "New Password and Confirm Password must be same"
            )
        }).required("Confirm Password is Required")
    });

    useEffect(() => {
        //getUser();
        handleOnGetBlockAccount();
    }, []);

    // const getUser = async () => {
    //     await new Promise(function (resolve, reject) {
    //         ChangePasswordService.get(parseInt(id)).then(result => {
    //             setStateEmailAddress(result.data?.data?.user?.email);
    //             resolve(result);
    //         }).catch(e => {
    //             toast(<Notification type="error" message="Something went wrong, please try again" />);
    //         })
    //     });
    // }
    const handleSubmit = async (values) => {
        if (parseInt(id) != 0) {
            let payload = { "userId": id, "password": values.newPassword }

            let loader = document.getElementById('loader');
            let successloader = document.getElementById('successloader');
            let failloader = document.getElementById('failloader');
            let success_message = document.getElementById('success-message');
            let fail_message = document.getElementById('fail-message');
            loader.style.display='block';/* Loader Section Activited */

            await new Promise(function (resolve, reject) {
                ChangePasswordService.changePassword(payload).then(result => {

                    if (result.data.statuscode == 200) {
                        // toast(<Notification type="success" message={result.data.message} />);
                        
                        /* Success Loader Called */
                        loader.style.display='none';
                        successloader.style.display='block';
                        success_message.innerHTML=result.data.data;
                        let timer =   setInterval(myTimer, 3000);
                        function myTimer() {
                            successloader.style.display='none';
                            clearInterval(timer);  
                        }
                        
                        history.push('/app/dashboard');
                    }
                    else {
                        // toast(<Notification type="error" message={result.data.message} />);

                        /* Fail Loader Called */
                        loader.style.display='none';
                        failloader.style.display='block';
                        fail_message.innerHTML=result.data.data;
                        let timer =  setInterval(myTimer, 3000);
                        function myTimer() {
                            failloader.style.display='none';
                            clearInterval(timer);   
                        }      

                    }
                    if (result.data == null) {
                        // toast(<Notification type="error" message={result} />);

                        /* Fail Loader Called */
                        loader.style.display='none';
                        failloader.style.display='block';
                        fail_message.innerHTML=result;
                        let timer =  setInterval(myTimer, 3000);
                        function myTimer() {
                            failloader.style.display='none';
                            clearInterval(timer);   
                        }  

                    }
                    resolve(result);
                }).catch(e => {
                    // toast(<Notification type="error" message="Something went wrong, please try again" />);

                    /* Fail Loader Called */
                    loader.style.display='none';
                    failloader.style.display='block';
                    fail_message.innerHTML="Something went wrong, please try again";
                    let timer =  setInterval(myTimer, 3000);
                    function myTimer() {
                        failloader.style.display='none';
                        clearInterval(timer);   
                    }  
                })
            });
        } else {
            // toast(<Notification type="error" message="Unable to save date" />);

            /* Fail Loader Called */
            loader.style.display='none';
            failloader.style.display='block';
            fail_message.innerHTML="Unable to save date";
            let timer =  setInterval(myTimer, 3000);
            function myTimer() {
                failloader.style.display='none';
                clearInterval(timer);   
            }  

        }
    };

    const handleOnBlockAccount =async ()=>{
        let data={
            isBlock : isBlockAccount.current.checked,
            userId: parseInt(id)
        }

        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */
        
        await ChangePasswordService.changeBlockAccount(data).then(result => {
            if (result.data.statuscode == 200) {

                // toast(<Notification type="success" message={result.data.data} />);

                /* Success Loader Called */
                loader.style.display='none';
                successloader.style.display='block';
                success_message.innerHTML=result.data.data;
                let timer =   setInterval(myTimer, 3000);
                function myTimer() {
                    successloader.style.display='none';
                    clearInterval(timer);  
                }

            }
        }).catch(e => {
            // toast(<Notification type="error" message="Something went wrong, please try again" />);

            /* Fail Loader Called */
            loader.style.display='none';
            failloader.style.display='block';
            fail_message.innerHTML="Something went wrong, please try again";
            let timer =  setInterval(myTimer, 3000);
            function myTimer() {
                failloader.style.display='none';
                clearInterval(timer);   
            }  
        });
    };

    const handleOnGetBlockAccount =async ()=>{
        await ChangePasswordService.getBlockAccount(parseInt(id)).then(result => {
            if (result.data.statuscode == 200) {
                isBlockAccount.current.checked = result.data?.data?.isBlock || false;
            }
        }).catch(e => {
            // toast(<Notification type="error" message="Something went wrong, please try again" />);

            let failloader = document.getElementById('failloader');
            let fail_message = document.getElementById('fail-message');
            loader.style.display='block';/* Loader Section Activited */

            failloader.style.display='block';
            fail_message.innerHTML="Something went wrong, please try again";
            let timer =  setInterval(myTimer, 3000);
            function myTimer() {
                failloader.style.display='none';
                clearInterval(timer);   
            }

        });
    };

    return (
        <div>
            <div className="card">
                <h5 className="card-header">{parseInt(id) != 0 ? '' : ''} Change Password</h5>
                <div className="card-body">
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        <Form id="form-employee">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="password">New Password</label>
                                        <div className="col-sm-9">
                                            <Field placeholder="New Password" className="form-control" type="password" name="newPassword" id="newPassword" />
                                            <ErrorMessage name="newPassword" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="confirmPassword">Confirm Password</label>
                                        <div className="col-sm-9">
                                            <Field placeholder="Confirm Password" className="form-control" type="password" name="confirmPassword" id="confirmPassword" />
                                            <ErrorMessage name="confirmPassword" />
                                        </div>
                                    </div>
                                    {/* <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="confirmPassword">Block Account</label>
                                    <div className="checkbox checkbox-primary m-2">
                                        <input name="taskIsCompletedOneChk" type="checkbox" className="styled" />
                                        <label htmlFor="taskIsCompletedOneChk" />
                                    </div>
                                </div> */}
                                </div>
                            </div>
                            <div className="form-buttons">
                                <button className="mr-3 btn btn-primary" type="submit" id="btnSubmit">Save</button>
                            </div>
                            <hr></hr>

                        </Form>
                    </Formik>
                </div>
            </div>
            <div className="card">
                <h5 className="card-header">{parseInt(id) != 0 ? '' : ''} Block Account</h5>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="form-group row mt-2">
                                <div className=" col-sm-7">
                                        <div className="checkbox checkbox-primary ">
                                            <input name="IsBlockedChck" type="checkbox" className="styled" ref={isBlockAccount} />
                                            <label htmlFor="IsBlockLabel"    > Do you want to block this user ? </label>
                                            {/* <h3 className="col-sm-5 col-form-label">Do you want to block this user ?</h3> */}
                                        </div> 
                                </div>

                                <div className="form-buttons col-sm-5">
                                    <button className="mr-3 btn btn-primary" onClick={() => handleOnBlockAccount()} type="submit" id="btnSubmit">Save</button>

                                    <Link to="/app/employeelist" className="mr-3 btn btn-secondary">Cancel</Link>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    );
}
export default EmployeeUserModify;
