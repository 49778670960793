import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";

import Header from "../Header/Header.js";
import Sidebar from "../Sidebar/Sidebar.js";
import Footer from "../Footer/Footer.js";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs.js";
//import Login from "../../pages/login/Login.js"
import Dashboard from "../../pages/dashboard/Dashboard.js";
import DashboardAdmin from "../../pages/dashboardAdmin/dashboardAdmin.js";
import EmployeeList from "../../pages/employee/employee-list.js";
import EmployeeView from "../../pages/employee/components/employee-view.js";
import Employee from "../../pages/employee/manage-employee.js";
import AssignmentList from "../../pages/assignment/assignment-list.js";
import ManageAssignment from "../../pages/assignment/manage-assignment.js";
import PracticeList from "../../pages/practice/practice-list.js";
import Practice from "../../pages/practice/managepractice.js";
import ResourceList from "../../pages/resource/resource-list.js";
import ManageResource from "../../pages/resource/manage-resources.js";
import TrainingList from "../../pages/training/training-list.js";
import ManageTraining from "../../pages/training/manage-training.js";
import PerformanceList from "../../pages/performance/performance-list.js";
import ManagePerformance from "../../pages/performance/manage-performance.js";
import Register from "../../pages/register/Register.js";
import s from "./Layout.module.scss";
import TeamMeetingList from "../../pages/teammeeting/teammeeting-list.js";
import TeamMeeting from "../../pages/teammeeting/teammeeting.js";
import Marketing from "../../pages/marketing/marketing.js";
import MarketingList from "../../pages/marketing/marketing-list.js";
import SocialMedia from "../../pages/socialmedia/socialmedia.js";
import SocialMediaList from "../../pages/socialmedia/socialmedia-list.js"
import ChangePassword from "../../pages/changePassword/changePassword.js";
import ManageEmployeeView from "../../pages/employee/components/manage-employee-view"
import EmployeeUserManagement from "../../pages/employee/components/employee-user-management"
import ContactList from "../../pages/contact/contact-list.js";
import Contact from "../../pages/contact/contact.js";
import PublicHolidayList from "../../pages/publicHoliday/publicholiday-list.js";
import SchoolTermsList from "../../pages/schoolTerms/schoolterms-list.js";
import KeyContactList from "../../pages/keyContact/keycontact-list.js";
import KeyContact from "../../pages/keyContact/keycontact.js";
import PatientList from "../../pages/patient/patient-list.js";
import Patient from "../../pages/patient/patient.js";
import LabList from "../../pages/Lab/LabList.js";
import ManageLab from "../../pages/Lab/manage-Lab.js";
import LabWorkList from "../../pages/LabWork/LabWorkList.js";
import ManageLabWork from "../../pages/LabWork/manage-LabWork.js"
import AboutUs from "../../pages/aboutUs/aboutUs.js";
import TermsOfUse from "../../pages/termsOfUse/termsOfUse.js";
import DatePicker from "../../pages/dashboard/datepicker.js";
import Loader from "../../components/Loader/loader.js"
import SuccessLoader from "../../components/Loader/success-loader.js"
import FailLoader from "../../components/Loader/fail-loader.js"
import chat from '../../pages/Chat/chat.js'
class Layout extends React.Component {
    static propTypes = {
        sidebarOpened: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        sidebarOpened: false,
    };

    render() {
        return (
            <div>
            <div id='loader' style={{display:'none' , zIndex: 1100, position: 'fixed'}}>  <Loader ></Loader></div>
            <div id='successloader' style={{display:'none', zIndex: 1100, position: 'fixed' }}>  <SuccessLoader ></SuccessLoader></div>
            <div id='failloader' style={{display:'none' , zIndex: 1100, position: 'fixed'}}>  <FailLoader ></FailLoader></div>
            <div className={s.root}>
                <div className={s.wrap}>
                    <Header />
                    <Sidebar />
                    <main className={s.content} >
                        <Breadcrumbs url={this.props.location.pathname} />
                        <Switch>
                            {/*<Route path="/" exact render={() => <Redirect to="/login" />} />*/}
                            <Route path="/app/dashboard" exact component={Dashboard} />
                            <Route path="/app/dashboardAdmin" exact component={DashboardAdmin} />
                            <Route path="/app/practicelist" name="Practice" exact component={PracticeList} />
                            <Route path="/app/practice/:id" exact component={Practice} />
                            <Route path="/app/employee/:id" exact component={Employee} />
                            <Route path="/app/employeelist" exact component={EmployeeList} />
                            <Route path="/app/register/:id" exact component={Register} />
                            <Route path="/app/users/:id" exact component={Register} />
                            <Route path="/app/employee-view/:id" exact component={EmployeeView}/>
                            <Route path="/app/employee-view/:id/:index" exact component={EmployeeView}/>
                            <Route path="/app/manage-employee-view/:id" exact component={ManageEmployeeView}/>
                            <Route path="/app/manage-employee-usermanagement/:id" exact component={EmployeeUserManagement}/>
                            <Route path="/app/assignmentlist/:id" exact component={AssignmentList} />
                            <Route path="/app/assignment/:id/:assignmentId" exact component={ManageAssignment} />
                            <Route path="/app/assignment/:id/:assignmentId/:index" exact component={ManageAssignment} />
                            <Route path="/app/resourcelist" exact component={ResourceList} />
                            <Route path="/app/resources/:resourceId" exact component={ManageResource} />
                            <Route path="/app/traininglist/:id" exact component={TrainingList} />
                            <Route path="/app/training/:id/:trainingId" exact component={ManageTraining} />
                            <Route path="/app/training/:id/:trainingId/:index" exact component={ManageTraining} />
                            <Route path="/app/performancelist/:id" exact component={PerformanceList} />
                            <Route path="/app/performance/:id/:performanceId" exact component={ManagePerformance} />
                            <Route path="/app/performance/:id/:performanceId/:index" exact component={ManagePerformance} />
                            <Route path="/app/teammeetinglist" exact component={TeamMeetingList} />
                            <Route path="/app/teammeeting/:id" exact component={TeamMeeting} />
                            <Route path="/app/marketing/:index/:id" exact component={Marketing} />
                            <Route path="/app/marketinglist" exact component={MarketingList} />
                            <Route path="/app/marketinglist/:index" exact component={MarketingList} />
                            <Route path="/app/socialmedia/:id" exact component={SocialMedia} />
                            <Route path="/app/socialmedialist" exact component={SocialMediaList} />
                            <Route path="/app/changepassword/:userid" exact component={ChangePassword} />
                            <Route path="/app/contactlist" exact component={ContactList} />
                            <Route path="/app/contact/:id" exact component={Contact} />
                            <Route path="/app/schoolterms" exact component={SchoolTermsList} />
                            <Route path="/app/publicholidays" exact component={PublicHolidayList} />
                            <Route path="/app/keycontactlist" exact component={KeyContactList} />
                            <Route path="/app/keycontact/:id" exact component={KeyContact} />
                            <Route path="/app/aboutUs" exact component={AboutUs} />
                            <Route path="/app/termsOfUse" exact component={TermsOfUse} />
                            <Route path="/app/datepicker" exact component={DatePicker} />
                            <Route path="/app/patientlist" exact component={PatientList} />
                            <Route path="/app/patient/:id" exact component={Patient} />
                            <Route path="/app/lablist" exact component={LabList} />
                            <Route path="/app/lab/:labId" exact component={ManageLab} />
                            <Route path="/app/labworklist" exact component={LabWorkList} />
                            <Route path="/app/LabWork/:labworkId" exact component={ManageLabWork} />
                            <Route path="/app/chat/:id" exact component={chat} />
                            <Route path='*' exact render={() => <Redirect to="/error" />} />
                        </Switch>
                    </main>
                    <Footer />
                </div>
            </div></div>
        );
    }
}

function mapStateToProps(store) {
    return {
        sidebarOpened: store.navigation.sidebarOpened,
    };
}

export default withRouter(connect(mapStateToProps)(Layout));
