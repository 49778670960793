import React, { useEffect, useState, useRef, Component } from "react";
import { v4 as uuidv4 } from "uuid";
import { Alert, Col, Row, Table,} from "reactstrap";
import ReactPaginate from 'react-paginate';
import dashboardService from "./../../services/dashboard/dashboard.service";
import { Link } from "react-router-dom";

// Modal Popup
import Modal from 'react-bootstrap/Modal';
import CommonHelper from "../../helper/common-helper";


const DashboardSearchModal = function ({ change }) {

    const [response, setHuddleList] = useState([], false);
    const [totalCount, setTotalCount] = useState(0, false);

    /* Patient Search Input Fields */
    const txtHuddleSearch = useRef("");
    const practiceId =localStorage.getItem("practiceId");

    let pageSize = 5;
    let pageNo = 1;

    useEffect(() => {}, []);

    function getHuddleDate(event) {
       let huddleDate = event.target.name;
       change(huddleDate);
       handleModalClose();
    }
    const handlePageClick = (data) => {
        let selected = data.selected + 1;
        pageNo = selected;
         getHuddles();
    };

    /* Api call for fatching patient records with search parameters */
    const getHuddles = async () => {
     
        var payload = { 
                        "searchTerm": txtHuddleSearch.current.value, 
                        "pageSize": pageSize, 
                        "pageNumber": pageNo,
                        "practiceId" :practiceId
                    }
     
        await new Promise(function (resolve, reject) {
      
            dashboardService.search(payload).then(result => {
                resolve(result);
                setHuddleList(result.data.data.huddleList);
                setTotalCount(Math.ceil(parseInt(result.data.data.totalRecords) / pageSize));
                pageNo = payload.pageNumber;
                document.getElementById("huddleResult").style.display = "block";
            }).catch(e => {
               console.log(e);
            })
        });
        
    }


  

    
    const [show, setModalShow] = useState(false);
    const[searchdisable,setSearchdisable]=useState(true);
    const handleModalClose = () => {setModalShow(false); };
    const handleModalShow = () => {  
    getHuddles();
    setModalShow(true);
};
        const huddlesearchKeyPress=(e)=>{
            e.target.value.trim()!="" ?  setSearchdisable(false) : setSearchdisable(true);
        };

    return (
        <>          
            {/* Patient Search Popup */}
            <Modal show={show} onHide={handleModalClose} animation={false} size="xl">
                <Modal.Header closeButton><Modal.Title>Huddle Result</Modal.Title></Modal.Header>

                <Modal.Body>
                
                <div className="widget-table-overflow" style={{marginBottom: "0px", display: "none"}} id="huddleResult">
                    {totalCount == 0 ? (
                         <div className="alert alert-secondary text-primary m-3 color">
                         No records found
                       </div>
                    ) : (
                        <div>
                    <Table className={`table-striped table-borderless`} style={{border: ".5px Solid gray", marginTop: "20px"}} responsive>
                                        <thead>
                                            <tr>
                                                <th className="w-20">Title</th>
                                                <th className="w-20">Huddle Date</th>
                                                <th className="w-20">Category</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { response.map(item => (
                                                    <tr key={uuidv4()}>
                                                        <td><a onClick={getHuddleDate} name={CommonHelper.ToDateFormat(item.huddleDate,"MM/DD/YYYY")} style={{textDecoration: "underline"}}>{item.title} </a></td>
                                                        <td> <a onClick={getHuddleDate} name={CommonHelper.ToDateFormat(item.huddleDate,"MM/DD/YYYY")} style={{textDecoration: "underline"}}>
                                                        {CommonHelper.ToDateFormat(item.huddleDate,"DD-MM-YYYY")}  </a></td>
                                                        <td><a onClick={getHuddleDate} name={CommonHelper.ToDateFormat(item.huddleDate,"MM/DD/YYYY")} style={{textDecoration: "underline"}}>{item.catagory} </a></td>
                                                      
                                                    </tr>
                                                ))
                                                
                                            }

                                        </tbody>
                     </Table>

                                    <Row>
                                        <Col className="col-12">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=" >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={pageSize}
                                                pageCount={Math.ceil(totalCount)}
                                                previousLabel="< "
                                                renderOnZeroPageCount={null}
                                                containerClassName={'pagination pagination-sm'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'previous'}
                                                previousLinkClassName={'page-item page-link'}
                                                nextClassName={'next'}
                                                nextLinkClassName={'page-item page-link'}
                                                activeClassName={'active'}
                                            />
                                        </Col>
                                    </Row>
                                    </div>
                                    )}
                </div>
                    
                </Modal.Body>
            </Modal>

          
            <div className="row align-items-center justify-content-center">
            <input type="text" ref={txtHuddleSearch} name="txtHuddle" className="form-control col-md-6" onKeyUp={(e)=>huddlesearchKeyPress(e)} placeholder="Search Huddle" />
            <input className="btn btn-secondary ml-5" title="Search" disabled={searchdisable} onClick={handleModalShow}  type="button" value="Search Huddle" />
            </div>
        </>
    )
}
export default DashboardSearchModal;
