import http from "../../config/http-common";

class dashboardService {
    getDailyHurdles(date,practiceId) {
        return  http.get(`dashboard/dailyhuddles?date=${date}&practiceId=${practiceId}`);
    }
    getDailyHuddlesById(taskId)
    {
        return http.get(`dashboard/dailyhuddlesById?dailyHuddleId=${taskId}`)
    }
    create(data,practiceId) {        
        return http.post(`dashboard/dailyhuddles?practiceId=${practiceId}`, data);
    }
    delete(id) {
        return http.delete(`employees`);
    }
    update(data, practiceId) {
        return http.post(`dashboard/dailyhuddles?practiceId=${practiceId}`, data);
    }
    getDailyNotesCategories(date, practiceId) {
        return http.get(`dashboard/dailyNotesCategory?date=${date}&practiceId=${practiceId}`);
    }
    getAllEmployees() {
        return http.get(`employees/getAll`);
    }
    addDailyNotes(data,practiceId) {
        return http.post(`dashboard/PostDailyNotesDetails?practiceId=${practiceId}`,data);
    }
    updateCriticalDrivers(data, practiceId, criticalDriversId){
        return http.post(`criticaldriver/${practiceId}?criticalDriverId=${criticalDriversId}`,data);
    }
    getCriticalDrivers(practiceId){
        return http.get(`criticaldriver/practiceId?practiceId=${practiceId}`);
    }
    delete(practiceId,id) {
        return http.delete(`dashboard/DeleteTask?practiceId=${practiceId}&DailyHuddleId=${id}`);
    }
    search(data){
        return http.post(`dashboard/search`,data);
    }
}

export default new dashboardService();