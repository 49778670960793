import http from "../../config/http-common";

class trainingService {
    async getAll(data, id, pageNo) {
        return await http.post(`training/${id}/${pageNo}`, data);
    }
    get(employeeid, trainingId) {
        return http.get(`training/${employeeid}/${trainingId}`);
    }
    create(data,employeeId,trainingId) {
        return http.post(`training/${employeeId}?trainingId=${trainingId}`, data);
    }
    search(data) {
        return http.post(`training/search`, data);
    }
    //delete(id) {
    //    return http.delete(`assignments`);
    //}
    update(data, employeeId, trainingId) {
        return http.post(`training/${employeeId}?trainingId=${trainingId}`, data);
    }
    delete(id, trainingId) {
        return http.delete(`training/${id}/${trainingId}`);
    }
}

export default new trainingService();