import React, { useEffect, useState,useRef } from "react";
import { Table } from "react-bootstrap";
import Logo from "../../components/Icons/SidebarIcons/SofiaLogo";
const aboutus = function () {


    return ( 
    <div className="container">
 <p align="center">
<div className="col-5">
    <br></br>
<Logo/>
<br></br>
<br></br>

</div>
<strong>    ABOUT US</strong>
<br></br>
<br></br>
</p>
<p>&ldquo;Dedicated  to all team members who consistently help us to achieve and surpass key practice milestones.&rdquo;</p>

<p> My  gratitude to the team at Timeless Smiles Dental.</p>
<br/>

<p> As  a principal dentist, it is evident we invest a significant amount of time to achieve formal qualifications, without learning anything on how to create and conduct our own successful dental practice. For myself, managing the team and endless everyday administrative  duties was substantially more challenging than performing dentistry. I am confident this issue is one my colleagues can relate to.</p>
<br/>
<p> In the early years of building my practice, I attended  a mass of business related seminars and sought after coaching, each of which years later, I continue to take part in. It was here I learned the secret to the successful growth and systemisation of businesses, the Daily Huddle.</p>
<br/>
<p> It  got me thinking, this would undeniably be a revolutionary tool, should it be Dental specific. Hence, my research began to create&#8230; &ldquo;The Daily Huddle - For a Dentist, By a Dentist.&rdquo;</p>
<br/>
<p> The  Daily Huddle is designed to set up team members and dentists for a positive and productive day. It achieves this by establishing key tasks/events, and connects the team via face to face contact for optimal function. In my practice, starting the day with this  mechanism creates a safe and welcoming atmosphere extended to not only my team but our clients.</p>
<br/>
<p> My  team trialed numerous versions of &lsquo;The Daily Huddle,&rsquo; originally introduced through a hard copy. After years of drafts, trialing and feedback, &lsquo;The Daily Huddle&rsquo; became an essential tool. Now updated as a computer software, the platform is too valuable not  to share. Although we did not reinvent the wheel entirely, &lsquo;The Daily Huddle,&rsquo; is specific to your dental practice needs and is refined by the very people this app is designed for.</p>
<br/>
<p> The  Daily Huddle not only provides the crucial tools required for practice management but is inclusive of resources targeted at successfully expanding your business. Publishing &lsquo;The Daily Huddle,&rsquo; is my sincere effort to make your business operations and management  as easy as possible&#8230; Allowing your practice to grow, while you get back to dentistry.</p>
<br/>
<p> I  am forever grateful for the dedication of my team, which I could not have achieved this without.</p>
<br/>
<p>I  would also like to thank all my coaches and mentors over the years, for their insight and ideas to create the perfect &lsquo;Daily Huddle.&rsquo;</p>
<br/>
<p>Finally,  I offer the deepest gratitude to my family, for their continuous and unparalleled love, help and support.</p>
<br/>
<p> I  am excited for you to start your journey with us, and would love to hear how applying &lsquo;The Daily Huddle,&rsquo; to YOUR practice goes.</p>
<br/>
<p> Sincerely,</p>

<p> Dr.  Nimesh Patel.</p>
    </div>
    ) 
    }
    
    export default aboutus;
    
    