import React from "react";
import classnames from "classnames";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import "font-awesome/css/font-awesome.min.css";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import "eva-icons/style/eva-icons.css";
import s from "./IconsPage.module.scss";
import Resources from "../../resource/resource-list.js"
import Performance from "../../performance/performance-list.js"
import EmployeeModify from "../../employee/manage-employee.js"
import EmployeeUserManage from "../../employee/components/employee-user-management.js"

import Traning from "../../training/training-list.js"
import Assignment from "../../assignment/assignment-list.js"

class ManageEmployeeView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: "1",
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }

    render() {
        return (
            <Widget className="widget-p-md">
                <Nav tabs className="mb-4">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === "1" })}
                            onClick={() => { this.toggle("1"); }}
                        >
                            <p className="headline-3">Team Member</p>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === "2" })}
                            onClick={() => { this.toggle("2"); }}
                        >
                            <p className="headline-3">User Management</p>
                        </NavLink>
                    </NavItem>
                  
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <EmployeeModify/>
                    </TabPane>
                    <TabPane tabId="2">
                        <EmployeeUserManage/>
                    </TabPane>
                   
                </TabContent>
            </Widget>
        );
    }
}

export default ManageEmployeeView;
