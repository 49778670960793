import http from "../../config/http-common";

class performanceService {
    async getAll(data, id, pageNo) {
        return await http.post(`performance/${id}/${pageNo}`, data);
    }
    get(employeeId, PerformanceId) {
        return http.get(`performance/${employeeId}/${PerformanceId}`);
    }
    create(data,employeeID,performanceId) {
        return http.post(`performance/${employeeID}?performanceId=${performanceId}`, data);
    }
    search(data) {
        return http.post(`performance/search`, data);
    }
    //delete(id) {
    //    return http.delete(`performance`);
    //}
    update(data, employeeID, performanceId) {
        return http.post(`performance/${employeeID}?performanceId=${performanceId}`, data);
    }
    delete(id, performanceId) {
        return http.delete(`performance/${id}/${performanceId}`);
    }
}

export default new performanceService();