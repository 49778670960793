import http from "../../config/http-common";

class marketingService {
    async getAll(pageNo, data) {
        return await http.post(`marketing/${pageNo}`,data);
    }
    get(id) {
        return http.get(`marketing/${id}`);
    }
    async create(data) {
        return await http.post("marketing", data);
    }
    async delete(id) {
        return await http.delete(`marketing/${id}`);
    }
    update(id, data) {
        return http.post("marketing?marketingId=" + id, data);
    }
    search(data) {
        return http.post(`marketing/search`,data);
    }

}

export default new marketingService();