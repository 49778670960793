import React from "react";
import HbLogo from "../../components/Icons/SidebarIcons//HuddleBook_logo.jpg";

const trainingvideos = function () {


    return ( 
<div className="container">
   <div className="text-center">
      <br></br>
      <div>
      <img src={HbLogo} style={{ width: "37%" }} />
      </div>
      <br></br>
 
   <h4>Training Videos</h4>
   <br></br>
   </div>
   <br></br>
      <table className="table table-bordered text-left">
         <tbody>
            <tr>
               <td className="font-weight-bold">Practice Admin Login</td>
               <td className="text-center"><a target="_blank" href="https://youtu.be/RlLXaKBag6c"><img src="http://i1.ytimg.com/vi/RlLXaKBag6c/default.jpg" title="YouTube Video" alt="YouTube Video" /></a></td>
            </tr>
            <tr>
               <td className="font-weight-bold">Create Team Member as an Employee</td>
               <td className="text-center"><a target="_blank" href="https://youtu.be/x8Y9QtLzxBI"><img src="http://i1.ytimg.com/vi/x8Y9QtLzxBI/default.jpg" title="YouTube Video" alt="YouTube Video" /></a></td>
            </tr>
            <tr>
               <td className="font-weight-bold">Edit Practice by Practice Admin</td>
               <td className="text-center"><a target="_blank" href="https://youtu.be/Yq3KvVBdUPQ"><img src="http://i1.ytimg.com/vi/Yq3KvVBdUPQ/default.jpg" title="YouTube Video" alt="YouTube Video" /></a></td>
            </tr>
            <tr>
               <td className="font-weight-bold">Change or Update Password</td>
               <td className="text-center"><a target="_blank" href="https://youtu.be/Sq-mqyHAD9w"><img src="http://i1.ytimg.com/vi/Sq-mqyHAD9w/default.jpg" title="YouTube Video" alt="YouTube Video" /></a></td>
            </tr>
            <tr>
               <td className="font-weight-bold">Block Team Member by Practice Admin only</td>
               <td className="text-center"><a target="_blank" href="https://youtu.be/7OpR94KRLEE"><img src="http://i1.ytimg.com/vi/7OpR94KRLEE/default.jpg" title="YouTube Video" alt="YouTube Video" /></a></td>
            </tr>
            <tr>
               <td className="font-weight-bold">Huddlebook Dashboard - Daily Notes and Critical Driver</td>
               <td className="text-center"><a target="_blank" href="https://youtu.be/q53p8gy6N54"><img src="http://i1.ytimg.com/vi/q53p8gy6N54/default.jpg" title="YouTube Video" alt="YouTube Video" /></a></td>
            </tr>
            <tr>
               <td className="font-weight-bold">Huddlebook Dashboard - How to Enter and Edit Daily Huddle</td>
               <td className="text-center"><a target="_blank" href="https://youtu.be/zQ4a19LtNP4"><img src="http://i1.ytimg.com/vi/zQ4a19LtNP4/default.jpg" title="YouTube Video" alt="YouTube Video" /></a></td>
            </tr>
            <tr>
               <td className="font-weight-bold">Add Performance, Training, Assignments</td>
               <td className="text-center"><a target="_blank" href="https://youtu.be/ieywiXVWUQU"><img src="http://i1.ytimg.com/vi/ieywiXVWUQU/default.jpg" title="YouTube Video" alt="YouTube Video" /></a></td>
            </tr>
            <tr>
               <td className="font-weight-bold">Social Media Marketing</td>
               <td className="text-center"><a target="_blank" href="https://youtu.be/aDQlmPTR6QY"><img src="http://i1.ytimg.com/vi/aDQlmPTR6QY/default.jpg" title="YouTube Video" alt="YouTube Video" /></a></td>
            </tr>
            <tr>
               <td className="font-weight-bold">Enter Key Contacts</td>
               <td className="text-center"><a target="_blank" href="https://youtu.be/G0VawTU_KaI"><img src="http://i1.ytimg.com/vi/G0VawTU_KaI/default.jpg" title="YouTube Video" alt="YouTube Video" /></a></td>
            </tr>
            <tr>
               <td className="font-weight-bold">Upload Resources</td>
               <td className="text-center"><a target="_blank" href="https://youtu.be/q8B5d4HJViA"><img src="http://i1.ytimg.com/vi/q8B5d4HJViA/default.jpg" title="YouTube Video" alt="YouTube Video" /></a></td>
            </tr>
            <tr>
               <td className="font-weight-bold">Set up Team Meetings</td>
               <td className="text-center"><a target="_blank" href="https://youtu.be/-myDBFkviJQ"><img src="http://i1.ytimg.com/vi/-myDBFkviJQ/default.jpg" title="YouTube Video" alt="YouTube Video" /></a></td>
            </tr>
            <tr>
               <td className="font-weight-bold">Plan your Marketing</td>
               <td className="text-center"><a target="_blank" href="https://youtu.be/Zjq3ZXBOUfY"><img src="http://i1.ytimg.com/vi/Zjq3ZXBOUfY/default.jpg" title="YouTube Video" alt="YouTube Video" /></a></td>
            </tr>
         </tbody>
      </table>
</div>) 
}

export default trainingvideos;
