import React, {useState,useEffect } from "react";
import 'react-phone-input-2/lib/style.css'
import StripeService from "../../services/stripe/stripe.service";
import { Link } from "react-router-dom";
import CommonHelper from "../../helper/common-helper.js";


const SuccessRegister = () => {
    const [users, setUsers] = useState({})
    var practice = JSON.parse(localStorage.getItem("Practice"));
    var practiceEmail = practice?.email;
    var Name = practice?.firstname + " " +practice?.lastname



    
useEffect(()=> {
     if(JSON.stringify(localStorage.getItem("Practice"))  == "null"){
        window.location = "/login";
    }else{
       var practiceId = JSON.parse(localStorage.getItem("tempPracticeId"));
       //alert(JSON.stringify(practiceId));
       getSuccessRegister(practiceId);

    }
},[]);


const getSuccessRegister = async (practiceId) => {
    await StripeService.getSucessRegister(practiceId).then((res) => {
        setUsers(res.data.data);
          })
          .catch((e) => {
          }); 
      }

return(
    <div className="card mx-auto subcontainer">
            <h5 className="card-header text-center">The Daily Huddle</h5>
            <div className="card-body">
                <div className="text-center">
                <p className="theme-color">Your subscription started successfully</p>
                </div>

                <div className="row justify-content-center">
                    <div className="card text-center sub-border">
                        <table className="table table-bordered text-left">
                            <thead>
                                    <tr>
                                        <th colSpan={2} className="text-center">  Your Subscription </th>
                                    </tr>
                            </thead>
                                <tbody>
                                    <tr>
                                        <td>Name</td>
                                        <td>{Name}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{practiceEmail}</td>
                                    </tr>
                                    <tr>
                                        <td>Plan</td>
                                        <td>{users?.subscriptionType}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>{users?.status}</td>
                                    </tr>
                                    <tr>
                                        <td>Trial Period End On : </td>
                                        <td>{CommonHelper.formatDate(users?.trialPeriodEndOn)}</td> 
                                    </tr>
                                </tbody>
                        </table>
                        <Link to="/login">Login</Link>
                    </div>
                </div>
            </div>
        </div>
);

};
 export default SuccessRegister;