import React from 'react';
import HbLogo from '../SidebarIcons/HuddleBook_logo.jpg'

class Logo extends React.Component {

  render() {
    return (
        <img src={HbLogo} className='img-logo-width' />
    );
  }
}

export default Logo;
