import http from "../../config/http-common";

class socialmediaService {
    async getAll(pageNo, data) {
        return await http.post(`socialmedia/${pageNo}`, data);
    }
    get(id) {
        return http.get(`socialmedia/${id}`);
    }
    async create(data) {
        return await http.post("socialmedia?socialMediaId=0", data);
    }
    async delete(id) {
        return await http.delete(`socialmedia/${id}`);
    }
    update(id, data) {
        return http.post(`socialmedia?socialMediaId=` + id, data);
    }
    search(data) {
        return http.post(`socialmedia/search`,data);
    }

}

export default new socialmediaService();