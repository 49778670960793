import React, { useEffect, useState, useRef, Component } from "react";
import { v4 as uuidv4 } from "uuid";
import { Alert, Col, Row, Table,} from "reactstrap";
import ReactPaginate from 'react-paginate';

import patientService from "./../../services/patient/patient.service"
import sampleCsv from "../../components/SampleFile/PatientList.csv"


// Modal Popup
import Modal from 'react-bootstrap/Modal';
import CommonHelper from "../../helper/common-helper";


const PatientBulkUploadModal = function ({ change }) {

    const [response, setHuddleList] = useState([], false);
    const [totalCount, setTotalCount] = useState(0, false);

    /* Patient Search Input Fields */
    const txtHuddleSearch = useRef("");
    const practiceId =localStorage.getItem("practiceId");

    let pageSize = 5;
    let pageNo = 1;

    useEffect(() => {}, []);

    function getHuddleDate(event) {
       change(true);
       handleModalClose();
    }
    const handlePageClick = (data) => {
        let selected = data.selected + 1;
        pageNo = selected;
         getHuddles();
    };

    /* Api call for fatching patient records with search parameters */
  


    const uploadfile = (e) => {

        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */

        var data= new FormData();
        data.append("file",e.target.files[0]);
        data.append("practiceId",localStorage.getItem("practiceId"));
        data.append("createdBy", CommonHelper.getCurrnetUserId());

        patientService.uploadPatinet(data).then((data) => {
               console.log(data)
               change(true);
               loader.style.display='none';
               successloader.style.display='block';
               success_message.innerHTML="CSV Data Uploaded Successfully.";
               let timer =   setInterval(myTimer, 3000);
               function myTimer() {
                   successloader.style.display='none';
                   clearInterval(timer);  
               }
               handleModalClose();
               //getPatients();
        })
        .catch((error) => {
          console.log(error)
          loader.style.display='none';
          failloader.style.display='block';
          fail_message.innerHTML="CSV Upload Failed";
          let timer =  setInterval(myTimer, 3000);
          function myTimer() {
              failloader.style.display='none';
              clearInterval(timer);   
          }     
           getPatients();
        })
     };

     const downloadFile = (e) =>{

     };
    const getFileName = () =>{
        let d = new Date();
        let dformat = `${d.getDate()}-${d.getMonth()+1}-${d.getFullYear()}`;
    
        console.log("getCurrentDate : ", dformat);
        return "PatientList_" + dformat + ".csv";
      };

    
    const [show, setModalShow] = useState(false);
    const[searchdisable,setSearchdisable]=useState(true);
    const handleModalClose = () => {setModalShow(false); };
    const handleModalShow = () => {  
    //getHuddles();
    setModalShow(true);
};
   

    return (
        <>          
            {/* Patient Search Popup */}
            <Modal show={show} onHide={handleModalClose} animation={false} size="xl">
                <Modal.Header closeButton><Modal.Title>Patient Bulk Upload</Modal.Title></Modal.Header>

                <Modal.Body>
                
            <div className="row align-items-center justify-content-center">
            <input type="file" id="actual-btn" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={uploadfile} hidden />
             <label for="actual-btn"  className="btn btn-primary" style={{padding:"7px 13px",marginLeft: "10px",marginTop: "10px"}}><i className="fa fa-upload" style={{fontSize: "18px"}}>  </i> Upload CSV File</label>
          
             <input type="file" id="actual-btn" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={downloadFile} hidden />
             <a for="actual-btn" download={getFileName()} href={sampleCsv}  className="btn btn-primary" style={{padding:"7px 13px",marginLeft: "10px"}}><i className="fa fa-download" style={{fontSize: "18px"}}>  </i>   Download Sample CSV</a>
            </div>
            <div>
                <br/>
                
                {/* <p>
                 <b>   Note : First Name,Last Name,Email Id & Phone Number is Mandatory Fields to Upload CSV File.</b>
                </p> */}
<p><b>Required/Mandatory Fields: <i><u>&nbsp;FirstName, LastName, Email &amp; Mobile</u></i></b></p>
<p><b><i>Note: You need to login as a Practice Admin on Computer/PC to upload your contacts.&nbsp;</i></b></p>
<p><br/></p>
<p><b>Step 1: Download the Sample CSV:</b></p>

- Begin by clicking on the designated button to download the Sample CSV provided within the WebApp.

<p><b>Step 2: Your Patient Data File:</b></p>

- Download the patients contact list from your booking software and save as csv file on your PC. <br/>
- Open your existing patient data file intended for upload within &quot;The Daily Huddle&quot; WebApp.

<p><b>Step 3: Transfer Data to Sample CSV:</b></p>

    - Carefully copy the required fields&apos; data from your patient data file to the downloaded Sample CSV, ensuring accuracy.
<br/>
<b><i><u>Note: Do not update or modify any column header names within the Sample CSV file to maintain compatibility for CSV upload.</u></i></b>
<p><b>Step 4: Verify Data Accuracy:</b></p>

    - Thoroughly review the copied data within the Sample CSV to confirm its accuracy and completeness.

<p><b>Step 5: Save the Modified CSV File:</b></p>

    - Save the modified CSV file containing the patient data to your local storage with an appropriate file name.

<p><b>Step 6: Initiate CSV Upload:</b></p>

    - Navigate to the CSV upload section within &quot;The Daily Huddle&quot; WebApp and commence the upload process. 

<p><b><i><u>Note: For larger data file, it may take longer so do not cancel the screen while it is processing as this transfer can take longer based on your connection and configuration.&nbsp;</u></i></b></p>
<p><b>Step 7: Select the Saved CSV File &amp; Upload:</b></p>

    - Choose the saved CSV file from your local storage and upload the file. 
    <br/>
    <br/>
    <b>Contact our support team via email and schedule time to guide you through this upload process should you require any assistance. </b>

<p><br/></p>
<p>By adhering to these steps without modifying any column header names in the Sample CSV file, you&apos;ll be able to smoothly upload your patient list while ensuring compatibility with &quot;The Daily Huddle&quot; Web application.&nbsp;</p>
<p><br/></p>
            </div>
                    
                </Modal.Body>
            </Modal>

            <input className="btn btn-primary ml-5" title="Search"  onClick={handleModalShow}  type="button" value="Bulk Upload" />
          
        </>
    )
}
export default PatientBulkUploadModal;
