import React,{useState} from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";
import LayoutComponent from "../components/Layout/Layout.js";
import ErrorPage from "./../pages/error/ErrorPage.js";
import Login from "./../pages/login/Login.js";
import ForgotPassword from "./../pages/forgotPassword/forgotPassword.js";
import PracticeService from "../services/practice/practice.service";
import { logoutUser } from "./actions/auth.js";
import "../styles/app.scss";
import { ToastContainer } from "react-toastify";
import cookiepolicy from "../pages/login/cookiepolicy.js";
import aboutus from "../pages/login/aboutus.js";
import websitepolicy from "../pages/login/websitepolicy.js";
import privacypolicy from "../pages/login/privacypolicy.js";
import platformpolicy from "../pages/login/platformpolicy";
import PracticeRegister from "../pages/autoPracticeRegister/PracticeRegister.js";
import SubscriptionPlan from "../pages/autoPracticeRegister/SubscriptionPlan.js";
import SuccessRegister from "../pages/autoPracticeRegister/SuccessRegister.js";
import FAQ from "../pages/login/faq.js";
import trainingvideos from "../pages/login/trainingvideos.js";
import contactus from "../pages/login/contactus.js";
import CheckCancelledSubscription from "../pages/RenewCancelledSubscription/CheckCancelledSubscription.js";
import RenewCancelledSubscriptionPlan from "../pages/RenewCancelledSubscription/RenewCancelledSubscriptionPlan.js";

const PrivateRoute = ({ dispatch, component, ...rest }) => {
const [IsAuthorised, setIsAuthorised]= useState(true);
const [IsBlocked, setIsBlocked]= useState(false);

    PracticeService.isBlock(JSON.parse(localStorage.getItem("practiceId"))).then((practice) => {
            setIsBlocked(practice.data.data["isBlock"]);
            setIsAuthorised(true);

        }).catch((practice) => { 
            if(practice.response && JSON.stringify(practice.response.status) == '401'){
                setIsAuthorised(false);
            }
        });


        if (IsAuthorised == false || IsBlocked == true || !Login.isAuthenticated(JSON.parse(localStorage.getItem("authenticated")))) {
            dispatch(logoutUser());
            return (<Redirect to="/login" />);
        } else {
            return (<Route {...rest} render={props => (React.createElement(component, props))} />);
        }
};

class App extends React.PureComponent {    
    render() { 
        return (
            <div>
                <ToastContainer closeButton={false} hideProgressBar={false}/>
                <BrowserRouter >
                    <Switch>
                        <Route path="/" exact render={() => <Redirect to="/login" />} />
                        <Route path="/app" exact render={() => <Redirect to="/login" />} />
                        <PrivateRoute path="/app" dispatch={this.props.dispatch} component={LayoutComponent} />
                        <Route path="/login" exact component={Login} />
                        <Route path="/error" exact component={ErrorPage} />
                        <Route path="/forgotPassword" exact component={ForgotPassword} />
                        <Route path="/cookiepolicy" exact component={cookiepolicy} />
                        <Route path="/websitepolicy" exact component={websitepolicy} />
                        <Route path="/privacypolicy" exact component={privacypolicy}/>
                        <Route path="/platformpolicy" exact component={platformpolicy}/>
                        <Route path="/aboutus" exact component={aboutus} />
                        <Route path="/PracticeRegister" exact component={PracticeRegister} />
                        <Route path="/SubscriptionPlan" exact component={SubscriptionPlan} />
                        <Route path="/SuccessRegister" exact component={SuccessRegister} />
                        <Route path="/FAQ" exact component={FAQ} />
                        <Route path="/trainingvideos" exact component={trainingvideos} />
                        <Route path="/contactus" exact component={contactus} />
                        <Route path="/CheckCancelledSubscription" exact component={CheckCancelledSubscription} />
                        <Route path="/RenewCancelledSubscriptionPlan" exact component={RenewCancelledSubscriptionPlan} />
                        <Route component={ErrorPage} />
                        <Route path='*' exact={true} render={() => <Redirect to="/error" />} />
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
