import React, {useEffect } from "react";
import 'react-phone-input-2/lib/style.css'
import '../autoPracticeRegister/subscriptionPlan.css'
import StripeService from "../../services/stripe/stripe.service";


const RenewCancelledSubscriptionPlan = () => {

var email="";
var customerid="";

    
useEffect(() => {
if(!localStorage.getItem("customerId")){
    window.location = "/login";
}
customerid = localStorage.getItem("customerId");
});


const createSubscription = async (priceId) => {
    StripeService.createcheckoutsession(JSON.stringify({priceId,email,customerid})).then((result) => {
             window.open(result.data.url,"_self");
      })
      .catch((e) => {
        alert(e);
      });
    }



return(
    <div className="card mx-auto subcontainer">
            <h5 className="card-header text-center">The Daily Huddle</h5>
            <div className="card-body">
                <div className="text-center">
                <p className="theme-color">Please select subscription plan as per your requirement, you can cancel anytime</p>
                </div>

                <div className="row justify-content-center mt-5">
                <div className="col-md-4">
                    <div className="card text-center sub-border">
                        <div className="card-header bold sub-border card-header">
                            Monthly
                        </div>
                        <div className="card-body mx-auto">
                            <p className="currency" ><span className="price">$</span>159.99</p>
                            <p className="mt-2">Cancel Anytime</p>
                            <p className="mt-2 bold" >$159.99/month</p>
                            <p className="card-text mt-3">Please select a monthly subscription to get access to daily huddle operations throughout the month</p>
                            <input type="button" onClick={() => createSubscription("price_1NOZNVIWz02ZIyO4eD7VBH8o")} className="btn btn-primary mt-5" value="START" />
                        </div>
                    </div>
                </div>
                                    
                    <div className="col-md-4">
                    <div className="card text-center sub-border">
                        <div className="card-header bold sub-border">
                            Yearly
                        </div>
                        <div className="card-body mx-auto">
                            <p className="currency" ><span className="price">$</span>1849.99</p>
                            <p className="mt-2">Cancel Anytime</p>
                            <p className="mt-2 bold" >$1849.99/year</p>
                            <p className="card-text mt-3">Please select a yearly subscription to get access to daily huddle operations throughout the year</p>
                            <input type="button" onClick={() => createSubscription("price_1NOZNVIWz02ZIyO4YiLV5HTv")} className="btn btn-primary mt-5" value="START" /> 
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
);

};
 export default RenewCancelledSubscriptionPlan;