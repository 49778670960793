import React, { useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../components/Error/TextError";
import PatientService from "./../../services/patient/patient.service"
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

//Notification
import { toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification.js";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

//DateRange Picker
import "bootstrap/dist/css/bootstrap.css";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CommonHelper from "../../helper/common-helper";
import { convertFromHTML } from "draft-js";


const ManagePatient = () => {
    const { id } = useParams();
    const practiceId = localStorage.getItem('practiceId');
    let history = useHistory(); 
    var curr = new Date(); curr.setDate(curr.getDate());    

    const initialValues={
        patientNo: "",
        title: "",
        firstName: "",
        lastName: "",
        dateOfBirth: curr.toISOString().substr(0, 10),
        sex: "",
        email: "",
        mobile: "",
        homePhone: "",
        description: "",
        addressLine1: "",
        addressLine2: "",
        state: "",
        suburb: "",
        postcode: "",
        patientCategory: "",
        referralSource: "",
        referralNotes: "",
    };

    const validationSchema = Yup.object({
        firstName: Yup.string() 
            .matches(/^[a-zA-Z`']*$/g, "Please enter valid First name")
            .max(50, 'First name has a maximum length of 50 characters')
            .required("First name is required"),

        lastName: Yup.string()
            .matches(/^[a-zA-Z`']*$/g, "Please enter valid Last name")
            .max(50, 'Last name has a maximum length of 50 characters')
            .required("Last name is required"),

        dateOfBirth: Yup.string()
        .nullable()
        .test("dateOfBirth", "DOB should be less than Today's Date", function (value) {
          return moment().diff(moment(value, "YYYY-MM-DD"), "days") >= 1;
        }) .required("Please enter DOB"),
        // dateOfBirth: Yup.string()
        // .matches(curr,"Please Select Valid Date")
        // .required("Please select date of birth"),
        sex: Yup.string().required("Please select sex"),

        mobile: Yup.string()
            .matches(/^[^-\sa-zA-Za-zA-Z!@#$%^&~ _\={}`;\[\]\\':\/"|,.<>?][0-9*()+ -]*$/g, "Please enter valid mobile number")
            .max(20, 'Mobile number has a maximum length of 20 characters')
            .min(10, 'Mobile number has a minimum length of 10 characters')
            .required("Mobile number is required"),

        // patientCategory: Yup.string().required("Please select patient category"),
      //  referralSource: Yup.string().required("Please select referral source"),
    });

    const handleSubmit = async (values) => {
        if (id == 0) {
            CreatePatientService(values);
        } else {
            UpdatePatientService(id, values);
        }
    }

    const UpdatePatientService = async (id, values) => {
        values.practiceId = practiceId;

        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */

        await PatientService.update(id, JSON.stringify(values))
            .then(result => {                
                // toast(<Notification type="success" message="Patientrecord updated successfully." />),

                /* Success Loader Called */
                loader.style.display='none';
                successloader.style.display='block';
                success_message.innerHTML="Patient record updated successfully.";
                let timer =   setInterval(myTimer, 3000);
                function myTimer() {
                    successloader.style.display='none';
                    clearInterval(timer);  
                }
                history.push('/app/patientlist');
            })
            .catch(e => { 
                 /* Fail Loader Called */
                loader.style.display='none';
                failloader.style.display='block';
                fail_message.innerHTML="Patient record not updated successfully.";
                let timer =  setInterval(myTimer, 3000);
                function myTimer() {
                    failloader.style.display='none';
                    clearInterval(timer);   
                }     
                toast(console.log(e)); 
            });
    }

    const CreatePatientService = async (values) => {
        values.practiceId =practiceId;
        
        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */

        var payload = JSON.stringify(values);
        await PatientService.create(payload).then(result => {
            // toast(<Notification type="success" message="Patien trecord saved successfully." />), 

            /* Success Loader Called */
            loader.style.display='none';
            successloader.style.display='block';
            success_message.innerHTML="Patient record saved successfully.";
            let timer =   setInterval(myTimer, 3000);
            function myTimer() {
                successloader.style.display='none';
                clearInterval(timer);  
            }
            history.push('/app/patientlist');
        })
        .catch(e => { 
            /* Fail Loader Called */
            loader.style.display='none';
            failloader.style.display='block';
            fail_message.innerHTML="Patient record not saved successfully.";
            let timer =  setInterval(myTimer, 3000);
            function myTimer() {
                failloader.style.display='none';
                clearInterval(timer);   
            }     
            toast(console.log(e)); 
        });
    }


    return (
        <div className="card">
            <h5 className="card-header">{id>0 ? 'Edit ' : 'Add ' } Patient</h5>
            <div className="card-body">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}> 
                    {({values, errors, touched, isSubmitting, handleChange,setFieldValue }) => {
                        useEffect(() => {
                            if (id > 0) {
                                PatientService.get(id).then(result => { 
                                    const fields = [ 'title','firstName','lastName','dateOfBirth','sex','email','mobile',
                                    'homePhone','description','addressLine1','addressLine2','state','suburb','postcode','referralSource','referralNotes',];
                                    // ,'patientCategory'
                                    fields.forEach(field => setFieldValue(field, result.data.data[field], false));
                                    setFieldValue("dateOfBirth",result.data.data["dateOfBirth"].substring(0, 10));
                                    setFieldValue("patientNo",result.data.data["patientNo"]);
                                    setFieldValue("createdBy",CommonHelper.getCurrnetUserId());
                                }); 
                            }
                        }, []);

                        return (
                                <Form id="form-employee">
                                    <div className="row">
                                        {/*Left Section: Presonal */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label null" htmlFor="sex">Title</label>
                                                <Field className="form-control" as="select" id="title" name="title">
                                                <option key={uuidv4()} className="form-control" value="">Select title</option>
                                                <option key={uuidv4()} className="form-control" value="Mr">Mr</option>
                                                <option key={uuidv4()} className="form-control" value="Ms">Ms</option>
                                                <option key={uuidv4()} className="form-control" value="Mrs">Mrs</option>
                                                <option key={uuidv4()} className="form-control" value="Miss">Miss</option>
                                                </Field>
                                                <ErrorMessage name="title" component={TextError} />

                                                <label className="col-form-label" htmlFor="firstName">First Name </label>
                                                 <Field placeholder="Enter first name" className="form-control" type="text" name="firstName" /> 
                                                <ErrorMessage name="firstName" component={TextError} />

                                                <label className="col-form-label" htmlFor="lastName">Last Name </label>
                                                 <Field placeholder="Enter last name" className="form-control" type="text" name="lastName" /> 
                                                <ErrorMessage name="lastName" component={TextError} />

                                                <label className="col-form-label null " htmlFor="dateOfBirth">Date Of Birth</label>
                                                <Field className="form-control" type="date" name="dateOfBirth" id="dateOfBirth"/>
                                                <ErrorMessage name="dateOfBirth" component={TextError}/>

                                                <label className="col-form-label null" htmlFor="sex">Sex</label>
                                                <Field className="form-control" as="select" id="sex" name="sex">
                                                <option key={uuidv4()} className="form-control" value="">Select sex</option>
                                                <option key={uuidv4()} className="form-control" value="M">Male</option>
                                                <option key={uuidv4()} className="form-control" value="F">Female</option>
                                                <option key={uuidv4()} className="form-control" value="O">Other</option>
                                                </Field>
                                                <ErrorMessage name="sex" component={TextError} />

                                                <label className="col-form-label null " htmlFor="email">Email</label>
                                                <Field placeholder="Enter email address" className="form-control" type="text" name="email"/>
                                                <ErrorMessage name="email" component={TextError} />

                                                <label className="col-form-label null " htmlFor="mobile">Mobile Number</label>
                                                <ReactPhoneInput country={'au'} value={values.mobile}  onChange={(value, country, e, formattedValue)=>{
                                                            handleChange(e);
                                                            {setFieldValue("mobile",formattedValue);}}}
                                                            inputProps={{ name: 'mobile', required: true, className:"form-control w-100"}} />
                                                <ErrorMessage name="mobile" component={TextError} />

                                                <label className="col-form-label null " htmlFor="homePhone">Home Contact Number</label>
                                                <ReactPhoneInput country={'au'} value={values.homePhone} onChange={(value, country, e, formattedValue)=>{
                                                            handleChange(e);
                                                            {setFieldValue("homePhone",formattedValue);}}}
                                                            inputProps={{ name: 'homePhone', required: true, className:"form-control w-100"}} />
                                                <ErrorMessage name="homePhone" component={TextError} />

                                                <label className="col-form-label null " htmlFor="email">Description</label>
                                                <Field placeholder="Enter description" className="form-control" type="text" name="description"/>
                                                <ErrorMessage name="description" component={TextError} />
                                            </div>
                                        </div>
                                        {/*Right Section: Address */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                    <label className="col-form-label" htmlFor="firstName">Address Line-1 </label>
                                                    <Field placeholder="Enter address line 1" className="form-control" type="text" name="addressLine1" /> 
                                                    <ErrorMessage name="addressLine1" component={TextError} />

                                                    <label className="col-form-label" htmlFor="firstName">Address Line-2 </label>
                                                    <Field placeholder="Enter address line 2" className="form-control" type="text" name="addressLine2" /> 
                                                    <ErrorMessage name="addressLine2" component={TextError} />

                                                    <label className="col-form-label" htmlFor="firstName">State </label>
                                                    <Field placeholder="Enter state" className="form-control" type="text" name="state" /> 
                                                    <ErrorMessage name="state" component={TextError} />

                                                    <label className="col-form-label" htmlFor="firstName">Suburb</label>
                                                    <Field placeholder="Enter suburb" className="form-control" type="text" name="suburb" /> 
                                                    <ErrorMessage name="suburb" component={TextError} />

                                                    <label className="col-form-label" htmlFor="firstName">Postcode</label>
                                                    <Field placeholder="Enter postcode" className="form-control" type="text" name="postcode" /> 
                                                    <ErrorMessage name="postcode" component={TextError} />

                                                    {/* <label className="col-form-label" htmlFor="firstName">Patient Category</label> */}
                                                    {/* <Field placeholder="Enter patient category" className="form-control" type="text" name="patientCategory" />  */}
                                                    {/* <Field className="form-control" as="select" id="sex" name="patientCategory">
                                                        <option key={uuidv4()} className="form-control" value="">Select patient category</option>
                                                        <option key={uuidv4()} className="form-control" value="VIP">VIP</option>
                                                        <option key={uuidv4()} className="form-control" value="General">General</option>
                                                    </Field>
                                                    <ErrorMessage name="patientCategory" component={TextError} /> */}

                                                    <label className="col-form-label" htmlFor="firstName">Referral Source</label>
                                                    {/* <Field placeholder="Enter referral source" className="form-control" type="text" name="referralSource" />  */}
                                                    <Field className="form-control" as="select" id="sex" name="referralSource">
                                                        <option key={uuidv4()} className="form-control" value="">Select referral source</option>
                                                        <option key={uuidv4()} className="form-control" value="search">Search</option>
                                                        <option key={uuidv4()} className="form-control" value="insurance">Insurance</option>
                                                        <option key={uuidv4()} className="form-control" value="friend">Friend</option>
                                                        <option key={uuidv4()} className="form-control" value="family">Family</option>
                                                        <option key={uuidv4()} className="form-control" value="socialmedia">Social Media</option>
                                                        <option key={uuidv4()} className="form-control" value="google">Google</option>
                                                        <option key={uuidv4()} className="form-control" value="other">Other</option>
                                                    </Field>
                                                    <ErrorMessage name="referralSource" component={TextError} />

                                                    <label className="col-form-label" htmlFor="firstName">Referral Notes</label>
                                                    <Field placeholder="Enter referral notes" className="form-control" type="text" name="referralNotes" /> 
                                                    <ErrorMessage name="referralNotes" component={TextError} />
                                            </div>
                                    </div>
                                    </div>
                                   
                                    <div className="form-buttons">
                                        <button className="mr-3 btn btn-primary" type="submit" id="btnSubmit">Save</button>
                                        <Link to="/app/patientlist" className="mr-3 btn btn-secondary">Cancel</Link>
                                    </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}
export default ManagePatient;
