
import EnumHelper from './enum-helper'
import * as html2pdf from 'html2pdf.js'


class PdfHelper {
    static downloadPdf(element , filename) {
        let options=EnumHelper.PdfOption, boundingRect=element.getBoundingClientRect();
        options['jsPDF']['format']=[boundingRect.width-200,boundingRect.height];
        options['filename']=filename;
        html2pdf().set(options).from(element).save();
    }

    static previewPdf(element){
        
        return new Promise(function (resolve, reject) {
            html2pdf(element,EnumHelper.PdfOption).outputPdf().then((pdf) => {                             
                resolve(btoa(pdf))
            }).catch(e => {
               console.log(e);
            })
        });         
    }

    static printPage(){
        window.print();
    }
}

export default PdfHelper