import React, {useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import TextError from "../../components/Error/TextError";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import HbLogo from "../../components/Icons/SidebarIcons/HuddleBook_logo.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import practiceService from "./../../services/practice/practice.service"

const contactus = () => {

const initialValues = {
    firstName:"",
    lastName:"",
    email:"",
    phone:"",
    seenVideosFaqs:false,
    concern:"" };

    const validationSchema = Yup.object().shape({
    firstName:Yup.string()
    .matches(/^[a-zA-Z`']*$/g, "Please enter valid First name")
    .max(50, 'First name has a maximum length of 50 characters')
    .required("First name is required"),
    lastName:Yup.string()
    .matches(/^[a-zA-Z`']*$/g, "Please enter valid Last name")
    .max(50, 'Last name has a maximum length of 50 characters')
    .required("Last name is required"),
    email:Yup.string()
    .email("Enter valid email")
    .max(50, 'Email has a maximum length of 50 characters')
    .required("Email is required"),
    phone:Yup.string()
    .matches(/^[^-\sa-zA-Za-zA-Z!@#$%^&~ _\={}`;\[\]\\':\/"|,.<>?][0-9*()+ -]*$/g, "Please enter valid phone number")
    .max(20, 'Phone number has a maximum length of 20 characters')
    .min(10, 'Phone number has a minimum length of 10 characters')
    .required("Phone number is required"),
    concern:Yup.string()
    .matches(/^[^-\s][a-zA-Z0-9!@#$%^&~* ()_+\-={}`;\[\]\\':\/"|,.<>?]*$/g, "Please enter valid details")
    .required("Concern is required"),
    })

const handleSubmitData = async (values) => {
       
    if(values != "" )
        {
         await practiceService.contactUs(values).then((res) => {

                if(res.data.status=="success"){
                    // give alert if practice is already registed
                    alert("Thank you, your query submited to our team.");
                    window.close();
                }
                }).catch((e) => {}); 
        }
};

useEffect(() => {
//localStorage.removeItem("Practice");
});

return(
    <div className="card">
    <div>
    <div>
            <div className="text-center">
                            <br></br>
                            <img src={HbLogo} style={{ width: "21.6%" }} />
                            <br></br>
                            <br></br>
            </div>
        <br></br>
   </div>

<Formik  initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmitData}>
                {({ values, errors, dirty, touched, handleChange, handleSubmit, isSubmitting }) => (
                    <Form id="contact-form" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
                        <div className="row justify-content-center">
                            <div className="col-md-10 border border-dark rounded p-4">
                                        <div className="row">
                                            <div className="col-md-11">
                                                <h4>CONTACT US</h4>
                                            </div>
                                            <div className="col-md-1 text-right">
                                                        <a href="https://www.facebook.com/thedailyhuddleonline"
                                                        target="_blank">
                                                            <FontAwesomeIcon icon={faFacebook} size="2x" />
                                                        </a>

                                                        <a href="https://www.instagram.com/thedailyhuddle_official/"
                                                            className="youtube social ml-2" target="_blank">
                                                            <FontAwesomeIcon icon={faInstagram} size="2x" />
                                                        </a>
                                            </div>
                                        </div>
                                                    <br></br>
                                                    <p>The Daily Huddle team will be more than happy to assist you with your inquiry. We recommend you watch our training videos for any task-related questions. Please fill out the following details to reach our team for further support.</p>
                                                    <br></br>
                                <div className="form-group">
                                    <label className="col-form-label null " htmlFor="firstName">First Name </label>
                                    <Field  type="text" placeholder="Enter First Name" name="firstName" value={values.firstName} onChange={handleChange} className="form-control"  />
                                    <ErrorMessage name="firstName" component={TextError} />
                                </div>
                             
                                <div className="form-group">
                                    <label className="col-form-label null " htmlFor="lastName">Last Name</label>
                                    <input type="text" placeholder="Enter Last Name" name="lastName" value={values.lastName} onChange={handleChange} className="form-control"  />
                                    <ErrorMessage name="lastName" component={TextError} />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label null " htmlFor="email">Contact Email</label>
                                    <input type="text" placeholder="Enter Contact Email" name="email" value={values.email} onChange={handleChange} className="form-control"  />
                                    <ErrorMessage name="email" component={TextError} />
                                </div>
               
                                <div className="form-group">
                                    <label className="col-form-label null " htmlFor="phone">Phone</label>
                                    <ReactPhoneInput country={'au'} value={values.phone} onChange={(value, country, e, formattedValue)=>{
                                            handleChange(e);
                                        }}
                                        inputProps={{ name: 'phone' }} />
                                    <ErrorMessage name="phone" component={TextError} />
                                </div>
                                <br></br>
                                <div className="form-group">
                                    <div className={`checkbox checkbox-primary contactus-checkbx`}>
                                                <input name="seenVideosFaqs" type="checkbox" className="styled" onChange={handleChange} />
                                                <label htmlFor="seenVideosFaqs"> I have watched all the training videos and read FAQs but I could not find an answer to my question </label>
                                    </div> 
                                </div>

                           
                                <div className="form-group">
                                    <label className="col-form-label null " htmlFor="concern">Describe your concern here : </label>
                                    <textarea placeholder="Enter your concern here.." className="form-control" value={values.concern} onChange={handleChange} type="concern" name="concern" id="concern" rows="4" cols="50" />
                                    <ErrorMessage name="concern" component={TextError} />
                                </div>


                            </div>
                        </div>
                        <div className="form-buttons text-center m-2">
                            <button type="submit"  className="btn btn-primary" >Submit</button>
                            <button className="btn btn-secondary ml-4" onClick={()=>window.close()}>Cancel </button>
                        </div>
                    </Form>
                    )}
                </Formik>
    </div>
</div>
);

};
 export default contactus;