import http from "../../config/http-common";

class teammeetingService {
    async getAll(data, pageNo) {
        return await http.post(`teammeeting/${pageNo}`, data);
    }
    get(id) {
        return http.get(`teammeeting/${id}`);
    }
    async create(data) {
        return await http.post("teammeeting?teamMeetingId=0", data);
    }
    async delete(id) {
        return await http.delete(`teammeeting/${id}`);
    }
    update(id, data) {
        return http.post("teammeeting?teamMeetingId=" + id, data);
    }
    search(data) {
        return http.post(`teammeeting/search`,data);
    }

}

export default new teammeetingService();