import React, { useEffect, useState,useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import {Col,Row,Table,Dropdown,DropdownMenu,DropdownToggle,DropdownItem,Label} from "reactstrap";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';

import Widget from "../../components/Widget/Widget.js";
import MarketingService from "./../../services/marketing/marketing.service";
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import s from "./Tables.module.scss";

//Notification
import { toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification.js";

//DateRange Picker
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const MarketingList = function () {

    const [response, setMarketingList] = useState([]);
    const [totalCount, setTotalCount] = useState([], false);

    //Date Range Textbox
    const [DateRange, setStateDateRange] = useState('', false);

    const txtSearch = useRef(null);
    let pageNo = 1, pageSize = 10;
    const practiceId = localStorage.getItem('practiceId');
    const role = localStorage.getItem('role');
    useEffect(() => {
        getMarketing();
        return () => {
            setMarketingList([]);
        };
    }, []);

    const handlePageClick = (data) => {
        let selected = data.selected + 1;
        pageNo = selected
        getMarketing();

    };

    const getMarketing = async () => {

        var payload = { "searchTerm": txtSearch.current.value, "pageSize": pageSize,  "pageNumber": pageNo,"practiceId":practiceId , 'dateRange': DateRange  }
 
        await new Promise(function (resolve, reject) {
            MarketingService.search(payload).then(result => {
                resolve(result);

                setMarketingList(result.data.data.marketingList);
                setTotalCount(Math.ceil(parseInt(result.data.data.totalRecords) / pageSize));
                pageNo = payload.pageNumber;

            }).catch(e => {
               console.log(e);
            })
        });

    }
    const deleteRecord = async (id) => {
        await MarketingService.delete(id)
            .then(result => {
                toast(<Notification type="success" message="Marketing record deleted successfully." />),
                getMarketing();
                //history.push('/app/marketinglist');
            })
            .catch(e => { toast(console.log(e)); });
    }

    const datePickerHandleApply = (event, picker) => {
        let selectedDate = picker.startDate.format('DD-MM-YYYY') + ' - ' + picker.endDate.format('DD-MM-YYYY')
        setStateDateRange(selectedDate); picker.element.val(selectedDate);
    }

    const datePickerHandleCancel = (event, picker) => { picker.element.val(''); setStateDateRange('') }

    return (
        <div>
            <Row>
                <Col>
                    <Row className="mb-4">
                        <Col>
                            <Widget>
                                <div className={s.tableTitle}>
                                    <div className="headline-2">Contact Details</div>
                                    <div className="d-flex">
                                        <a href="/#"><img className="d-none d-sm-block" src={cloudIcon} alt="Cloud" /></a>
                                        <a href="/#"><img src={printerIcon} alt="Printer" /></a><a href="/#">&nbsp;&nbsp;&nbsp; Preview</a>
                                    </div>
                                </div>
                                <div className={s.tableTitle}>
                                    <Col className="col-3">
                                        <input type="text"  ref={txtSearch} name="txtSearch" className="form-control " placeholder="Search by company or name" />
                                    </Col>
                                    {/*<Col>*/}
                                    {/*    <DateRangePicker onApply={datePickerHandleApply} onCancel={datePickerHandleCancel} initialSettings={{ autoUpdateInput: false, locale: { 'format': 'DD-MM-YYYY' } }}>*/}
                                    {/*        <input type="text" className="form-control" defaultValue="" placeholder="Select Date" />*/}
                                    {/*    </DateRangePicker>*/}
                                    {/*</Col>*/}
                                    <Col>
                                        <input className="btn btn-secondary" type="button" value="Search" onClick={getMarketing}/>
                                    </Col>
                                    {/*<Col>*/}
                                    {/*    {role === "Employee" ? <div></div> : <Link to="/app/marketing/0" className="btn btn-primary pull-right">*/}
                                    {/*        Add Conatct*/}
                                    {/*    </Link>}*/}
                                    {/*</Col>*/}
                                </div>
                                <div className="widget-table-overflow">
                                    <Table className={`table-striped table-borderless table-hover ${s.statesTable}`} responsive>
                                        <thead>
                                            <tr>
                                                <th className="w-2"></th>
                                                <th className="W-50">COMPANY NAME</th>
                                                <th className="W-15">REPRESENTATIVE NAME</th>
                                                <th className="W-15">ACCOUNT NO</th>
                                                <th className="W-15">CONTACT</th>
                                                <th className="W-15">DETAILS OF COMPANY</th>
                                                {role === "Employee" ?<th></th> :<th className="W-100">ACTIONS</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td><input type="text" name="txtSearch" className="form-control " placeholder="Enter company name" /></td>
                                                <td><input type="text" name="txtSearch" className="form-control " placeholder="Enter representative name" /></td>
                                                <td><input type="text" name="txtSearch" className="form-control " placeholder="Enter account no" /></td>
                                                <td><input type="text" name="txtSearch" className="form-control " placeholder="Enter contact no" /></td>
                                                <td><input type="text" name="txtSearch" className="form-control " placeholder="Enter details of company" /></td>
                                                <td><i className={'eva eva-checkmark-outline ' + s.greenColor} />&nbsp;<i className={'eva eva-close-outline ' + s.redColor} /></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>Toronto General</td>
                                                <td>Jose V. Williams</td>
                                                <td>4485 5162 8196 9320</td>
                                                <td>670-234-9705</td>
                                                <td>Pediatricians</td>
                                                <td>
                                                    <Link to={"#"} className={s.blackColor}><i className="fa fa-edit"></i></Link>&nbsp;
                                                    <Link to="#"className={s.blackColor}><i className="fa fa-trash"></i></Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>Sunnybrook Health Sciences</td>
                                                <td>Howard J. Thompson</td>
                                                <td>5102 4545 4975 0961</td>
                                                <td>360-341-8691</td>
                                                <td>General Surgeon</td>
                                                <td>
                                                    <Link to={"#"} className={s.blackColor}><i className="fa fa-edit"></i></Link>&nbsp;
                                                    <Link to="#" className={s.blackColor}><i className="fa fa-trash"></i></Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>Mount Sinai Hospital</td>
                                                <td>Joel R. Milo</td>
                                                <td>4485 5608 4081 8545</td>
                                                <td>620-233-7665</td>
                                                <td>Dentist</td>
                                                <td>
                                                    <Link to={"#"} className={s.blackColor}><i className="fa fa-edit"></i></Link>&nbsp;
                                                    <Link to="#" className={s.blackColor}><i className="fa fa-trash"></i></Link>
                                                </td>
                                            </tr>
                                            
                                            {/*{response*/}
                                            {/*    .map(item => (*/}
                                            {/*        <tr key={uuidv4()}>*/}
                                            {/*            <td></td>*/}
                                            {/*            <td>{item.points}</td>*/}
                                            {/*            {<td>{item.strMarketingDate}</td>}*/}
                                                       
                                            {/*            <td>{item.strCreatedDate}</td>*/}
                                            {/*            <td>{item.strUpdatedDate}</td>*/}
                                                         
                                            {/*            {role === "Employee" ? <td></td> : <td>*/}
                                            {/*                <Link to={"/app/marketing/" + item.id} className={s.blackColor}><i className="fa fa-edit"></i></Link>&nbsp;*/}
                                            {/*                <Link to="#" onClick={()=>deleteRecord(item.id)} className={s.blackColor}><i className="fa fa-trash"></i></Link></td>}*/}

                                            {/*        </tr>*/}
                                            {/*    ))}*/}
                                            {/*    {*/}
                                            {/*        response.length == 0 ? <tr><td colSpan="6"><div className='alert alert-secondary text-primary m-3 color' >No records found</div></td></tr> : ''*/}
                                            {/*    }*/}
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col className="ml-2">

                                        </Col>
                                        <Col className="col-10">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=" >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={pageSize}
                                                pageCount={Math.ceil(totalCount)}
                                                previousLabel="< "
                                                renderOnZeroPageCount={null}
                                                containerClassName={'pagination pagination-sm'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'previous'}
                                                previousLinkClassName={'page-item page-link'}
                                                nextClassName={'next'}
                                                nextLinkClassName={'page-item page-link'}
                                                activeClassName={'active'}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Widget>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default MarketingList;
