import http from "../../config/http-common";

class roleService {
    // async getAll(data, id, pageNo) {
    //     return await http.post(`assignments/${id}/${pageNo}`, data);
    // }
    get() {
        return http.get(`roles`);
    }
    //create(data) {
    //    return http.post("register", data);
    //}
    //delete(id) {
    //    return http.delete(`assignments`);
    //}
    //update(id, data) {
    //    return http.post(`assignments/${id}`, data);
    //}
}

export default new roleService();