import http from "../../config/http-common";

class assignmentService {
    async getAll(data, id,pageNo) {
        return await http.post(`assignments/${id}/${pageNo}`, data);
    }
    get(employeeID, assignmnetId) {
        return http.get(`assignments/${employeeID}/${assignmnetId}`);
    }
    //create(data) {
    //    return http.post("assignments", data);
    //}
    //delete(id) {
    //    return http.delete(`assignments`);
    //}
    search(data) {
        return http.post(`assignments/search`, data);
    }
    update(employeeID, assignmentId, data) {
        return http.post(`assignments/${employeeID}?assignmentId=${assignmentId}`, data);
    }
    create(data, employeeID, assignmentId) {
        return http.post(`assignments/${employeeID}?assignmentId=${assignmentId}`, data);
    }
    delete(id, assignmentId) {
        return http.delete(`assignments/${id}/${assignmentId}`);
    }
}

export default new assignmentService();