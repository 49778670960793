import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import d from "../../pages/dashboard/Dashboard.module.scss";

import {
    Navbar,
    Nav,
    NavItem,
    NavLink,
    InputGroupAddon,
    InputGroup,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup,
} from "reactstrap";

import { logoutUser } from "./../actions/auth.js";
import { closeSidebar, openSidebar } from "./../actions/navigation.js";
import MenuIcon from "../Icons/HeaderIcons/MenuIcon.js";
import SearchBarIcon from "../Icons/HeaderIcons/SearchBarIcon.js";
import BellIcon from "../Icons/HeaderIcons/BellIcon.js";
import SearchIcon from "../Icons/HeaderIcons/SearchIcon.js";

import ProfileIcon from "../../assets/navbarMenus/pfofileIcons/ProfileIcon.js";
import MessagesIcon from "../../assets/navbarMenus/pfofileIcons/MessagesIcon.js";
import TasksIcon from "../../assets/navbarMenus/pfofileIcons/TasksIcon.js";

import logoutIcon from "../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg";
import basketIcon from "../../assets/navbarMenus/basketIcon.svg";
import calendarIcon from "../../assets/navbarMenus/calendarIcon.svg";
import envelopeIcon from "../../assets/navbarMenus/envelopeIcon.svg";
import mariaImage from "../../assets/navbarMenus/mariaImage.jpg";
import notificationImage from "../../assets/navbarMenus/notificationImage.jpg";
import userImg from "../../assets/user1.svg";
import { Link } from "react-router-dom";

import s from "./Header.module.scss";
import "animate.css";

import { Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification.js";
import PracticeService from "./../../services/practice/practice.service";
import StripeService from "./../../services/stripe/stripe.service";

class Header extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.toggleMenu = this.toggleMenu.bind(this);
        this.doLogout = this.doLogout.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.toggleNotifications = this.toggleNotifications.bind(this);
        this.handleFeedback= this.handleFeedback.bind(this);
        this.showfeedback= this.showfeedback.bind(this);
        this.closeFeedback= this.closeFeedback.bind(this);

        this.toggle2=this.toggle2.bind(this);
        this.handlecancelFeedback= this.handlecancelFeedback.bind(this);
        this.openManageSubscription = this.openManageSubscription.bind(this);
        this.renderCancelSubscriptionModal = this.renderCancelSubscriptionModal.bind(this);
        this.showcancelfeedback = this.showcancelfeedback.bind(this);
        this.closecancelFeedback = this.closecancelFeedback.bind(this);
        

        this.state = {
            menuOpen: false,
            notificationsOpen: false,
            searchFocused: false,
            loggedUserId: localStorage.getItem('userId'),
            role : localStorage.getItem('role'),
            showfeedbackModal:false,
            showCancelFeedbackModal:false,
            dropdownOpen2: false
        };
    }
    doLogout() {
        this.props.dispatch(logoutUser());
        localStorage.setItem('token', '');
        localStorage.setItem('username', '');
        localStorage.setItem('userId', '');
    }

    toggleSidebar() {
        if (this.props.sidebarOpened) {
            this.props.dispatch(closeSidebar());
        } else {
            const paths = this.props.location.pathname.split('/');
            paths.pop();
            this.props.dispatch(openSidebar());
        }
    }

    toggleMenu() {
        this.setState({
            menuOpen: !this.state.menuOpen,
        });
    }

    toggle2() {
        this.setState(prevState => ({
          dropdownOpen2: !prevState.dropdownOpen2
        }));
      }

    showfeedback() {
        this.setState({
            showfeedbackModal: true
        });
    }

    closeFeedback(){
        this.setState({
            showfeedbackModal: false
        });
    }

    toggleNotifications() {
        this.setState({
            notificationsOpen: !this.state.notificationsOpen,
        });
    }


    handleFeedback =  async (values) => {
        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */
        var payload ={
          CustomerEmail:values.customerEmail,
          Feedback1:values.feedback1,
          Feedback2:values.feedback2,
          Feedback3:values.feedback3,
          CustomerFeedback:values.customerFeedback
        }
    
        PracticeService.feedback(payload)
        .then((result) => {
          if (result.data.statuscode == 200) {
              this.setState({ showfeedbackModal: false });
            //   toast(<Notification type="success" message="Thank you for your feedback" />);

            /* Success Loader Called */
            loader.style.display='none';
            successloader.style.display='block';
            success_message.innerHTML="Thank you for your feedback";
            let timer =   setInterval(myTimer, 3000);
            function myTimer() {
                successloader.style.display='none';
                clearInterval(timer);  
            }
          }
        })
        .catch((e) => {
             /* Fail Loader Called */
             loader.style.display='none';
             failloader.style.display='block';
             fail_message.innerHTML="Your feedback is not Submited Successfully";
             let timer =  setInterval(myTimer, 3000);
             function myTimer() {
                 failloader.style.display='none';
                 clearInterval(timer);   
             }               
          toast();
        });
    
      }

      // cancelfeedback 

      showcancelfeedback() {
        this.setState({
            showCancelFeedbackModal: true
        });
    }

    closecancelFeedback(){
        this.setState({
            showCancelFeedbackModal: false
        });
    }

    handlecancelFeedback =  async (values) => {
        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */
       await PracticeService.cancelSubscriptionFeedback(values)
         .then((result) => {
           if (result.data.statuscode == 200) {
               this.setState({ showCancelFeedbackModal: false });
            // toast(<Notification type="success" message="Thank you for your feedback" />);
            /* Success Loader Called */
            loader.style.display='none';
            successloader.style.display='block';
            success_message.innerHTML="Thank you for your feedback";
            let timer =   setInterval(myTimer, 3000);
            function myTimer() {
                successloader.style.display='none';
                clearInterval(timer);  
            }
           }
         })
         .catch((e) => {
             /* Fail Loader Called */
             loader.style.display='none';
             failloader.style.display='block';
             fail_message.innerHTML="Your feedback is not Submited Successfully";
             let timer =  setInterval(myTimer, 3000);
             function myTimer() {
                 failloader.style.display='none';
                 clearInterval(timer);   
             }               
           toast();
         });

        var practiceId=localStorage.getItem('practiceId');
        await StripeService.managesubscription(practiceId).then((result) => {
            this.closeFeedback();
               window.open(result.data.url);
                 })
                 .catch((e) => {
                   alert("invalid subscription data");
                 });
       }

       openManageSubscription = async () =>{
        var practiceId=localStorage.getItem('practiceId');
        await StripeService.managesubscription(practiceId).then((result) => {
               window.open(result.data.url);
                 })
                 .catch((e) => {
                   alert("invalid subscription data");
                 });
       }

      renderCancelSubscriptionModal(){

        return (        <Modal show={this.state.showCancelFeedbackModal} dialogClassName={s.feedbackmodal}>
            <Modal.Header>
            <Modal.Title className="headline-2 justify-content-center">Cancellation Feedback</Modal.Title>
            <button className="btn btn-close" onClick={this.closecancelFeedback} ></button>
             
            </Modal.Header>
            <Modal.Body className="justify-content-right">
            <div>
              <div className="card">
                  <div className="card-body">
                      <Formik initialValues={{customerEmail: localStorage.getItem("username"), otherCancelFeedback: "", cancelFeedback1:"", cancelFeedback2:""}} 
                      validationSchema={Yup.object({
                                                otherCancelFeedback: Yup.string().required("This field is required"),
                                                cancelFeedback1: Yup.string().required("This field is required"),
                                                cancelFeedback2: Yup.string().required("This field is required")
                                                })
                        } onSubmit={this.handlecancelFeedback}>
                          <Form id="form-employee">
                              <div className="row">
                                  <div className="col-md-12">
                                      <div className="form-group row">
                                          <div className="col-sm-6">
                                              <Field  className="form-control" type="hidden"  disabled value={localStorage.getItem("username")} name="customerEmail" id="customerEmail" />
                                          </div>
                                      </div>
    
                                      <p className={s.headStyle}>The Daily Huddle team is very grateful for using our application. We would appreciate if you could share your experience with this cancellation form.</p><br/>
    
            <div className={"form-group border rounded p-4 "+s.brdrcolor}>
            <div id="my-radio-group" className="font-weight-bold">1. How long have you been using the subscription?</div><br/>
                <div role="group" aria-labelledby="my-radio-group">
                        <label>
                        <Field type="radio" name="cancelFeedback1" value="0-1 month" />
                        <span className="px-2">0-1 month</span>
                        </label><br/>
                        <label>
                        <Field type="radio" name="cancelFeedback1" value="1-3 months" />
                        <span className="px-2">1-3 months</span>
                        </label><br/>
                        <label>
                        <Field type="radio" name="cancelFeedback1" value="3-6 months" />
                        <span className="px-2">3-6 months</span>
                        </label><br/>
                        <label>
                        <Field type="radio" name="cancelFeedback1" value="1+ year" />
                        <span className="px-2">1+ year</span>
                        </label><br/>
                        <label>
                        <Field type="radio" name="cancelFeedback1" value="Did not use at all" />
                        <span className="px-2">Did not use at all</span>
                        </label><br/>
                </div>
                <div className="text-danger"><ErrorMessage name="cancelFeedback1" /></div>
            </div>
              
              <div className="form-group border rounded p-4">
                    <div id="my-radio-group" className='font-weight-bold'>2. What is the primary reason for the cancellation?</div><br/>
                            <div role="group" aria-labelledby="my-radio-group">
                                    <label>
                                    <Field type="radio" name="cancelFeedback2" value="I didn’t find it useful" />
                                    <span className="px-2">I didn’t find it useful</span>
                                    </label><br/>
                                    <label>
                                    <Field type="radio" name="cancelFeedback2" value="Customer service is not enough" />
                                    <span className="px-2">Customer service is not enough</span>
                                    </label><br/>
                                    <label>
                                    <Field type="radio" name="cancelFeedback2" value="Quality of content is not rich" />
                                    <span className="px-2">Quality of content is not rich</span>
                                    </label><br/>
                                    <label>
                                    <Field type="radio" name="cancelFeedback2" value="Failed to implement in our team" />
                                    <span className="px-2">Failed to implement in our team</span>
                                    </label><br/> <label>
                                    <Field type="radio" name="cancelFeedback2" value="Other" />
                                    <span className="px-2">Other</span>
                                    </label><br/>
                            </div>
                    <div className="text-danger"><ErrorMessage name="cancelFeedback2" /></div>
                </div>
    
              <div className="form-group border rounded p-4">
              <div id="my-radio-group" className='font-weight-bold'>3. If other, please share some details to help us improve our product and services.</div><br/>
                                      <div className="form-group row">
                                          <div className="col-sm-12">
                                              <Field placeholder="Enter your comments" as="textarea" className="form-control" type="textarea" name="otherCancelFeedback" id="otherCancelFeedback" />
                                              <div className="text-danger"><ErrorMessage name="otherCancelFeedback" /></div>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="form-buttons text-center">
                                  <button className="mr-3 btn btn-primary" type="submit" id="btnSubmit">Next</button>
                              </div>
                          </Form>
                      </Formik>
                  </div>
              </div>
    
          </div>          
          </Modal.Body>
          </Modal>);
    }

    render() {
        return (

            <Navbar className={`${s.root} d-print-none`}>
                {/* <div>
                    <NavLink
                        onClick={this.toggleSidebar}
                        className={`d-md-none mr-3 side-tgl-btn ${s.navItem }`}
                        href="#"
                    >
                        <MenuIcon className={s.menuIcon} />
                    </NavLink>
                </div> */}

                <Nav className="ml-auto nav-main">
                <NavItem className="mr-4 headline-3" >
                    <NavLink
                        onClick={this.toggleSidebar}
                        className={`d-md-none mr-3 side-tgl-btn ${s.navItem }`}
                        href="#"
                    >
                        <MenuIcon className={s.menuIcon} />
                    </NavLink>
                </NavItem>

                <NavItem className="mr-4 headline-3" >
                        <NavLink
                            className=""
                             href="/platformpolicy" target="_blank"               >
                        Terms of Use         
                                       </NavLink>
                    </NavItem>
                    <NavItem className="mr-4  headline-3">
                        <NavLink
                            className=""
                            href="/aboutUs"
                            target="_blank"  
                        >
                            About Us
                        </NavLink>
                    </NavItem>
                    <NavItem className="mr-4  headline-3">
                        <NavLink
                            className=""
                            href="/FAQ" 
                            target="_blank"  
                        >
                            FAQs
                        </NavLink>
                    </NavItem>
                    <NavItem className="mr-4  headline-3">
                        <NavLink
                            className=""
                            href="/trainingvideos"
                            target="_blank"  
                        >
                            Training Videos
                        </NavLink>
                    </NavItem>
                    <NavItem className="mr-4  headline-3">
                        <NavLink
                            className=""
                            href="/contactus"
                            target="_blank"  
                        >
                            Contact Us
                        </NavLink>
                    </NavItem>
                    <Dropdown nav isOpen={this.state.menuOpen} toggle={this.toggleMenu} className="tutorial-dropdown mr-2 mr-sm-3">
                        <DropdownToggle nav>
                            {/*<div className={s.navbarBlock}>*/}
                            {/*    <BellIcon maskId={114}></BellIcon>*/}
                            {/*    <div className={s.count}></div>*/}
                            {/*</div>*/}
                        </DropdownToggle>
                        <DropdownMenu right className="navbar-dropdown notifications-dropdown" style={{ width: "340px" }}>
                            <div className="label muted">No new notification</div>
                            {/*<DropdownItem><img src={basketIcon} alt="Basket Icon"/><span>12 new orders have arrived today</span></DropdownItem>*/}
                            {/*<DropdownItem>*/}
                            {/*  <div>*/}
                            {/*    <div className="d-flex flex-row mb-1">*/}
                            {/*      <img src={mariaImage} alt="Maria" className={s.mariaImage} />*/}
                            {/*      <div className="d-flex flex-column">*/}
                            {/*        <p className="body-3">Maria</p>*/}
                            {/*        <p className="label muted">15 min ago</p>*/}
                            {/*      </div>*/}
                            {/*    </div>*/}
                            {/*    <img src={notificationImage} alt="Notification Icon" className={s.notificationImage}/>*/}
                            {/*    <p className="body-2 muted">It is just a simple image that can define th..</p>*/}
                            {/*  </div>*/}
                            {/*</DropdownItem>*/}
                            {/*<DropdownItem><img src={calendarIcon} alt="Calendar Icon"/><span>1 event has been canceled and ..</span></DropdownItem>*/}
                            {/*<DropdownItem><img src={envelopeIcon} alt="Envelope Icon"/><span>you have 2 new messages</span></DropdownItem>*/}
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown isOpen={this.state.notificationsOpen} toggle={this.toggleNotifications} nav id="basic-nav-dropdown" className="ml-1">
                        <DropdownToggle nav caret className="navbar-dropdown-toggle">
                            <span className={`${s.avatar} rounded-circle float-left mr-2`}>
                                <img src={userImg} alt="User" />
                            </span>
                            <span className="small d-none d-sm-block ml-1 mr-2 body-1 usernm-title">{localStorage.getItem('username')}</span>
                        </DropdownToggle>
                        <DropdownMenu className="navbar-dropdown profile-dropdown">
                            <DropdownItem className={s.dropdownProfileItem}><span>Profile</span></DropdownItem>
                            {/* <DropdownItem className={s.dropdownProfileItem}> <Link to={"/app/changepassword/" +  this.state.loggedUserId}><span> Change Password</span> </Link></DropdownItem> */}

                            {this.state.role != "Practice-Admin" ? <div></div> :  <DropdownItem className={s.dropdownProfileItem} onClick={this.showfeedback}><span>Feedback</span></DropdownItem>}
                            {this.state.role != "Practice-Admin" ? <div></div> :  
                        // <Dropdown direction="left" nav onMouseOver={this.onMouseEnter2} onMouseLeave={this.onMouseLeave2} isOpen={this.state.dropdownOpen2} toggle={this.toggle2}>
                            <Dropdown direction="left" nav isOpen={this.state.dropdownOpen2} toggle={this.toggle2}>
                            <DropdownToggle nav caret className="navbar-dropdown-toggle">
                                <div  className="profile-submenu">Manage Subscription</div>
                            </DropdownToggle>
                            <DropdownMenu className="navbar-dropdown profile-dropdown" >
                                <DropdownItem className={s.dropdownProfileItem} onClick={this.openManageSubscription}><span>Update Subscription</span></DropdownItem>
                                <DropdownItem className={s.dropdownProfileItem} onClick={this.showcancelfeedback}><span>Cancel Subscription</span></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
          }                        
                            <div>
                                    <button onClick={this.doLogout} className="btn btn-primary rounded-pill logout-btn" type="submit"><img src={logoutIcon} alt="Logout" /><span className="ml-1">Logout</span></button>
                            </div>
                        </DropdownMenu>
                    </Dropdown>
                    {/* <FeedbackModal handleSendFeedback={this.handleFeedback} showpopup={() => this.state.showfeedbackModal1}/> */}

        <Modal show={this.state.showfeedbackModal} dialogClassName={s.feedbackmodal}>
        <Modal.Header>
        <Modal.Title className="headline-2 justify-content-center">Give Your Feedback</Modal.Title>
        <button className="btn btn-close" onClick={this.closeFeedback} ></button>
         
        </Modal.Header>
        <Modal.Body className="justify-content-right">
        <div>
          <div className="card">
              <div className="card-body">
                  <Formik initialValues={{customerEmail: localStorage.getItem("username"), customerFeedback: "", feedback1:"", feedback2:"", feedback3:""}} 
                  validationSchema={Yup.object({
                                            customerFeedback: Yup.string().required("This field is required"),
                                            feedback1: Yup.string().required("This field is required"),
                                            feedback2: Yup.string().required("This field is required"),
                                            feedback3: Yup.string().required("This field is required")
                                            })
                    } onSubmit={this.handleFeedback}>
                      <Form id="form-employee">
                          <div className="row">
                              <div className="col-md-12">
                                  <div className="form-group row">
                                      <div className="col-sm-6">
                                          <Field  className="form-control" type="hidden"  disabled value={localStorage.getItem("username")} name="customerEmail" id="customerEmail" />
                                      </div>
                                  </div>

                                  <p className={s.headStyle}>The Daily Huddle believes that there is never a failure but always a feedback. It is paramount for our team to know that how we could improve our digital application to increase your productivity. Hence we would really appreciate if you could share your experience.</p><br/>

        <div className={"form-group border rounded p-4 "+s.brdrcolor}>
        <div id="my-radio-group" className="font-weight-bold">1. What is a no 1 reason that resonates with your team for failing to implement The Daily huddle digital platform?</div><br/>
            <div role="group" aria-labelledby="my-radio-group">
                    <label>
                    <Field type="radio" id="feedback1" name="feedback1" value="Training to implement" />
                    <span className="px-2">Training to Implement</span>
                    </label><br/>
                    <label>
                    <Field type="radio" id="feedback1" name="feedback1" value="Team participation" />
                    <span className="px-2">Team Participation</span>
                    </label><br/>
                    <label>
                    <Field type="radio" id="feedback1" name="feedback1" value="Application not being useful" />
                    <span className="px-2">Application not being useful</span>
                    </label><br/>
            </div>
            <div className="text-danger"><ErrorMessage name="feedback1" /></div>
        </div>
		  
          <div className="form-group border rounded p-4">
                <div id="my-radio-group" className='font-weight-bold'>2. How could we help you implementing this application better at your practice?</div><br/>
                        <div role="group" aria-labelledby="my-radio-group">
                                <label>
                                <Field type="radio" name="feedback2" value="Team Training" />
                                <span className="px-2">Team Training</span>
                                </label><br/>
                                <label>
                                <Field type="radio" name="feedback2" value="Rich Content" />
                                <span className="px-2">Rich Content</span>
                                </label><br/>
                                <label>
                                <Field type="radio" name="feedback2" value="Subscription Cost" />
                                <span className="px-2">Subscription Cost</span>
                                </label><br/>
                        </div>
                <div className="text-danger"><ErrorMessage name="feedback2" /></div>
            </div>
		  
          <div className="form-group border rounded p-4">
		                        <div id="my-radio-group" className='font-weight-bold'>3. If we were able to solve road blocks that you faced, would you consider to reconnect with our app? </div><br/>
                                <div role="group" aria-labelledby="my-radio-group">
                                                <label>
                                                <Field type="radio" name="feedback3" value="Yes" />
                                                <span className="px-2">Yes</span>
                                                </label><br/>
                                                <label>
                                                <Field type="radio" name="feedback3" value="No" />
                                                <span className="px-2">No</span>
                                                </label><br/>
                                                <label>
                                                <Field type="radio" name="feedback3" value="May be" />
                                                <span className="px-2">May be</span>
                                                </label><br/>
                                </div>
                                <div className="text-danger"><ErrorMessage name="feedback3" /></div>
        </div>

          <div className="form-group border rounded p-4">
          <div id="my-radio-group" className='font-weight-bold'>4. Share your experience in few words that would help us to improve our product and implementation.</div><br/>
                                  <div className="form-group row">
                                      <div className="col-sm-12">
                                          <Field placeholder="Enter your feedback" as="textarea" className="form-control" type="textarea" name="customerFeedback" id="customerFeedback" />
                                          <div className="text-danger"><ErrorMessage name="customerFeedback" /></div>
                                      </div>
                                  </div>
                                  </div>
                              </div>
                          </div>
                          <div className="form-buttons text-center">
                              <button className="mr-3 btn btn-primary" type="submit" id="btnSubmit">Send</button>
                          </div>
                      </Form>
                  </Formik>
              </div>
          </div>
      </div>          
      </Modal.Body>
      </Modal>

      {this.renderCancelSubscriptionModal()}
                </Nav>
            </Navbar>
        )
    }
}

function mapStateToProps(store) {
    return {
        sidebarOpened: store.navigation.sidebarOpened,
        sidebarStatic: store.navigation.sidebarStatic,
    };
}

export default withRouter(connect(mapStateToProps)(Header));

