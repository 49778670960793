import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Label,
  Badge,
  Progress,
} from "reactstrap";
import ReactPaginate from "react-paginate";

import Widget from "../../components/Widget/Widget.js";
import TrainingService from "./../../services/training/training.service";
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../assets/tables/optionsIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import searchIcon from "../../assets/tables/searchIcon.svg";
import moreIcon from "../../assets/tables/moreIcon.svg";
import { Link, useParams } from "react-router-dom";
import s from "./Tables.module.scss";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";

import CommonHelper from "../../helper/common-helper.js";
import EnumHelper from "../../helper/enum-helper";
import PdfHelper from "../../helper/pdf-helper";
import PreviewModal from "../pdfPreview/preview.js";
import * as html2pdf from "html2pdf.js";
import { saveAs } from "file-saver";
import Spinner from "react-bootstrap/Spinner";

import Notification from "../../components/Notification/Notification.js";

const TrainingList = function () {
  const [response, setTrainingList] = useState([]);
  const [totalCount, setTotalCount] = useState([], false);
  const txtSearch = useRef(null);
  const [showModal, handleModalShowHide] = useState([], false);
  const [showHide, setShowHide] = useState([], false);
  const [logoPreview, setLogoPreview] = useState(null);
  const [showloader, setLoading] = useState([], false);

  let pageSize = 10;
  let pageNo = 1;
  let { id } = useParams();
  const practiceId = localStorage.getItem("practiceId");
  const role = localStorage.getItem("role");

  const [pdfURl, setPdfUrl] = useState("", false);
  const [showModel, setModel] = useState(false);

  useEffect(() => {
    getTrainings();
    handleModalShowHide(false);
  }, []);

  const handlePageClick = (data) => {
    let selected = data.selected + 1;
    pageNo = selected;

    getTrainings();
  };

  const getTrainings = async () => {
    var payload = {
      searchTerm: txtSearch.current.value,
      pageSize: pageSize,
      pageNumber: pageNo,
      employeeId: id,
    };
    setLoading(  true );

    await new Promise(function (resolve, reject) {
      TrainingService.search(payload)
        .then((result) => {
          resolve(result);
          if (result.data.data) {
            setTrainingList(result.data.data.trainingList);
            setTotalCount(
              Math.ceil(parseInt(result.data.data.totalRecords) / pageSize)
            );
            pageNo = payload.pageNumber;
            setLoading(  false );

          }
          setLoading(  false );

        })
        .catch((e) => {
          console.log(e);
        });
    });
  };
  const viewAttachment = async (performanceId) => {
    TrainingService.get(id, performanceId).then((performance) => {
      let base64 = performance?.data?.data["documentBase64"];
      if (base64 != "") {
        if (CommonHelper.isFilePreviewPossible(base64)) {
          saveAs(base64);
        } else {
          CommonHelper.downloadAttachment(base64);
        }
      } else {
        return toast(
          <Notification type="warning" message="No attachment found" />
        );
      }
    });
  };

  const deleteRecord = async (trainingId) => {
    const result = await confirm(
      "Are you sure? \nYou will not be able to recover this Training!"
    );
    if (result) {
    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */
      await TrainingService.delete(id, trainingId)
        .then((result) => {
          // toast(
          //   <Notification
          //     type="success"
          //     message="Training record deleted successfully."
          //   />
          // ),

           /* Success Loader Called */
            loader.style.display='none';
            successloader.style.display='block';
            success_message.innerHTML="Training record deleted successfully.";
            let timer =   setInterval(myTimer, 3000);
            function myTimer() {
                successloader.style.display='none';
                clearInterval(timer);  
            }
            getTrainings();
          //history.push('/app/marketinglist');
        })
        .catch((e) => {
          /* Fail Loader Called */
          loader.style.display='none';
          failloader.style.display='block';
          fail_message.innerHTML="Training record not deleted successfully.";
          let timer =  setInterval(myTimer, 3000);
          function myTimer() {
              failloader.style.display='none';
              clearInterval(timer);   
          }      
          toast(console.log(e));
        });
    }
  };

  const handleOnPdfPreview = (event) => {
    if (
      event.nativeEvent.target &&
      event.nativeEvent.target.classList.contains("ppreview")
    ) {
      let options = EnumHelper.PdfOption,
        boundingRect = document
          .getElementById("mainTraining")
          .getBoundingClientRect();
      options["jsPDF"]["format"] = [
        boundingRect.width - 200,
        boundingRect.height - 100,
      ];
      html2pdf()
        .from(document.getElementById("mainTraining"))
        .set(options)
        .outputPdf()
        .then((pdf) => {
          setPdfUrl(btoa(pdf));
          setModel(!showModel);
        });
    } else if (showModel) {
      setModel(!showModel);
    }
  };

  return (
    <div id="mainTraining">
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">Training</div>
                  <div className="d-flex">
                    <a onClick={(e) => PdfHelper.printPage()}>
                      <img src={printerIcon} alt="Printer" />
                    </a>
                    <a
                      className="mx-2 ppreview"
                      onClick={(e) => {
                        handleOnPdfPreview(e);
                      }}
                    >
                      <PreviewModal isOpen={showModel} url={pdfURl} /> Preview
                    </a>
                    <a
                      onClick={() =>
                        PdfHelper.downloadPdf(
                          document.getElementById("mainTraining"),
                          "Training"
                        )
                      }
                    >
                      <img src={cloudIcon} alt="Download" />
                    </a>
                  </div>
                </div>
                <div className={s.tableTitle}>
                  <Col className="col-3">
                    <input
                      type="text"
                      ref={txtSearch}
                      name="txtSearch"
                      className="form-control "
                      placeholder="Search"
                    />
                  </Col>
                  <Col>
                    <input
                      className="btn btn-secondary"
                      type="button"
                      value="Search"
                      onClick={getTrainings}
                    />
                  </Col>
                  <Col>
                    {role === "Employee" ? (
                      <div></div>
                    ) : (
                      <Link
                        to={
                          "/app/training/" +
                          id +
                          "/0/" +
                          EnumHelper.ManageEmployee.Training
                        }
                        className="btn btn-primary pull-right"
                      >
                        Add Training
                      </Link>
                    )}
                  </Col>
                </div>

                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless`}
                    responsive
                  >
                    <thead>
                      <tr>
                   <th className="w-25">TITLE</th>
                        <th className="w-25">DATE OF UPLOAD</th>
                        <th className="w-40">CATEGORY </th>
                        <th className="w-25">ACTIONS</th>

                      </tr>
                    </thead>
                    <tbody>
                      {showloader ? (
                  <tr>
                    <td rowSpan="4" colSpan="4">
                      <div className="text-center py-5">
                          <Spinner animation="border" />
                      </div>
                    </td>
                  </tr>
                ) :response.length == 0 ? (
                  <tr>
                    <td colSpan="4">
                      <div className="alert alert-secondary text-primary m-3 color">
                        No records found
                      </div>
                    </td>
                  </tr>
                ) : response.map((item) => (
                        <tr key={uuidv4()}>
                          <td>{item.title}</td>
                          <td>{item.strDateOfUpload}</td>
                          <td>{item.category}</td>
                          {role === "Employee" ? (
                            <td>
                              
                              <Link
                                to="#"
                                onClick={() => viewAttachment(item.id)}
                                title="Attachments"
                                className={s.blackColor}
                              >
                                <i className="fa fa-paperclip"></i>
                              </Link>
                              &nbsp;
                            </td>
                          ) : (
                            <td>
                              <Link
                                to={
                                  "/app/training/" +
                                  id +
                                  "/" +
                                  item.id +
                                  "/" +
                                  EnumHelper.ManageEmployee.Training
                                }
                                title="Edit"
                                className={s.blackColor}
                              >
                                <i className="fa fa-edit"></i>
                              </Link>
                              &nbsp;
                              <Link
                                to="#"
                                onClick={() => deleteRecord(item.id)}
                                title="Delete"
                                className={s.blackColor}
                              >
                                <i className="fa fa-trash"></i>
                              </Link>
                              &nbsp;
                              <Link
                                to="#"
                                onClick={() => viewAttachment(item.id)}
                                title="Attachments"
                                className={s.blackColor}
                              >
                                <i className="fa fa-paperclip"></i>
                              </Link>
                              &nbsp;
                            </td>
                          )}
                        </tr>
                      ))}
                      
                      
                    </tbody>
                  </Table>
                  <Row>
                    <Col className="ml-2"></Col>
                    <Col className="col-10">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=" >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={pageSize}
                        pageCount={Math.ceil(totalCount)}
                        previousLabel="< "
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination pagination-sm"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"previous"}
                        previousLinkClassName={"page-item page-link"}
                        nextClassName={"next"}
                        nextLinkClassName={"page-item page-link"}
                        activeClassName={"active"}
                      />
                    </Col>
                  </Row>
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default TrainingList;
