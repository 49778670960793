import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import {
    Col,
    Row,
    Table,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';

import Widget from "../../components/Widget/Widget.js";
import PracticeService from "./../../services/practice/practice.service"
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../assets/tables/optionsIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import searchIcon from "../../assets/tables/searchIcon.svg";
import moreIcon from "../../assets/tables/moreIcon.svg";
import PreviewModal from "../pdfPreview/preview.js";
import * as html2pdf from 'html2pdf.js'
import EnumHelper from "../../helper/enum-helper";
import PdfHelper from "../../helper/pdf-helper";
import Spinner from "react-bootstrap/Spinner";

import s from "./Tables.module.scss";

import { toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification.js";

const PracticeList = function () {

    const [response, setPracticeList] = useState([]);
    const [totalCount, setTotalCount] = useState([], false);
    const txtSearch = useRef(null);

    let pageNo = 1, pageSize = 10,searchTerm="";
    const practiceId = localStorage.getItem('practiceId');
    const role = localStorage.getItem('role');

    const [pdfURl, setPdfUrl ] = useState('', false);
    const [showModel , setModel] = useState(false);
    const [showloader, setLoading] = useState([], false);


    useEffect(() => {
        getPractice();
    }, []);




    const handlePageClick = (data) => {
        let selected = data.selected + 1;
        pageNo = selected
        getPractice();

    };
    const search = () => {
        getPractice();
    }

    const getPractice = async () => {

        var payload = {
            "practiceId":practiceId,
            "searchTerm": txtSearch.current.value,
            "pageSize": pageSize,
            "pageNumber": pageNo
        }

        setLoading(  true );


        await new Promise(function (resolve, reject) {
            PracticeService.search(payload).then(result => {
                resolve(result);

                if(result.data.data.practiceList !=null)
                {
                setPracticeList(result.data.data.practiceList);
                setTotalCount(Math.ceil(parseInt(result.data.data.totalRecords) / pageSize));
                pageNo = payload.pageNumber;
                }
                setLoading(  false );

            }).catch(e => {
                setLoading(  false );

            })
        });

    }

         
    const deleteRecord = async (practiceId) => {
        const result = await confirm("Are you sure? \nYou will not be able to recover this Practice!");
        if(result){
        
        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */

        await PracticeService.delete(practiceId)
            .then(result => {
                // toast(<Notification type="success" message="Practice record deleted successfully." />),

                /* Success Loader Called */
                loader.style.display='none';
                successloader.style.display='block';
                success_message.innerHTML="Practice record deleted successfully.";
                let timer =   setInterval(myTimer, 3000);
                function myTimer() {
                    successloader.style.display='none';
                    clearInterval(timer);  
                }

                getResources();
                //history.push('/app/marketinglist');
            })
            .catch(e => { 
                
                /* Fail Loader Called */
                loader.style.display='none';
                failloader.style.display='block';
                fail_message.innerHTML="Practice record not deleted successfully.";
                let timer =  setInterval(myTimer, 3000);
                function myTimer() {
                    failloader.style.display='none';
                    clearInterval(timer);   
                }

                toast(); 
            });
        }
    }

    const handleOnPdfPreview = (event) => {                
        if(event.nativeEvent.target && event.nativeEvent.target.classList.contains('ppreview')){
            let options=EnumHelper.PdfOption, boundingRect=document.getElementById('main').getBoundingClientRect();
            options['jsPDF']['format']=[boundingRect.width-200,boundingRect.height];
            html2pdf().from(document.getElementById('main')).set(options).outputPdf().then((pdf) => {
                setPdfUrl(btoa(pdf));
                setModel(!showModel)
            })
        }
        else if(showModel){
            setModel(!showModel)
        }        
    }
    
    return (
        <div id = "main">
            <Row>
                <Col>
                    <Row className="mb-4">
                        <Col>
                            <Widget>
                            <div className={s.tableTitle}>
                                    <div className="headline-2">Practices</div>
                                    <div className="d-flex">                                        
                                        <a onClick={(e) => PdfHelper.printPage()}><img src={printerIcon} alt="Printer" /></a>
                                    <a className="mx-2 ppreview" onClick={(e) => { handleOnPdfPreview(e); }}><PreviewModal isOpen={showModel} url={pdfURl} /> Preview</a>
                                    <a onClick={() => PdfHelper.downloadPdf(document.getElementById('main'), "Practice")}><img src={cloudIcon} alt="Download" /></a>
                                    </div>
                                </div>
                                <div className={s.tableTitle}>
                                    <Col className="col-3">
                                        <input type="text" ref={txtSearch} name="txtSearch" className="form-control " placeholder="Search" />
                                    </Col>
                                    <Col>
                                        {/*<input className="btn btn-secondary" type="button" value="Search" />*/}
                                        <button className="mr-3 btn btn-secondary" onClick={getPractice} type="submit" id="btnSearch">Search</button>

                                    </Col>
                                    <Col>
                                        {role === "Admin" ? <Link to="/app/practice/0" title="Add Practice" className="btn btn-primary pull-right">
                                            Add Practice
                                        </Link> : <div></div>}
                                    </Col>
                                </div>
                                
                                <div className="widget-table-overflow">
                                    <Table className={`table-striped table-borderless table-hover ${s.statesTable}`} responsive>
                                        <thead>
                                            <tr>                                               
                                                <th className="W-25">BUSINESS NAME</th>
                                                <th className="W-25">ABN NUMBER</th>
                                                <th className="W-25">EMAIL</th>
                                                <th className="W-25">PHONE NUMBER</th>
                                                <th className="w-25">ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {showloader ? (
                  <tr>
                    <td rowSpan="5" colSpan="5">
                      <div className="text-center py-5">
                          <Spinner animation="border" />
                      </div>
                    </td>
                  </tr>
                ) :response.length == 0 ? (
                  <tr>
                    <td colSpan="5">
                      <div className="alert alert-secondary text-primary m-3 color">
                        No records found
                      </div>
                    </td>
                  </tr>
                ) :
                                                response
                                                    .map(item => (
                                                        <tr key={uuidv4()}>
                                                            <td>{item.businessName}</td>
                                                            <td>{item.abnNo}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.phoneNo}</td>
                                                            {role === "Employee" ? <td></td> : <td>
                                                                <Link to={"/app/register/" + item.id} title="Add User" className={s.blackColor}><i className="fa fa-user-plus"></i></Link>&nbsp;
                                                                <Link to={"/app/practice/" + item.id} title="Edit" className={s.blackColor}><i className="fa fa-edit"></i></Link>&nbsp;
                                                                <a  title="Delete" className={s.blackColor}><i className="fa fa-trash"></i></a></td>} 
                                                                {/* onClick={()=>deleteRecord(item.id)} */}
                                                        </tr>
                                                    ))
                                            }
                                          
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col className="ml-2">
                                        </Col>
                                        <Col className="col-10">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=" >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={pageSize}
                                                pageCount={Math.ceil(totalCount)}
                                                previousLabel="< "
                                                renderOnZeroPageCount={null}
                                                containerClassName={'pagination pagination-sm'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'previous'}
                                                previousLinkClassName={'page-item page-link'}
                                                nextClassName={'next'}
                                                nextLinkClassName={'page-item page-link'}
                                                activeClassName={'active'}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Widget>
                        </Col>
                    </Row>


                </Col>
            </Row>
        </div>
    )
}

export default PracticeList;
