import React, { useEffect, useState,useRef } from "react";
import Logo from "../../components/Icons/SidebarIcons/SofiaLogo";

const websitepolicy = function () {


    return ( <div className="container">
    <p align="center">
<div className="col-5">
<br></br>
<Logo/>
<br></br>
<br></br>
</div>
    <h4>WEBSITE TERMS</h4>
</p>
<p>
    By using this website you agree to be bound by the terms and conditions set
    out on this webpage. If you do not accept these terms and conditions, you
    must refrain from using this website.
</p>
<p>
    This website is owned and operated by The Daily Huddle Pty Ltd ACN 665 245 205 operating as The Daily Huddle (referred to in these terms and
    conditions as ‘we’, ‘us’, or ‘our’).
</p>
<br></br>
<p>
<strong>1&nbsp;&nbsp; INTELLECTUAL PROPERTY</strong>
</p>
<p>
    Unless otherwise indicated, all materials used on this website (including
    text, graphics, logos, icons, sound recordings and software) are subject to
    copyright, trade mark or other intellectual property rights that are owned
    or licensed by us. You may only access and use these materials for
    non-commercial or personal use, except to the extent permitted by law or
    where you have received prior written approval from us.
</p>
<br></br>
<p>
    <strong>2&nbsp;&nbsp; LIABILITY</strong>
</p>
<br></br>
<p>
    We make no warranties or representations about this website or any of its
    content and will not be responsible to you or any third party for any
    direct or consequential loss suffered in connection with the use of this
    website. To the maximum extent permitted by law, we exclude any liability
    that may arise due to your use of our website and/or the information or
    materials contained on it. You agree to indemnify us for any loss or
    liability arising out of your use of this site.
</p>
<br></br>
<p>
     <strong>3&nbsp;&nbsp; EXTERNAL LINKS</strong>
</p>
<br></br>
<p>
    Our website may contain links to websites operated by third parties. Those
    links are provided for convenience and may not remain current or be
    maintained. Unless expressly stated otherwise, we do not endorse and are
    not responsible for the content on those linked websites and have no
    control over or rights in those linked websites.
</p>
<br></br>
<p>
    <strong>4 &nbsp;&nbsp;UPDATES</strong>
</p>
<br></br>
<p>
    We may change these terms and conditions at any time by updating this
    webpage and your continued use of the website following such update will
    represent an agreement by you to be bound by the terms and conditions as
    amended.
</p>
<br></br>
<p>
     <strong>5&nbsp;&nbsp; PRIVACY</strong>
</p>
<br></br>
<p>
    These terms and conditions also include our Privacy Policy that can be
    accessed by clicking the link on this page.
</p>
<br></br>
<p>
     <strong>6&nbsp;&nbsp; JURISDICTION</strong>
</p>
<br></br>
<p>
    Your use of the website and these terms and conditions are governed by the
    laws of New South Wales, Australia.
</p></div>) 
}

export default websitepolicy;
