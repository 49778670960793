import http from "../../config/http-common";

class registerService {
   // async getAll(data, id, pageNo) {
   //     return await http.post(`assignments/${id}/${pageNo}`, data);
   // }
    //get(id) {
    //    return http.get(`assignments/${id}`);
    //}
    create(practiceId,data) {
        return http.post(`${practiceId}/register`, data);
    }
    SendMail(email) {
        return http.post(`forgotPassword?email=${email}`);
    }
    //delete(id) {
    //    return http.delete(`assignments`);
    //}
    //update(id, data) {
    //    return http.post(`assignments/${id}`, data);
    //}
}

export default new registerService();