import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { v4 as uuidv4 } from "uuid";
import {
    Col,
    Row,
    Button
} from "reactstrap";
import TextError from "../../components/Error/TextError";
import DashboardService from "./../../services/dashboard/dashboard.service"
import DailyTaskService from "./../../services/dashboard/daily-task.service"
import EmployeeService from "../../services/employee/employee.service"
import CategoryService from "./../../services/category/category.service"
import { Modal } from 'react-bootstrap'
import { Formik, Field, Form, ErrorMessage } from "formik";
import Widget from "../../components/Widget/Widget.js";
import printerIcon from "../../assets/tables/printerIcon.svg";
import { Link } from "react-router-dom";
import ChangePasswordService from "./../../services/changePassword/changePassword.service"
import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import { toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification.js";

const ChangePassword = () => {
    const { userid } = useParams();
    const initialValues = { email: "", username: "", oldPassword: "", newPassword: "", confirmPassword :"" };
    const [emailAddress, setStateEmailAddress] = useState("", false);
    let history = useHistory();  // declare here, inside a React component.


    useEffect(() => {
        getUser();
    }, []);
    const validationSchema = Yup.object({
     
        oldPassword: Yup.string().required("Please enter old password"),
        newPassword: Yup.string().required("Password is required")
            .oneOf([Yup.ref('confirmPassword'), null], "New Password and Confirm Password must be same")
            .required('Confirm Password is required'),
        confirmPassword: Yup.string().required("Password is required")
            .oneOf([Yup.ref('newPassword'), null], "New Password and Confirm Password must be same")
            .required('Confirm Password is required')
       
    });

    const handleSubmit = async (values) => {        
        if (userid != null) {

            let payload = { "email": emailAddress, "currentPassword": values.oldPassword, "newPassword": values.newPassword, "confirmNewPassword": values.confirmPassword }

            await new Promise(function (resolve, reject) {
                ChangePasswordService.changePassword(userid,payload).then(result => {
                    resolve(result);
                    if (result.data.statuscode == 200) {
                        toast(<Notification type="success" message={result.data.data} />)
                        history.push('/app/dashboard')
                    }
                    else {
                        toast(<Notification type="error" message={result.data.message}/>)

                    }
                   
                }).catch(e => {
                })
            });
        }
           
    }
    const getUser = async () => {
        await new Promise(function (resolve, reject) {
            ChangePasswordService.get(userid).then(result => {
                resolve(result);
                setStateEmailAddress(result.data.data.user.email);
            }).catch(e => {
            })
        });
    }

    const changePassword = async () => {



    }

    return (
        <div className="card">
            <h5 className="card-header">Change Password</h5>
            <div className="card-body">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>

                    <Form id="form-employee">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="col-form-label null " htmlFor="email">Email </label>
                                    <Field placeholder="Enter email Id" className="form-control" type="text" disabled value={emailAddress} name="email" />
                                    <ErrorMessage name="email" component={TextError} />
                                </div>
                                <div className="form-group has-validation">
                                    <label className="col-form-label null " htmlFor="oldPassword"> Old Password </label>
                                    <Field placeholder="Enter Old Password" className="form-control" type="password" name="oldPassword" />
                                    <ErrorMessage name="oldPassword" component={TextError} />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label null " htmlFor="newPassword">  New Password </label>
                                    <Field placeholder="Enter email address" className="form-control" type="password" name="newPassword" />
                                    <ErrorMessage name="newPassword" component={TextError} />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label null " htmlFor="confirmPassword">
                                        Confirm Password
                                    </label>
                                    <Field placeholder="Enter confirm password" className="form-control" type="password" name="confirmPassword" />
                                    <ErrorMessage name="confirmPassword" component={TextError} />
                                </div>
                            </div>
                        </div>
                        <div className="form-buttons">
                            <button className="mr-3 btn btn-primary" type="submit" id="btnSubmit">Save</button>
                            <Link to="/app/employeelist" className="mr-3 btn btn-secondary">Cancel</Link>
                        </div>
                    </Form>
                    

                </Formik>
            </div>
        </div>
    );
}
export default ChangePassword;
