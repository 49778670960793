import React, { useState,forwardRef } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays,faCaretLeft,faCaretRight} from "@fortawesome/free-solid-svg-icons";

const datepicker = ({onChangeMethod}) => {
    const [seletedDate, setSeletedDate] = useState(new Date());

    function handleChange(selectedDate) {
        setSeletedDate(selectedDate);
        onChangeMethod(selectedDate);
     }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (

<span className="fa-layers fa-fw fa-main" onClick={onClick} ref={ref}>
<FontAwesomeIcon icon={faCalendarDays} size="2x"  className="cal-icon-color"/>
<span className="fa-layers-text fa-inverse fa-cal" data-fa-transform="shrink-8 down-3" ></span>
</span>
    ));
    return (
      <DatePicker selected={seletedDate} onChange={handleChange} showMonthDropdown showYearDropdown dropdownMode="scroll"
      
      todayButton={<button className="btn btn-primary rounded" style={{color:"white",backgroundColor:"#00abbe",padding:"5px 10px"}}>Today</button>}
        customInput={<ExampleCustomInput />}
      />
    );
  };
export default datepicker;