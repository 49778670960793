import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import {
    Col,
    Row,
    Table,
    Label,
} from "reactstrap";
import ReactPaginate from 'react-paginate';
import Widget from "../../components/Widget/Widget.js";
import EmployeeService from "./../../services/employee/employee.service"
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../assets/tables/optionsIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import searchIcon from "../../assets/tables/searchIcon.svg";
import s from "./Tables.module.scss";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PreviewModal from "../pdfPreview/preview.js";
import * as html2pdf from 'html2pdf.js'
import EnumHelper from "../../helper/enum-helper";
import PdfHelper from "../../helper/pdf-helper";
import Spinner from "react-bootstrap/Spinner";



const EmployeeList = function () {

    const [response, setEmployeeList] = useState([], false);
    const [totalCount, setTotalCount] = useState([], false);
    const [showloader, setLoading] = useState([], false);

    const txtSearch = useRef(null);
    let pageSize = 10;
    let pageNo = 1;
    const practiceId = localStorage.getItem('practiceId');
    const role = localStorage.getItem('role');

    const [pdfURl, setPdfUrl ] = useState('', false);
    const [showModel , setModel] = useState(false);


    useEffect(() => {

        getEmployees();
        return () => {
            setEmployeeList([]); // This worked for me
        };
    }, []);

    const handlePageClick = (data) => {
        let selected = data.selected + 1;
        pageNo = selected;
        getEmployees();
    };

    const getEmployees = async () => {

        var payload = { "searchTerm": txtSearch.current.value, "pageSize": pageSize, "pageNumber": pageNo, "practiceId": practiceId }

        setLoading(  true );

        await new Promise(function (resolve, reject) {
            EmployeeService.search(payload).then(result => {
                resolve(result);

                setEmployeeList(result.data.data.employeeList);
                setTotalCount(Math.ceil(parseInt(result.data.data.totalRecords) / pageSize));
                pageNo = payload.pageNumber;
                setLoading(  false );

            }).catch(e => {
               console.log(e);
            })
        });
    }

    const deleteRecord = async (id) => {
        const result = await confirm("Are you sure? \nYou will not be able to recover this Employee!");
        if(result)
        {
            let loader = document.getElementById('loader');
            let successloader = document.getElementById('successloader');
            let failloader = document.getElementById('failloader');
            let success_message = document.getElementById('success-message');
            let fail_message = document.getElementById('fail-message');
            loader.style.display='block';/* Loader Section Activited */

            await EmployeeService.delete(id)
            .then(result => {
                // toast("Employee deleted successfully."),

                /* Success Loader Called */
                loader.style.display='none';
                successloader.style.display='block';
                success_message.innerHTML="Employee deleted successfully.";
                let timer =   setInterval(myTimer, 3000);
                function myTimer() {
                    successloader.style.display='none';
                    clearInterval(timer);  
                }

                getEmployees()
                //history.push('/app/socialmedialist');
            })
            .catch(e => { 
                /* Fail Loader Called */
                loader.style.display='none';
                failloader.style.display='block';
                fail_message.innerHTML="Employee not deleted successfully.";
                let timer =  setInterval(myTimer, 3000);
                function myTimer() {
                    failloader.style.display='none';
                    clearInterval(timer);   
                }

                toast(console.log(e)); 
            });
        }
    }

    const handleOnPdfPreview = (event) => {                
        if(event.nativeEvent.target && event.nativeEvent.target.classList.contains('ppreview')){
            let options=EnumHelper.PdfOption, boundingRect=document.getElementById('main').getBoundingClientRect();
            options['jsPDF']['format']=[boundingRect.width-200,boundingRect.height-100];
            html2pdf().from(document.getElementById('main')).set(options).outputPdf().then((pdf) => {
                setPdfUrl(btoa(pdf));
                setModel(!showModel)
            })
        }
        else if(showModel){
            setModel(!showModel)
        }        
    }

    return (
        <div id = "main">
            <Row>
                <Col>
                    <Row className="mb-4">
                        <Col>
                            <Widget>
                                <div className={s.tableTitle}>
                                    <div className="headline-2">Team Members</div>
                                    <div className="d-flex">                                        
                                        <a onClick={(e) => PdfHelper.printPage()}><img src={printerIcon} alt="Printer" /></a>
                                    <a className="mx-2 ppreview" onClick={(e) => { handleOnPdfPreview(e); }}><PreviewModal isOpen={showModel} url={pdfURl} /> Preview</a>
                                    <a onClick={() => PdfHelper.downloadPdf(document.getElementById('main'), "Team Members")}><img src={cloudIcon} alt="Download" /></a>
                                    </div>
                                </div>
                                <div className={s.tableTitle}>
                                    <Col className="col-3">
                                        <input type="text" ref={txtSearch} name="txtSearch" className="form-control " placeholder="Search by Name" />
                                    </Col>
                                    <Col>
                                        <input className="btn btn-secondary" title="Search" onClick={getEmployees} type="button" value="Search" />
                                    </Col>
                                    <Col>
                                        
                                        {role === "Employee" ? <div></div> : <Link to="/app/employee/0" title="Add" className="btn btn-primary pull-right">
                                            Add Team Member
                                        </Link>}
                                    </Col>
                                </div>
                                <div className="widget-table-overflow">
                                    {/*<Table className={`table-striped table-borderless table-hover ${s.statesTable}`} responsive>*/}
                                    <Table className={`table-striped table-borderless`} responsive>
                                        <thead>
                                            <tr>
                                                {/* <th className={s.checkboxCol}>
                                                    <div className="checkbox checkbox-primary">
                                                        <input  className="styled" id="checkbox100"  type="checkbox"
                                                        />
                                                        <label htmlFor="checkbox100" />
                                                    </div>
                                                </th> */}
                                                {/* <th className="w-15">ID</th> */}
                                                <th className="w-25">FIRST NAME</th>
                                                <th className="w-25">LAST NAME</th>
                                                <th className="w-25">POSITION</th>
                                                <th className="w-100">ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {showloader ? (
                  <tr>
                    <td rowSpan="4" colSpan="4">
                      <div className="text-center py-5">
                          <Spinner animation="border" />
                      </div>
                    </td>
                  </tr>
                ) :   response.length == 0 ? <tr><td colSpan="6"><div className='alert alert-secondary text-primary m-3 color' >No records found</div></td></tr> : response
                                                .map(item => (
                                                    <tr key={uuidv4()}>
                                                        {/* <td>
                                                            <div className="checkbox checkbox-primary">
                                                                <input
                                                                    id={item.id.toString()}
                                                                    className="styled"
                                                                    type="checkbox"
                                                                />
                                                                <Label htmlFor={item.id.toString()} />
                                                            </div>
                                                        </td> */}
                                                        {/* <td>{item.id}</td> */}
                                                        <td>{item.firstName}</td>
                                                        <td>{item.lastName}</td>
                                                        <td>{item.position}</td>
                                                        {role === "Employee" ? <td><Link to={"/app/employee-view/" + item.id} title="View" className={s.blackColor}><i className="fa fa-eye"></i></Link></td> : <td><Link to={"/app/employee-view/" + item.id} title="View" className={s.blackColor}><i className="fa fa-eye"></i></Link>&nbsp;
                                                            <Link to={"/app/manage-employee-view/" + item.id} title="Edit" className={s.blackColor}><i className="fa fa-edit"></i></Link>&nbsp;
                                                            <Link to="#" onClick={()=>deleteRecord(item.id)} className={s.blackColor}><i className="fa fa-trash"></i></Link></td>}

                                                       
                                                    </tr>
                                                ))}
                                          
                                        </tbody>
                                    </Table>

                                    <Row>
                                        <Col className="ml-2">

                                        </Col>
                                        <Col className="col-10">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=" >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={pageSize}
                                                pageCount={Math.ceil(totalCount)}
                                                previousLabel="< "
                                                renderOnZeroPageCount={null}
                                                containerClassName={'pagination pagination-sm'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'previous'}
                                                previousLinkClassName={'page-item page-link'}
                                                nextClassName={'next'}
                                                nextLinkClassName={'page-item page-link'}
                                                activeClassName={'active'}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Widget>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
export default EmployeeList;
