import http from "../../config/http-common";

class employeeService {   
    async getAll(data,pageNo) {
        return await http.post(`employees/${pageNo}`,data);
    }
    get(id) {
        return http.get(`employees/${id}`);
    }
    getPracticeEmployees(id) {
        return http.get(`employees/getAll?practiceId=${id}`);
    }
    search(data) {
        return http.post(`employees/search`,data);
    }
    async create(data) {
        return await http.post("employees?employeeId=0", data);
    }
    async delete(id) {
        return await http.delete(`employees/${id}`);
    }
    update(id,data) {
        return  http.post("employees?employeeId="+id, data);
    }


}

export default new employeeService();