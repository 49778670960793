import React from "react";
import s from "./Footer.module.scss";

class Footer extends React.Component {
  render() {
    return (
      <div className={s.footer}>
        <span className={s.footerLabel}> Powered by SimPos</span>
      </div>
    )
  }
}

export default Footer;
