import http from "../../config/http-common";

class DailyTaskService
{
    create(data) {        
        return http.post("dailytask", data);
    }
    update(id,value) {        
        return http.post(`dailytask/dailyTaskUpdate?id=${id}&isChecked=${value}`);
    }
    get(date,id) {
        return http.get(`dailyTask?date=${date}&practiceId=${id}`);
    }


}

export default new DailyTaskService();