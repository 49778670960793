import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Col,Row,Table} from "reactstrap";
import ReactPaginate from 'react-paginate';
import Widget from "../../components/Widget/Widget.js";
import LabService from "./../../services/Lab/LabService.js";
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import s from "./Tables.module.scss";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PreviewModal from "../pdfPreview/preview.js";
import * as html2pdf from 'html2pdf.js';
import EnumHelper from "../../helper/enum-helper";
import PdfHelper from "../../helper/pdf-helper";
import Spinner from "react-bootstrap/Spinner";



const LabList = function () {

    const [LabList, setLabList] = useState([], false);
    const [totalCount, setTotalCount] = useState([], false);
    const [showloader, setLoading] = useState([], false);

    const txtLabName = useRef(null);
    const txtTechName = useRef(null);
    const txtEmail = useRef(null);
    const txtContactPhone = useRef(null);
    let pageSize = 10;
    let pageNo = 1;
    const practiceId = localStorage.getItem('practiceId');
    const role = localStorage.getItem('role');

    const [pdfURl, setPdfUrl ] = useState('', false);
    const [showModel , setModel] = useState(false);


    useEffect(() => {

        getLabs();
        return () => {
            setLabList([]);
        };
    }, []);

    const handlePageClick = (data) => {
        let selected = data.selected + 1;
        pageNo = selected;
        getLabs();
    };

    const getLabs = async () => {

        var payload = { "searchTerm": txtLabName.current.value, "Email": txtEmail.current.value,"TechnicianName": txtTechName.current.value,"ContactPhone": txtContactPhone.current.value,"pageSize": pageSize, "pageNumber": pageNo, "practiceId": practiceId }

        setLoading(  true );

        await new Promise(function (resolve, reject) {
            LabService.search(payload).then(result => {
                resolve(result);

                setLabList(result.data.data.labList);
                setTotalCount(Math.ceil(parseInt(result.data.data.totalRecords) / pageSize));
                pageNo = payload.pageNumber;
                setLoading(  false );

            }).catch(e => {
               console.log(e);
            })
        });
    }


     /* Function for reset the modal popup */
     const resetSearch = async () => {
        /*Reset All Search Fields */
        txtLabName.current.value = "";
        txtTechName.current.value = "";
        txtEmail.current.value = "";
        txtContactPhone.current.value = "";
        getLabs();
    }

    const deleteRecord = async (id) => {
        const result = await confirm("Are you sure? \nYou will not be able to recover this Lab!");
        if(result)
        {
            let loader = document.getElementById('loader');
            let successloader = document.getElementById('successloader');
            let failloader = document.getElementById('failloader');
            let success_message = document.getElementById('success-message');
            let fail_message = document.getElementById('fail-message');
            loader.style.display='block';/* Loader Section Activited */

            await LabService.delete(id)
            .then(result => {

                /* Success Loader Called */
                loader.style.display='none';
                successloader.style.display='block';
                success_message.innerHTML="Lab deleted successfully.";
                let timer =   setInterval(myTimer, 3000);
                function myTimer() {
                    successloader.style.display='none';
                    clearInterval(timer);  
                }

                getLabs()
                //history.push('/app/socialmedialist');
            })
            .catch(e => { 
                /* Fail Loader Called */
                loader.style.display='none';
                failloader.style.display='block';
                fail_message.innerHTML="Lab not deleted successfully.";
                let timer =  setInterval(myTimer, 3000);
                function myTimer() {
                    failloader.style.display='none';
                    clearInterval(timer);   
                }
                toast(console.log(e)); 
            });
        }
    }

    const handleOnPdfPreview = (event) => {                
        if(event.nativeEvent.target && event.nativeEvent.target.classList.contains('ppreview')){
            let options=EnumHelper.PdfOption, boundingRect=document.getElementById('main').getBoundingClientRect();
            options['jsPDF']['format']=[boundingRect.width-200,boundingRect.height-100];
            html2pdf().from(document.getElementById('main')).set(options).outputPdf().then((pdf) => {
                setPdfUrl(btoa(pdf));
                setModel(!showModel)
            })
        }
        else if(showModel){
            setModel(!showModel)
        }        
    }

    return (
        <div id = "main">
            <Row>
                <Col>
                    <Row className="mb-4">
                        <Col>
                            <Widget>
                                <div className={s.tableTitle}>
                                    <div className="headline-2">Lab</div>
                                    <div className="d-flex">                                        
                                        <a onClick={(e) => PdfHelper.printPage()}><img src={printerIcon} alt="Printer" /></a>
                                    <a className="mx-2 ppreview" onClick={(e) => { handleOnPdfPreview(e); }}><PreviewModal isOpen={showModel} url={pdfURl} /> Preview</a>
                                    <a onClick={() => PdfHelper.downloadPdf(document.getElementById('main'), "Labs")}><img src={cloudIcon} alt="Download" /></a>
                                    </div>
                                </div>
                                <div className={s.tableTitle}>
                                    <Col className="col-2">
                                        <input type="text" ref={txtLabName} name="txtLabName" className="form-control " placeholder="Lab Name" />
                                    </Col>
                                    <Col className="col-2">
                                        <input type="text" ref={txtTechName} name="txtTechName" className="form-control " placeholder="Technician Name" />
                                    </Col>
                                    <Col className="col-2">
                                        <input type="text" ref={txtEmail} name="txtEmail" className="form-control " placeholder="Email" />
                                    </Col>
                                    <Col className="col-2">
                                        <input type="text" ref={txtContactPhone} name="txtContactPhone" className="form-control " placeholder="Contact Phone" />
                                    </Col>
                                    <Col className="col-4">
                                        <input className="btn btn-secondary" title="Search" onClick={getLabs} type="button" value="Search" />
                                        <input className="btn btn-secondary ml-2" title="Reset" onClick={resetSearch}  type="button" value="Reset" />
                                        {role === "Employee" ? <div></div> : <Link to="/app/lab/0" title="Add" className="btn btn-primary pull-right">
                                            Add Lab
                                        </Link>}
                                    </Col>
                                </div>
                                <div className="widget-table-overflow">
                                    <Table className={`table-striped table-borderless`} responsive>
                                        <thead>
                                            <tr>
                                                <th className="w-25">LAB NAME</th>
                                                <th className="w-20">TECHNICIAN NAME</th>
                                                <th className="w-25">EMAIL</th>
                                                <th className="w-20">CONTACT PHONE</th>
                                                <th className="w-80">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {showloader ? (
                  <tr>
                    <td rowSpan="4" colSpan="4">
                      <div className="text-center py-5">
                          <Spinner animation="border" />
                      </div>
                    </td>
                  </tr>
                ) :   LabList.length == 0 ? <tr><td colSpan="6"><div className='alert alert-secondary text-primary m-3 color' >No records found</div></td></tr> : LabList
                                                .map(item => (
                                                    <tr key={uuidv4()}>
                                                        <td>{item.labName}</td>
                                                        <td>{item.technicianName}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.contactPhoneNo}</td>
                                                        
                                                           <td> <Link to={"/app/lab/" + item.id} title="Edit" className={s.blackColor}><i className="fa fa-edit"></i></Link>&nbsp;
                                                            <Link to="#" onClick={()=>deleteRecord(item.id)} className={s.blackColor}><i className="fa fa-trash"></i></Link></td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>

                                    <Row>
                                        <Col className="ml-2">

                                        </Col>
                                        <Col className="col-10">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=" >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={pageSize}
                                                pageCount={Math.ceil(totalCount)}
                                                previousLabel="< "
                                                renderOnZeroPageCount={null}
                                                containerClassName={'pagination pagination-sm'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'previous'}
                                                previousLinkClassName={'page-item page-link'}
                                                nextClassName={'next'}
                                                nextLinkClassName={'page-item page-link'}
                                                activeClassName={'active'}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Widget>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
export default LabList;
