import React from "react";
import { Col, Row, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import Widget from "../../components/Widget/Widget.js";
import s from "./Tables.module.scss";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import HolidayService from "../../services/holiday/holiday.service.js"
import EnumHelper from '../../helper/enum-helper.js'

export default class SchoolTermsList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.getSchoolTerms = this.getSchoolTerms.bind(this);

        this.state = {
            year: new Date().getFullYear(),
            schoolTerms: [{
                "term": "Term 1",
                "season": "Autumn holidays",
                "duration": "Saturday 13th April 2024 - Sunday 28th April 2024",
                "startDate": "2024-04-13T00:00:00",
                "endDate": "2024-04-28T00:00:00",
                "territory": "Australian Capital Territory",
                "country": "Australia"
            }],
            contries:[""],
            territorys_aus:[""],
            territorys_other:[""]         
        };
    };

    componentDidMount() {
        this.getSchoolTerms();
    }

    getSchoolTerms = async () => {
        await HolidayService.getAllSchoolTerm().then(result => {
            this.setState({schoolTerms:result.data.data});
            this.setState({contries:[...new Set(result.data.data.map(item=>item.country))]})

            this.setState({territorys_other:[...new Set(result.data.data
                .filter(data => { return (data.country.toLowerCase() != EnumHelper.HolidayCountry.Australia 
                    && data.territory != "") })
                .map(item=>item.territory))]})

            this.setState({territorys_aus:[...new Set(result.data.data
                .filter(data => { return (data.country.toLowerCase() == EnumHelper.HolidayCountry.Australia 
                    && data.territory != "") })
                .map(item=>item.territory))]})
        }).catch(e => {
            console.log(e);
        }) 
    };

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <Row className="mb-4">
                            <Col>
                                <Widget>
                                    <div className="col-12">
                                        <div className={s.tableTitle}>
                                            <div className="headline-2">{this.state.year} School Terms</div>
                                        </div>
                                        <div>
                                            {/* Australia School Terms */}
                                            {this.state.contries.filter(data => { return data.toLowerCase() == EnumHelper.HolidayCountry.Australia }).map(country => (
                                                <div key={uuidv4()}>
                                                    <div className="my-3 headline-3" style={{color:" #00abbe"}}>{country} School Terms</div>
                                                    {this.state.territorys_aus.map( territory =>  {
                                                        return(                       
                                                            <div key={uuidv4()}>
                                                                <div className="my-3 headline-3" >{territory}</div>
                                                                <Table className={`table-striped table-borderless`} responsive>
                                                                    <tbody>
                                                                        {
                                                                        this.state.schoolTerms
                                                                        .filter(data => { return (data.country.toLowerCase() == EnumHelper.HolidayCountry.Australia && data.territory.toLowerCase() == territory.toLowerCase()) })
                                                                        .map((schoolTerm, i) => (
                                                                            <tr key={uuidv4()}>
                                                                                <td className="col-2">{schoolTerm.term}</td>
                                                                                <td className="col-10" dangerouslySetInnerHTML={{ __html: schoolTerm.duration }}></td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            ))}

                                            {/* Other Countries School Terms */}
                                            {this.state.contries.filter(data => { return data.toLowerCase() != EnumHelper.HolidayCountry.Australia }).map( country =>  {
                                                return(                 
                                                    <div key={uuidv4()}>
                                                        <div className="my-3 headline-3" style={{color:" #00abbe"}}>{country} School Terms</div>
                                                        {this.state.territorys_other.map( territory =>  {
                                                            if(this.state.schoolTerms.some(d=>d.country.toLowerCase()==country.toLowerCase() && d.territory.toLowerCase()==territory.toLowerCase() && d.territory!="")){
                                                                return(                       
                                                                    <div key={uuidv4()}>
                                                                            <div className="my-3 headline-3 ">{territory} School Terms</div>
                                                                            <Table className={`table-striped table-borderless`} responsive>
                                                                                <tbody>
                                                                                    {this.state.schoolTerms
                                                                                    .filter(data => { return (data.country.toLowerCase() == country.toLowerCase() && data.territory.toLowerCase() == territory.toLowerCase()) })
                                                                                    .map((schoolTerm, i) => (
                                                                                        <tr key={uuidv4()}>
                                                                                            <td className="col-2">{schoolTerm.term}</td>
                                                                                            <td className="col-10" dangerouslySetInnerHTML={{ __html: schoolTerm.duration }}></td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </Table>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                )                     
                                            })}
                                        </div>
                                        <div className="mb-3 small text-muted">
                                            <div>School Term dates are subject to change without notice. Please contact relevant State or Territory Departments of Education to verify these dates at the commencement of each year.</div>                                            
                                        </div>
                                    </div>
                                </Widget>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}