import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import Footer from "../../components/Footer/Footer.js";
import { loginUser } from "./../../components/actions/auth.js";
import LoginService from "./../../services/login/login.service";
import Logo from "../../components/Icons/SidebarIcons/SofiaLogo";
import { toast } from "react-toastify";

import Notification from "../../components/Notification/Notification.js";
import Spinner from "react-bootstrap/Spinner";

import Loader from "../../components/Loader/loader.js"
import SuccessLoader from "../../components/Loader/success-loader.js"
import FailLoader from "../../components/Loader/fail-loader.js"

//import 'react-toastify/dist/ReactToastify.css';
class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  static isAuthenticated(token) {
    if (token) return true;
  }

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
    };

    this.doLogin = this.doLogin.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  doLogin(e) {
    e.preventDefault();

    var payload = {
      email: this.state.email,
      password: this.state.password,
      userName: "admin",
      rollId: 1,
    };
    this.setState({ loading: true });

    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

    LoginService.login(payload)
      .then((result) => {
        if (result.data.statuscode == 200) {
          localStorage.setItem("authenticated", true);
          localStorage.setItem("token", "Bearer " + result.data.data.token);
          localStorage.setItem("username", result.data.data.userName);
          localStorage.setItem("userId", result.data.data.userId);
          localStorage.setItem("practicelogo", result.data.data.practiceImage);
          localStorage.setItem("practiceName", result.data.data.practiceName);
          localStorage.setItem("role", result.data.data.roleName);
          localStorage.setItem("practiceId", result.data.data.practiceId);
          localStorage.setItem("employeeId", result.data.data.employeeId);
          localStorage.setItem("anyPastLogin", result.data.data.anyPastLogin);
          localStorage.removeItem("Practice");
          localStorage.removeItem("tempPracticeId");
          localStorage.removeItem("tempPracticeEmail");
          var d = new Date();
          var minutes = d.getTimezoneOffset();
          localStorage.setItem("clientOffset", minutes);

          this.props.dispatch(
            loginUser({
              email: this.state.email,
              password: this.state.password,
            })
          );
          this.setState({ loading: false });

           /* Success Loader Called */
           loader.style.display='none';
           successloader.style.display='block';
           success_message.innerHTML="Login Successfully";
           let timer =   setInterval(myTimer, 3000);
           function myTimer() {
               successloader.style.display='none';
               clearInterval(timer);  
           }
           
        } else {
          // toast(<Notification type="error" message={result.data.message} />);

           /* Fail Loader Called */
           loader.style.display='none';
           failloader.style.display='block';
           fail_message.innerHTML=result.data.message;
           let timer =  setInterval(myTimer, 3000);
           function myTimer() {
               failloader.style.display='none';
               clearInterval(timer);   
           }

        }
      })
      .catch((e) => {});
  }

  changeEmail(event) {
    this.setState({ email: event.target.value });
  }

  changePassword(event) {
    this.setState({ password: event.target.value });
  }

  render() {
    let practiceId = localStorage.getItem("practiceId");
    let role = localStorage.getItem("role");

    if (role == "Admin") {
      const { from } = this.props.location.state || {
        from: { pathname: "/app/practicelist" },
      };
      if (
        Login.isAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
      ) {
        // return <Redirect to={from} />;
        
        // window.location = "/app/practicelist";

        let timer =  setInterval(myTimerLogin, 2000);
        function myTimerLogin() {
          window.location = "/app/dashboardAdmin";
             clearInterval(timer);   
        }
      }
    } else {
      const { from } = this.props.location.state || {
        from: { pathname: "/app/dashboard" },
      };
      if (
        Login.isAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
      ) {
        // window.location = "/app/dashboard";
        let timer =  setInterval(myTimerLogin, 2000);
        function myTimerLogin() {
          window.location = "/app/dashboard";
             clearInterval(timer);   
        }

        

        // return <Redirect to={from} />;
      }
    }

    return (
      <div className="auth-page">
        <div id='loader' style={{display:'none'}}>  <Loader ></Loader></div>
        <div id='successloader' style={{display:'none'}}>  <SuccessLoader ></SuccessLoader></div>
        <div id='failloader' style={{display:'none'}}>  <FailLoader ></FailLoader></div>
        <Container className="col-10">
          <Row className="d-flex align-items-center">
            <Col xs={10} lg={6} className="left-column">
              <Widget className="widget-auth widget-p-lg">
                <div className="d-flex align-items-center justify-content-center py-3">
                  {/*<p className="auth-header mb-0"></p>*/}
                  <div className="logo-block w-75 ">
                    <Logo />
                  </div>
                </div>
                <div className="logo-block"></div>
                <form onSubmit={this.doLogin}>
                  <FormGroup className="my-3">
                    <FormText>Email</FormText>

                    <Input
                      id="email"
                      className="input-transparent pl-3"
                      value={this.state.email}
                      onChange={this.changeEmail}
                      type="email"
                      required
                      name="email"
                      placeholder="Email"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Email is required")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                    />
                  </FormGroup>
                  <FormGroup className="my-3">
                    <div className="d-flex justify-content-between">
                      <FormText>Password</FormText>
                    </div>
                    <Input
                      id="password"
                      className="input-transparent pl-3"
                      value={this.state.password}
                      onChange={this.changePassword}
                      type="password"
                      required
                      name="password"
                      placeholder="Password"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Password is required")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                    />
                  </FormGroup>
                  <div className="bg-widget d-flex justify-content-center">
                    <Button
                      className="rounded-pill my-3"
                      type="submit"
                      color="secondary-red"
                    >
                      Login
                    </Button>
                    <div>
                      {this.state.loader ? (
                        <div className="text-center py-5">
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </form>

            
                {/* <div >
                  <div className="col-12">
                  <Link to="cookiepolicy"> Cookies Policy</Link>{" "}

                    &nbsp;|&nbsp;{" "}
                    <Link to="websitepolicy"> Website Terms & Conditions</Link>
                  </div>
                  <div className="col-12">
                  <Link to="privacypolicy">Privacy Policy </Link>{" "}
                    &nbsp;&nbsp;|&nbsp;
                    <Link to="platformpolicy">
                      {" "}
                      Platform Terms & Conditions{" "}
                    </Link>
                  </div>
                </div> */}
                
                 <div className="container" >
                 <div className="col-12 d-flex justify-content-center">
                 <Link to="/PracticeRegister" className="m-4"><h6>Register</h6></Link>
                  </div>
                  <div className="col-12">
                  <a href="cookiepolicy" target="_blank"> Cookies Policy</a>

                    &nbsp;|&nbsp;
                    <a href="websitepolicy" target="_blank"> Website Terms & Conditions</a>
                  </div>
                  <div className="col-12">
                  <a href="privacypolicy" target="_blank">Privacy Policy </a>
                    &nbsp;&nbsp;|&nbsp;
                    <a href="platformpolicy" target="_blank">
                      
                      Platform Terms & Conditions{" "}
                    </a>
                  </div>

                  <div className="col-12 text-center">
                  <a href="contactus" target="_blank">Contact Us</a>
                  </div>
                  <div className="col-12 text-center">
                  <a href="CheckCancelledSubscription" >Subscription Expired?</a>
                  </div>
                </div>
              </Widget>
            </Col>

            <Col xs={0} lg={6} className="right-column">
              <div></div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
