import React, { useEffect, useState,useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import {Col,Row,Table,Label,} from "reactstrap";
import ReactPaginate from 'react-paginate';
import Widget from "../../components/Widget/Widget.js";
import MeetingService from "./../../services/teammeeting/teammeeting.service";
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import s from "./Tables.module.scss";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

//Notification
import { toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification.js";

//DateRange Picker
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import PreviewModal from "../pdfPreview/preview.js";
import * as html2pdf from 'html2pdf.js'
import EnumHelper from "../../helper/enum-helper";
import PdfHelper from "../../helper/pdf-helper";


const MeetingList = function () {
    const [response, setMeetingList] = useState([], false);
    const [totalCount, setTotalCount] = useState([], false);
    const [showloader, setLoading] = useState([], false);

    //Date Range Textbox
    const [DateRange, setStateDateRange] = useState('', false);

    const txtSearch = useRef(null);
    let pageNo = 1,pageSize = 10;
    
    const practiceId = localStorage.getItem('practiceId');
    const role = localStorage.getItem('role');

    const [pdfURl, setPdfUrl ] = useState('', false);
    const [showModel , setModel] = useState(false);

    useEffect(() => {
        getMeetings();
        return () => {
            setMeetingList([]); 
        };
    }, []);

    const handlePageClick = (data) => { let selected = data.selected + 1; pageNo = selected;getMeetings();};

    const getMeetings = async () => {
        var payload = { "searchTerm": txtSearch.current.value, "pageSize": pageSize, "pageNumber": pageNo,'practiceId': practiceId, 'dateRange': DateRange  }
        setLoading(  true );

      //  console.log('payload-->'+ JSON.stringify(payload) );

        await new Promise(function (resolve, reject) {
            MeetingService.search(payload).then(result => {
                resolve(result);
                setMeetingList(result.data.data.teamMeetingList);

                // console.log('result'+JSON.stringify(result.data.data.teamMeetingList));

                setTotalCount(Math.ceil(parseInt(result.data.data.totalRecords) / pageSize));
                pageNo = payload.pageNumber;
                setLoading(  false );

            }).catch(e => {
               console.log(e);
            })
        });
    }
    
    const deleteRecord = async (id) => {
        const result = await confirm("Are you sure? \nYou will not be able to recover this Team Meeting!");
        if(result){
        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */
        await MeetingService.delete(id)
            .then(result => {
                // toast(<Notification type="success" message="Team meeting record deleted successfully." />),

                /* Success Loader Called */
                loader.style.display='none';
                successloader.style.display='block';
                success_message.innerHTML="Team meeting record deleted successfully.";
                let timer =   setInterval(myTimer, 3000);
                function myTimer() {
                    successloader.style.display='none';
                    clearInterval(timer);  
                }
                getMeetings()
            })
            .catch(e => { 
                /* Fail Loader Called */
                loader.style.display='none';
                failloader.style.display='block';
                fail_message.innerHTML="Team meeting record is not deleted successfully.";
                let timer =  setInterval(myTimer, 3000);
                function myTimer() {
                    failloader.style.display='none';
                    clearInterval(timer);   
                }      
                toast(console.log(e)); 
            });
        }
    }

    const datePickerHandleApply = (event, picker) => {
        let selectedDate = picker.startDate.format('DD-MM-YYYY') + ' - ' + picker.endDate.format('DD-MM-YYYY')
        setStateDateRange(selectedDate); picker.element.val(selectedDate);
    }

    const datePickerHandleCancel = (event, picker) => { picker.element.val(''); setStateDateRange('') }

    const handleOnPdfPreview = (event) => {                
        if(event.nativeEvent.target && event.nativeEvent.target.classList.contains('ppreview')){
            let options=EnumHelper.PdfOption, boundingRect=document.getElementById('main').getBoundingClientRect();
            options['jsPDF']['format']=[boundingRect.width-200,boundingRect.height-100];
            html2pdf().from(document.getElementById('main')).set(options).outputPdf().then((pdf) => {
                setPdfUrl(btoa(pdf));
                setModel(!showModel)
            })
        }
        else if(showModel){
            setModel(!showModel)
        }        
    }

    return (
        <div id ="main">
            <Row>
                <Col>
                    <Row className="mb-4">
                        <Col>
                            <Widget>
                                <div className={s.tableTitle}>
                                    <div className="headline-2">Team Meeting</div>
                                    <div className="d-flex">                                                                           
                                    </div>
                                </div>
                                <div className={s.tableTitle}>
                                    <Col className="col-3">
                                        <input type="text"  ref={txtSearch} name="txtSearch" className="form-control " placeholder="Search by Note" />
                                    </Col>
                                    <Col>
                                        <DateRangePicker onApply={datePickerHandleApply} onCancel={datePickerHandleCancel} initialSettings={{ autoUpdateInput: false, locale: { 'format': 'DD-MM-YYYY' } }}>
                                            <input type="text" className="form-control" defaultValue="" placeholder="Select Date" />
                                        </DateRangePicker>
                                    </Col>
                                    <Col>
                                        <input className="btn btn-secondary" type="button" value="Search" onClick={getMeetings}/>
                                    </Col>
                                    <Col>
                                        {role === "Employee" ? <div></div> : <Link to="/app/teammeeting/0" className="btn btn-primary pull-right">
                                            Add Team Meeting
                                        </Link>}
                                    </Col>
                                </div>
                                <div className="widget-table-overflow">
                                    <Table className={`table-striped table-borderless table-hover ${s.statesTable}`} responsive>
                                        <thead>
                                            <tr>
                                                <th className="w-2"></th>
                                                <th className="w-25">MEETING NAME</th>
                                                <th className="w-25">MEETING DATE</th>
                                                <th className="w-20">NOTE</th>
                                                <th className="w-20">ACTION LIST</th>
                                                {/* <th className="w-15">CREATED DATE</th> */}
                                                {role === "Employee" ? <th></th> : <th className="W-100">ACTIONS</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {showloader ? (
                  <tr>
                    <td rowSpan="4" colSpan="4">
                      <div className="text-center py-5">
                          <Spinner animation="border" />
                      </div>
                    </td>
                  </tr>
                ) : response.length == 0 ? (
                  <tr>
                    <td colSpan="4">
                      <div className="alert alert-secondary text-primary m-3 color">
                        No records found
                      </div>
                    </td>
                  </tr>
                ) : response
                                                .map(item => (
                                                    <tr key={uuidv4()}>
                                                        <td> </td>
                                                        <td>{item.meetingTitle}</td>
                                                        <td>{item.strMeetingDate}</td>
                                                        <td>{item.note}</td>
                                                        <td>{item.actionList.replace( /(<([^>]+)>)/g, '')}</td>
                                                        {/* <td>{item.strCreatedDate}</td> */}
                                                        {role === "Employee" ? <td></td> : <td>
                                                            <Link to={"/app/teammeeting/" + item.id} className={s.blackColor}><i className="fa fa-edit"></i></Link>&nbsp;
                                                            <Link to="#" onClick={()=>deleteRecord(item.id)} className={s.blackColor}><i className="fa fa-trash"></i></Link></td>}
                                                    </tr>
                                                ))}
                                          
                                        </tbody>
                                    </Table>

                                    <Row>
                                        <Col className="ml-2">

                                        </Col>
                                        <Col className="col-10">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=" >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={pageSize}
                                                pageCount={Math.ceil(totalCount)}
                                                previousLabel="< "
                                                renderOnZeroPageCount={null}
                                                containerClassName={'pagination pagination-sm'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'previous'}
                                                previousLinkClassName={'page-item page-link'}
                                                nextClassName={'next'}
                                                nextLinkClassName={'page-item page-link'}
                                                activeClassName={'active'}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Widget>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
export default MeetingList;
