import React, { useEffect, useState,useRef } from "react";
import Logo from "../../components/Icons/SidebarIcons/SofiaLogo";


const privacypolicy = function () {


    return ( <div className="container">
        <p align="center">
<div className="col-5">
    <br></br>
<Logo/>
<br></br>
<br></br>

</div>
    <h4><a name="_Toc8723750">PRIVACY POLICY</a></h4>
</p>
<br></br>
<p>
   <strong> 1 &nbsp;&nbsp;INTRODUCTION </strong>



   
</p>

<br></br><p>
    This document sets out the privacy policy of The Daily Huddle Pty Ltd ACN 665 245 205
    operating as The Daily Huddle (referred to in this privacy
    policy as 'we', 'us', or 'our'). For the purposes of applicable data
    protection law, (in particular, the General Data Protection Regulation (EU)
    2016/679 (the “<strong>GDPR</strong>”) and the UK Data Protection Act
    2018), your data will be controlled by us.
</p>
<p>
    This privacy policy applies whenever we collect your personal information
    and/or personal data (your <strong>personal data</strong>). This includes
    between you, the visitor to this website (whether directly as our customer
    or as personnel of our customer), and us, the owner and provider of this
    website and also where we are directed by a third party to process your
    personal data. This privacy policy applies to our use of any and all data
    collected by us or provided by you in relation to your use of the website
    and the provision of our services to you.
</p>
<p>
    We take our privacy obligations seriously. Please read this privacy policy
    carefully as it contains important information on who we are and how and
    why we collect, store, use and share your personal data in connection with
    your use of our website. It also explains your rights in relation to your
    personal data and how to contact us or a relevant regulator in the event
    you have a complaint.
</p>
<br></br>
<p>
    <strong>2&nbsp;&nbsp; TYPES OF PERSONAL INFORMATION WE COLLECT</strong>
</p>
<br></br>
<p>
    The personal data we collect may include the following:
</p>
<p>
    (a) name;
</p>
<p>
    (b) mailing or street address;
</p>
<p>
    (c) email address;
</p>
<p>
    (d) social media information;
</p>
<p>
    (e) telephone number and other contact details;
</p>
<p>
    (f) age;
</p>
<p>
    (g) date of birth;
</p>
<p>
    (h) credit card or other payment information;
</p>
<p>
    (i) information about your business or personal circumstances;
</p>
<p>
    (j) information in connection with any client surveys, questionnaires and
    promotions you participate in;
</p>
<p>
    (k) when we use analytical cookies, your device identity and type, I.P.
    address, geo-location information, page view statistics, advertising data
    and standard web log information;
</p>
<p>
    (l) information about third parties; and
</p>
<p>
    (m) any other information provided by you to us via this website, in the
    course of us providing services to you, or otherwise required by us or
    provided by you.
</p>
<br></br>
<p>
  <strong>  3&nbsp;&nbsp; HOW WE COLLECT PERSONAL INFORMATION</strong>
</p>
<br></br>
<p>
    We endeavour to ensure that information we collect is complete, accurate,
    accessible and not subject to unauthorised access.
</p>
<p>
    We may collect personal data either directly from you, or from third
    parties, including where you:
</p>
<p>
    (a) contact us through on our website;
</p>
<p>
    (b) communicate with us via email, telephone, SMS, social applications
    (such as LinkedIn, Facebook or Twitter) or otherwise;
</p>
<p>
    (c) engage us to perform services to you;
</p>
<p>
    (d) when you or your organisation offer to provide, or provides, services
    to us;
</p>
<p>
    (e) interact with our website, social applications, services, content and
    advertising; and
</p>
<p>
    (f) invest in our business or enquire as to a potential purchase in our
    business.
</p>
<p>
    We may also collect personal data from you when you use or access our
    website or our social media pages. This may be done through use of web
    analytics tools, 'cookies' or other similar tracking technologies that
    allow us to track and analyse your website usage. For more information,
    please see our Cookie Policy.
</p>
<br></br>
<p>
    <strong>4&nbsp;&nbsp; USE OF YOUR PERSONAL INFORMATION</strong>
</p>
<br></br>
<p>
    We collect and use personal data for the following purposes:
</p>
<p>
    (a) to provide services or information to you;
</p>
<p>
    (b) for record keeping and administrative purposes;
</p>
<p>
    (c) to comply with our legal obligations, resolve disputes or enforce our
    agreements with third parties;
</p>
<p>
    (d) where we have your consent, including to send you marketing and
    promotional messages and other information that may be of interest to you.
    In this regard, we may use email, SMS, social media or mail to send you
    direct marketing communications. You can opt-out of receiving marketing
    materials from us by using the opt-out facility provided (e.g. an
    unsubscribe link);
</p>
<p>
    (e) for our legitimate interests including:
</p>
<p>
&nbsp;&nbsp;&nbsp;(i) to develop and carry out marketing activities and to conduct market
    research and analysis and develop statistics;
</p>
<p>
&nbsp;&nbsp;&nbsp;(ii) to improve and optimise our service offering and customer experience;
</p>
<p>
&nbsp;&nbsp;&nbsp;(iii) to send you administrative messages, reminders, notices, updates and
    other information requested by you;
</p>
<p>
&nbsp;&nbsp;&nbsp;(iv) to consider an application of employment from you; and
</p>
<p>
&nbsp;&nbsp;&nbsp;(v) the delivery of our services.
</p>
<br></br>
<p>
    <strong>5&nbsp;&nbsp; SHARING YOUR DATA</strong>
</p>
<br></br>
<p>
    We may share your personal data in certain circumstances, as follows:
</p>
<p>
    (a) where there is a change of control in our business or a sale or
    transfer of business assets, we reserve the right to transfer to the extent
    permissible at law our user databases, together with any personal data and
    non-personal data contained in those databases. This information may be
    disclosed to a potential purchaser under an agreement to maintain
    confidentiality. We would seek to only disclose information in good faith
    and where required by any of the above circumstances;
</p>
<p>
    (b) credit-checking agencies for credit control reasons;
</p>
<p>
    (c) disclosures required by law or regulation; and
</p>
<p>
    (d) service providers and other affiliated third parties to enable us to
    provide our services to you including other professional advisers such as
    accountants, disaster recovery service providers or auditors and/or
    overseas counsel.
</p>
<br></br>
<p>
    <strong>6&nbsp;&nbsp; SECURITY</strong>
</p>
<br></br>
<p>
    We take reasonable steps to ensure your personal data is secure and
    protected from misuse or unauthorised access. Our information technology
    systems are password protected, and we use a range of administrative and
    technical measures to protect these systems. However, we cannot guarantee
    the security of your personal data.
</p>
<br></br>
<p>
    <strong>7&nbsp;&nbsp; LINKS</strong>
</p>
<br></br>
<p>
    Our website may contain links to other websites. Those links are provided
    for convenience and may not remain current or be maintained. We are not
    responsible for the privacy practices of those linked websites and we
    suggest you review the privacy policies of those websites before using
    them.
</p>
<br></br>
<p>
    <strong>8&nbsp;&nbsp; YOUR RIGHTS</strong>
</p>
<br></br>
<p>
    You have various rights with respect to our use of your personal data:
</p>
<p>
    (a) <strong>Access</strong>: You have the right to obtain access to your
    information (if we're processing it) and certain other information (similar
    to that provided in this privacy notice). This is so that you're aware and
    can check that we're using your information in accordance with data
    protection law.
</p>
<p>
    (b) <strong>Be informed</strong>: You have the right to be provided with
    clear, transparent and easily understandable information about how we use
    your information and your rights. This is why we're providing you with the
    information in this privacy policy.
</p>
<p>
    (c) <strong>Rectification</strong>: We aim to keep your personal data
    accurate, current, and complete. We encourage you to contact us using our
    contact form to let us know if any of your personal data is not accurate or
    changes, so that we can keep your personal data up-to-date.
</p>
<p>
    (d) <strong>Objecting</strong>: You also have the right to object to
    processing of your personal data in certain circumstances, including
    processing for direct marketing.
</p>
<p>
    (e) <strong>Restricting</strong>: You have the right to 'block' or suppress
    further use of your information. When processing is restricted, we can
    still store your information, but may not use it further.
</p>
<p>
    (f) <strong>Erasure</strong>: You have the right to ask us to erase your
    personal data when the personal data is no longer necessary for the
    purposes for which it was collected, or when, among other things, your
    personal data have been unlawfully processed.
</p>
<p>
    (g) <strong>Portability</strong>: You have the right to request that some
    of your personal data is provided to you, or to another data controller, in
    a commonly used, machine-readable format.
</p>
<p>
    (h) <strong>Complaints</strong>: If you believe that your data protection
    rights may have been breached, you have the right to lodge a complaint with
    the applicable supervisory authority. In the UK, the supervisory authority
    is the Information Commissioner's Office.
</p>
<p>
    (i) <strong>Withdraw consent</strong>: If you have given your consent to
    anything we do with your personal data, you have the right to withdraw your
    consent at any time. This includes your right to withdraw consent to us
    using your personal data for marketing purposes.
</p>
<p>
    You may, at any time, exercise any of the above rights, by contacting our
    email address provided below.
</p>
<br></br>
<p>
    <strong>9&nbsp;&nbsp; HOW LONG WE KEEP DATA</strong>
</p>
<br></br>
<p>
    We will only retain your personal data for as long as necessary to fulfil
    the purposes we collected it for, including for the purposes of satisfying
    any legal, accounting, or reporting requirements. We will securely destroy
    your personal data in accordance with applicable laws and regulations.
</p>
<p>
    If you would like further information about our specific retention periods
    for your personal data, please contact us using our email address provided
    below.
</p>
<br></br>
<p>
    <strong>10 TRANSFERS OUTSIDE THE EUROPEAN ECONOMIC AREA ('EEA')</strong>
</p>
<br></br>
<p>
    To provide our services, we may transfer the personal data we collect to
    countries outside of the UK or EEA which do not provide the same level of
    data protection as the country in which you reside and are not recognised
    by the European Commission as providing an adequate level of data
    protection.
</p>
<p>
    When we do this, we will make sure that it is protected to the same extent
    as in the EEA and UK as we will put in place appropriate safeguards to
    protect your personal data, which may include standard contractual clauses.
</p>
<p>
    For more information, please contact us at our email address provided
    below.
</p>
<br></br>
<p>
    <strong>11&nbsp;&nbsp; CONTACT US</strong>
</p>
<br></br>
<p>
    For further information about our privacy policy or practices, or to access
    or correct your personal data, or make a complaint, please contact us using
    the details set out below:
</p>
<p>
    <strong>Email</strong>
    : team@thedailyhuddle.online
</p>
<p>
    We may change this privacy policy from time to time by posting an updated
    copy on our website and we encourage you to check our website regularly to
    ensure that you are aware of our most current privacy policy. Where we make
    any significant changes, we will endeavour to notify you by email.
</p></div>) 
}

export default privacypolicy;