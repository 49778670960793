import http from "../../config/http-common";

class practiceService {
    async getAll(pageNo,data) {
        return await http.post(`practices/${pageNo}`,data);
    }
    get(id) {
        return http.get(`practices/${id}`);
    }
    create(data) {
        return http.post("practices", data);
    }
    delete(practiceId) {
        return http.delete(`practices/${practiceId}`);
    }
    update(id, data) {
        return http.post(`practices?practiceId=${id}`, data);
    }
    search(data) {
        return http.post(`practices/search`, data);
    }
    getAllPractices() {
        return http.post(`practices/getAll`);
    }
    isBlock(id) {
        return http.get(`practices/isBlock?practiceId=${id}`);
    }
    setLoginStatus(id) {
        return http.get(`practices/setLoginStatus?practiceId=${id}`);
    }
    feedback(data) {
        return http.post(`practices/feedback`, data);
    }
    cancelSubscriptionFeedback(data) {
        return http.post(`practices/cancelSubscriptionFeedback`, data);
    }
    contactUs(data) {
        return http.post(`practices/ContactUs`, data);
    }

    

}

export default new practiceService();