import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Col, Row, Table,} from "reactstrap";
import ReactPaginate from 'react-paginate';
import Widget from "../../components/Widget/Widget.js";
import PatientService from "./../../services/patient/patient.service"
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import s from "./Tables.module.scss";
import { Link } from "react-router-dom";
import PreviewModal from "../pdfPreview/preview.js";
import PdfHelper from "../../helper/pdf-helper";
import Spinner from "react-bootstrap/Spinner";
import PatientBulkUploadModal from "./patient-bulk-upload-popup.js";


// Modal Popup
// import PatientSearchModal from "./patient-search-popup.js";

import CommonHelper from "../../helper/common-helper";
import patientService from "./../../services/patient/patient.service";


const PatientList = function () {

    const [response, setPatientList] = useState([], false);
    const [totalCount, setTotalCount] = useState([], false);
    const [showloader, setLoading] = useState([], false);
    const [remountComponent, setRemountComponent] = useState(0);

    let pageSize = 10;
    let pageNo = 1;
    const role = localStorage.getItem('role');
    const practiceId = localStorage.getItem('practiceId');

    const [pdfURl, setPdfUrl ] = useState('', false);
    const [showModel , setModel] = useState(false);

    /* Patient Search Input Fields */
    const txtFirstName = useRef("");
    const txtLastName = useRef("");
    const txtSex = useRef("");
    const txtDateOfBirth = useRef("");
    const txtMobile= useRef("");
    const txtEmail = useRef("");
    const txtPatientCategory = useRef("");


    useEffect(() => {

        getPatients();
        return () => {
            setPatientList([]); // This worked for me
        };
    }, []);

    const handlePageClick = (data) => { 
        let selected = data.selected + 1;
        pageNo = selected;
        getPatients();
    };

    const uploadfile = (e) => {
        var data= new FormData();
        data.append("file",e.target.files[0]);
        data.append("practiceId",localStorage.getItem("practiceId"));
        data.append("createdBy", CommonHelper.getCurrnetUserId());

        patientService.uploadPatinet(data).then((data) => {
               console.log(data)
               getPatients();
        })
        .catch((error) => {
          console.log(error)
           getPatients();
        })
     };
    
    const getPatients = async () => {
        /* Reset Selected Page to 1 for Search and Reset */
        // const listItems = document.querySelectorAll('.page-link');
        // for (let i = 0; i < listItems.length; i++) {
        //     if (listItems[i].textContent == 1){
        //         listItems[i].click();
        //     }
        // }

        var payload = { 
                        "searchTerm": "", 
                        "practiceId" : practiceId,
                        "pageSize": pageSize, 
                        "pageNumber": pageNo, 
                        "lastName": txtLastName.current.value,
                        "firstName": txtFirstName.current.value,
                        "sex": txtSex.current.value,
                        "dateOfBirth": txtDateOfBirth.current.value,
                        "mobile": txtMobile.current.value,
                        "email": txtEmail.current.value,
                        "patientCategory": txtPatientCategory.current.value
                    }

        setLoading( true );

        await new Promise(function (resolve, reject) {
            PatientService.search(payload).then(result => {
                resolve(result);
                setPatientList(result.data.data.patientList);
                setTotalCount(Math.ceil(parseInt(result.data.data.totalRecords) / pageSize));
                pageNo = payload.pageNumber;
                setLoading(  false );
            }).catch(e => {
               console.log(e);
            })
        });
    }

    const deleteRecord = async (id) => {
        const result = await confirm("Are you sure? \nYou will not be able to recover this Patient!");
        if(result){
            let loader = document.getElementById('loader');
            let successloader = document.getElementById('successloader');
            let failloader = document.getElementById('failloader');
            let success_message = document.getElementById('success-message');
            let fail_message = document.getElementById('fail-message');
            loader.style.display='block';/* Loader Section Activited */

            await PatientService.delete(id).then(result => {
                // toast(<Notification type="success" message="Patient record Deleted successfully." />), 

                /* Success Loader Called */
                loader.style.display='none';
                successloader.style.display='block';
                success_message.innerHTML="Patient record deleted successfully.";
                let timer =   setInterval(myTimer, 3000);
                function myTimer() {
                    successloader.style.display='none';
                    clearInterval(timer);  
                }
                //history.push('/app/patientlist');
                getPatients();
            })
            .catch(e => { 
                /* Fail Loader Called */
                loader.style.display='none';
                failloader.style.display='block';
                fail_message.innerHTML="Patient record not deleted successfully.";
                let timer =  setInterval(myTimer, 3000);
                function myTimer() {
                    failloader.style.display='none';
                    clearInterval(timer);   
                }     
                toast(console.log(e)); 
            });
        }
    };   
   
    /* Function for reset the modal popup */
    const resetSearch = async () => {

        /*Reset All Search Fields */
        txtFirstName.current.value = "";
        txtLastName.current.value = "";
        txtSex.current.value = "";
        txtDateOfBirth.current.value = "";
        txtMobile.current.value= "";
        txtEmail.current.value = "";
        txtPatientCategory.current.value = "";
        setRemountComponent(Math.random());
        getPatients();
    }


    let [patientId, setState] = useState(" ");

    function handleState(patientId) {
        if(patientId == true){
            getPatients();
            // setState(patientId);
        }
     }
    
    return (
        <div id = "main">      
            <Row>
                <Col>
                    <Row className="mb-4">
                        <Col>
                            <Widget>
                                <div className={s.tableTitle}>
                                    <div className="headline-2">Patient </div>
                                    <div className="d-flex">                                        
                                        <a onClick={(e) => PdfHelper.printPage()}><img src={printerIcon} alt="Printer" /></a>
                                    <a className="mx-2 ppreview" onClick={(e) => { handleOnPdfPreview(e); }}><PreviewModal isOpen={showModel} url={pdfURl} /> Preview</a>
                                    <a onClick={() => PdfHelper.downloadPdf(document.getElementById('main'), "Team Members")}><img src={cloudIcon} alt="Download" /></a>
                                    </div>
                                </div>
                                <div style={{padding:"24px 24px"}}>
                                    <Row>
                                        <Col className="col-2">
                                            <input type="text" ref={txtFirstName} name="txtFirstName" className="form-control" placeholder="Firstname" />
                                        </Col> 
                                        <Col className="col-2">
                                            <input type="text" ref={txtLastName} name="txtLastName" className="form-control" placeholder="Lastname" />
                                        </Col> 
                                        <Col className="col-2">
                                            <select ref={txtSex} name="txtSex" className="form-control">
                                                <option value="">Sex</option>
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                                <option value="O">Other</option>
                                            </select>
                                        </Col> 
                                        <Col className="col-2">
                                            <input type="date" ref={txtDateOfBirth} name="txtDateOfBirth" className="form-control" />
                                        </Col>
                                        <Col className="col-4">
                                        {role === "Employee" ? <div></div> :   
                                                  <div className="row align-items-center justify-content-center">
                                                <Link to="/app/patient/0" title="Add" className="btn btn-primary" style={{padding:"8px 32px"}}>Add Patient </Link>
                                              <PatientBulkUploadModal change = {handleState}>

                                                </PatientBulkUploadModal> 
                                                {/* <DashboardSearchModal change = {handleState("data")}></DashboardSearchModal> */}

                                                {/* <input type="file" id="actual-btn" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={uploadfile} hidden />
                                                <label for="actual-btn"  className="btn btn-primary" style={{padding:"7px 13px",marginLeft: "10px"}}><i className="fa fa-upload" style={{fontSize: "18px"}}></i></label>  */}
                                            </div>
                                        
                                        }
                                        </Col>
                                    </Row>

                                    <Row style={{paddingTop: "10px"}}> 
                                        <Col className="col-2">
                                            <input type="text" ref={txtMobile} name="txtMobile" className="form-control" placeholder="Mobile" />
                                        </Col> 
                                        <Col className="col-2">
                                            <input type="text" ref={txtEmail} name="txtEmail" className="form-control" placeholder="Email" />
                                        </Col> 
                                        <Col className="col-2">
                                            <select ref={txtPatientCategory} name="txtPatientCategory" className="form-control">
                                                <option value="">Patient Category</option>
                                                <option value="General">General</option>
                                                <option value="VIP">VIP</option>
                                            </select>
                                        </Col> 
                                        <Row style={{justifyContent:"space-evenly"}}>
                                            <Col className="col-5">
                                                <input className="btn btn-secondary" title="Search" onClick={getPatients} type="button" value="Search" />
                                            </Col>
                                            <Col className="col-5" style={{marginLeft: "4px"}}>
                                                <input className="btn btn-secondary pull-left" title="Reset" onClick={resetSearch}  type="button" value="Reset" />
                                            </Col>
                                        </Row>
                                    </Row>
                                </div>
                                    
                                {/* Patient Search Modal Popup Component */}
                                {/* <div className={s.tableTitle}>
                                    <Col>
                                        <PatientSearchModal change = {handleState}></PatientSearchModal>
                                    </Col>  
                                </div> */}

                            <div key={remountComponent}>
                                <div className="widget-table-overflow">
                                    <Table className={`table-striped table-borderless`} responsive>
                                        <thead>
                                            <tr>
                                                <th className="w-20">NAME</th>
                                                <th className="w-20">DOB</th>
                                                <th className="w-20">Mobile</th>
                                                <th className="w-20">Email</th>
                                                <th className="w-20">ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {showloader ? (
                                                <tr>
                                                    <td rowSpan="4" colSpan="7">
                                                    <div className="text-center py-5">
                                                        <Spinner animation="border" />
                                                    </div>
                                                    </td>
                                                </tr>
                ) :   response.length == 0 ? <tr><td colSpan="7"><div className='alert alert-secondary text-primary m-3 color' >No records found</div></td></tr> : response
                                                .map(item => (
                                                    <tr key={uuidv4()}>
                                                        <td>{item.title+" "+item.firstName +" "+item.lastName}</td>
                                                        <td>{CommonHelper.ToDateFormat(item.dateOfBirth,"DD-MM-YYYY")}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{item.email}</td>
                                                        {role === "Employee" ? 
                                                        <td>
                                                            {/* <Link to={"/app/patient-view/" + item.id} title="View" className={s.blackColor}><i className="fa fa-eye"></i></Link> */}
                                                        </td> : 
                                                            <td>{/*<Link to={"/app/patient-view/" + item.id} title="View" className={s.blackColor}><i className="fa fa-eye"></i></Link>&nbsp; */}
                                                            <Link to={"/app/patient/" + item.id} title="Edit" className={s.blackColor}><i className="fa fa-edit"></i></Link>&nbsp;
                                                            <Link to={"/app/chat/" + item.id} title="Chat" className={s.blackColor}><i className="fa fa-envelope"></i></Link>&nbsp;
                                                            <Link to="#" onClick={()=>deleteRecord(item.id)} className={s.blackColor}><i className="fa fa-trash"></i></Link></td>}
                                                    </tr>
                                                ))}
                                          
                                        </tbody>
                                    </Table>

                                    <Row>
                                        <Col className="ml-2"></Col>
                                        <Col className="col-10">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=" >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={pageSize}
                                                pageCount={Math.ceil(totalCount)}
                                                previousLabel="< "
                                                renderOnZeroPageCount={null}
                                                containerClassName={'pagination pagination-sm'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'previous'}
                                                previousLinkClassName={'page-item page-link'}
                                                nextClassName={'next'}
                                                nextLinkClassName={'page-item page-link'}
                                                activeClassName={'active'}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            </Widget>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
    
}
export default PatientList;
