import http from "../../config/http-common";

class categoryService {
    async getAll() {
        return await http.get(`category`);
    }
  

}

export default new categoryService();