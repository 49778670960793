import React, { useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../components/Error/TextError";
import keyContactService from "./../../services/keyContact/keycontact.service.js";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';

//Notification
import { toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification.js";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const ManageKeyContact = () => {
    const { id } = useParams();
    const practiceId = localStorage.getItem('practiceId');
    let history = useHistory(); 
    var curr = new Date(); curr.setDate(curr.getDate());    

    const initialValues = { companyName:"", representativeName: "", accountNo: "",  contactNo: "", detailsOfCompany: ""};

    const validationSchema = Yup.object({
        companyName: Yup.string().required("Please enter company name"),
        representativeName: Yup.string().required("Please enter representative name"),
        accountNo: Yup.string().required("Please enter account no"),
        contactNo: Yup.string()
        .matches(/^[^-\sa-zA-Za-zA-Z!@#$%^&~ _\={}`;\[\]\\':\/"|,.<>?][0-9*()+ -]*$/g, "Please enter valid contact number")
        .max(20, 'Contact number has a maximum length of 20 characters')
        .min(10, 'Contact number has a minimum length of 10 characters')
        .required("Please enter contact no"),
        detailsOfCompany: Yup.string().required("Please enter details of company"),
    });

    const handleSubmit = async (values) => {
        if (id == 0) {
            CreateKeyContact(values);
        } else {
            UpdateKeyContact(id, values);
        }
    }

    const UpdateKeyContact = async (id, values) => {
        values.practiceId =practiceId;

        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */

        await keyContactService.update(id, JSON.stringify(values))
            .then(result => {                
                // toast(<Notification type="success" message="Key Contact record updated successfully." />),

                /* Success Loader Called */
                loader.style.display='none';
                successloader.style.display='block';
                success_message.innerHTML="Key Contact record updated successfully.";
                let timer =   setInterval(myTimer, 3000);
                function myTimer() {
                    successloader.style.display='none';
                    clearInterval(timer);  
                }
                history.push('/app/keycontactlist');
            })
            .catch(e => { 
                 /* Fail Loader Called */
                loader.style.display='none';
                failloader.style.display='block';
                fail_message.innerHTML="Key Contact record not updated successfully.";
                let timer =  setInterval(myTimer, 3000);
                function myTimer() {
                    failloader.style.display='none';
                    clearInterval(timer);   
                }     

                toast(console.log(e)); 
            });
    }

    const CreateKeyContact = async (values) => {
        values.practiceId =practiceId;
        var payload = JSON.stringify(values);
        await keyContactService.create(payload).then(result => { toast(<Notification type="success" message="Key Contact record saved successfully." />), history.push('/app/keycontactlist'); }).catch(e => { toast(console.log(e)); });
    }


    return (
        <div className="card">
            <h5 className="card-header">{id>0 ? 'Edit ' : 'Add ' } Key Contact</h5>
            <div className="card-body">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({values, errors, touched, isSubmitting, handleChange,setFieldValue }) => {
                        useEffect(() => {
                            if (id > 0) {
                                keyContactService.get(id).then(result => { 
                                    const fields = [ 'companyName', 'representativeName', 'accountNo', 'contactNo','detailsOfCompany'];
                                    fields.forEach(field => setFieldValue(field, result.data.data[field], false));
                                }); 
                            }
                        }, []);

                        return (
                                <Form id="form-employee">
                                    <div className="row">
                                        <div className="col-md-6">
                                        <div className="form-group">
                                                <label className="col-form-label" htmlFor="idea">Company Name </label>
                                                 <Field placeholder="Enter company name" className="form-control" type="text" name="companyName" /> 
                                                <ErrorMessage name="companyName" component={TextError} />
                                            </div>
                                        <div className="form-group">
                                                <label className="col-form-label" htmlFor="representativeName"> Representative Name </label>
                                                 <Field placeholder="Enter representative name" className="form-control" type="text" name="representativeName" /> 
                                                <ErrorMessage name="representativeName" component={TextError} />
                                         </div>
                                         <div className="form-group">
                                                <label className="col-form-label" htmlFor="accountNo"> Account No </label>
                                                 <Field placeholder="Enter account no" className="form-control" type="text" name="accountNo" /> 
                                                <ErrorMessage name="accountNo" component={TextError} />
                                         </div>
                                         <div className="form-group">
                                                <label className="col-form-label" htmlFor="contactNo"> Contact No </label>
                                                <ReactPhoneInput country={'au'} value={values.contactNo} onChange={(value, country, e, formattedValue)=>{
                                                        handleChange(e);
                                                        setFieldValue("contactNo",formattedValue);
                                                    }}
                                                    inputProps={{ name: 'contactNo', required: true, className:"form-control w-100"}} />
                                                <ErrorMessage name="contactNo" component={TextError} />
                                         </div>
                                         <div className="form-group">
                                                <label className="col-form-label" htmlFor="detailsOfCompany"> Details Of Company </label>
                                                 <Field placeholder="Enter details of company" className="form-control" type="text" name="detailsOfCompany" /> 
                                                <ErrorMessage name="detailsOfCompany" component={TextError} />
                                         </div>
                                        </div>
                                    </div>
                                    <div className="form-buttons">
                                        <button className="mr-3 btn btn-primary" type="submit" id="btnSubmit">Save</button>
                                        <Link to="/app/keycontactlist" className="mr-3 btn btn-secondary">Cancel</Link>
                                    </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}
export default ManageKeyContact;
