import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Col, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { useParams } from 'react-router-dom'; 
import Widget from "../../components/Widget/Widget.js";
import MarketingService from "./../../services/marketing/marketing.service";
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import s from "./Tables.module.scss";
import CommonHelper from "../../helper/common-helper";
import PreviewModal from "../pdfPreview/preview.js";
import * as html2pdf from 'html2pdf.js'
import EnumHelper from "../../helper/enum-helper";
import PdfHelper from "../../helper/pdf-helper";
import Spinner from "react-bootstrap/Spinner";

//Notification
import { toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification.js";

//DateRange Picker
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const MarketingList = function () {
    const months = CommonHelper.getMonthList();
    const { index } = useParams(); 
    let pageNo = 1, pageSize = 10,runningMonth=new Date().getMonth(), currentSelection = { month: months[ index? parseInt(index) :runningMonth], index: index?  parseInt(index): runningMonth };
    const [showloader, setLoading] = useState([], false);

    const [response, setMarketingList] = useState([]);
    const [totalCount, setTotalCount] = useState([], false);
    const [currentMonth, setCurrentMonth] = useState(currentSelection, false);
    const [DateRange, setStateDateRange] = useState(CommonHelper.getMonthDateRangeString(currentSelection.index), false);

    const txtSearch = useRef(null);
    const txtPoint = useRef(null);

    const [pdfURl, setPdfUrl ] = useState('', false);
    const [showModel , setModel] = useState(false);
    
    const practiceId = localStorage.getItem('practiceId');
    const role = localStorage.getItem('role');
    useEffect(() => {
        getMarketing();
    }, [DateRange]);

    const handlePageClick = (data) => {
        let selected = data.selected + 1;
        pageNo = selected
        getMarketing();

    };

    const getMarketing = async () => {        
        var payload = { "searchTerm": txtSearch.current.value, "pageSize": pageSize, "pageNumber": pageNo, "practiceId": parseInt(practiceId), "dateRange": DateRange }
        setLoading(  true );

        await new Promise(function (resolve, reject) {
            MarketingService.search(payload).then(result => {
                resolve(result);
                setMarketingList(result.data.data.marketingList);
                setTotalCount(Math.ceil(parseInt(result.data.data.totalRecords) / pageSize));
                pageNo = payload.pageNumber;
                setLoading(  false );

            }).catch(e => {
            })
        });

    }
    const deleteRecord = async (id) => {
        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */

        await MarketingService.delete(id)
            .then(result => {
                // toast(<Notification type="success" message="Marketing record deleted successfully." />),

                /* Success Loader Called */
                loader.style.display='none';
                successloader.style.display='block';
                success_message.innerHTML="Marketing record deleted successfully.";
                let timer =   setInterval(myTimer, 3000);
                function myTimer() {
                    successloader.style.display='none';
                    clearInterval(timer);  
                }

                    getMarketing();
                //history.push('/app/marketinglist');
            })
            .catch(e => { 
                 /* Fail Loader Called */
                loader.style.display='none';
                failloader.style.display='block';
                fail_message.innerHTML="Marketing record not deleted successfully.";
                let timer =  setInterval(myTimer, 3000);
                function myTimer() {
                    failloader.style.display='none';
                    clearInterval(timer);   
                }     

                toast();
            });
    }

    const setCurrentSelection = (idx) => {
        setCurrentMonth({ month: months[idx], index: idx });
        setStateDateRange(CommonHelper.getMonthDateRangeString(idx));
    }

    const datePickerHandleApply = (event, picker) => {
        let selectedDate = picker.startDate.format('DD-MM-YYYY') + ' - ' + picker.endDate.format('DD-MM-YYYY')
        setStateDateRange(selectedDate); picker.element.val(selectedDate);
    }

    const datePickerHandleCancel = (event, picker) => { picker.element.val(''); setStateDateRange('') }

    const addPoint = async () => {
        if (txtPoint.current.value) {
            var date = CommonHelper.getMonthsIsoDate(currentMonth.index);
            var payload = { "marketingDate": date, "points": txtPoint.current.value, "createdDate": date, "updatedDate": date, "practiceId": parseInt(practiceId) }
            
            let loader = document.getElementById('loader');
            let successloader = document.getElementById('successloader');
            let failloader = document.getElementById('failloader');
            let success_message = document.getElementById('success-message');
            let fail_message = document.getElementById('fail-message');
            loader.style.display='block';/* Loader Section Activited */

            await new Promise(function (resolve, reject) {
                MarketingService.create(payload).then(result => {
                    // toast(<Notification type="success" message="Marketing record added successfully." />);

                    /* Success Loader Called */
                    loader.style.display='none';
                    successloader.style.display='block';
                    success_message.innerHTML="Marketing record added successfully.";
                    let timer =   setInterval(myTimer, 3000);
                    function myTimer() {
                        successloader.style.display='none';
                        clearInterval(timer);  
                    }

                    getMarketing();
                    resolve(result);
                }).catch(e => {
                    // toast(<Notification type="error" message="Something went wrong, please try again." />);

                    /* Fail Loader Called */
                    loader.style.display='none';
                    failloader.style.display='block';
                    fail_message.innerHTML="Marketing record not added successfully.";
                    let timer =  setInterval(myTimer, 3000);
                    function myTimer() {
                        failloader.style.display='none';
                        clearInterval(timer);   
                    }     

                    reject(e);
                })
            });
        }
    }

    const clearPoint = () => {
        txtPoint.current.value = '';
        txtPoint.current.focus();
    }

    const handleOnPdfPreview = (event) => {                
        if(event.nativeEvent.target && event.nativeEvent.target.classList.contains('ppreview')){
            let options=EnumHelper.PdfOption, boundingRect=document.getElementById('main').getBoundingClientRect();
            options['jsPDF']['format']=[boundingRect.width-200,boundingRect.height-100];
            html2pdf().from(document.getElementById('main')).set(options).outputPdf().then((pdf) => {
                setPdfUrl(btoa(pdf));
                setModel(!showModel)
            })
        }
        else if(showModel){
            setModel(!showModel)
        }        
    }

    return (
        <div id = "main">
            <Row>
                <Col>
                    <Row className="mb-4">
                        <Col>
                            <Widget>
                                <div className={s.tableTitle}>
                                    <div className="headline-2">Marketing</div>
                                    <div className="d-flex">                                        
                                        <a onClick={(e) => PdfHelper.printPage()}><img src={printerIcon} alt="Printer" /></a>
                                    <a className="mx-2 ppreview" onClick={(e) => { handleOnPdfPreview(e); }}><PreviewModal isOpen={showModel} url={pdfURl} /> Preview</a>
                                    <a onClick={() => PdfHelper.downloadPdf(document.getElementById('main'), "Marketing")}><img src={cloudIcon} alt="Download" /></a>
                                    </div>
                                </div>
                                <Row className="mb-4">
                                    <Col lg="3">
                                        {months.map((item, idx) => (
                                            <Row className="m-4" key={uuidv4()}>
                                                <div className="col-sm-12">
                                                    <input className={"btn " + ((currentMonth.index === idx) ? "btn-primary" : "btn-secondary") + " btn-lg btn-block"} type="button" value={item} onClick={() => { setCurrentSelection(idx); }} />
                                                </div>
                                            </Row>
                                        ))}
                                    </Col>
                                    <Col>
                                        <div className={s.tableTitle}>
                                            <Col className="pl-0">
                                                Date : {currentMonth.month}
                                                <input type="text" ref={txtSearch} name="txtSearch" className="form-control d-none" placeholder="Search" />
                                            </Col>
                                            <Col>
                                                <DateRangePicker onApply={datePickerHandleApply} onCancel={datePickerHandleCancel} initialSettings={{ autoUpdateInput: false, locale: { 'format': 'DD-MM-YYYY' } }}>
                                                    <input type="text" className="form-control d-none" defaultValue="" placeholder="Select Date" />
                                                </DateRangePicker>
                                            </Col>
                                            <Col>
                                                <input className="btn btn-secondary d-none" type="button" value="Search" onClick={() => { getMarketing() }} />
                                            </Col>
                                            <Col>
                                                {role === "Employee" ? <div></div> : <Link to={"/app/marketing/"+ currentMonth.index +"/0"} className="btn btn-primary pull-right d-none">
                                                    Add Marketing
                                                </Link>}
                                            </Col> 
                                        </div>
                                        <div className="widget-table-overflow">
                                            <Col>
                                                <Table className={`table-striped table-borderless table-hover ${s.statesTable}`} responsive>
                                                    <thead>
                                                        <tr>
                                                            <th className="W-50">POINTS</th>
                                                            {role === "Employee" ? <th></th> : <th className="W-100">ACTIONS</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr key={uuidv4()}>
                                                            <td><input type="text" ref={txtPoint} name="txtPoint" className="form-control" placeholder="Points" /></td>
                                                            <td>
                                                                <Link to="#" onClick={() => addPoint()}><i className={'eva eva-checkmark-outline ' + s.greenColor} /></Link>&nbsp;
                                                                <Link to="#" onClick={() => clearPoint()}><i className={'eva eva-close-outline ' + s.redColor} /></Link>
                                                            </td>
                                                        </tr>
                                                        {showloader ? (
                  <tr>
                    <td rowSpan="4" colSpan="4">
                      <div className="text-center py-5">
                          <Spinner animation="border" />
                      </div>
                    </td>
                  </tr>
                ) : response.length == 0 ? (
                  <tr>
                    <td colSpan="4">
                      <div className="alert alert-secondary text-primary m-3 color">
                        No records found
                      </div>
                    </td>
                  </tr>
                ) : response
                                                            .map(item => (
                                                                <tr key={uuidv4()}>
                                                                    <td>{item.points}</td>
                                                                    {role === "Employee" ? <td></td> : <td>
                                                                        <Link to={"/app/marketing/"+ currentMonth.index +"/" + item.id} className={s.blackColor}><i className="fa fa-edit"></i></Link>&nbsp;
                                                                        <Link to="#" onClick={() =>confirm('Are you sure, you want to delete this?') &&  deleteRecord(item.id)} className={s.blackColor}><i className="fa fa-trash"></i></Link></td>}
                                                                </tr>
                                                            ))}
                                                       
                                                    </tbody>
                                                </Table>
                                                <Row>
                                                    <Col className="ml-2">

                                                    </Col>
                                                    <Col className="col-10">
                                                        <ReactPaginate
                                                            breakLabel="..."
                                                            nextLabel=" >"
                                                            onPageChange={handlePageClick}
                                                            pageRangeDisplayed={pageSize}
                                                            pageCount={Math.ceil(totalCount)}
                                                            previousLabel="< "
                                                            renderOnZeroPageCount={null}
                                                            containerClassName={'pagination pagination-sm'}
                                                            pageClassName={'page-item'}
                                                            pageLinkClassName={'page-link'}
                                                            previousClassName={'previous'}
                                                            previousLinkClassName={'page-item page-link'}
                                                            nextClassName={'next'}
                                                            nextLinkClassName={'page-item page-link'}
                                                            activeClassName={'active'}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </div>

                                    </Col>
                                </Row>
                            </Widget>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default MarketingList;
