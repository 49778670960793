import React, { useEffect, useState, useRef, Component } from "react";
import { PieChart } from '@mui/x-charts/PieChart';
import Modal from 'react-bootstrap/Modal';
import { v4 as uuidv4 } from "uuid";
import { Alert, Col, Row, Table,Label} from "reactstrap";
import ReactPaginate from 'react-paginate';
import dashboardAdminService from "./../../services/dashboardAdmin/dashboardAdmin.service";
import Spinner from "react-bootstrap/Spinner";
import Widget from "../../components/Widget/Widget.js";
import { Link } from "react-router-dom";
import s from "./Tables.module.scss";
import CommonHelper from "../../helper/common-helper.js";
const DashBoardAdmin = function () {

    const [itemData, setItemData] = React.useState();
    const [totalCount, setTotalCount] = useState([], false);
    const [PieChartData,setPieChartData] = useState([], false);
    const [PieChartData2,setPieChartData2] = useState([], false);
    const [response, setPracticeList] = useState([], false);
    const [subscriptionType, setsubscriptionType] = useState("", false);
    let pageSize = 10;
    let pageNo = 1;

    const [showloader, setLoading] = useState([], false);

    const handlePageClick = (data) => {
        let selected = data.selected + 1;
        pageNo = selected;
        getPracticeList(subscriptionType);
    };

const getSubTypeList = (d) =>{

    console.log(d);
   if(d.seriesId==1){
    setsubscriptionType("");
    getPracticeList("");
   }
   else{
    setsubscriptionType(PieChartData[d.dataIndex].label.split(" -")[0]);
    getPracticeList(PieChartData[d.dataIndex].label.split(" -")[0]);
}
};
const GetDateTimeinLocal = (date)  =>{
    if(date != ""){
      // convert utc date to local date time
      let Localdate=  CommonHelper.getISODateTimeinLocalTZ(new Date(date.replace("T"," ")));

      return CommonHelper.ToDateFormat(Localdate,"DD-MM-YYYY");
      
    }
}
    const getPieChartData = async () => {

        await new Promise(function (resolve, reject) {

            dashboardAdminService.piechart().then(result => {
                resolve(result);
console.log(JSON.stringify(result.data.data));
                setPieChartData(result.data.data.filter((x)=>!x.label.includes('Total')));
                setPieChartData2(result.data.data.filter((x)=>x.label.includes('Total')).map(v=>({...v,color:'#00abbe'})));
            }).catch(e => {
               console.log(e);
            })
        });
    }
    useEffect(() => {
        getPieChartData();
        getPracticeList("");
        return () => {
            getPracticeList([]); // This worked for me
        };
    }, []);

    const getPracticeList = async (stype) => {

        var payload = { "searchTerm": stype, "pageSize": pageSize, "pageNumber": pageNo, "practiceId": 0 }
        setLoading(  true );

        await new Promise(function (resolve, reject) {
            dashboardAdminService.practiceBySubscription(payload).then(result => {
                resolve(result);
                setPracticeList(result.data.data.data);
                setTotalCount(Math.ceil(parseInt(result.data.data.totalRecords) / pageSize));
                pageNo = payload.pageNumber;
                setLoading(  false );

            }).catch(e => {
               console.log(e);
            })
        });
    }


  return (
<>
    <div style={{marginBottom: "0px", display: "block",alignContent:"center"}}>
        <p>Practice Subscription Data</p>
    <PieChart
  //  colors={['red', 'blue', 'green']}
      series={[
        {
          id:1,
          data: PieChartData2,
          outerRadius:30,
        //  colors:'#00abbe',
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30,  color: 'gray' },
        },
        {
            id:2,
            data: PieChartData,
            innerRadius:50,
            highlightScope: { faded: 'global', highlighted: 'item' },
            faded: { outerRadius: 30,  color: 'gray' },
        },
      ]}
//      width={750}
      height={350}

      onItemClick={(event, d) => getSubTypeList(d)}
    />
      </div>
      <div id = "main">
            <Row>
                <Col>
                    <Row className="mb-4">
                        <Col>
                            <Widget>
      <div className="widget-table-overflow">
                    <Table className={`table-striped table-borderless`} style={{border: ".5px Solid gray", marginTop: "20px"}} responsive>
                                        <thead>
                                            <tr>
                                               
                                                <th className="w-20">Practice Name</th>
                                                <th className="w-20">Status</th>
                                                <th className="w-20">Subscription Type</th>
                                                <th className="w-20">Last Payment Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {showloader ? (
                  <tr>
                    <td rowSpan="4" colSpan="4">
                      <div className="text-center py-5">
                          <Spinner animation="border" />
                      </div>
                    </td>
                  </tr>
                ) : response.length == 0 ? <tr><td colSpan="6"><div className='alert alert-secondary text-primary m-3 color' >No records found</div></td></tr> : response.map(item => (
                                                    <tr key={uuidv4()}>
                                                        <td>
                                                        <Link to={"/app/practice/" + item.practiceId} title="Edit" className={s.blackColor}><i >{item.practiceName}</i></Link>
                                                        </td>
                                                        <td>{item.status}</td>
                                                        <td>{item.subscriptionType}</td>
                                                        <td> {item.lastPaymentDate ? GetDateTimeinLocal(item.lastPaymentDate) : ""}</td>
                                                    </tr>
                                                ))

                                            }
                                          
                                        </tbody>
                                    </Table>

                                    <Row>
                                        <Col className="col-12">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=" >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={pageSize}
                                                pageCount={Math.ceil(totalCount)}
                                                previousLabel="< "
                                                renderOnZeroPageCount={null}
                                                containerClassName={'pagination pagination-sm'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'previous'}
                                                previousLinkClassName={'page-item page-link'}
                                                nextClassName={'next'}
                                                nextLinkClassName={'page-item page-link'}
                                                activeClassName={'active'}
                                            />
                                        </Col>
                                    </Row>
                </div>
                </Widget>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
            </>
    
  );
}
export default DashBoardAdmin;