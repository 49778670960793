import http from "../../config/http-common";

class holidayService { 
    async getAllHoliday() {
        return await http.get(`holiday/getAllPublicHoliday`);
    }
    async getAllSchoolTerm() {
        return await http.get(`holiday/getAllSchoolTerm`);
    }
}
export default new holidayService();