import React, { useEffect, useState,useRef } from "react";

import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useField,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import TextError from "../../components/Error/TextError";
import TeamMeeting from "./../../services/teammeeting/teammeeting.service";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import PreviewModal from "../pdfPreview/preview.js";
import * as html2pdf from "html2pdf.js";
import EnumHelper from "../../helper/enum-helper";
import PdfHelper from "../../helper/pdf-helper";
import printerIcon from "../../assets/tables/printerIcon.svg";
import "react-datepicker/dist/react-datepicker.css";
import CommonHelper from "../../helper/common-helper";
//Notification
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification.js";
import { Editor } from "react-draft-wysiwyg";
import { v4 as uuidv4 } from "uuid";

const ManageTramMeeting = () => {
  const { id } = useParams();
  let history = useHistory();
  var curr = new Date();

  const [patientExperienceState, onPatientExperienceChange] = useState(
    "",
    false
  );
  const [teamManagementState, onteamManagementChange] = useState("", false);
  const [editorState, onEditorStateChange] = useState("", false);
  const [actionListState, onactionListChange] = useState("", false);

  const practiceId = localStorage.getItem("practiceId");
  const initialValues = {
    meetingTitle: "",
    meetingDate:  curr.toISOString().substr(0, 10),
    note: "",
    actionList: "",
    attendees: "",
    nextMeetingTitle: "",
    dueDate: "",
    clinicalExcellence: "",
    patientExperience: "",
    teamManagement: "",
    practiceId:""
  };

  const validationSchema = Yup.object({
    meetingTitle: Yup.string().required("Please enter meeting title"),
    meetingDate: Yup.string().required("Please enter meeting date"),
    //note: Yup.string().required("Please enter note"),
    //actionList: Yup.string().required("Please enter action list"),
    attendees: Yup.string().required("Please enter attendees"),
    nextMeetingTitle: Yup.string().required("Please enter next meeting title"),
    // clinicalExcellence: Yup.string().required(
    //   "Please enter clinical excellence"
    // ),
    // patientExperience: Yup.string().required("Please enter patient experience"),
    // teamManagement: Yup.string().required("Please enter team management"),
  });

const autoSave = async () =>{
  if(formRef.current.values.meetingDate != '' && formRef.current.values.meetingTitle != '' && formRef.current.values.attendees != '' && formRef.current.values.nextMeetingTitle != '')
  {
    formRef.current.handleSubmit();
  }
}

const handleCancle =  async () => {
  await autoSave();
  await history.push('/app/teammeetinglist');
  };

  const handleSubmit = async (values) => {
    if (id == 0) {
      CreateTeamMeeting(values);
    } else {
      UpdateTeamMeeting(id, values);
    }
  };

  const [pdfURl, setPdfUrl] = useState("", false);
  const [showModel, setModel] = useState(false);

  const UpdateTeamMeeting = async (id, values) => {
    values.practiceId = practiceId;
    values.clinicalExcellence = CommonHelper.convertEditorToHtml(editorState);
    values.patientExperience = CommonHelper.convertEditorToHtml(
      patientExperienceState
    );
    values.teamManagement =
      CommonHelper.convertEditorToHtml(teamManagementState);
    values.actionList = CommonHelper.convertEditorToHtml(actionListState);
    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

    await TeamMeeting.update(id, JSON.stringify(values))
      .then((result) => {
        // toast(
        //   <Notification
        //     type="success"
        //     message="Team meeting record updated successfully."
        //   />
        // );

        /* Success Loader Called */
        loader.style.display='none';
        successloader.style.display='block';
        success_message.innerHTML="Team meeting record updated successfully.";
        let timer =   setInterval(myTimer, 1000);
        function myTimer() {
            successloader.style.display='none';
            clearInterval(timer);  
        }

       // history.push("/app/teammeetinglist");
      })
      .catch((e) => {
        /* Fail Loader Called */
        loader.style.display='none';
        failloader.style.display='block';
        fail_message.innerHTML="Team meeting record not updated successfully.";
        let timer =  setInterval(myTimer, 3000);
        function myTimer() {
            failloader.style.display='none';
            clearInterval(timer);   
        }      
        toast(console.log(e));
      });
  };

  const CreateTeamMeeting = async (values) => {
    values.clinicalExcellence = CommonHelper.convertEditorToHtml(editorState);
    values.patientExperience = CommonHelper.convertEditorToHtml(
      patientExperienceState
    );
    values.teamManagement =
      CommonHelper.convertEditorToHtml(teamManagementState);
    values.actionList = CommonHelper.convertEditorToHtml(actionListState);
    values.practiceId = practiceId;
    var payload = JSON.stringify(values);

    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */
    await TeamMeeting.create(payload)
      .then((result) => {
        // toast(
        //   <Notification
        //     type="success"
        //     message="Team meeting record created successfully."
        //   />
        // );

         /* Success Loader Called */
         loader.style.display='none';
         successloader.style.display='block';
         success_message.innerHTML="Team meeting record created successfully.";
         let timer =   setInterval(myTimer, 1000);
         function myTimer() {
             successloader.style.display='none';
             clearInterval(timer);  
         }

         history.push("/app/teammeeting/"+result.data.data);
      })
      .catch((e) => {
        /* Fail Loader Called */
        loader.style.display='none';
        failloader.style.display='block';
        fail_message.innerHTML="Team meeting record not created successfully.";
        let timer =  setInterval(myTimer, 3000);
        function myTimer() {
            failloader.style.display='none';
            clearInterval(timer);   
        }      
        toast(console.log(e));
      });
  };

  const handleOnPdfPreview = (event) => {
    if (
      event.nativeEvent.target &&
      event.nativeEvent.target.classList.contains("ppreview")
    ) {

        let options = EnumHelper.PdfOption,
        boundingRect = document.getElementById("main").getBoundingClientRect();
      options["jsPDF"]["format"] = [
        boundingRect.width - 200,
        boundingRect.height - 100,
      ];
    

      html2pdf()
      .from(document.getElementById("main"))
      .set(options)
      .outputPdf()
      .then((pdf) => {
        setPdfUrl(btoa(pdf));
        setModel(!showModel);
      });    } else if (showModel) {
      setModel(!showModel);
    }
  };

  const formRef = useRef();

  return (
    <div className="card" id="main">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="m-0 text-white">{id > 0 ? "Edit" : "Add"} Team Meeting</h5>
        <div>
          <a onClick={(e) => PdfHelper.printPage()}>
            <img src={printerIcon} alt="Printer" />
          </a>
          <a
            className="mx-2 ppreview"
            onClick={(e) => {
              handleOnPdfPreview(e);
            }}
          >
            <PreviewModal isOpen={showModel} url={pdfURl} /> Preview
          </a>
        </div>
      </div>
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ errors, touched, isSubmitting, setFieldValue }) => {
            useEffect(() => {
              if (id > 0) {
                TeamMeeting.get(id).then((teamMeeting) => {
                  const fields = [
                    "meetingTitle",
                    "actionList",
                    "note",
                    "attendees",
                    "nextMeetingTitle",
                    "dueDate",
                    "clinicalExcellence",
                    "patientExperience",
                    "teamManagement",
                  ];
                  fields.forEach((field) =>
                    setFieldValue(field, teamMeeting.data.data[field], false)
                  );
                  let dateExists = new Date();
                  setFieldValue(
                    "meetingDate",
                    teamMeeting.data.data["meetingDate"].substring(0, 10)
                  );

                  onEditorStateChange(
                    CommonHelper.createEditorState(
                      teamMeeting.data.data["clinicalExcellence"]
                    )
                  );
                  onPatientExperienceChange(
                    CommonHelper.createEditorState(
                      teamMeeting.data.data["patientExperience"]
                    )
                  );
                  onteamManagementChange(
                    CommonHelper.createEditorState(
                      teamMeeting.data.data["teamManagement"]
                    )
                  );
                  onactionListChange(
                    CommonHelper.createEditorState(
                      teamMeeting.data.data["actionList"]
                    )
                  );
                });
              }
            }, []);

            return (
              <Form id="form-employee">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="col-form-label null "
                        htmlFor="meetingDate"
                      >
                        
                        Meeting Date
                      </label>
                      <Field
                        className="form-control"
                        type="date"
                        name="meetingDate"
                        id="meetingDate"
                        onBlur={()=>autoSave()}
                      />

                      <ErrorMessage name="meetingDate" component={TextError} />
                    </div>
                    <div className="form-group">
                      <label
                        className="col-form-label null "
                        htmlFor="meetingTitle"
                      >
                        
                        Scribe
                      </label>
                      <Field
                        placeholder="Enter scribe "
                        className="form-control"
                        type="text"
                        name="meetingTitle"
                        onBlur={()=>autoSave()}
                      />
                      <ErrorMessage name="meetingTitle" component={TextError} />
                    </div>
                    <div className="form-group has-validation">
                      <label
                        className="col-form-label null "
                        htmlFor="attendees"
                      >
                        
                        Attendees
                      </label>
                      <Field
                        placeholder="Enter attendees"
                        className="form-control"
                        type="text"
                        name="attendees"
                        onBlur={()=>autoSave()}
                      />
                      <ErrorMessage name="attendees" component={TextError} />
                    </div>
                 
                    <div className="form-group">
                      <label
                        className="col-form-label null "
                        htmlFor="nextMeetingTitle"
                      >
                        Next Meeting
                      </label>
                      <Field
                        placeholder="Enter next meeting title"
                        className="form-control"
                        type="text"
                        name="nextMeetingTitle"
                        onBlur={()=>autoSave()}
                      />
                      <ErrorMessage
                        name="nextMeetingTitle"
                        component={TextError}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="form-group">
                      <label className="col-form-label null " htmlFor="note">
                        
                        Notes
                      </label>
                      <Field
                        placeholder="Enter notes"
                        className="form-control"
                        type="text"
                        name="note"
                        onBlur={()=>autoSave()}
                      />
                      <ErrorMessage name="note" component={TextError} />
                    </div>
                    <div className="form-group" style={{ marginRight: "25px" }}>
                      <label
                        className="col-form-label null"
                        style={{ color: "#00abbe", fontWeight: "600" }}
                        htmlFor="actionList"
                      >
                        
                        Action List
                      </label>
                      <Editor
                        key={uuidv4()}
                        name="actionList"
                        style={{ height: "200px" }}
                        className="form-control"
                        wrapperClassName="wyswing-wrapper"
                        editorState={actionListState}
                        placeholder="Enter action"
                        id="actionEditor"
                        toolbar={{
                          options: ["inline", "list"],
                          inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ["bold", "italic", "underline"],
                          },
                        }}
                        onBlur={() => {
                          actionListState.getCurrentContent().getPlainText() !=
                          ""
                            ? setFieldValue(
                                "actionList",
                                actionListState
                                  .getCurrentContent()
                                  .getPlainText()
                              )
                            : setFieldValue("actionList", "");
                            autoSave();
                        }}
                        onEditorStateChange={onactionListChange}
                      />

                      <ErrorMessage name="actionList" component={TextError} />
                    </div>
                    <div className="col-md-12" style={{ display: "flex" }}>
                      <div className="col-md-4" style={{ marginLeft: "-14px" }}>
                        <div className="form-group">
                          <label
                            className="col-form-label null"
                            style={{ color: "#00abbe", fontWeight: "600" }}
                            htmlFor="clinicalExcellence"
                          >
                            
                            Clinical Excellence
                          </label>
                          <Editor
                            key={uuidv4()}
                            name="clinicalExcellence"
                            style={{ height: "200px" }}
                            className="form-control"
                            id="clinicalExperienceEditor"

                            wrapperClassName="wyswing-wrapper"
                            editorState={editorState}
                            placeholder="Enter clinical excellence"
                            toolbar={{
                              options: ["inline", "list"],
                              inline: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: [
                                  "bold",
                                  "italic",
                                  "underline"
                                ],
                              },
                            }}
                            onBlur={() => {
                              editorState.getCurrentContent().getPlainText() !=
                              ""
                                ? setFieldValue(
                                    "clinicalExcellence",
                                    editorState
                                      .getCurrentContent()
                                      .getPlainText()
                                  )
                                : setFieldValue("clinicalExcellence", "");
                                autoSave();
                            }}
                            onEditorStateChange={onEditorStateChange}
                          />

                          <ErrorMessage
                            name="clinicalExcellence"
                            component={TextError}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            className="col-form-label null"
                            style={{ color: "#00abbe", fontWeight: "600" }}
                            htmlFor="patientExperience"
                          >
                            
                            Patient Experience
                          </label>
                          <Editor
                            key={uuidv4()}
                            name="patientExperience"
                            style={{ height: "200px" }}
                            className="form-control"
                            wrapperClassName="wyswing-wrapper"
                            editorState={patientExperienceState}
                            placeholder="Enter patient experience"
                            id="patientExperienceEditor"

                            toolbar={{
                              options: ["inline", "list"],
                              inline: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: [
                                  "bold",
                                  "italic",
                                  "underline"
                                ],
                              },
                            }}
                            onBlur={() => {
                              patientExperienceState
                                .getCurrentContent()
                                .getPlainText() != ""
                                ? setFieldValue(
                                    "patientExperience",
                                    patientExperienceState
                                      .getCurrentContent()
                                      .getPlainText()
                                  )
                                : setFieldValue("patientExperience", "");
                                autoSave();
                            }}
                            onEditorStateChange={onPatientExperienceChange}
                          />

                          <ErrorMessage
                            name="patientExperience"
                            component={TextError}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">

                        <div className="form-group">
                          <label
                            className="col-form-label null"
                            style={{ color: "#00abbe", fontWeight: "600" }}
                            htmlFor="teamManagement"
                          >
                            Team Management
                          </label>
                          <Editor
                            key={uuidv4()}
                            name="teamManagement"
                            style={{ height: "200px" }}
                            className="form-control"
                            wrapperClassName="wyswing-wrapper"
                            editorState={teamManagementState}
                            placeholder="Enter team management"
                            id="teamManagementEditor"

                            toolbar={{
                              options: ["inline", "list"],
                              inline: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: [
                                  "bold",
                                  "italic",
                                  "underline"
                                ],
                              },
                            }}
                            onBlur={() => {
                              teamManagementState
                                .getCurrentContent()
                                .getPlainText() != ""
                                ? setFieldValue(
                                    "teamManagement",
                                    teamManagementState
                                      .getCurrentContent()
                                      .getPlainText()
                                  )
                                : setFieldValue("teamManagement", "");
                                autoSave();
                            }}
                            onEditorStateChange={onteamManagementChange}
                          />

                          <ErrorMessage
                            name="teamManagement"
                            component={TextError}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-buttons">
                  <button
                    className="mr-3 btn btn-primary"
                    type="submit"
                    id="btnSubmit"
                    to="/app/teammeetinglist"
                  >
                    Save
                  </button>
                  <button
                    className="mr-3 btn btn-secondary"
                    id="btnSubmit"
                    onMouseDown={handleCancle}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
export default ManageTramMeeting;
