import React, { useEffect, useState, useRef, Component } from "react";
import { v4 as uuidv4 } from "uuid";
import { Alert, Col, Row, Table,} from "reactstrap";
import ReactPaginate from 'react-paginate';
import PatientService from "./../../services/patient/patient.service";

// Modal Popup
import Modal from 'react-bootstrap/Modal';
import CommonHelper from "../../helper/common-helper";


const PatientSearchModal = function ({ change }) {

    const [response, setPatientList] = useState([], false);
    const [totalCount, setTotalCount] = useState([], false);

    /* Patient Search Input Fields */
    const txtFirstName = useRef("");
    const txtLastName = useRef("");
    const txtSex = useRef("");
    const txtDateOfBirth = useRef("");
    const txtMobile= useRef("");
    const txtEmail = useRef("");
    const txtPatientCategory = useRef("");

    let pageSize = 5;
    let pageNo = 1;
    const practiceId = localStorage.getItem('practiceId');

    useEffect(() => {}, []);

    const handlePageClick = (data) => {
        let selected = data.selected + 1;
        pageNo = selected;
         getPatients();
    };

    /* Api call for fatching patient records with search parameters */
    const getPatients = async () => {
        var payload = { 
                        "searchTerm": "", 
                        "pageSize": pageSize, 
                        "pageNumber": pageNo, 
                        "practiceId" : practiceId,
                        "lastName": txtLastName.current.value,
                        "firstName": txtFirstName.current.value,
                        "sex": txtSex.current.value,
                        "dateOfBirth": txtDateOfBirth.current.value,
                        "mobile": txtMobile.current.value,
                        "email": txtEmail.current.value,
                        "patientCategory": txtPatientCategory.current.value
                    }


        await new Promise(function (resolve, reject) {
            PatientService.search(payload).then(result => {
                resolve(result);
                setPatientList(result.data.data.patientList);
                setTotalCount(Math.ceil(parseInt(result.data.data.totalRecords) / pageSize));
                pageNo = payload.pageNumber;
                // Hide Patient Table
                document.getElementById("patientResult").style.display = "none";

                if(result.data.data?.totalRecords > 0){
                    // Show Patient Table
                    document.getElementById("patientResult").style.display = "block";
                } 
            }).catch(e => {
               console.log(e);
            })
        });
    }


    /* Function for reset the modal popup */
    const resetSearch = async () => {
        /*Reset All Search Fields */
        txtFirstName.current.value = "";
        txtLastName.current.value = "";
        txtSex.current.value = "";
        txtDateOfBirth.current.value = "";
        txtMobile.current.value= "";
        txtEmail.current.value = "";
        txtPatientCategory.current.value = "";

        setPatientList([]);
        // Hide Patient Table
        document.getElementById("patientResult").style.display = "none";
    }

    const [value, setPatientId] = useState("");
    function getPatientId(event) {
       let patientId = event.target.name;
       var patientName = event.target.attributes.getNamedItem('data-patient').value;
      // alert(JSON.stringify(attribute1));
       setPatientId(patientId);
       change(patientId,patientName);
       handleModalClose();
    }

    
    //Modal States
    const [show, setModalShow] = useState(false);
    const handleModalClose = () => {setModalShow(false); resetSearch();};
    const handleModalShow = () => {setModalShow(true);};

    return (
        <>          
            {/* Patient Search Popup */}
            <Modal show={show} onHide={handleModalClose} animation={false} size="xl">
                <Modal.Header closeButton><Modal.Title>Search Patient</Modal.Title></Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col className="col-4">
                            <input type="text" ref={txtFirstName} name="txtFirstName" className="form-control" placeholder="Firstname" />
                        </Col> 
                        <Col className="col-4">
                            <input type="text" ref={txtLastName} name="txtLastName" className="form-control" placeholder="Lastname" />
                        </Col> 
                        <Col className="col-4">
                            <select ref={txtSex} name="txtSex" className="form-control">
                                <option value="">Select Sex</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="O">Other</option>
                            </select>
                        </Col> 
                    </Row>
                    <Row style={{paddingTop: "10px"}}>
                        <Col className="col-4">
                            <input type="text" ref={txtMobile} name="txtMobile" className="form-control" placeholder="Mobile" />
                        </Col> 
                        <Col className="col-4">
                            <input type="text" ref={txtEmail} name="txtEmail" className="form-control" placeholder="Email" />
                        </Col> 
                        <Col className="col-4">
                            <select ref={txtPatientCategory} name="txtPatientCategory" className="form-control">
                                <option value="">Select Patient Category</option>
                                <option value="General">General</option>
                                <option value="VIP">VIP</option>
                            </select>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "10px"}}>
                        <Col className="col-4">
                            <input type="date" ref={txtDateOfBirth} name="txtDateOfBirth" className="form-control " placeholder="Search by Mobile" />
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "10px"}}>
                        <Col className="col-4"></Col>
                        <Col className="col-2">
                            <input className="btn btn-primary" title="Search" onClick={getPatients} type="button" value="Search" />
                        </Col>
                        <Col className="col-2">
                            <input className="btn btn-primary" title="Reset" onClick={resetSearch}  type="button" value="Reset" />
                        </Col>
                       
                    </Row>
                <div className="widget-table-overflow" style={{marginBottom: "0px", display: "none"}} id="patientResult">
                    <Table className={`table-striped table-borderless`} style={{border: ".5px Solid gray", marginTop: "20px"}} responsive>
                                        <thead>
                                            <tr>
                                               
                                                <th className="w-20">NAME</th>
                                                <th className="w-20">DOB</th>
                                                <th className="w-20">Mobile</th>
                                                <th className="w-20">Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { response.map(item => (
                                                    <tr key={uuidv4()}>
                                                        <td>
                                                            <a onClick={getPatientId} data-patient={item.title+" "+item.firstName +" "+item.lastName} name={item.id} style={{textDecoration: "underline"}}>
                                                                {item.title+" "+item.firstName +" "+item.lastName}
                                                            </a>
                                                        </td>
                                                        <td>{CommonHelper.ToDateFormat(item.dateOfBirth,"DD-MM-YYYY")}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{item.email}</td>
                                                    </tr>
                                                ))

                                            }
                                          
                                        </tbody>
                                    </Table>

                                    <Row>
                                        <Col className="col-12">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=" >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={pageSize}
                                                pageCount={Math.ceil(totalCount)}
                                                previousLabel="< "
                                                renderOnZeroPageCount={null}
                                                containerClassName={'pagination pagination-sm'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'previous'}
                                                previousLinkClassName={'page-item page-link'}
                                                nextClassName={'next'}
                                                nextLinkClassName={'page-item page-link'}
                                                activeClassName={'active'}
                                            />
                                        </Col>
                                    </Row>
                </div>
                    
                </Modal.Body>
            </Modal>

            {/* Button for showing modal popup */}
            <input className="btn btn-secondary" title="Search" onClick={handleModalShow}  type="button" value="Search Patient" />
        </>
    )
}
export default PatientSearchModal;
