import React, { useEffect, useState,useRef } from "react";

const AboutUs = function () {


    return (
        <div className="card">
            <div className="card-body headline-2">
                 Coming Soon...
            </div>
        </div>
      )
}

export default AboutUs;
