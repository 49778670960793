import axios from "axios";
const token = localStorage.getItem('token');
const clientOffset = localStorage.getItem('clientOffset')
export default axios.create({
  baseURL:process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `${token}`,
    'clientOffset': `${clientOffset}`,
    'Content-type': "application/json",
    'Access-Control-Allow-Origin': '*'
  }
}
);































































