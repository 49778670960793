import http from "../../config/http-common";

class resourceService {
    async getAll(data, pageNo, id) {
        return await http.post(`resources/${id}/${pageNo}`, data);
    }
    get(resourceId) {        
        return http.get(`resources/${resourceId}`);
    }
    create(data, practiceId,resourceId) {        
        return http.post(`resources/${practiceId}/${resourceId}`, data);
    }
    update(data, practiceId, resourceId) {
        return http.post(`resources/${practiceId}/${resourceId}`, data);
    }
    search(data) {
        return http.post(`resources/search`, data);
    }
    //delete(id) {
    //    return http.delete(`assignments`);
    //}
    delete(resourceId) {
       return http.delete(`resources/${resourceId}`);
    }
}

export default new resourceService();