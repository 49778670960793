import React from "react";
import classnames from "classnames";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import "font-awesome/css/font-awesome.min.css";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import "eva-icons/style/eva-icons.css";
import s from "./IconsPage.module.scss";
import Resources from "../../resource/resource-list.js"
import Performance from "../../performance/performance-list.js"
import Training from "../../training/training-list.js"
import Assignment from "../../assignment/assignment-list.js"


class IconsPage extends React.Component {

    constructor(props) {
        super(props);
        let index = this.props?.match?.params?.index;
        this.state = {
            activeTab: "2",
        };
        if (index && ["2", "3", "4"].includes(index)) {
            this.state.activeTab = index;
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
            let params = this.props?.match?.params;
            if (params)
                this.props.history.push(this.props.match.path.replace(':id', params['id']).replace(':index', tab));
        }
    }

    render() {
        return (
            <Widget className="widget-p-md">
                <Nav tabs className="mb-4">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === "2" })}
                            onClick={() => { this.toggle("2"); }}
                        >
                            <p className="headline-3">Performance</p>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === "3" })}
                            onClick={() => { this.toggle("3"); }}
                        >
                            <p className="headline-3">Training</p>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === "4" })}
                            onClick={() => { this.toggle("4"); }}
                        >
                            <p className="headline-3">Assignment</p>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="2">
                        <Performance />
                    </TabPane>
                    <TabPane tabId="3">
                        <Training />
                    </TabPane>
                    <TabPane tabId="4">
                        <Assignment />
                    </TabPane>
                </TabContent>
            </Widget>
        );
    }
}

export default IconsPage;
