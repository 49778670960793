
import React, { useState } from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Yup from "yup";
import TextError from "../../components/Error/TextError";
import { useHistory } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    FormText,
    Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import Footer from "../../components/Footer/Footer.js";
import loginImage from "../../assets/loginImage.svg";
import RegisterService from "./../../services/register/register.service.js";
import Logo from "../../components/Icons/SidebarIcons/SofiaLogo";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification.js";

const ForgotPassword = () => {
    let history = useHistory();
    const initialValues = { email: "" };

    const validationSchema = Yup.object({

        

    });

    const handleSubmit = async (values) => {
        //var payload = JSON.stringify(values);
        //alert("payload mail=>" + values.email);
        await RegisterService.SendMail(values.email).then(result => {
            toast(<Notification type="success" message="Mail Send successfully." />),
                history.push('/login');
        }).catch(e => { toast(console.log(e)); });
    }



    return (
        <div className="auth-page">
            <Container className="col-12">
                <Row className="d-flex align-items-center">
                    <Col xs={12} lg={6} className="left-column">
                        <Widget className="widget-auth widget-p-lg">
                            <div className="d-flex align-items-center justify-content-center py-3">
                                {/*<p className="auth-header mb-0"></p>*/}
                                <div className="logo-block w-75 ">
                                    <Logo />
                                </div>
                            </div>
                            <div className="logo-block">
                            </div>
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                <Form id="form-employee">

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="col-form-label null " htmlFor="email">Email </label>
                                                <Field placeholder="Enter email Id" className="form-control" type="text"   name="email" />
                                                <ErrorMessage name="email" component={TextError} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-buttons">
                                        <button className="mr-3 btn btn-primary" type="submit" id="btnSubmit">Save</button>
                                    </div>
                                </Form>
                            </Formik>
                        </Widget>
                    </Col>
                    <Col xs={0} lg={6} className="right-column">
                        <div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}



export default ForgotPassword;
