import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import s from "./Breadcrumbs.module.scss";

class Breadcrumbs extends React.Component {

    renderBreadcrumbsTitle = () => {
        let route = this.props.url.split('/');
        let TitleNew = route[2];
        if (TitleNew == 'employeelist' || TitleNew == 'employee') {
            TitleNew = "Manage Team Members"
        }
        else if (TitleNew == 'practicelist') {
            TitleNew = "Manage Practice"
        }
        else if (TitleNew == 'dashboard') {
            TitleNew = "Daily Huddle"
        }
        else if (TitleNew == 'practice') {
            TitleNew = "Manage Practice"
        }
        else if (TitleNew == 'employee-view') {
            TitleNew = "Manage Employee"
        }
        else if (TitleNew == 'resources') {
            TitleNew = "Resources"
        }
        else if (TitleNew == 'performance') {
            TitleNew = "Performance"
        }
        else if (TitleNew == 'training') {
            TitleNew = "Training"
        }
        else if (TitleNew == 'assignment') {
            TitleNew = "Assignment"
        }
        else if (TitleNew == 'teammeetinglist' || TitleNew == 'teammeeting') {
            TitleNew = "Team Meeting"
        }
        else if (TitleNew == 'marketinglist' || TitleNew == 'marketing') {
            TitleNew = "Marketing"
        }
        else if (TitleNew == 'socialmedialist' || TitleNew =='socialmedia') {
            TitleNew = "Social Media Brainstorming"
        }
        else if (TitleNew == 'register') {
            TitleNew = "Register"
        }
        else if (TitleNew == 'resourcelist') {
            TitleNew = "Resource List"
        }
        else if (TitleNew == 'manage-employee-view') {
            TitleNew = "Team Members"
        }
        else if (TitleNew == 'keycontactlist') {
            TitleNew = "Key Contact List"
        }
        else if (TitleNew == 'patientlist') {
            TitleNew = "Patient List"
        }
        else if (TitleNew == 'patient') {
            TitleNew = "Patient"
        }
        else if (TitleNew == 'schoolterms') {
            TitleNew = "School Terms"
        }
        else if (TitleNew == 'publicholidays') {
            TitleNew = "Public Holidays"
        }
        else if (TitleNew == 'aboutUs') {
            TitleNew = "About Us"
        }
        else if (TitleNew == 'termsOfUse') {
            TitleNew = "Terms Of Use"
        }
        else if (TitleNew == 'keycontact') {
            TitleNew = "Key Contact"
        }else if (TitleNew == 'lablist') {
            TitleNew = "Lab List"
        }
        else if (TitleNew == 'lab') {
            TitleNew = "Lab"
        }else if (TitleNew == 'labworklist') {
            TitleNew = "Labwork List"
        }else if(TitleNew == "LabWork"){
            TitleNew = "Labwork"
        }else if(TitleNew == "dashboardAdmin"){
            TitleNew = "Admin Dashboard"
        }
        return TitleNew; 
    }

    renderBreadcrumbs = () => {
        let url = this.props.url;

        let route = this.props.url.split('/')
            .slice(1)
            .map(route => route
                .split('-')
                .map(word => word.length < 3
                    ? word.toUpperCase()
                    : word[0].toUpperCase() + word.slice(1))
                .join(' ')
            );
        const length = route.length

        return <>
            <BreadcrumbItem key={uuidv4()}> <Link to='/app/dashboard'> Home </Link></BreadcrumbItem>

            {
                this.renderBreadcrumbsTitle() == 'Resources'
                    || this.renderBreadcrumbsTitle() == 'Performance'
                    || this.renderBreadcrumbsTitle() == 'Training'
                    || this.renderBreadcrumbsTitle() == 'Assignment' ?
                    <BreadcrumbItem key={uuidv4()}> <Link to='/app/employeelist'> Team Members </Link> </BreadcrumbItem>
                    : ''
            }

            <BreadcrumbItem key={uuidv4()}> {this.renderBreadcrumbsTitle()}</BreadcrumbItem>
        </>


        // route.map((item, index) => {

        //   let middlewareUrl = "/" + url.split("/").slice(1, index + 2).join("/"); 

        //   middlewareUrl = url;// updated.
        //   return (
        //     <BreadcrumbItem key={uuidv4()}>
        //       {length === index + 1
        //         ?   this.renderBreadcrumbsTitle()
        //         : <Link to={middlewareUrl}> {item} </Link>
        //       }
        //     </BreadcrumbItem>
        //   )
        // })
    }

    render() {

        let routeArr = this.props.url.split('/');
        let title = routeArr[routeArr.length - 1];
        let breadcrumbTitle = title[0].toUpperCase() + title.slice(1)

        return (
            <div className={s.breadcrumbs}>
                <div className="headline-2">  {this.renderBreadcrumbsTitle()}  </div>
                {breadcrumbTitle !== "Dashboard" &&
                    <Breadcrumb tag="nav" listTag="div">
                        {this.renderBreadcrumbs()}
                    </Breadcrumb>
                }
            </div>
        )
    }
};

export default Breadcrumbs;
