import { Link, useParams } from "react-router-dom";
import React, { useEffect,forwardRef } from "react";

import { v4 as uuidv4 } from "uuid";
import { Col, Row, Button } from "reactstrap";
import TextError from "../../components/Error/TextError";
import DashboardService from "./../../services/dashboard/dashboard.service";
import DailyTaskService from "./../../services/dashboard/daily-task.service";
import EmployeeService from "../../services/employee/employee.service";
import LocalstoreHelper from "../../helper/localstore-helper";
import CategoryService from "./../../services/category/category.service";
import DashboardSearchModal from "./dashboard-search-popup.js";
import { Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Widget from "../../components/Widget/Widget.js";
import printerIcon from "../../assets/tables/printerIcon.svg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import s from "./Dashboard.module.scss";
import t from "./Tables.module.scss";
import * as Yup from "yup";
import { Editor } from "react-draft-wysiwyg";
import UserHelper from "../../helper/user-helper";
import CommonHelper from "../../helper/common-helper";
import PdfHelper from "../../helper/pdf-helper";
//Notification
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification.js";
import PreviewModal from "../pdfPreview/preview.js";
import cloudIcon from "../../assets/tables/cloudIcon.svg";
import * as html2pdf from "html2pdf.js";
import EnumHelper from "../../helper/enum-helper";
import ChangePasswordService from "../../services/changePassword/changePassword.service"
import PracticeService from "./../../services/practice/practice.service";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCaretLeft,faCaretRight,faBriefcase,faHospitalUser,faTruckMedical,faSyringe} from "@fortawesome/free-solid-svg-icons";
import { faSquare} from "@fortawesome/free-regular-svg-icons";
import { fa, faInstagram } from "@fortawesome/free-brands-svg-icons";
import DatePicker from "../../pages/dashboard/datepicker.js";
import LinksGroup from "../../components/Sidebar/LinksGroup/LinksGroup.js"

export default class Dashboard extends React.PureComponent {
  constructor(props) {
    super(props);
  
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = "-";
    this.onDateNavigationClicked = this.onDateNavigationClicked.bind(this);

    this.fetchCategories = this.fetchCategories.bind(this);
    this.getDailyNotesCategories = this.getDailyNotesCategories.bind(this);
    this.dailyNotes = this.dailyNotes.bind(this);

    //get daily tasks bottom in dashboard
    this.dailyTasks = this.dailyTasks.bind(this);
    //get list of employees
    this.getEmployees = this.getEmployees.bind(this);
    //update daily tasks bottom in dashboard
    this.UpdateDailyTask = this.UpdateDailyTask.bind(this);
    //render htmls for daily tasks
    this.renderTasks = this.renderTasks.bind(this);
    this.renderTasks1 = this.renderTasks1.bind(this);
    this.renderTasks2 = this.renderTasks2.bind(this);
    this.handleCalendarChangeStateValue=this.handleCalendarChangeStateValue.bind(this);
    
   
    this.state = {
      localPracticeId: localStorage.getItem("practiceId"),
      showModal: false,
      showHide: false,
      checkboxes: [false, true],
      items: [],
      huddles: [],
      dailyNotesCategories: [],
      employees: [],
      dailyHuddleIdEditMode: "",
      dailyNotesDetails: [
        {
          dailyNotesCategoryId: "",
          dailyNotesCategoryName: "",
          description: "",
          isCompleted: false,
          practiceId: 0,
        },
      ],
      currentdate: `${date}${separator}${
        month < 10 ? `0${month}` : `${month}`
      }${separator}${year}`,
      checkboxes: [false, true],
      dailyTasks: [[], [], [], []],
      huddleTasksTodaysDate: new Date(),
      //JobRole Model
      ShowHideJobModel: false,
      SelectedJobRoleId: 0,
      role: localStorage.getItem("role"),
      quote: LocalstoreHelper.getDailyQuote(),
      editorState: CommonHelper.createEditorState(props.text || ""),
      editorTitle: "",
      JoRoleInfo: [],
      createdDate: "",
      pdfgenerate: "",
      taskCategory:"Front Desk Team",
      HuddleTitle:"Add Task"
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleState = this.handleState.bind(this);
  }

  handleState(huddledate) {
    this.state.huddleTasksTodaysDate=huddledate;
    this.getHuddle(0);
    this.dailyTasks(0),
    this.renderTasks(),
    this.renderTasks1(),
    this.renderTasks2(),
    this.getDailyNotesCategories(0);
 }
  onDateNavigationClicked(duration) {
    const todayDate=new Date();
          switch(duration){
            case "NXT-DAY": this.setState({huddleTasksTodaysDate : this.state.huddleTasksTodaysDate.setDate(this.state.huddleTasksTodaysDate.getDate() +1)});break;
            case "NXT-WEEK": this.setState({huddleTasksTodaysDate : this.state.huddleTasksTodaysDate.setDate(this.state.huddleTasksTodaysDate.getDate() +7)});break;
            case "NXT-MNT":this.setState({huddleTasksTodaysDate : this.state.huddleTasksTodaysDate.setMonth(this.state.huddleTasksTodaysDate.getMonth() +1)});break;
            case "NXT-3MNT":this.setState({huddleTasksTodaysDate : this.state.huddleTasksTodaysDate.setMonth(this.state.huddleTasksTodaysDate.getMonth() +3)});break;
            case "NXT-6MNT":this.setState({huddleTasksTodaysDate : this.state.huddleTasksTodaysDate.setMonth(this.state.huddleTasksTodaysDate.getMonth() +6)});break;
            case "PRV-DAY": this.setState({huddleTasksTodaysDate : this.state.huddleTasksTodaysDate.setDate(this.state.huddleTasksTodaysDate.getDate() -1)});break;
            case "PRV-WEEK": this.setState({huddleTasksTodaysDate : this.state.huddleTasksTodaysDate.setDate(this.state.huddleTasksTodaysDate.getDate() -7)});break;
            case "PRV-MNT":this.setState({huddleTasksTodaysDate : this.state.huddleTasksTodaysDate.setMonth(this.state.huddleTasksTodaysDate.getMonth() -1)});break;
            case "PRV-3MNT":this.setState({huddleTasksTodaysDate : this.state.huddleTasksTodaysDate.setMonth(this.state.huddleTasksTodaysDate.getMonth() -3)});break;
            case "PRV-6MNT":this.setState({huddleTasksTodaysDate : this.state.huddleTasksTodaysDate.setMonth(this.state.huddleTasksTodaysDate.getMonth() -6)});break;
            case "NXT-0": this.setState({ huddleTasksTodaysDate: this.state.huddleTasksTodaysDate.setDate(todayDate.getDate()) });
                          this.setState({ huddleTasksTodaysDate: this.state.huddleTasksTodaysDate.setMonth(todayDate.getMonth()) });
                          this.setState({ huddleTasksTodaysDate: this.state.huddleTasksTodaysDate.setFullYear(todayDate.getFullYear()) });break;
          }
     this.getHuddle(0);
     this.dailyTasks(0),
     this.renderTasks(),
     this.renderTasks1(),
     this.renderTasks2(),
     this.getDailyNotesCategories(0);
  }
  handleCalendarChangeStateValue(seletedDate){
    this.setState({ huddleTasksTodaysDate: this.state.huddleTasksTodaysDate.setDate(seletedDate.getDate()) });
                          this.setState({ huddleTasksTodaysDate: this.state.huddleTasksTodaysDate.setMonth(seletedDate.getMonth()) });
                          this.setState({ huddleTasksTodaysDate: this.state.huddleTasksTodaysDate.setFullYear(seletedDate.getFullYear()) });
    this.getHuddle(0);
    this.dailyTasks(0),
    this.renderTasks(),
    this.renderTasks1(),
    this.renderTasks2(),
    this.getDailyNotesCategories(0);
  }
  
  validationSchema() {
    Yup.object({
      title1: Yup.string().required("Title is required"),
      dateOfHuddle: Yup.string().required("Date of Huddle is required"),
      huddleCategory: Yup.string().required("Huddle category is required"),
    });
  }

  dailyTasks = async (key) => {
    var date1 = new Date(this.state.huddleTasksTodaysDate);
    if (key == 1) {
      date1 = new Date(event.target.value);
    }
    this.setState({ huddleTasksTodaysDate: date1 });
    let huddleCalendardate =
      date1.getDate() +
      "/" +
      parseInt(date1.getMonth() + 1) +
      "/" +
      date1.getFullYear();
    await DailyTaskService.get(huddleCalendardate, this.state.localPracticeId)
      .then((result) => {
        this.setState({ dailyTasks: result.data.data });
        //toast(<Notification type="success" message="Task addded Successfully." />)
      })
      .catch((e) => {
        toast(console.log(e));
      });
  };

  handleSetLoginStatus = async (practiceId) => {
    await PracticeService.setLoginStatus(practiceId).then((result)=>{
    }).catch((e) => {
      toast();
    });
};

  componentDidMount() {
    let role = localStorage.getItem("role");
    if (role == "Practice-Admin"){
          if(localStorage["anyPastLogin"] == "null" || localStorage["anyPastLogin"] == "false"){
            this.setState({ viewPopup: true});
          }
       }

    this.fetchCategories();
    this.getHuddle(0);
    this.getDailyNotesCategories(0);
    this.getEmployees();
    this.dailyTasks(0);
    this.handleOnGetHuddleCategories();
  }

  handleChangePassword = async (values) => {
    var statethis=this;
    let practiceId = localStorage.getItem("practiceId");
    let employeeId = localStorage.getItem('employeeId');
        let payload = { "userId": employeeId, "password": values.newPassword }

        //set first user login for the practice flag to true
        this.handleSetLoginStatus(practiceId);
        localStorage["anyPastLogin"] = true;

        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */

        await new Promise(function (resolve, reject) {
            ChangePasswordService.changePassword(payload).then(result => {
                if (result.data.statuscode == 200) {
                  statethis.setState({ viewPopup: false });
                  // toast(<Notification type="success" message={result.data.data} />);

                  /* Success Loader Called */
                  loader.style.display='none';
                  successloader.style.display='block';
                  success_message.innerHTML=result.data.data;
                  let timer =   setInterval(myTimer, 3000);
                  function myTimer() {
                      successloader.style.display='none';
                      clearInterval(timer);  
                  }

                }
                else {
                    // toast(<Notification type="error" message={result.data.data} />);

                    /* Fail Loader Called */
                    loader.style.display='none';
                    failloader.style.display='block';
                    fail_message.innerHTML=result.data.data;
                    let timer =  setInterval(myTimer, 3000);
                    function myTimer() {
                        failloader.style.display='none';
                        clearInterval(timer);   
                    }

                }
                if (result.data == null) {
                    // toast(<Notification type="error" message={result} />);

                    /* Fail Loader Called */
                    loader.style.display='none';
                    failloader.style.display='block';
                    fail_message.innerHTML=result;
                    let timer =  setInterval(myTimer, 3000);
                    function myTimer() {
                        failloader.style.display='none';
                        clearInterval(timer);   
                    }
                }
                resolve(result);
            }).catch(e => {

              /* Fail Loader Called */
              loader.style.display='none';
              failloader.style.display='block';
              fail_message.innerHTML="Password Not Changed Successfully";
              let timer =  setInterval(myTimer, 3000);
              function myTimer() {
                  failloader.style.display='none';
                  clearInterval(timer);   
              }

              console.log(e);
            })
        });
};

  handleModalShowHide(isEditMode = false, dailyHuddleId = "") {
    if (isEditMode == true) {
      if (dailyHuddleId != "") {
        this.setState({ dailyHuddleIdEditMode: dailyHuddleId });
      }
    } else {
      this.setState({ dailyHuddleIdEditMode: "" });
    }
    this.setState({ showHide: !this.state.showHide });
  }
  handleModalShowHideJob(slectedVal) {
    if (slectedVal) {
      let description = this.state.JoRoleInfo.filter(
        (x) => x.id === parseInt(slectedVal)
      );    
      let title = "";
      let createdDate = "";
      if (description && !this.state.ShowHideJobModel) {
        title = description[0]["title"];
        createdDate = description[0]["createdDate"];
        description = description[0]["description"];
      }
      this.setState({
        ShowHideJobModel: !this.state.ShowHideJobModel,
        createdDate: createdDate,
        SelectedJobRoleId: parseInt(slectedVal),
        editorState: description
          ? CommonHelper.createEditorState(description)
          : null,
        editorTitle: title,
      });
    } else {
      this.setState({ ShowHideJobModel: !this.state.ShowHideJobModel });
    }
  }

  handleFormUpdate() {
    return (e) => {
      const field = e.target.name;
      const { form } = this.state;
      form[field] = e.target.value;
      this.setState({ form });
    };
  }

  handleModalHide() {
    return () => {
      let { items } = this.state;
      items = items.map((item) => ({
        ...item,
        showModal: false,
      }));
      this.setState({ items });
    };
  }

  handleModalShow() {
    return (e) => {
      e.preventDefault();
      this.setState({ showModal: true });
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  changeCheck = async (event, checkbox, id) => {
    this.state[checkbox][id] = event.target.checked;
    if (!event.target.checked) {
      this.state[checkbox][id] = false;
    }
    this.setState({
      [checkbox]: this.state[checkbox],
    });
    var payload = JSON.stringify({
      dailyHuddleId: id,
      dateOfHuddle: "2022-03-11T18:49:02.814Z",
      huddleCategory: "",
      title: "",
      createdAt: "2022-03-11T18:49:02.814Z",
      isCompleted: event.target.checked,
    });

    await DashboardService.update(payload, this.state.localPracticeId)
      .then((result) => {
        // this.getHuddle(0);
        // this.setState({ items });
        // this.handleModalShowHide();
        toast(
          <Notification type="success" message="Record updated successfully." />
        );
      })
      .catch((e) => {
        toast();
      });
  };
  dailyNotes(event) {
    const target = event.target;
    const name = target.getAttribute("data-index");
    const type = target.type;
    let markers = [...this.state.dailyNotesCategories];

    const value =
      target.type === "checkbox" ? markers[name].description : target.value;

    markers[name] =
      type == "text"
        ? {
            ...markers[name],
            dailyNotesCategoryId: markers[name].dailyNotesCategoryId,
            description: value,
            dailyNotesDetailsId: markers[name].dailyNotesDetailsId
          }
        : {
            ...markers[name],
            dailyNotesCategoryId: markers[name].dailyNotesCategoryId,
            description: value,
            isCompleted: event.target.checked,
            dailyNotesDetailsId: markers[name].dailyNotesDetailsId
          };

    this.setState({ dailyNotesCategories: markers });
    DashboardService.addDailyNotes(markers, this.state.localPracticeId)
      .then((result) => {
        this.getDailyNotesCategories(0);
        /* Loader is not needed because this is auto save functionality on Dashboard Screen */
        toast(
          <Notification type="success" message="Notes added Successfully." />
        );
      })
      .catch((e) => {
        toast();
      });
  }

  fetchCategories() {
    CategoryService.getAll()
      .then((result) =>
        this.setState({
          items: result.data.data,
        })
      )
      .catch((e) => {
        toast();
      });
  }

  getHuddle(key) {
    var date1 = new Date(this.state.huddleTasksTodaysDate);
    if (key == 1) {
      date1 = new Date(event.target.value);
    }
    this.setState({ huddleTasksTodaysDate: date1 });
    //var date =
    let huddleCalendardate =
      date1.getDate() +
      "/" +
      parseInt(date1.getMonth() + 1) +
      "/" +
      date1.getFullYear();

    DashboardService.getDailyHurdles(
      huddleCalendardate,
      this.state.localPracticeId
    )
      .then((result) =>
        this.setState({
          huddles: result.data.data,
        })
      )
      .catch((e) => {
        toast();
      });
  }

  getEmployees() {
    EmployeeService.getPracticeEmployees(this.state.localPracticeId)
      .then((result) =>
        this.setState({
          employees: result.data.data,
        })
      )
      .catch((e) => {
        toast(console.log(e));
      });
  }

  getDailyNotesCategories(key) {
    var date1 = new Date(this.state.huddleTasksTodaysDate);
    if (key == 1) {
      date1 = new Date(event.target.value);
    }
    this.setState({ huddleTasksTodaysDate: date1 });

    let huddleCalendardate =
      date1.getDate() +
      "/" +
      parseInt(date1.getMonth() + 1) +
      "/" +
      date1.getFullYear();

    DashboardService.getDailyNotesCategories(
      huddleCalendardate,
      this.state.localPracticeId
    )
      .then((result) =>
        this.setState({
          dailyNotesCategories: result.data.data,
        })
      )
      .catch((e) => {
        toast();
      });
  }

  handleSubmit = async (values) => {
    var payload = JSON.stringify(values);

    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

    await DashboardService.create(payload, this.state.localPracticeId)
      .then((result) => {
        let { items } = this.state;
        items = items.map((item) => ({
          ...item,
          showModal: false,
        }));
        this.setState({ dailyHuddleIdEditMode: "" });
        this.getHuddle(0);
        this.setState({ items });
        this.handleModalShowHide();
        if(values.dailyHuddleId == null){

        //   toast(
        //   <Notification type="success" message="Huddle added successfully." />
        // );
          /* Success Loader Called */
          loader.style.display='none';
          successloader.style.display='block';
          success_message.innerHTML="Huddle added successfully.";
          let timer =   setInterval(myTimer, 3000);
          function myTimer() {
              successloader.style.display='none';
              clearInterval(timer);  
          }
        }
        else{
          // toast(
          //   <Notification type="success" message="Huddle updated successfully." />
          // );

          /* Success Loader Called */
          loader.style.display='none';
          successloader.style.display='block';
          success_message.innerHTML="Huddle updated successfully.";
          let timer =   setInterval(myTimer, 3000);
          function myTimer() {
              successloader.style.display='none';
              clearInterval(timer);  
          }

        }
      })
      .catch((e) => {

        /* Fail Loader Called */
        loader.style.display='none';
        failloader.style.display='block';
        fail_message.innerHTML="Huddle record not saved successfully.";
        let timer =  setInterval(myTimer, 3000);
        function myTimer() {
            failloader.style.display='none';
            clearInterval(timer);   
        }

        toast();
      });
  };

  handleOnJobRoleUpdate = async () => {
    let data = {
      description: CommonHelper.convertEditorToHtml(this.state.editorState),
      title: this.txtTitle?.value,
      practiceId: this.state.localPracticeId,
      createdDate: this.state.createdDate,
    };

    let loader = document.getElementById('loader');
    let successloader = document.getElementById('successloader');
    let failloader = document.getElementById('failloader');
    let success_message = document.getElementById('success-message');
    let fail_message = document.getElementById('fail-message');
    loader.style.display='block';/* Loader Section Activited */

    await DashboardService.updateCriticalDrivers(
      data,
      this.state.localPracticeId,
      this.state.SelectedJobRoleId
    ).then((result) => {
      this.setState({
        ...this.state,
        ShowHideJobModel: false,
        showModal: false,
        showHide: false,
      });
      this.handleOnGetHuddleCategories();
      // toast(<Notification type="success" message="Updated successfully." />);

      /* Success Loader Called */
      loader.style.display='none';
      successloader.style.display='block';
      success_message.innerHTML="Updated successfully.";
      let timer =   setInterval(myTimer, 3000);
      function myTimer() {
          successloader.style.display='none';
          clearInterval(timer);  
      }

    }).catch((e) => {

      /* Fail Loader Called */
      loader.style.display='none';
      failloader.style.display='block';
      fail_message.innerHTML="Not Updated successfully.";
      let timer =  setInterval(myTimer, 3000);
      function myTimer() {
          failloader.style.display='none';
          clearInterval(timer);   
      }

      toast();
    });
  };

  handleOnGetHuddleCategories = async () => {
    await DashboardService.getCriticalDrivers(
      parseInt(this.state.localPracticeId)
    ).then((result) => {
      this.setState({ JoRoleInfo: result.data.data });
    });
  };

  //Dashboard bottom down 3 tasks
  SaveDailyHuddleOne = () => {
    if (this.taskTitleOne.value == "" || this.employeeIdOne.value == "") {
      toast(
        <Notification
          type="error"
          message="Please select Title and Employee."
        />
      );
      return false;
    }
    let data = {
      taskTitle: this.taskTitleOne.value,
      employeeId: this.employeeIdOne.value,
      createdOn: new Date(),
      isCompleted: this.taskIsCompletedOne.checked,
      practiceId: this.state.localPracticeId,
    };
    DailyTaskService.create(JSON.stringify(data)).then((result) => {
      toast(
        <Notification type="success" message="Task addded Successfully." />
      );
      this.dailyTasks(0);
      this.renderTasks();
    });
  };

  SaveDailyHuddleTwo = () => {
    if (this.taskTitleTwo.value == "" || this.employeeIdTwo.value == "") {
      toast(
        <Notification
          type="error"
          message="Please select Title and Employee."
        />
      );
      return false;
    }
    let data = {
      taskTitle: this.taskTitleTwo.value,
      employeeId: this.employeeIdTwo.value,
      createdOn: new Date(),
      isCompleted: this.taskIsCompletedTwo.checked,
      practiceId: this.state.localPracticeId,
    };

    DailyTaskService.create(JSON.stringify(data)).then((result) => {
      toast(
        <Notification type="success" message="Task addded Successfully." />
      );
      this.dailyTasks(0);
      this.renderTasks1();
    });
  };
  SaveDailyHuddleThree = () => {
    if (this.taskTitleThree.value == "" || this.employeeIdThree.value == "") {
      toast(
        <Notification
          type="error"
          message="Please select Title and Employee."
        />
      );
      return false;
    }
    let data = {
      taskTitle: this.taskTitleThree.value,
      employeeId: this.employeeIdThree.value,
      createdOn: new Date(),
      isCompleted: this.taskIsCompletedThree.checked,
      practiceId: this.state.localPracticeId,
    };

    DailyTaskService.create(JSON.stringify(data)).then((result) => {
      toast(
        <Notification type="success" message="Task addded Successfully." />
      );
      this.dailyTasks(0);
      this.renderTasks2();
    });
  };

  GetTasksById = (key) => {
    DashboardService.getDailyHuddlesById(this.state.dailyHuddleIdEditMode).then(
      (result) => {
        return result.data.data;
      }
    );
  };

  UpdateDailyTask = (key, isChecked) => {
    DailyTaskService.update(key, isChecked).then((result) => {
      toast(
        <Notification type="success" message="Task updated Successfully." />
      );
      this.dailyTasks(0);
      this.renderTasks();
    });
  };
   deleteRecord = async (id) => {
       const result = await confirm(
         "Are you sure? \nYou will not be able to recover this Task!"
       );
       if (result) {

        let loader = document.getElementById('loader');
        let successloader = document.getElementById('successloader');
        let failloader = document.getElementById('failloader');
        let success_message = document.getElementById('success-message');
        let fail_message = document.getElementById('fail-message');
        loader.style.display='block';/* Loader Section Activited */

          await DashboardService.delete(this.state.localPracticeId,id)
           .then((result) => {
            this.setState({ dailyHuddleIdEditMode: "" });
            this.getHuddle(0);
            //  toast(
            //    <Notification
            //      type="success"
            //      message="Task deleted successfully."
            //    />
            //  );

            /* Success Loader Called */
            loader.style.display='none';
            successloader.style.display='block';
            success_message.innerHTML="Task deleted successfully.";
            let timer =   setInterval(myTimer, 3000);
            function myTimer() {
                successloader.style.display='none';
                clearInterval(timer);  
            }
           })
           .catch((e) => {

            /* Fail Loader Called */
            loader.style.display='none';
            failloader.style.display='block';
            fail_message.innerHTML="Task not deleted successfully.";
            let timer =  setInterval(myTimer, 3000);
            function myTimer() {
                failloader.style.display='none';
                clearInterval(timer);   
            }

             toast(console.log(e));
           });
       }
     };
  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  renderTasks = () => {
    if (
      this.state.dailyTasks[0].taskTitle !== "" ||
      this.state.dailyTasks[0].taskTitle == "undefined"
    ) {
      return (
        <div className="input-group p-3">
          <div className="checkbox checkbox-primary m-2">
            <input
              type="hidden"
              ref={(c) => (this.taskIsCompletedOneId = c)}
              name="taskIsCompletedOneId"
              value={this.state.dailyTasks[0].id}
            ></input>
            <input
              ref={(c) => (this.taskIsCompletedOneChk = c)}
              name="taskIsCompletedOneChk"
              type="checkbox"
              className="styled"
              defaultChecked={this.state.dailyTasks[0].isCompleted}
            />
            <label htmlFor="taskIsCompletedOneChk" />
          </div>
          <label className="form-control rounded mr-2">

            {this.state.dailyTasks[0].taskTitle}
          </label>
          <div className="input-group-append">
            <select
              className="form-control"
              as="select"
              disabled
              name="employeeId1"
            >
              {this.state.employees
                .filter((t) => t.id == this.state.dailyTasks[0].employeeId)
                .map((item) => (
                  <option
                    key={uuidv4()}
                    className="form-control"
                    value={item.id}
                  >
                    {item.firstName}
                  </option>
                ))}
            </select>
            <button
              className="btn btn-primary ml-2 mr-2 rounded"
              type="button"
              onClick={() =>
                this.UpdateDailyTask(
                  this.taskIsCompletedOneId.value,
                  this.taskIsCompletedOneChk.checked
                )
              }
            >
              Save
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="input-group p-3">
          <div className="checkbox checkbox-primary m-2">
            <input
              ref={(c) => (this.taskIsCompletedOne = c)}
              type="checkbox"
              className="styled"
              defaultChecked={this.state.dailyTasks[0].isCompleted}
            />
            <label htmlFor="taskIsCompletedOneChk" />
          </div>

          <input
            type="text"
            className="form-control rounded  mr-2"
            ref={(c) => (this.taskTitleOne = c)}
            name="taskTitleOne"
            placeholder="Enter the task"
            aria-label="Enter the task"
            aria-describedby="basic-addon2"
          />

          <div className="input-group-append">
            <select
              className="form-control"
              as="select"
              ref={(c) => (this.employeeIdOne = c)}
              name="employeeIdOne"
            >
              <option value="">Select</option>
              {this.state.employees.map((option) => (
                <option
                  key={uuidv4()}
                  className="form-control"
                  value={option.id}
                >
                  {option.firstName}
                </option>
              ))}
            </select>
            <button
              className="btn btn-primary ml-2 mr-2 rounded"
              type="button"
              onClick={() => this.SaveDailyHuddleOne()}
            >
              Save
            </button>
            <button className="btn btn-secondary rounded" type="button">
              Cancel
            </button>
          </div>
        </div>
      );
    }
  };

  renderTasks1 = () => {
    if (
      this.state.dailyTasks[1].taskTitle !== "" ||
      this.state.dailyTasks[1].taskTitle == "undefined"
    ) {
      return (
        <div className="input-group p-3">
          <div className="checkbox checkbox-primary m-2">
            <input
              type="hidden"
              ref={(c) => (this.taskIsCompletedTwoId = c)}
              name="taskIsCompletedTwoId"
              value={this.state.dailyTasks[1].id}
            ></input>

            <input
              ref={(c) => (this.taskIsCompletedTwoChk = c)}
              name="taskIsCompletedTwoChk"
              type="checkbox"
              className="styled"
              defaultChecked={this.state.dailyTasks[1].isCompleted}
            />
            <label htmlFor="taskIsCompletedTwoChk" />
          </div>
          <label className="form-control rounded mr-2">
            {this.state.dailyTasks[1].taskTitle}
          </label>

          <div className="input-group-append">
            <select
              className="form-control"
              as="select"
              disabled
              name="employeeId2"
            >
              {this.state.employees
                .filter((t) => t.id == this.state.dailyTasks[1].employeeId)
                .map((item) => (
                  <option
                    key={uuidv4()}
                    className="form-control"
                    value={item.id}
                  >
                    {item.firstName}
                  </option>
                ))}
            </select>
            <button
              className="btn btn-primary ml-2 mr-2 rounded"
              type="button"
              onClick={() =>
                this.UpdateDailyTask(
                  this.taskIsCompletedTwoId.value,
                  this.taskIsCompletedTwoChk.checked
                )
              }
            >
              Save
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="input-group p-3">
          <div className="checkbox checkbox-primary m-2">
            <input
              ref={(c) => (this.taskIsCompletedTwo = c)}
              type="checkbox"
              className="styled"
              defaultChecked={this.state.dailyTasks[1].isCompleted}
            />
            <label htmlFor="checkbox0" />
          </div>
          <input
            type="text"
            className="form-control rounded  mr-2"
            ref={(c) => (this.taskTitleTwo = c)}
            name="taskTitleTwo"
            placeholder="Enter the task"
            aria-label="Enter the task"
            aria-describedby="basic-addon2"
          />

          <div className="input-group-append">
            <select
              className="form-control"
              as="select"
              ref={(c) => (this.employeeIdTwo = c)}
              name="employeeIdTwo"
            >
              <option value="">Select</option>
              {this.state.employees.map((option) => (
                <option
                  key={uuidv4()}
                  className="form-control"
                  value={option.id}
                >
                  {option.firstName}
                </option>
              ))}
            </select>
            <button
              className="btn btn-primary ml-2 mr-2 rounded"
              type="button"
              onClick={() => this.SaveDailyHuddleTwo()}
            >
              Save
            </button>
            <button className="btn btn-secondary rounded" type="button">
              Cancel
            </button>
          </div>
        </div>
      );
    }
  };
  renderTasks2 = () => {
    if (
      this.state.dailyTasks[2].taskTitle !== "" ||
      this.state.dailyTasks[2].taskTitle == "undefined"
    ) {
      return (
        <div className="input-group p-3">
          <div className="checkbox checkbox-primary m-2">
            <input
              type="hidden"
              ref={(c) => (this.taskIsCompletedThreeId = c)}
              name="taskIsCompletedThreeId"
              value={this.state.dailyTasks[2].id}
            ></input>

            <input
              ref={(c) => (this.taskIsCompletedThreeChk = c)}
              name="taskIsCompletedThreeChk"
              type="checkbox"
              className="styled"
              defaultChecked={this.state.dailyTasks[2].isCompleted}
            />
            <label htmlFor="taskIsCompletedThreeChk" />
          </div>
          <label className="form-control rounded mr-2">
            {this.state.dailyTasks[2].taskTitle}
          </label>
          <div className="input-group-append">
            <select
              className="form-control"
              as="select"
              disabled
              name="employeeId3"
            >
              {this.state.employees
                .filter((t) => t.id == this.state.dailyTasks[2].employeeId)
                .map((item) => (
                  <option
                    key={uuidv4()}
                    className="form-control"
                    value={item.id}
                  >
                    {item.firstName}
                  </option>
                ))}
            </select>
            <button
              className="btn btn-primary ml-2 mr-2 rounded"
              type="button"
              onClick={() =>
                this.UpdateDailyTask(
                  this.taskIsCompletedThreeId.value,
                  this.taskIsCompletedThreeChk.checked
                )
              }
            >
              Save
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="input-group p-3">
          <div className="checkbox checkbox-primary m-2">
            <input
              ref={(c) => (this.taskIsCompletedThree = c)}
              type="checkbox"
              className="styled"
              defaultChecked={this.state.dailyTasks[2].isCompleted}
            />
            <label htmlFor="checkbox0" />
          </div>
          <input
            type="text"
            className="form-control rounded  mr-2"
            ref={(c) => (this.taskTitleThree = c)}
            name="taskTitleThree"
            placeholder="Enter the task"
            aria-label="Enter the task"
            aria-describedby="basic-addon2"
          />
          <div className="input-group-append">
            <select
              className="form-control"
              as="select"
              ref={(c) => (this.employeeIdThree = c)}
              name="employeeIdThree"
            >
              <option value="">Select</option>
              {this.state.employees.map((option) => (
                <option
                  key={uuidv4()}
                  className="form-control"
                  value={option.id}
                >
                  {option.firstName}
                </option>
              ))}
            </select>
            <button
              className="btn btn-primary ml-2 mr-2 rounded"
              type="button"
              onClick={() => this.SaveDailyHuddleThree()}
            >
              Save
            </button>
            <button className="btn btn-secondary rounded" type="button">
              Cancel
            </button>
          </div>
        </div>
      );
    }
  };


  handleOnPdfPreview = (event) => {
    if (
      event.nativeEvent.target &&
      event.nativeEvent.target.classList.contains("ppreview")
    ) {
      let options = EnumHelper.PdfOption,
        boundingRect = document.getElementById("main").getBoundingClientRect();
      options["jsPDF"]["format"] = [
        boundingRect.width - 200,
        boundingRect.height - 100,
      ];

      html2pdf()
        .from(document.getElementById("main"))
        .set(options)
        .outputPdf()
        .then((pdf) => {
          this.setState({ pdfgenerate: btoa(pdf) });
          this.toggleModal();
        });
    } else if (this.state.showModal) {
      this.toggleModal();
    }
  };

  handleCriticalDriverPreview = (event) => {
    if (
      event.nativeEvent.target &&
      event.nativeEvent.target.classList.contains("imgprnt")
    ) {
      let options = EnumHelper.PdfOption,
        boundingRect = document
          .getElementById("crticalDrivers")
          .getBoundingClientRect();
      options["jsPDF"]["format"] = [
        boundingRect.width - 100,
        boundingRect.height,
      ];
      delete options["jsPDF"]["orientation"];
      html2pdf()
        .from(document.getElementById("crticalDrivers"))
        .set(options)
        .outputPdf()
        .then((pdf) => {
          this.setState({ pdfgenerate: btoa(pdf) });
          this.toggleModal();
        });
    } else if (this.state.showModal) {
      this.toggleModal();
    }
  };

  render() {
    const huddles = this.state.huddles;
    const notesCategories = this.state.dailyNotesCategories;
    const { editorState } = this.state;
    var curr =new Date();
    var huddleCategories = [
      "Front Desk Team",
      "Dental Assistant",
      "Clinicians",
      "Other Huddle Items",
    ];
    var initialValues = {
      title: "",
      dateOfHuddle: CommonHelper.getISODateinLocalTZ(curr),
      huddleCategory: "",
    };

    const disablePastDate = () => {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    };

    const validationSchema = Yup.object({
      numberPattern: Yup.string().matches(
        /[0-9]$/,
        "Please enter numbers only"
      ),
    });

    var initialValuesforPasswordChange = { newPassword: "", confirmPassword: ""};

    const validationSchemaforPasswordChange = Yup.object({
      newPassword: Yup.string().required("Password is required"),
      confirmPassword: Yup.string().when("newPassword", {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
              [Yup.ref("newPassword")],
              "New Password and Confirm Password must be same"
          )
      }).required("Confirm Password is Required")
  });



    return (
      <div id="main">            
        <Row>
          <Col className="pr-grid-col" xs={12} lg={8}>
            <Row className="gutter mb-4">
              <Col xs={12}>
                <Widget className="widget-p-none">
                <div className="row text-center" style={{justifyContent:"center"}}> 
                <div style={{paddingTop:"20px"}} className="col-md-10" >
                <button className="btn btn-primary rounded btnToday" type="submit" id="btnNewTask"  onClick={() =>this.onDateNavigationClicked("NXT-0")}>Today</button>
                </div>
                </div>
                  <div className={t.tableTitle+ " p-2"}>
                      
                      <div className="row text-center" style={{justifyContent:"center"}}>
                            <div className="col-md-3 p-0">
                              <div className="row" style={{justifyContent:"end",paddingRight:"10px"}}>
                                  <div className="col-md-1">
                                  {/* <span className="fa-layers fa-fw fa-main" onClick={() =>this.onDateNavigationClicked("NXT-0")}>
                                        <FontAwesomeIcon icon={faSquare} size="2x"  className="cal-icon-color"/>
                                        <span className={s.caltodayfont + " fa-layers-text fa-inverse fa-cal"} data-fa-transform="shrink-8 down-3" >Today</span>
                                    </span> */}
                                  </div>
                            <div className="col-md-2">
                                    <span className="fa-layers fa-fw fa-main" onClick={() =>this.onDateNavigationClicked("PRV-6MNT")}>
                                        <FontAwesomeIcon icon={faSquare} size="2x"  className="cal-icon-color"/>
                                        <span className="fa-layers-text fa-inverse fa-cal span-pointer text-dark" data-fa-transform="shrink-8 down-3" >6M</span>
                                    </span>
                                  </div>
                                  <div className="col-md-2">
                                    <span className="fa-layers fa-fw fa-main" onClick={() =>this.onDateNavigationClicked("PRV-3MNT")}>
                                        <FontAwesomeIcon icon={faSquare} size="2x"  className="cal-icon-color"/>
                                        <span className="fa-layers-text fa-inverse fa-cal span-pointer text-dark" data-fa-transform="shrink-8 down-3" >3M</span>
                                    </span>
                                  </div>
                                  <div className="col-md-2">
                                    <span className="fa-layers fa-fw fa-main" onClick={() =>this.onDateNavigationClicked("PRV-MNT")}>
                                        <FontAwesomeIcon icon={faSquare} size="2x" className="cal-icon-color" />
                                        <span className="fa-layers-text fa-inverse fa-cal span-pointer text-dark" data-fa-transform="shrink-8 down-3" >1M</span>
                                    </span>
                                  </div>
                                  <div className="col-md-2">
                                    <span className="fa-layers fa-fw fa-main" onClick={() =>this.onDateNavigationClicked("PRV-WEEK")}>
                                        <FontAwesomeIcon icon={faSquare} size="2x"  className="cal-icon-color"/>
                                        <span className="fa-layers-text fa-inverse fa-cal span-pointer text-dark" data-fa-transform="shrink-8 down-3" >1W</span>
                                    </span>
                                  </div>
                              </div>
                            </div>
                            <div className="col-md-3 p-0">
                                              <span>
                                              <FontAwesomeIcon icon={faCaretLeft} size="1x" onClick={() =>this.onDateNavigationClicked("PRV-DAY")} className="cal-icon-color mr-2 pre-nxt-day" />
                                                    {(this.state.huddleTasksTodaysDate.getDate() < 9
                                                    ? "0" + this.state.huddleTasksTodaysDate.getDate()
                                                    : this.state.huddleTasksTodaysDate.getDate()) +
                                                    "-" +
                                                    (parseInt(
                                                    this.state.huddleTasksTodaysDate.getMonth() + 1
                                                    ) < 10
                                                    ? "0" +
                                                    parseInt(
                                                    this.state.huddleTasksTodaysDate.getMonth() + 1
                                                    )
                                                    : parseInt(
                                                    this.state.huddleTasksTodaysDate.getMonth() + 1
                                                    )) +
                                                    "-" +
                                                    this.state.huddleTasksTodaysDate.getFullYear()}
                                                <FontAwesomeIcon icon={faCaretRight} size="1x" onClick={() =>this.onDateNavigationClicked("NXT-DAY")} className="cal-icon-color ml-2 pre-nxt-day" />
                                                </span><br></br>
                                      <div className="dash-calendar"> <DatePicker onChangeMethod={this.handleCalendarChangeStateValue} /> </div>  
                                
                            </div>
                            <div className="col-md-3 p-0">
                                <div className="row" style={{justifyContent:"start"}}>
                                {/* <div className="col-md-2">
                                      <span className="fa-layers fa-fw fa-main">
                                      </span>
                                  </div> */}
                                  <div className="col-md-2">
                                      <span className="fa-layers fa-fw fa-main" onClick={() =>this.onDateNavigationClicked("NXT-WEEK")}>
                                        <FontAwesomeIcon icon={faSquare} size="2x"  className="cal-icon-color" />
                                        <span className="fa-layers-text fa-inverse fa-cal span-pointer text-dark" data-fa-transform="shrink-8 down-3" >1W</span>
                                      </span>
                                  </div>
                                  <div className="col-md-2">
                                      <span className="fa-layers fa-fw fa-main" onClick={() =>this.onDateNavigationClicked("NXT-MNT")}>
                                        <FontAwesomeIcon icon={faSquare} size="2x"  className="cal-icon-color"/>
                                        <span className="fa-layers-text fa-inverse fa-cal span-pointer text-dark" data-fa-transform="shrink-8 down-3" >1M</span>
                                      </span>
                                  </div>
                                  <div className="col-md-2">
                                      <span className="fa-layers fa-fw fa-main" onClick={() =>this.onDateNavigationClicked("NXT-3MNT")}>
                                        <FontAwesomeIcon icon={faSquare} size="2x" className="cal-icon-color" />
                                        <span className="fa-layers-text fa-inverse fa-cal span-pointer text-dark" data-fa-transform="shrink-8 down-3" >3M</span>
                                      </span>
                                  </div>
                                  <div className="col-md-2">
                                      <span className="fa-layers fa-fw fa-main" onClick={() =>this.onDateNavigationClicked("NXT-6MNT")}>
                                        <FontAwesomeIcon icon={faSquare} size="2x"  className="cal-icon-color"/>
                                        <span className="fa-layers-text fa-inverse fa-cal span-pointer text-dark" data-fa-transform="shrink-8 down-3" >6M</span>
                                      </span>
                                  </div>
                                <div className="col-md-2">
                                      
                                  </div>
                                  <div className="col-md-2">
                                      <button className="btn btn-primary rounded newTask" type="submit" id="btnNewTask" onClick={() => {this.setState({taskCategory:"Front Desk Team"}); this.handleModalShowHide();}}>New Task</button>
                                  </div>
                                </div>
                            </div>
                      </div>
                  </div>
                  <div className="pl-4 pb-3 text-center">
                    <div className={s.headStyle}> "{this.state.quote}"</div>
                  </div>
                </Widget>
              </Col>

              <Col>
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <div className="d-flex flex-column align-items-center col-12 col-xl-12 p-sm-4 headline-2">
                    Today's Huddle
                  </div>
                </div>
                 <div className={s.tableTitle}>
                                    <Col>
                                        <DashboardSearchModal change = {this.handleState}></DashboardSearchModal>
                                    </Col>                
                                      </div>
              </Col>
            </Row>
            <Row className="gutter mb-4">
              <Col xs={6}>
                <Widget className="widget-p-none pb-3">
                  <div className="d-flex flex-wrap">
                    <div className="d-flex flex-column align-items-center col-12 col-xl-12 p-sm-4 headline-2">
                    <a onClick={() => {this.setState({taskCategory:"Front Desk Team"}); this.handleModalShowHide();}}>

                        Front Desk Team
                      </a>
                    </div>
                  </div>

                  {huddles
                    .filter((huddle) =>
                      huddle.huddleCategory.includes("Front Desk")
                    )
                    .map((item) => (
                      <div key={uuidv4()} className={`mt-3 ${s.widgetBlock}`}>
                        <div className={s.widgetBody}>
                          <div className="d-flex">
                            <div className="checkbox checkbox-primary">
                              <input
                                id={item.dailyHuddleId}
                                type="checkbox"
                                className="styled"
                                defaultChecked={item.isCompleted}
                                onChange={(event) =>
                                  this.changeCheck(
                                    event,
                                    "checkboxes",
                                    item.dailyHuddleId
                                  )
                                }
                              />
                              <label htmlFor="checkbox0" />
                            </div>
                            <div className="d-flex flex-column">
                              <p className="body-3 muted text-break">{item.title}</p>
                            </div>
                          </div>

                          <div className="body-3 muted">
                            <Link
                              to="#"
                              onClick={() =>
                                this.handleModalShowHide(
                                  true,
                                  item.dailyHuddleId
                                )
                              }
                              className={s.blackColor + " mr-1"}
                            >
                              <i className="fa fa-edit"></i>
                            </Link>
                            <Link
                              to="#"
                              onClick={() => this.deleteRecord(item.dailyHuddleId)}
                              className={s.blackColor}
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}

                  {huddles.filter((huddle) =>
                    huddle.huddleCategory.includes("Front Desk")
                  ).length == 0 ? (
                    <div className="alert alert-secondary text-primary m-3 color">
                      No records found
                    </div>
                  ) : (
                    ""
                  )}
                </Widget>
              </Col>
              <Col xs={6}>
                <Widget className="widget-p-none pb-3">
                  <div className="d-flex flex-wrap">
                    <div className="d-flex flex-column align-items-center col-12 col-xl-12 p-sm-4 headline-2">
                    <a onClick={() => {this.setState({taskCategory:"Dental Assistant"}); this.handleModalShowHide();}}>

                        Dental Assistant
                      </a>
                    </div>
                  </div>
                  {huddles
                    .filter((huddle) =>
                      huddle.huddleCategory.includes("Dental Assistant")
                    )
                    .map((item) => (
                      <div key={uuidv4()} className={`mt-3 ${s.widgetBlock}`}>
                        <div className={s.widgetBody}>
                        <div className="d-flex">
                          <div className="checkbox checkbox-primary">
                            <input
                              id={item.dailyHuddleId}
                              type="checkbox"
                              className="styled"
                              defaultChecked={item.isCompleted}
                              onChange={(event) =>
                                this.changeCheck(
                                  event,
                                  "checkboxes",
                                  item.dailyHuddleId
                                )
                              }
                            />
                            <label htmlFor="checkbox0" />
                          </div>
                            <div className="d-flex flex-column">
                              <p className="body-3 muted text-break">{item.title}</p>
                            </div>
                          </div>
                          <div className="body-3 muted">
                            <Link
                              to="#"
                              onClick={() =>
                                this.handleModalShowHide(
                                  true,
                                  item.dailyHuddleId
                                )
                              }
                              className={s.blackColor + " mr-1"}
                            >
                              <i className="fa fa-edit"></i>
                            </Link>
                            <Link
                              to="#"
                              onClick={() => this.deleteRecord(item.dailyHuddleId)}
                              className={s.blackColor}
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  {huddles.filter((huddle) =>
                    huddle.huddleCategory.includes("Dental Assistant")
                  ).length == 0 ? (
                    <div className="alert alert-secondary text-primary m-3 color">
                      No records found
                    </div>
                  ) : (
                    ""
                  )}
                </Widget>
              </Col>
            </Row>
            <Row className="gutter mb-4">
              <Col xs={6}>
                <Widget className="widget-p-none pb-3">
                  <div className="d-flex flex-wrap">
                    <div className="d-flex flex-column align-items-center col-12 col-xl-12 p-sm-4 headline-2">
                      <a onClick={() => {this.setState({taskCategory:"Clinicians"}); this.handleModalShowHide();}}>

                        Clinicians
                      </a>
                    </div>
                  </div>
                  {huddles
                    .filter((huddle) =>
                      huddle.huddleCategory.includes("Clinicians")
                    )
                    .map((item) => (
                      <div key={uuidv4()} className={`mt-3 ${s.widgetBlock}`}>
                      <div className={s.widgetBody}>
                      <div className="d-flex">
                          <div className="checkbox checkbox-primary">
                            <input
                              id={item.dailyHuddleId}
                              type="checkbox"
                              className="styled"
                              defaultChecked={item.isCompleted}
                              onChange={(event) =>
                                this.changeCheck(
                                  event,
                                  "checkboxes",
                                  item.dailyHuddleId
                                )
                              }
                            />
                            <label htmlFor="checkbox0" />
                          </div>
                            <div className="d-flex flex-column">
                              <p className="body-3 muted text-break">{item.title}</p>
                            </div>
                          </div>
                          <div className="body-3 muted">
                            <Link
                              to="#"
                              onClick={() =>
                                this.handleModalShowHide(
                                  true,
                                  item.dailyHuddleId
                                )
                              }
                              className={s.blackColor + " mr-1"}
                            >
                              <i className="fa fa-edit"></i>
                            </Link>
                            <Link
                              to="#"
                              onClick={() => this.deleteRecord(item.dailyHuddleId)}
                              className={s.blackColor}
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}

                  {huddles.filter((huddle) =>
                    huddle.huddleCategory.includes("Clinicians")
                  ).length == 0 ? (
                    <div className="alert alert-secondary text-primary m-3 color">
                      No records found
                    </div>
                  ) : (
                    ""
                  )}
                </Widget>
              </Col>
              <Col xs={6}>
                <Widget className="widget-p-none pb-3">
                  <div className="d-flex flex-wrap">
                    <div className="d-flex flex-column align-items-center col-12 col-xl-12 p-sm-4 headline-2">
                    <a onClick={() => {this.setState({taskCategory:"Other Huddle Items"}); this.handleModalShowHide();}}>

                        Other Huddle Items
                      </a>
                    </div>
                  </div>
                  {huddles
                    .filter((huddle) => huddle.huddleCategory.includes("Other"))
                    .map((item) => (
                      <div key={uuidv4()} className={`mt-3 ${s.widgetBlock}`}>
                      <div className={s.widgetBody}>
                      <div className="d-flex">

                          <div className="checkbox checkbox-primary">
                            <input
                              id={item.dailyHuddleId}
                              type="checkbox"
                              className="styled"
                              defaultChecked={item.isCompleted}
                              onChange={(event) =>
                                this.changeCheck(
                                  event,
                                  "checkboxes",
                                  item.dailyHuddleId
                                )
                              }
                            />
                            <label htmlFor="checkbox0" />
                          </div>
                            <div className="d-flex flex-column">
                              <p className="body-3 muted text-break">{item.title}</p>
                            </div>
                          </div>
                          <div className="body-3 muted">
                            <Link
                              to="#"
                              onClick={() =>
                                this.handleModalShowHide(
                                  true,
                                  item.dailyHuddleId
                                )
                              }
                              className={s.blackColor + " mr-1"}
                            >
                              <i className="fa fa-edit"></i>
                            </Link>
                            <Link
                              to="#"
                              onClick={() => this.deleteRecord(item.dailyHuddleId)}
                              className={s.blackColor}
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  {huddles.filter((huddle) =>
                    huddle.huddleCategory.includes("Other")
                  ).length == 0 ? (
                    <div className="alert alert-secondary text-primary m-3 color">
                      No records found
                    </div>
                  ) : (
                    ""
                  )}
                </Widget>
              </Col>
            </Row>
            <Row className="gutter mb-4">
              <Col xs={12}>
                <Widget className="widget-p-none">
                  {this.renderTasks()}

                  {this.renderTasks1()}

                  {this.renderTasks2()}
                </Widget>
              </Col>
            </Row>
          </Col>
          <Col className="mt-4 mt-lg-0 pl-grid-col" xs={12} lg={4}>
            <Widget className="widget-p-lg">
              {/* <div className={t.tableTitle}>
                ?menu
              </div> */}

              {/* <div className="row">
                <div className="col-8">

                  <a
                    className="mx-2 ppreview"
                    onClick={(e) => {
                      this.handleOnPdfPreview(e);
                    }}
                  >
                    <PreviewModal
                      isOpen={this.state.showModal}
                      url={this.state.pdfgenerate}
                    />
                    <img src={printerIcon} alt="Printer" />
                  </a>
                  <a
                    className="ppreview"
                    onClick={(e) => {
                      this.handleOnPdfPreview(e);
                    }}
                  >
                    <PreviewModal
                      isOpen={this.state.showModal}
                      url={this.state.pdfgenerate}
                    />
                    Preview
                  </a>
                  <a
                    onClick={() =>
                      PdfHelper.downloadPdf(
                        document.getElementById("main"),
                        "Dashboard"
                      )
                    }
                  >
                    <img className="mx-2" src={cloudIcon} alt="Download" />
                  </a>
                </div>
              </div> */}

              <div className="row">

              <div className="col-sm-12" >

                <ul>
                <LinksGroup
                    header="Labs"
                    isHeader
                    iconName={<FontAwesomeIcon icon={faSyringe} size="1x" />}
                    link="/app/lablist"
                    index="Labs"
                />

                <LinksGroup
                    header="Labwork"
                    isHeader
                    iconName={<FontAwesomeIcon icon={faTruckMedical} size="1x" />}
                    link="/app/labworklist"
                    index="Labwork"
                />



                <LinksGroup
                    header="Patient"
                    isHeader
                    iconName={<FontAwesomeIcon icon={faHospitalUser}  size="1x" />}
                    link="/app/patientlist"
                    index="Patient"
                />
                    {/* <li>  <Link to={"/app/lablist"}> <FontAwesomeIcon icon={faBriefcase} size="2x" /> <span>Labs</span></Link></li>
                    <li>  <Link to={"/app/labworklist"} ><FontAwesomeIcon icon={faBriefcaseMedical} size="2x" /> <span>LabWork</span></Link></li>
                    <li>  <Link to={"/app/patientlist"}> <FontAwesomeIcon icon={faPerson} size="2x" /> <span>Patients</span></Link></li> */}
                </ul>
              </div>
              </div>
             
              <hr />

              {notesCategories.map((option,index) => (
                <div key={uuidv4()}>
                  <label
                    className={s.headStyle}
                    id="cus-test"
                    htmlFor="basic-url"
                  >
                    {option.dailyNotesCategoryName}
                  </label>
                  <div className="input-group mb-3">
                    {option.dailyNotesCategoryName == "Follow up" ||
                    option.dailyNotesCategoryName == "Labwork" ? (
                      <textarea
                        rows="4"
                        id={"textbox_" + option.dailyNotesDetailsId}
                        name={option.dailyNotesDetailsId}
                        defaultValue={option.description}
                        className="form-control"
                        onBlur={this.dailyNotes}
                        aria-label="text input with checkbox"
                        data-index={index}
                      ></textarea>
                    ) : (
                      <input
                        type={
                          option.dailyNotesCategoryName == "New Patients" ||
                          option.dailyNotesCategoryName ==
                            "Call Conversions" ||
                          option.dailyNotesCategoryName == "Cancellations"
                            ? "number"
                            : "text"
                        }
                        min="0"
                        id={"textbox_" + option.dailyNotesDetailsId}
                        name={option.dailyNotesDetailsId}
                        defaultValue={option.description}
                        className="form-control"
                        onBlur={this.dailyNotes}
                        aria-label="text input with checkbox"
                        data-index={index}
                      />
                    )}

                    <div className="input-group-append">
                      <div className="input-group-text">
                        <input
                          type="checkbox"
                          id={"checkbox_" + option.dailyNotesDetailsId}
                          name={option.dailyNotesDetailsId}
                          defaultChecked={option.isCompleted}
                          onChange={(event) => this.dailyNotes(event)}
                          aria-label="checkbox for following text input"
                          data-index={index}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <hr />
              <select
                name="jobRole"
                className="form-control"
                onChange={(event) =>
                  this.handleModalShowHideJob(event.target?.value)
                }
              >
                <option
                  key={uuidv4()}
                  className="form-control"
                  defaultValue={"Non-Negotiables"}
                >
                  {"Non-Negotiables"}
                </option>
                {this.state.JoRoleInfo?.map((option) => (
                  <option
                    key={uuidv4()}
                    className="form-control"
                    value={option.id}
                  >
                    {option.title}
                  </option>
                ))}
              </select>

              <div className="row">
                <div className="col-12 text-center mt-4">

                  <a
                    className="mx-2 ppreview"
                    onClick={(e) => {
                      this.handleOnPdfPreview(e);
                    }}
                  >
                    <PreviewModal
                      isOpen={this.state.showModal}
                      url={this.state.pdfgenerate}
                    />
                    <img src={printerIcon} alt="Printer" />
                  </a>
                  <a
                    className="ppreview"
                    onClick={(e) => {
                      this.handleOnPdfPreview(e);
                    }}
                  >
                    <PreviewModal
                      isOpen={this.state.showModal}
                      url={this.state.pdfgenerate}
                    />
                    Preview
                  </a>
                  <a
                    onClick={() =>
                      PdfHelper.downloadPdf(
                        document.getElementById("main"),
                        "Dashboard"
                      )
                    }
                  >
                    <img className="mx-2" src={cloudIcon} alt="Download" />
                  </a>
                </div>
              </div>
            </Widget>
          </Col>
        </Row>
        <Modal show={this.state.ShowHideJobModel} id="crticalDrivers">
          <Modal.Header
            closeButton
            onClick={(e) => this.handleModalShowHideJob(0)}
            className={s.justifyeven}
          >
            <Modal.Title>
              Details
              <a
                className="headline-2"
                onClick={(e) => {
                  this.handleCriticalDriverPreview(e);
                }}
              >
                <PreviewModal
                  isOpen={this.state.showModal}
                  url={this.state.pdfgenerate}
                />
                <img src={printerIcon} alt="Printer" className="imgprnt" />
              </a>
            </Modal.Title>
            {UserHelper.IsPracticeAdmin() ? (
              <a
                className={s.moveright + " btn btn-primary btn-small"}
                onClick={() => this.handleOnJobRoleUpdate()}
              >
                Save
              </a>
            ) : (
              ""
            )}
          </Modal.Header>
          <Modal.Body>
            {UserHelper.IsPracticeAdmin() ? (
              <>
                <h6>Title</h6>
                <input
                  type="text"
                  ref={(c) => (this.txtTitle = c)}
                  name="txtTitle"
                  className="form-control "
                  placeholder="Title"
                  defaultValue={this.state.editorTitle}
                />
                <hr />
                <Editor
                  key={uuidv4()}
                  wrapperClassName="wyswing-wrapper"
                  editorState={editorState}
                  toolbar={{
                    options: ["inline", "list"],
                    inline: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ["bold", "italic", "underline"],
                    },
                  }}
                  onEditorStateChange={this.onEditorStateChange}
                />
              </>
            ) : (
              this.state.JoRoleInfo.filter(
                (x) => x.id == this.state.SelectedJobRoleId
              ).map((y) => (
                <div key={uuidv4()}>
                  <h6>Title : {y.title}</h6>
                  <hr />
                  <p dangerouslySetInnerHTML={{ __html: y.description }}></p>
                </div>
              ))
            )}
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showHide}>
          <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            <Modal.Title className="headline-2">{this.state.HuddleTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            {() => this.handleModalShowHide()}
            <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
              {({ errors, touched, isSubmitting, setFieldValue }) => {
                useEffect(() => {
                  const fields = [
                    "dailyHuddleId",
                    "title",
                    "dateOfHuddle",
                    "huddleCategory",
                  ];

                  

                  if (
                    this.state.dailyHuddleIdEditMode != undefined &&
                    this.state.dailyHuddleIdEditMode != "" &&
                    this.state.dailyHuddleIdEditMode != null
                  ) {
                      this.setState({HuddleTitle:"Edit Task"});
                    DashboardService.getDailyHuddlesById(
                      this.state.dailyHuddleIdEditMode
                    ).then((result) => {
                      fields.forEach((field) =>{
                        if(field == "dateOfHuddle"){
                          setFieldValue(field, result.data.data[field].substr(0,10), false)
                        }else{
                          setFieldValue(field, result.data.data[field], false)
                        }
                      }
                      );
                    });
                  }else{
                    this.setState({HuddleTitle:"Add Task"});
                    fields.forEach((field) =>{
                      if(field == "huddleCategory"){
                        setFieldValue(field, this.state.taskCategory, false)
                      }else if(field == "dateOfHuddle"){

                          if(this.state.huddleTasksTodaysDate < (new Date())){
                          setFieldValue(field, CommonHelper.getISODateinLocalTZ(new Date()), false)
                        }else{
                          setFieldValue(field, CommonHelper.getISODateinLocalTZ(this.state.huddleTasksTodaysDate), false)
                        }
                        
                      }
                    });
                  }
                }, []);
                return (
                  <Form id="form-performance">
                    <div className="row1">
                      <div className="form-group row">
                        <label
                          className="col-sm-3 col-form-label"
                          htmlFor="dateOfHuddle"
                        >
                          Date
                        </label>
                        <div className="col-sm-9">
                          
                  {(this.state.dailyHuddleIdEditMode != undefined && this.state.dailyHuddleIdEditMode != "" && this.state.dailyHuddleIdEditMode != null) ?  
                    <Field
                    className="form-control"
                    type="date"
                    data-date-format="DD-MM-YYYY"
                    name="dateOfHuddle"
                    id="dateOfHuddle"
                   /> 
                    : 
                    <Field      
                    className="form-control"
                    type="date"
                    data-date-format="DD-MM-YYYY"
                    min={disablePastDate()}
                    name="dateOfHuddle"
                    id="dateOfHuddle"
                    />
                } 
                       
                          <ErrorMessage
                            name="dateOfHuddle"
                            component={TextError}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3 col-form-label"
                          htmlFor="huddleCategory"
                        >
                          Category
                        </label>
                        <div className="col-sm-9">
                          <Field
                            className="form-control"
                            as="select"
                            id="huddleCategory"
                            name="huddleCategory"
                          >
                            <option
                              key={uuidv4()}
                              className="form-control"
                              value={this.state.taskCategory}
                            >
                              {this.state.taskCategory}
                            </option>

                            {huddleCategories.filter(category => (!category.includes(this.state.taskCategory))).map((option) => (
                              <option
                                key={uuidv4()}
                                className="form-control"
                                value={option}
                              >
                                {option}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="huddleCategory"
                            component={TextError}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3 col-form-label"
                          htmlFor="title"
                        >
                          Title
                        </label>
                        <div className="col-sm-9">
                          <Field
                            as="textarea"
                            className="form-control"
                            type="textArea"
                            name="title"
                            id="title"
                            rows="4"
                            cols="50"
                          ></Field>
                          <ErrorMessage name="title" component={TextError} />
                        </div>
                      </div>
                    </div>
                    <div className="form-buttons offset-md-2">
                      <button
                        className="mr-3 btn btn-primary"
                        type="submit"
                        id="btnSubmit"
                      >
                        Save
                      </button>
                      <Button
                        className=" mr-3 btn btn-secondary"
                        onClick={() => this.handleModalShowHide()}
                      >
                        Close
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.viewPopup}>
          <Modal.Header className="justify-content-center">
            <Modal.Title className="headline-2">Password Reset</Modal.Title>
          </Modal.Header>
          <Modal.Footer className="justify-content-center">
          <div>
            <div className="card">
                {/* <h5 className="card-header text-center"> Change Password</h5> */}
                <div className="card-body">
                    <Formik initialValues={initialValuesforPasswordChange} validationSchema={validationSchemaforPasswordChange} onSubmit={this.handleChangePassword}>
                        <Form id="form-employee">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="password">New Password</label>
                                        <div className="col-sm-9">
                                            <Field placeholder="New Password" className="form-control" type="password" name="newPassword" id="newPassword" />
                                            <ErrorMessage name="newPassword" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="confirmPassword">Confirm Password</label>
                                        <div className="col-sm-9">
                                            <Field placeholder="Confirm Password" className="form-control" type="password" name="confirmPassword" id="confirmPassword" />
                                            <ErrorMessage name="confirmPassword" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-buttons text-center">
                                <button className="mr-3 btn btn-primary" type="submit" id="btnSubmit">Save</button>
                            </div>


                        </Form>
                    </Formik>
                </div>
            </div>

        </div>
        </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
