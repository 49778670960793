import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import { changeActiveSidebarItem } from "../actions/navigation.js";
import { logoutUser } from "../actions/auth.js";
import Logo from "../Icons/SidebarIcons/SofiaLogo.js";


class Sidebar extends React.Component {
  
    static propTypes = {
        sidebarOpened: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        activeItem: PropTypes.string,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
    };

    static defaultProps = {
        activeItem: ""
    }

    constructor(props) {
        super(props);

        this.doLogout = this.doLogout.bind(this);
        this.renderNav = this.renderNav.bind(this);
        this.renderLogo = this.renderLogo.bind(this);

        this.state = {
            role : localStorage.getItem('role')
        };
    }



    componentDidMount() {
        this.element.addEventListener('transitionend', () => {
            if (this.props.sidebarOpened) {
                this.element.classList.add(s.sidebarOpen);
            }
        }, false);
    }

    componentDidUpdate(prevProps) {

        if (this.props.sidebarOpened !== prevProps.sidebarOpened) {
            if (this.props.sidebarOpened) {
                this.element.style.height = `276px`;
            } else {
                this.element.classList.remove(s.sidebarOpen);
                setTimeout(() => {
                    this.element.style.height = '';
                }, 0);
            }
        }
    }
    renderLogo() {
        let practicelogo = localStorage.getItem('practicelogo');
        let practiceId = localStorage.getItem('practiceId');
        let role = localStorage.getItem('role');
        if (role != "Admin") {

            return <div className="d-flex justify-content-center"><img src={practicelogo} className={s.practicelogo} /> </div>
           // return <img src={practicelogo} alt="practicelogo"/>
        }
    }
    renderNav() {
        let practiceId = localStorage.getItem('practiceId');
        let role = localStorage.getItem('role');
        let practicelogo = localStorage.getItem('practicelogo');
        //let imageUrl = "208.109"

        if (role == "Admin") {
            return <ul className={s.nav}>
                  <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Dashboard"
                    isHeader
                    iconName={<i className={'eva eva-layers-outline'} />}
                    link="/app/dashboardAdmin"
                    index="Dashboard"
                />
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Practice"
                    isHeader
                    iconName={<i className={'eva eva-grid-outline'} />}
                    link="/app/practicelist"
                    index="Practice"
                />
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Team Members"
                    isHeader
                    iconName={<i className={'eva eva-text-outline'} />}
                    link="/app/employeelist"
                    index="Team Members"
                />
            </ul>
        }
        else {
            return <ul className={s.nav}>
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Daily Huddle"
                    isHeader
                    iconName={<i className={'eva eva-home-outline'} />}
                    link="/app/dashboard"
                    index="Dashboard"
                />
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Team Members"
                    isHeader
                    iconName={<i className={'eva eva-text-outline'} />}
                    link="/app/employeelist"
                    index="Team Members"
                />
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Practice"
                    isHeader
                    iconName={<i className={'eva eva-grid-outline'} />}
                    link="/app/practicelist"
                    index="Practice"
                />
                 <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Patient SMS"
                    isHeader
                    iconName={<i className={'eva eva-message-circle-outline'} />}
                    link="/app/chat/0"
                    index="Practice"
                />
            
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Social Media"
                    isHeader
                    iconName={<i className={'eva eva-globe-outline'} />}
                    link="/app/socialmedialist"
                    index="Practice"
                />
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Marketing"
                    isHeader
                    iconName={<i className={'eva eva-trending-up-outline'} />}
                    link="/app/marketinglist"
                    index="Marketing"
                />
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Team Meeting"
                    isHeader
                    iconName={<i className={'eva eva-people-outline'} />}
                    link="/app/teammeetinglist"
                    index="Team Meeting"
                />
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Resources"
                    isHeader
                    iconName={<i className={'eva eva-attach-outline'} />}
                    link="/app/resourcelist"
                    index="Practice"
                />
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Key Contacts"
                    isHeader
                    iconName={<i className={'eva eva-email-outline'} />}
                    link="/app/keycontactlist"
                    index="Contact"
                />
                {/* <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Patient"
                    isHeader
                    iconName={<i className={'eva eva-person-outline'} />}
                    link="/app/patientlist"
                    index="Patient"
                /> */}

                {/* <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Lab"
                    isHeader
                    iconName={<i className={'eva eva-briefcase-outline'} />}
                    link="/app/lablist"
                    index="Lab"
                />   */}

                {/* <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="LabWork"
                    isHeader
                    iconName={<i className={'eva eva-briefcase-outline'} />}
                    link="/app/labworklist"
                    index="LabWork"
                />   */}

                <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="School Terms"
                    isHeader
                    iconName={<i className={'eva eva-calendar-outline'} />}
                    link="/app/schoolterms"
                    index="SchoolTerms"
                />
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={this.props.activeItem}
                    header="Public Holidays"
                    isHeader
                    iconName={<i className={'eva eva-calendar-outline'} />}
                    link="/app/publicholidays"
                    index="PublicHolidays"
                />
               
            </ul>
        }
    }
    doLogout(id) {
        this.props.dispatch(logoutUser());
    } 


    render() {
                return (
            <nav className={s.root}
                ref={(nav) => {
                    this.element = nav;
                }}
            >
                <header className={s.logo}>
                    <Logo />
                </header>
                
                {this.renderLogo()}

                {this.renderNav()}
                
            </nav>
        );
    }
}

function mapStateToProps(store) {
    return {
        sidebarOpened: store.navigation.sidebarOpened,
        activeItem: store.navigation.activeItem,
    };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
