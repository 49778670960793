import { EditorState, convertToRaw } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import draftToHtml from 'draftjs-to-html';
import EnumHelper from "./enum-helper";
import moment from "moment";


class CommonHelper {

    static createEditorState = (text) => {
        const contentState = stateFromHTML(text);
        return EditorState.createWithContent(contentState);
    };

    static convertEditorToHtml = (editorState) => {
        return editorState && draftToHtml(convertToRaw(editorState?.getCurrentContent()));        
    };

    static async getBase64(file) {
        return new Promise(resolve => {
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    static getMonthDateRangeString(month) {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), month, 1);
        var lastDay = new Date(date.getFullYear(), month + 1, 0);
        return this.formatDate(firstDay) + ' - ' + this.formatDate(lastDay);
    }

    static getMonthsIsoDate(month) {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), month, 5);
        return firstDay.toISOString();
    }

    static padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    static formatDate(date) {
        date=new Date(date);
        return [
            this.padTo2Digits(date.getDate()),
            this.padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('-');
    }

    static ToDateFormat(date,format){
        var d = new Date(date);
        d = moment(date).format(format);
        return d;
    }

    static getCurrnetUserId(){
        return localStorage.getItem("employeeId");
    }

    static getMonthList() {
        var year = new Date().getFullYear();
        return ['January ' + year, 'February ' + year, 'March ' + year, 'April ' + year, 'May ' + year, 'June ' + year, 'July ' + year, 'August ' + year, 'September ' + year, 'October ' + year, 'November ' + year, 'December ' + year];
    }

    static getISODate(date) {
        if (date) {
            return new Date(date).toISOString();
        }
        else {
            return null;
        }
    }

    static bytesToMegaBytes(bytes) {
        return bytes / (1024 * 1024);
    }

    static isValidUploadfileSize(size) { 
        
        return (this.bytesToMegaBytes(size) < EnumHelper.AllowedFileSizes.uploadFiles);
    }

    static bindAttachment(logoPreview){
        if(logoPreview != null) {
            return <iframe width='700px' height='700px' src={logoPreview}>The “iframe” tag is not supported by your browser.</iframe>;
        }
        else{
            return <div></div>;
        }   
    }

    static getMIMEFromBase64(base64){
        if(base64.indexOf(",")>-1){
            return base64.split(",")[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];
        }        
    }

    static isFilePreviewPossible(base64){
        return EnumHelper.PreviewTypes.includes(this.getMIMEFromBase64(base64));
    }

    static downloadAttachment(base64){      
          

        let anchor=document.createElement('a');        
        anchor.href = base64;        
        document.body.appendChild(anchor);
        anchor.click();
        setTimeout(() => {
            anchor.remove();
        }, 100);
    }
    
    static DatePickerField = ({...props}) => {
        //const props="meetingDate";
         const { setFieldValue } = useFormikContext();
         const [field] = useField(props);
         return (
           <DatePicker
             {...field}
             {...props}
             className="form-control"
             dateFormat="dd-MM-yyyy"
             selected={(field.value && new Date(field.value)) || null}
             onChange={(val) => {
               setFieldValue(field.name, val);
             }}
           />
         );
       };

       static getDayAsString(dayOfWeek){

         // Array to store the names of the days
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

      // Get the day name from the array
      return daysOfWeek[dayOfWeek];

       }

        static  getISODateinLocalTZ(date) {
    
        //get local time in yyyy-mm-dd formate
        const offset = date.getTimezoneOffset();
        date = new Date(date.getTime() - (offset*60*1000));
        return date.toISOString().split('T')[0].toString();
    }

    static  getISODateTimeinLocalTZ(date) {
        const offset = date.getTimezoneOffset();
        date = new Date(date.getTime() - (offset*60*1000));
        return date;
    }
    
}

export default CommonHelper