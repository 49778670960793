import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import React, { useEffect, useState, useRef } from "react";
import {Sidebar,MessageSeparator,MessageFooterProps,InfoButton,Search,ExpansionPanel,unreadDot,ConversationHeader,ConversationList,Conversation,Avatar, MessageListContent} from '@chatscope/chat-ui-kit-react';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput
} from "@chatscope/chat-ui-kit-react";
import Avatar2 from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { faDisplay } from "@fortawesome/free-solid-svg-icons";
 import patientMessageService from "../../services/chat/patientMessage.service.js";
import PatientService from "./../../services/patient/patient.service"
import PracticeService from "./../../services/practice/practice.service";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import AddpatientModel from "./patient-add-popup.js"
import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { Formik, Field, Form, ErrorMessage } from "formik";
import TextError from "../../components/Error/TextError";
import { v4 as uuidv4 } from "uuid";
import "./chat.css";
import {range} from "lodash";


export default function Chat() {
  const practiceId = localStorage.getItem("practiceId");
  const { id } = useParams();
  const [conversations, setConversations] = useState([]);
  const [searchpatient, setsearchpatient] = useState("");
  const [messages, setmessages] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [HeadName,setHeadName]=useState("");
  const[patientId,setPatientId] = useState(0);
  const[patientdata,setpatientdata]=useState([]);
  const[practicedata,setpracticedata]=useState([]);
  const[sender,setSender] = useState(0);
  const[receiver,setReceiver] = useState(0);
  const[senderName,setsenderName] = useState("");
  const[TwillioNumber,setTwillioNumber] = useState("0");

  const[selectedTemplate,setSelectedTemplate] = useState(null);
  const[txtTemplate,setTxtTemplate] = useState("");
  const[txtTitle,setTxtTitle] = useState("");


  let pageNo = 1, pageSize = 10,searchTerm="";
  const patinetname=useRef("");
  let history = useHistory(); 
  const [show, setModalShow] = useState(false);
  const handleModalClose = () => {setModalShow(false); };
  const handleModalShow = () => {  
  setModalShow(true);
};

  const Conversations = async () => {
    var payload = { 
      "searchTerm": searchpatient, 
      "pageSize": pageSize, 
      "pageNumber": pageNo, 
  };

    await patientMessageService.ConversationList(practiceId,payload)
      .then((result) => {
        setConversations(result.data.data);
      })
      .catch((e) => {

        toast();
      });
  };

  const searchPatient = async (search) => {
    //console.log(searchpatient);
    if (search.length >= 2 || search.length==-1){
    setsearchpatient(search);
    Conversations();
    }
  }

const CancelTemplate = async () =>{
  setTxtTitle("") ;
setTxtTemplate("");
setSelectedTemplate(null); 
Templates();
}

 const SaveTemplate = async () =>{
  var payload = {
    Id: selectedTemplate != null ? JSON.parse(selectedTemplate).id : 0,
    Title: txtTitle,
    Message: txtTemplate,
    IsActive: true,
    PracticeId:practiceId
  };
  await patientMessageService.ManageTemplate(practiceId,payload)
.then((result) => {
//  console.log(result.data.data);
setTxtTitle("") ;
setTxtTemplate("");
setSelectedTemplate(null); 
Templates();

})
.catch((e) => {
  toast();
});

  }
 
  const AddCustomeTags = async (myValue) =>{

    var myField = document.getElementById('txtTemplate');

    if (document.selection) {
      myField.focus();
      sel = document.selection.createRange();
      sel.text = myValue;
  }
  // Microsoft Edge
  else if(window.navigator.userAgent.indexOf("Edge") > -1) {
    var startPos = myField.selectionStart; 
    var endPos = myField.selectionEnd; 

    myField.value = myField.value.substring(0, startPos)+ myValue 
           + myField.value.substring(endPos, myField.value.length); 

    var pos = startPos + myValue.length;
    myField.focus();
    myField.setSelectionRange(pos, pos);
  }
  //MOZILLA and others
  else if (myField.selectionStart || myField.selectionStart == '0') {
      var startPos = myField.selectionStart;
      var endPos = myField.selectionEnd;
      myField.value = myField.value.substring(0, startPos)
          + myValue
          + myField.value.substring(endPos, myField.value.length);
  } else {
      myField.value += myValue;
  }
  triggerEvent(myField,'input');
    
  }

function triggerEvent(el, type){
  if ('createEvent' in document) {
    // modern browsers, IE9+
    var e = document.createEvent('HTMLEvents');
    e.initEvent(type, false, true);
    el.dispatchEvent(e);
  } else {
    // IE 8
    var e = document.createEventObject();
    e.eventType = type;
    el.fireEvent('on'+e.eventType, e);
  }
}
  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    if (name !=""){
    return {
      sx: {
        bgcolor: stringToColor(name),
        marginRight:2
      },
      children: `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`,
    }};
  }

  function onTemplateClick(message){
    //setTemplateMsg(message);
  var msg =  message;
  //console.log(JSON.stringify(patientdata));
  msg= msg.replace("###PatientName###",patientdata.data.firstName);
  msg= msg.replace("###Practice###",practicedata.businessName);
  msg= msg.replace("###PracticeEmail###",practicedata.email);
  msg= msg.replace("###socialmedialink###",practicedata.facebookUrl + " - " +practicedata.instagramUrl);
   msg= msg.replace("###GoogleReview###",practicedata.googleReviewUrl);
   msg= msg.replace("###CancellationPolicy###",practicedata.cancellationPolicyUrl);


    setMsgInputValue(msg);
  }

const practiceData = async() => {

  PracticeService.get(practiceId).then((practice) => {
    setpracticedata(practice.data.data);
    setTwillioNumber(practice.data.data.twillioPhoneNumber)
  });
};

  const getMessageList = async (pid) => {

var PtId = 0 ;
if (pid == 0 && id > 0){
  PtId=id;
}
else{
  PtId=pid;
}
setMsgInputValue("");
await PatientService.get(PtId).then(result => { 
  setpatientdata(result.data);
  // const optionscc = Object.keys(patientdata.data).map(key => ({
  //   value: patientdata.data[key],
  //   label: key
  // }));

  // console.log("options"+JSON.stringify(optionscc));

 // console.log(JSON.stringify(result.data));
  setHeadName(result.data.data.firstName + " " +result.data.data.lastName);
  
}); 

   // setHeadName(conversations.find(x=>x._id==patinetId).name);
    setPatientId(PtId);

    var payload = {
      "PatientId":PtId,
      "PracticeId": practiceId,
      "Lastdate": "2023-05-08",
      "PageSize": pageSize,
      "PageNumber":pageNo
  } 

    await patientMessageService.MessageList(payload)
      .then((result) => {
        setmessages(result.data.data);
        //console.log("msglist"+JSON.stringify(result.data.data));
      })
      .catch((e) => {
        toast();
      });
  };

  const Templates = async () => {

    await patientMessageService.Templates(practiceId)
      .then((result) => {
        setTemplates(result.data.data);

      //  console.log(JSON.stringify(templates));
      })
      .catch((e) => {
        toast();
      });
  };

  // const optionscc = Object.keys(patientdata.data).map(key => ({
  //   value: patientdata[key],
  //   label: key
  // }));
  // const [selectedOption, setSelectedOption] = useState(null);
 
  const handleChange = (selectedOption) => {
    
     // console.log(`Selected id:`, selectedOption);
      var message = templates.find(x=>x.id==selectedOption.target.selectedIndex).message;
  //  console.log(message);
    setMsgInputValue(message);
  };
  const handleChange2 = (e) => {
    if(e.target.value == null || e.target.value == ""){
      setTxtTitle("") ;
      setTxtTemplate("");
      setSelectedTemplate(null);
    }
    else{
    setSelectedTemplate(e.target.value);
    setTxtTitle(JSON.parse(e.target.value)?.title) ;
    setTxtTemplate(JSON.parse(e.target.value)?.message);

  }
    console.log(e.target.value);
    // if (selectedOption) {
    // //// console.log(`Selected id:`, selectedOption);  
    // }
  };
      const inputRef = useRef(null);
      const senderRef = useRef(null);

        
      const idRef = useRef(7);
      const [loadingMore, setLoadingMore] = useState(false);
      const [msgInputValue, setMsgInputValue] = useState("");
      const[TemplateTitle,setTemplateTitle]=useState("");
      const[TemplateMsg,setTemplateMsg]=useState("");
      
      useEffect(() => {
      
        if(patientId == 0 && id> 0){
        setPatientId(id);
        getMessageList(id);
        
      }
        Conversations();
        Templates();
        practiceData();
        
        // if(id > 0 && patientId == null){

        //   getMessageList(id);
        // }
      
        const intervalId = setInterval(Conversations, 6000);
        
        return () => clearInterval(intervalId);
      },[]);
      //   if ( loadingMore === true ) {
      //     setTimeout(() => {
      //       const newConversations = [];
      
      //       // Add 4 conversations                      
      //       for(let i=0;i<4;i++) {
      //         newConversations.push({ _id: ++idRef.current, name: `Emily ${idRef.current}`, avatarSrc: "emilyIco" });
      //     }
      
      //       setConversations(conversations.concat(newConversations));
      //       setLoadingMore(false);
      
      //     }, 1500);
      //   }
      // },[loadingMore]);
        
      const onYReachEnd = () => setLoadingMore(true);

      const handleSend =async( message) => {
       // console.log("msg--->"+msgInputValue);
      //  console.log("sender--->"+senderName);
        handleModalClose();
        var payload = {
          PatientId: patientId,
          Message: msgInputValue,
          Receiver: "",
          Sender: "",
          PracticeId:practiceId,
          Direction:"outgoing",
          SenderName:senderName
        };
        await patientMessageService.SendSms(payload)
      .then((result) => {
      //  console.log(result.data.data);
        getMessageList(patientId);
        Conversations();
      })
      .catch((e) => {
        toast();
      });
       // setMessages([...messages,{ _id: `a-${lastIdRef.current}`, message, direction: 'outgoing' }]);
       // console.log("--->"+message);
        setMsgInputValue("");
        inputRef.current?.focus();
      };

  return (
    <div>
   
   {TwillioNumber ==null || TwillioNumber =="" ?(
   <div>
                
                <Modal.Body>
                <div className="card" >
            <div className="card-body">
                             
                                    <div className="row" style={{justifyContent:"center"}}>
                                       
    <b> Please contact The Daily Huddle team to enable Patient SMS service via email - "team@thedailyhuddle.online"</b>
                                      
                                    </div>
            </div>
        </div>
                </Modal.Body>
   </div>):(
<div className="col-md-12" style={{ height: "80%", overflow: "hidden" ,maxHeight: "calc(100vh - 210px)"}}>
  <MainContainer>
  
<div as = "ConversationList" className="col-md-3 p-0">
<div className="m-1">
<AddpatientModel change={getMessageList}></AddpatientModel>

  <Search placeholder="Search..."    onChange={(v) =>  searchPatient(v)} />
  </div>
  <ConversationList style={{ height:"700px"}} scrollable loadingMore={loadingMore} onYReachEnd={onYReachEnd}>
 
    {conversations.map( c => <Conversation key={c._id} name={c.name} info={c.info} lastSenderName={c.lastSenderName} unreadDot={c.isUnread} onClick={evt => getMessageList( c._id)}>
    <div as="Avatar" > 
    <span >
      <Avatar2 {...stringAvatar(c.name)} /></span> </div>
    </Conversation>)}
  </ConversationList>
  </div>
  <div className="col-md-5 p-0" style={{overflow: "hidden" ,maxHeight: "calc(100vh - 210px)",  overflowY: "scroll"}}>
  {patientId > 0 ? (
    <ChatContainer>

<ConversationHeader>
  <ConversationHeader.Back  onClick={(v)=> setmessages()}/>
 
     <div as="Avatar" >
      <Avatar2 {...stringAvatar(HeadName)} /> </div>
  <ConversationHeader.Content>
    <span
      style={{
        alignSelf: 'flex-center',
        color: '#ec1212'
      }}
    >
     <p> {HeadName} </p>
    </span>
  </ConversationHeader.Content>
</ConversationHeader>
    <MessageList 
                 >
      {messages?.map((m,i) => m.type === "separator" ? <MessageSeparator key={i} {...m.props} /> : 
      <Message key={i} {...m.props}  > <Message.Footer sender={m.props.model.sender} />  </Message>
      )}{/*sentTime={i} {...m.props.model.sentTime}*/}
    </MessageList>
      {/* <MessageInput placeholder="Type message here" attachButton="false" ref={inputRef} onSend={function Na(){console.log(inputRef.value);}} />   */}
      <div as={MessageInput}>
      <MessageInput placeholder="Type message here" attachButton="false" aria-multiline  onSend={handleModalShow} onChange={setMsgInputValue} value={msgInputValue} ref={inputRef} />
      {/* <input className="btn btn-secondary ml-5" title="Search"   type="button" value="Search Templates" onClick={(v)=>setMsgInputValue("DEmo Messafe <div> breaked Line </div><div> one line more </div>")}/>  &nbsp; */}

  </div>
  </ChatContainer>
):(
  <ChatContainer></ChatContainer>
)}
</div>
<div className="col-md-4 p-0">
  <Sidebar position="right">
  <ExpansionPanel
      close
      title="Templates Setting"
  >
      <div>
      
        <div className="col-md-12 form-group">
        <label className="col-form-label" htmlFor="email"> Templates </label>
        <select className="form-control" as="select" name="country" value={selectedTemplate}   onChange={handleChange2}>
          <option key = {uuidv4()}  className="form-control" value="">
                                            Add New Template
                                            </option>
                                        {templates.map((option) => {
                                         
                                          return(  <option id={option.id} key={uuidv4()} className="form-control" value={JSON.stringify(option)}>
                                          {option.title}
                                          </option>)
                                          
                                          })}
                                    </select>
                                    <label className="col-form-label" htmlFor="email"> Template Title </label> 
                                   <input type="text" className="form-control" value={txtTitle}   onChange={(e)=> setTxtTitle(e.target.value)}  />
      

<br/>
<p><b>Click on Custom Auto Populating text to add in Your Message</b></p>
 <input className="btn btn-secondary m-1 p-1" title="demo" type="button" value= {"Patient Name"} onClick={(v)=>AddCustomeTags("###PatientName###")}/>      
 <input className="btn btn-secondary m-1 p-1" title="demo" type="button" value= {"Practice"} onClick={(v)=>AddCustomeTags("###Practice###")}/>
 <input className="btn btn-secondary m-1 p-1" title="demo" type="button" value= {"Cancellation Policy"} onClick={(v)=>AddCustomeTags("###CancellationPolicy###")}/>      
 <input className="btn btn-secondary m-1 p-1" title="demo" type="button" value= {"Social Links"} onClick={(v)=>AddCustomeTags("###socialmedialink###")}/>      
 <input className="btn btn-secondary m-1 p-1" title="demo" type="button" value= {"Google Review"} onClick={(v)=>AddCustomeTags("###GoogleReview###")}/>      

     <br/>

     <label className="col-form-label" htmlFor="email">  Message </label><textarea id="txtTemplate" type="textArea" rows="3" value={txtTemplate} onChange={(e)=> setTxtTemplate(e.target.value)} className="form-control multiline" />
      
 <input className="btn btn-primary m-2 p-2 pr-3 pl-3" title="demo" type="button" value= "Save" onClick={(v)=>SaveTemplate()}/>      
 <input className="btn btn-secondary m-2 p-2 pr-3 pl-3" title="demo" type="button" value= "Cancle" onClick={(v)=>CancelTemplate()}/>      
    
         </div>
      </div> 
      </ExpansionPanel> 
    <ExpansionPanel
      open
      title="Message Templates"
    >
        {templates.map((item) => (
      
      <div className="col-md-12">
      <input className="btn btn-secondary mt-1 " style={{width: "100%"}}   title="Search"   type="button" value= {item.title} onClick={(v)=>onTemplateClick(item.message)}/>      
      </div>
      ))}

    </ExpansionPanel>

   
   
  </Sidebar>
</div>
  </MainContainer>
  <Modal show={show} onHide={handleModalClose} animation={false} size="l">
                <Modal.Header closeButton>
                    {/* <Modal.Title>Add Patient </Modal.Title> */}
                    </Modal.Header>

                <Modal.Body>
                <div className="card" >
            <h5 className="card-header" >Sender Name</h5>
            <div className="card-body">
                             
                                    <div className="row">
                                        {/*Left Section: Presonal */}
                                        <div className="col-md-12">
                                            <div className="form-group">

                                                {/* <label className="col-form-label" htmlFor="firstName">First Name </label>
                                                 <Field placeholder="Enter first name" className="form-control" type="text" name="firstName" /> 
                                                <ErrorMessage name="firstName" component={TextError} /> */}
   <div as={MessageInput}> <MessageInput placeholder="Enter Team Member Name" attachButton="false" aria-multiline  onSend={handleSend} onChange={setsenderName} value={senderName} ref={senderRef} />
                                               
   </div>        
                                            </div>
                                        </div>
                                       
                                      
                                    </div>
                                    {/* <div className="form-buttons">
                                        <button className="m-1 btn btn-primary" type="submit" id="btnSubmit">Send Message</button>
                                    </div> */}
                                   
                       
                 
            </div>
        </div>
              
                    
                </Modal.Body>
            </Modal>          
</div>
)}
</div>);
  
}