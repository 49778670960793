import http from "../../config/http-common";

class patientService {   
    
    async create(data) {
        return await http.post("patient?Id=0", data);
    }
    async delete(id) {
        return await http.delete(`patient/${id}`);
    }
    async update(id,data) {
        return await http.post(`patient?Id=${id}`, data);
    }
    async get(id) {
        return await http.get(`patient/${id}`);
    }
    async search(data) {
        return await http.post(`patient/search`,data);
    }
    async uploadPatinet(data){
        return await http.post(`patient/UploadPatinetFile`,data);
    }
}

export default new patientService();